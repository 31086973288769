import React from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import moment, { max, min } from 'moment';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  ReferenceLine,
} from 'recharts';
import { theme } from 'theme';

import { Typography } from 'components/Typography';

import { useCoreApi } from 'hooks/useApi';

import { capitalize, formatNumber, splitIdNameStr } from 'utils/formatting';

interface ChartDataRecord {
  item?: number;
  all?: number;
  current?: number;
  smart?: number;
  /** unix ms */
  date: number;
}

export const LeadTimeHistoryChart = ({
  itemId,
  itemCode,
  supplier,
  location,
  currentLeadTime,
  smartLeadTime,
}: {
  itemId: string;
  itemCode: string;
  supplier: string;
  location: string;
  currentLeadTime: number;
  smartLeadTime: number;
}) => {
  const { foreignId: supplierId } = splitIdNameStr(supplier);
  const { foreignId: locationId } = splitIdNameStr(location);
  const { data: supplierData } = useCoreApi(schemas.purchasing.getLeadTimeHistoryV2, {
    queryParams: {
      limit: 1000,
      filter: {
        locationId,
        supplierId,
      },
    },
  });
  const { data: itemData } = useCoreApi(schemas.purchasing.getLeadTimeHistoryV2, {
    queryParams: {
      filter: {
        itemId,
        locationId,
        supplierId,
      },
    },
  });
  const data: ChartDataRecord[] = [
    ...(itemData?.items ?? []).map((value) => ({
      item: Number(value.leadTime),
      date: moment(value.orderedAt).valueOf(),
    })),
    ...(supplierData?.items ?? [])
      .filter((value) => value.itemId !== itemId)
      .map((value) => ({
        all: Number(value.leadTime),
        date: moment(value.orderedAt).valueOf(),
      })),
    {
      current: currentLeadTime,
      date: moment().valueOf(),
    },
    {
      smart: smartLeadTime,
      date: moment().valueOf(),
    },
  ];

  const minDate = max(
    itemData?.items.length ? min(itemData.items.map((value) => moment(value.orderedAt))) : moment().subtract(5, 'y'),
    min((supplierData?.items ?? []).map((value) => moment(value.orderedAt))),
  );

  const ticks = [];
  let date = moment(minDate.set('day', 1));
  while (moment() >= date) {
    ticks.push(date.valueOf());
    date = date.add(3, 'M');
  }
  const itemValues = (itemData?.items ?? []).map((value) => value.leadTime);
  const maxItem = Math.max(...itemValues);
  const minItem = Math.min(...itemValues);
  return (
    <div
      className={css`
        height: 100%;
        width: 100%;
      `}
    >
      <div
        className={css`
          text-align: center;
          margin-bottom: 4px;
        `}
      >
        Lead Time History for Supplier #{supplier} at Location {location}
      </div>
      <ResponsiveContainer
        width="100%"
        height={300}
        className={css`
          user-select: none;
        `}
      >
        <ScatterChart
          data={data}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          // @ts-expect-error Recharts typing doesn't include cursor even though it's supported
          cursor="crosshair"
        >
          <XAxis
            type="number"
            dataKey="date"
            tickFormatter={(value) => moment(value).format("MMM 'YY")}
            scale="time"
            ticks={ticks}
            allowDataOverflow
            domain={[minDate.subtract(1, 'M').valueOf(), moment().set('d', 1).add(1, 'M').valueOf()]}
          />
          <YAxis
            type="number"
            tickFormatter={formatNumber}
            domain={[0, (dataMax) => dataMax * 1.05]}
            allowDataOverflow
          />

          <Legend formatter={capitalize} />
          <Tooltip
            labelFormatter={(value) => moment(value).format('MMMM YYYY')}
            formatter={(value) => formatNumber(Number(value))}
            cursor={{ stroke: theme.colors.neutral[400] }}
            content={(e: TooltipProps) => {
              // We render a custom tooltip to remove the connection point between historical and forecasted data
              const { payload } = e;
              if (e.active && payload && payload.length) {
                return (
                  <div
                    className={css`
                      background-color: rgba(255, 255, 255, 0.9);
                      font-weight: 500;
                      border-radius: 8px;
                      border: 2px solid;
                    `}
                  >
                    <div
                      className={css`
                        padding: 6px;
                      `}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>
                        {payload[1].dataKey === 'all'
                          ? 'All Items'
                          : payload[1].dataKey === 'item'
                          ? `Item #${itemCode}`
                          : capitalize(String(payload[1].dataKey))}
                      </Typography>
                      <Typography>{moment(payload[1].payload.date).format('MMM YYYY')}</Typography>
                      <Typography>{formatNumber(payload[1].value as number)} days</Typography>
                    </div>
                  </div>
                );
              }

              return null;
            }}
          />
          <Scatter
            name="All Items"
            dataKey="all"
            strokeWidth={2}
            fillOpacity={0.8}
            fill={theme.colors.neutral[300]}
            isAnimationActive={false}
          />
          <ReferenceLine y={minItem} stroke={theme.colors.neutral[500]} strokeDasharray="3 3" />
          <ReferenceLine y={maxItem} stroke={theme.colors.neutral[500]} strokeDasharray="3 3" />
          <Scatter
            name={`Item #${itemCode}`}
            dataKey="item"
            strokeWidth={2}
            fillOpacity={1}
            strokeDasharray="3 3"
            fill={theme.colors.neutral[500]}
            isAnimationActive={false}
          />

          <Scatter
            shape="square"
            legendType="square"
            dataKey="smart"
            strokeWidth={2}
            fill={theme.colors.chart.aloeGreen}
            stroke={theme.colors.chart.aloeGreen}
            isAnimationActive={false}
          />
          {currentLeadTime !== smartLeadTime && (
            <Scatter
              shape="square"
              legendType="square"
              dataKey="current"
              strokeWidth={2}
              fill={theme.colors.chart.malibuOrange}
              stroke={theme.colors.chart.malibuOrange}
              isAnimationActive={false}
            />
          )}
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};
