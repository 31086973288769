import React from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';

import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { linkablePhoneNumber, linkableEmail } from 'components/Links';
import { NewContactButton } from 'components/recipes/NewContactButton';

import { sortableIdColumn, sortableStringColumn } from 'utils/tables';

export const ContactsTable = ({
  customerId,
  customerName,
  customerCompanyId,
  showNewContactButton,
}: {
  customerId: string | undefined;
  customerName: string | undefined;
  customerCompanyId?: string | undefined;
  showNewContactButton: boolean;
}) => {
  const columns = [
    sortableIdColumn({
      title: 'Contact ID',
      dataIndex: 'contactId',
    }),
    sortableStringColumn({
      title: 'Contact Name',
      dataIndex: 'contactName',
    }),
    sortableStringColumn({
      title: 'Title',
      dataIndex: 'title',
    }),
    sortableStringColumn({
      title: 'Email',
      dataIndex: 'email',
      render: (email: string) => linkableEmail(email),
    }),
    sortableStringColumn({
      title: 'Phone',
      dataIndex: 'phone',
      render: (phone: string) => linkablePhoneNumber(phone),
    }),
    sortableStringColumn({
      title: 'Fax',
      dataIndex: 'fax',
    }),
  ];

  const tableProps = useCoreApiTableProps({
    schema: schemas.contacts.getContacts,
    queryParams: { filter: { customerId } },
  });

  return (
    <>
      {showNewContactButton ? (
        <div
          className={css`
            display: flex;
            flex-direction: row-reverse;
            margin-bottom: 10px;
          `}
        >
          <NewContactButton
            size="small"
            type="primary"
            initialValues={{
              customer: {
                foreignId: customerId || '',
                name: customerName || '',
                companyId: customerCompanyId || '',
              },
            }}
            onSubmit={tableProps.reload}
          />
        </div>
      ) : null}
      <AsyncTable
        tableProps={tableProps}
        columns={columns}
        rowKey="contactId"
        isLoading={tableProps.isLoading || tableProps.isReloading}
      />
    </>
  );
};
