import React from 'react';

import { useHistory } from 'react-router-dom';

import { HourglassOutlined, LeftOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import {
  PurchaseTargetLineStatus,
  PurchasingHubAndSpokeType,
  TenantFeatureFlag,
} from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { PurchaseOrderResponseDTO } from '@recurrency/core-api-schema/dist/purchaseOrders/createPurchaseOrder';
import { TransferOrderResponseDTO } from '@recurrency/core-api-schema/dist/transferOrders/createTransferOrder';
import { notification, Steps } from 'antd';
import { AxiosResponse } from 'axios';
import { colors } from 'theme/colors';

import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Container } from 'components/Container';
import { FlexSpace } from 'components/FlexSpace';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { ConfirmModal } from 'components/Modal/ConfirmModal';
import { PageHeader } from 'components/PageHeader';
import { openPdfInNewTab } from 'components/recipes/OpenPdfButton';
import { Tooltip } from 'components/Tooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { ConcurrentPromiseQueue, FulfilledResult, PromiseQueueState } from 'utils/concurrentPromiseQueue';
import { getErpName, pluralize } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes, useHashState } from 'utils/routes';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { getTenantConfiguration } from 'utils/tenantConf/tenantConf';
import { getTenantSetting, isHubAndSpokeEnabled } from 'utils/tenantSettings';
import { PdfDocumentType, track, TrackEvent, TransferCreationType } from 'utils/track';

import { PurchaseTargetLinesHashState, PurchasingLineInput, PurchasingStep } from 'types/hash-state';

import { DocSummaryType, GenericTargetLineDTO, GroupedPOSummary, TransferSummary } from '../types';
import {
  createPurchaseOrderBodyFromPOSummary,
  createTransferOrderBodyFromTransferSummary,
  getFinalizedPOSummaries,
  getLineKey,
  getMergedPurchaseLines,
  getSupplierLocationSettingFromList,
  getTransferSummaries,
  getUniqueSuppliersAndLocations,
  removeSuccessfulLinesFromPurchasingLinesById,
  updatePurchasingLinesWithUpdatedTransferSummary,
} from '../utils';
import { MarkSupplierLocationsAsReviewedButton } from './MarkSupplierLocationsAsReviewedButton';
import { PurchaseTargetLinesTable } from './PurchaseTargetLinesTable';
import { PurchasingFinalizeStep } from './PurchasingFinalizePage';
import { PurchasingOrdersStepPage } from './PurchasingOrdersStepPage';
import { usePurchasingStatuses } from './utils';

export function PurchaseTargetLinesPage() {
  const history = useHistory();
  const { activeTenant, activeUser } = useGlobalApp();
  const [hashState, updateHashState] = useHashState<PurchaseTargetLinesHashState>();
  const {
    supplierLocations = [],
    step: currentStep = PurchasingStep.AllLines,
    groupBuy = false,
    poFinalizeInputByKey = {},
    toFinalizeInputByKey = {},
    purchaseGroups,
    groupIds,
  } = hashState;
  const { uniqueLocations, uniqueSuppliers } = getUniqueSuppliersAndLocations(supplierLocations);
  /** Migrate all users off old hash state */
  if (hashState.company && !hashState.companyIds) {
    updateHashState({ company: undefined, companyIds: [hashState.company.foreignId] });
  }
  const { shouldCreateSpecialOrderLines, defaultStatusFilters } = usePurchasingStatuses();

  const isPurchasingHubAndSpoke =
    !!groupIds &&
    getTenantSetting(TenantSettingKey.FeaturePurchasingHubAndSpoke) ===
      PurchasingHubAndSpokeType.EnablePurchasingHubAndSpoke;

  const isPlanningHubAndSpoke = isHubAndSpokeEnabled();

  const useDefaultTransferDays = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.UseDefaultTranferDays,
  );

  const {
    data: supplierLocationSettingsData,
    isLoading: supplierLocationSettingsIsLoading,
    reload: reloadSupplierLocationSettings,
  } = useCoreApi(schemas.purchasing.getSupplierLocationSettingsV2, {
    queryParams: {
      supplierLocationIds: supplierLocations.map((sl) => `${sl.supplierId}|${sl.locationId}`),
    },
  });

  const {
    data: targetLinesData,
    isLoading: targetLinesIsLoading,
    error,
    reload: reloadTargetLines,
  } = useCoreApi(
    isPurchasingHubAndSpoke
      ? schemas.purchaseGroups.getPurchaseGroupsCombinedTargetLines
      : schemas.purchasing.getPurchaseTargetLines,
    supplierLocations.length > 0
      ? {
          queryParams: {
            filter: {
              companyIds: hashState.companyIds,
              ...(isPurchasingHubAndSpoke
                ? { groupIds }
                : { supplierLocationIds: supplierLocations.map((sl) => `${sl.supplierId}|${sl.locationId}`) }),
              // default to non-stock statuses if NonStock is not filtered on
              // filtering out NonStock drastically reduces the amount of data returned
              // for heitek, loading all lines with NonStock crashes core-api for certain supplier-location pairs
              statuses:
                hashState.statuses && hashState.statuses.includes(PurchaseTargetLineStatus.Nonstock)
                  ? hashState.statuses
                  : defaultStatusFilters,
            },
          },
        }
      : null,
  );

  const isLoading = targetLinesIsLoading || supplierLocationSettingsIsLoading;

  // initialize purchasingLinesById with lines recommended to buy
  const targetLinesDataItems: GenericTargetLineDTO[] | undefined = targetLinesData?.items;

  const purchasingLinesById: Obj<PurchasingLineInput> =
    hashState.purchasingLinesById ||
    (targetLinesDataItems
      ? Object.fromEntries(
          targetLinesDataItems
            .filter((line) => line.qtyToOrder > 0 || (shouldCreateSpecialOrderLines && line.qtyForSpecialOrders > 0))
            .map((line) => [
              getLineKey(line),
              {
                qtyToOrder: line.qtyToOrder,
                qtyForSpecialOrders: 0,
              },
            ]),
        )
      : {});

  const allLines = getMergedPurchaseLines(targetLinesData?.items || [], purchasingLinesById);
  const poSummaries = getFinalizedPOSummaries(
    allLines,
    supplierLocations,
    poFinalizeInputByKey,
    shouldCreateSpecialOrderLines,
    supplierLocationSettingsData,
  );
  const finalizedPOSummaries = poSummaries.filter((poSummary) => poSummary.totalLines > 0);

  const { data: transferDaysData } = useCoreApi(schemas.transferDays.getTransferDays);
  const transferSummaries = getTransferSummaries(allLines, transferDaysData?.items || [], toFinalizeInputByKey);

  const posTosSubmitTitle = [
    pluralize(finalizedPOSummaries.length, 'PO', 'POs', true),
    transferSummaries.length ? pluralize(transferSummaries.length, 'TO', 'TOs', true) : null,
  ]
    .filter(Boolean)
    .join(' and ');

  // if all values of a line are undefined or zero, remove from hash state
  // this removes any purchasing lines from purchasingLinesById state if there is no useful information in the line
  const handleUpdatePurchaseLines = (newPurchasingLinesById: Obj<PurchasingLineInput>) => {
    Object.entries(newPurchasingLinesById).forEach(([key, value]) => {
      if (Object.values(value).every((v) => v === 0 || v === undefined)) {
        delete newPurchasingLinesById[key];
      }
    });
    updateHashState({ purchasingLinesById: newPurchasingLinesById });
  };

  const handleTransferLinesChange = (newTransferSummary: TransferSummary) => {
    const purchaseLinesUpdated = updatePurchasingLinesWithUpdatedTransferSummary(
      newTransferSummary,
      purchasingLinesById,
    );
    if (purchaseLinesUpdated) {
      updateHashState({ purchasingLinesById });
    }
  };

  const handleGroupBuyChange = (newGroupBuy: boolean) => {
    const update: Partial<PurchaseTargetLinesHashState> = { groupBuy: newGroupBuy };
    if (newGroupBuy === true) {
      update.supplierLocations = supplierLocations.map((sl) => {
        // group all purchases by supplier to the first purchasing location
        const firstSupplierLocation = supplierLocations.find((sl1) => sl1.supplierId === sl.supplierId);
        return {
          ...sl,
          purchasingLocationId: firstSupplierLocation?.locationId,
          purchasingLocationName: firstSupplierLocation?.locationName,
        };
      });
    } else {
      // clear purchasing location fields when group buy is off
      update.supplierLocations = supplierLocations.map((sl) => ({
        ...sl,
        purchasingLocationId: undefined,
        purchasingLocationName: undefined,
      }));
    }
    updateHashState(update);
  };

  const goToStep = (step: PurchasingStep) => {
    updateHashState({ step });
  };

  const handlePOsAndTOsSubmit = async () => {
    const isBuyerFieldRequired = getTenantSetting(TenantSettingKey.FormPurchaseOrderBuyerRequired) ?? false;
    if (isBuyerFieldRequired && finalizedPOSummaries.length > 0 && !finalizedPOSummaries[0].buyer) {
      notification.error({
        key: 'BUYER_ID_VALIDATION_ERROR',
        message: 'Validation error: Buyer is required.',
      });
      return;
    }

    // Check if any supplier minimums were not hit
    const hasMinValuesNotHit = finalizedPOSummaries.some((poSummary) => {
      const supplierLocationSetting = getSupplierLocationSettingFromList(
        poSummary.supplier.foreignId,
        poSummary.purchasingLocation.foreignId,
        supplierLocationSettingsData,
      );
      return (
        supplierLocationSetting?.minTargetValue &&
        poSummary.currentValueForMinType != null &&
        poSummary.currentValueForMinType < supplierLocationSetting.minTargetValue
      );
    });

    if (hasMinValuesNotHit) {
      const didUserConfirm = await showAsyncModal(ConfirmModal, {
        title: 'Supplier Minimum Warning',
        content: (
          <div>
            At least one purchase order hasn't reached its supplier minimum, but can still be created. Are you sure you
            want to finish creating the PO(s)?
          </div>
        ),
        okText: `Send to ${getErpName(activeTenant.erpType)}`,
        okType: 'primary',
        cancelText: 'Cancel',
        width: 450,
        // This fixes the constant visible scroll bar
        className: css`
          .ant-modal-body {
            overflow-y: auto;
          }
        `,
      });

      if (!didUserConfirm) {
        return;
      }
    }

    const submitNotification = createSubmissionNotification({
      entityName: posTosSubmitTitle,
      expectedWaitSeconds: 60,
      erpType: activeTenant.erpType,
    });

    try {
      const purchaseOrderFormConfig = getTenantConfiguration(activeTenant).purchaseOrderEntryFlow.formConfig;
      const shouldCreateTransferBackOrders =
        getTenantSetting(TenantSettingKey.FeatureCreateTransferBackorders) ?? false;

      const docSummaries = [...finalizedPOSummaries, ...transferSummaries];
      const concurrentQueue = new ConcurrentPromiseQueue<
        GroupedPOSummary | TransferSummary,
        AxiosResponse<PurchaseOrderResponseDTO | TransferOrderResponseDTO>
      >({
        taskIntervalMs: 2_000, // wait 2 seconds between each task so we don't deadlock pending_imports table
        maxConcurrency: 5, // submit 5 POs/TOs at a time
        requests: docSummaries,
        promiseFn: (docSummary) =>
          docSummary.kind === DocSummaryType.TransferOrder
            ? coreApiFetch(schemas.transferOrders.createTransferOrder, {
                bodyParams: { ...createTransferOrderBodyFromTransferSummary(docSummary), useDefaultTransferDays },
              })
            : coreApiFetch(schemas.purchaseOrders.createPurchaseOrder, {
                bodyParams: createPurchaseOrderBodyFromPOSummary(
                  docSummary,
                  purchaseOrderFormConfig,
                  shouldCreateTransferBackOrders,
                  shouldCreateSpecialOrderLines,
                  groupBuy,
                ),
              }),
        onStateChange: (queueState) => {
          notification.info({
            key: submitNotification.notificationKey,
            message: `${posTosSubmitTitle} status`,
            description: (
              <FlexSpace
                direction="column"
                gap={8}
                className={css`
                  max-height: 50vh;
                  overflow-y: auto;
                `}
              >
                {queueState.map((task, idx) => (
                  <div key={idx}>
                    {task.request.kind === DocSummaryType.TransferOrder ? (
                      <div>
                        TO from Location #{task.request.sourceLocation.foreignId} to Location #
                        {task.request.destinationLocation.foreignId}:
                      </div>
                    ) : (
                      <div>
                        PO to Supplier #{task.request.supplier.foreignId} at Location #
                        {task.request.purchasingLocation.foreignId}:
                      </div>
                    )}

                    {task.state === PromiseQueueState.Queued ? (
                      <span>
                        Queued ... <HourglassOutlined />
                      </span>
                    ) : task.state === PromiseQueueState.Pending ? (
                      <span>
                        Submitting ... <LoadingOutlined />
                      </span>
                    ) : task.state === PromiseQueueState.Fulfilled ? (
                      (() => {
                        if (task.request.kind === DocSummaryType.TransferOrder) {
                          return (
                            <div>
                              Transfer Order #
                              <ActionButton
                                label={`${(task.response.data as TransferOrderResponseDTO).transferOrderId} `}
                                className={css`
                                  display: inline;
                                `}
                                onClick={() =>
                                  history.push(
                                    (task.response.data as TransferOrderResponseDTO).transferOrderId
                                      ? routes.purchasing.transferOrderDetails(
                                          (task.response.data as TransferOrderResponseDTO).transferOrderId!,
                                        )
                                      : routes.purchasing.transferOrderList(),
                                  )
                                }
                              />{' '}
                              created
                            </div>
                          );
                        }
                        // we should always have a purchaseOrderId if successful
                        // @ts-expect-error tsc v4.5 doesn't infer task.response, but 5.XX vscode version does
                        const purchaseOrderId = task.response.data.purchaseOrderId || '';
                        const linkedSalesOrderLines =
                          (task.response.data as PurchaseOrderResponseDTO).salesOrderLinked?.map(
                            (linkedSalesOrder) => linkedSalesOrder.salesOrderNo,
                          ) || [];
                        return (
                          <>
                            {linkedSalesOrderLines.length > 0 && (
                              <div
                                className={css`
                                  margin-bottom: 20px;
                                `}
                              >
                                Automatically linked to backorders on:{' '}
                                {linkedSalesOrderLines.map((salesOrderNo) => (
                                  <ActionButton
                                    key={salesOrderNo}
                                    label={`#${salesOrderNo}`}
                                    onClick={() => history.push(routes.orders.orderDetails(salesOrderNo.toString()))}
                                  />
                                ))}
                              </div>
                            )}
                            <FlexSpace gap={4}>
                              <ActionButton
                                label={`View PO #${purchaseOrderId}`}
                                onClick={() => history.push(routes.purchasing.purchaseOrderDetails(purchaseOrderId))}
                              />
                              |
                              <ActionButton
                                label="Open PDF"
                                onClick={() =>
                                  openPdfInNewTab({
                                    foreignId: purchaseOrderId,
                                    type: PdfDocumentType.PurchaseOrder,
                                  })
                                }
                              />
                            </FlexSpace>
                          </>
                        );
                      })()
                    ) : task.state === PromiseQueueState.Rejected ? (
                      <div>
                        <span
                          className={css`
                            white-space: pre-wrap;
                            color: ${colors.danger[500]};
                          `}
                        >
                          Error: {task.error.message}
                        </span>
                        <div>{task.request.kind} failed submission, try resubmitting again.</div>
                      </div>
                    ) : null}
                  </div>
                ))}
              </FlexSpace>
            ),
            duration: 0,
          });
        },
      });

      const results = await concurrentQueue.processQueue();
      const rejectedResults = results.filter((result) => result.state === PromiseQueueState.Rejected);
      const fulfilledPOResults = results.filter(
        (result) =>
          result.state === PromiseQueueState.Fulfilled && result.request.kind === DocSummaryType.PurchaseOrder,
      ) as FulfilledResult<GroupedPOSummary, PurchaseOrderResponseDTO>[];

      const fulfilledTOResults = results.filter(
        (result) =>
          result.state === PromiseQueueState.Fulfilled && result.request.kind === DocSummaryType.TransferOrder,
      ) as FulfilledResult<TransferSummary, TransferOrderResponseDTO>[];

      for (const result of fulfilledPOResults) {
        track(TrackEvent.Purchasing_EditPurchaseOrder_Submit, {
          numLineItems: result.request.totalLines,
          totalCost: result.request.totalPOCost,
        });
      }
      for (const result of fulfilledTOResults) {
        track(TrackEvent.Purchasing_EditTransferOrder_Submit, {
          type: TransferCreationType.PuchaseFlow,
          numLineItems: result.request.totalLines,
          totalCost: result.request.totalCost,
        });
      }

      // only go to purchaseOrderList page if all POs were successful and user hasn't navigated away
      if (rejectedResults.length === 0) {
        if (history.location.pathname === routes.purchasing.purchaseTargetLines()) {
          // show submitted purchase orders
          history.push(routes.purchasing.purchaseOrderList());
        }
      } else {
        // keep failed orders in hash state, so user can fix and retry
        updateHashState({
          purchasingLinesById: removeSuccessfulLinesFromPurchasingLinesById(
            purchasingLinesById,
            fulfilledPOResults,
            fulfilledTOResults,
          ),
        });
      }
    } catch (err) {
      submitNotification.error(err);
    }
  };

  // go back to purchase targets list page, if required hash state is missing
  if (supplierLocations.length === 0) {
    history.push(routes.purchasing.purchaseTargets());
    return <></>;
  }

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !targetLinesData) {
    return <CenteredLoader />;
  }

  return (
    <Container>
      {!isLoading && (
        <PageHeader
          title={
            isPurchasingHubAndSpoke && purchaseGroups ? (
              purchaseGroups?.length === 1 ? (
                <InfoTooltip
                  useInfoIcon
                  title={[
                    <div key="purchaseLocation">{purchaseGroups[0].purchaseLocationName}</div>,
                    purchaseGroups[0].spokeLocations?.map((sl, i) => <div key={i}>{sl.name}</div>),
                  ]}
                >
                  {purchaseGroups[0].groupName}
                </InfoTooltip>
              ) : (
                <InfoTooltip
                  useInfoIcon
                  title={purchaseGroups.map((g, i) => (
                    <div key={i}>{g.groupName}</div>
                  ))}
                >
                  {purchaseGroups.length} Groups
                </InfoTooltip>
              )
            ) : (
              <>
                {uniqueSuppliers.length === 1 ? (
                  uniqueSuppliers[0]
                ) : (
                  <InfoTooltip
                    useInfoIcon
                    title={uniqueSuppliers.map((supplierIdName, idx) => (
                      <div key={idx}>{supplierIdName}</div>
                    ))}
                  >
                    {uniqueSuppliers.length} Suppliers
                  </InfoTooltip>
                )}{' '}
                at{' '}
                <>
                  {uniqueLocations.length === 1 ? (
                    uniqueLocations[0]
                  ) : (
                    <InfoTooltip
                      useInfoIcon
                      title={uniqueLocations.map((locationIdName, idx) => (
                        <div key={idx}>{locationIdName}</div>
                      ))}
                    >
                      {uniqueLocations.length} Locations
                    </InfoTooltip>
                  )}
                </>
              </>
            )
          }
          entity={{ kind: `Purchase ${isPurchasingHubAndSpoke ? 'Groups' : 'Targets'}` }}
          headerActions={
            <>
              <MarkSupplierLocationsAsReviewedButton
                isPurchasingHubAndSpoke={isPurchasingHubAndSpoke}
                supplierLocations={poSummaries.map((po) => ({
                  locationId: po.purchasingLocation.foreignId,
                  supplierId: po.supplier.foreignId,
                }))}
              />

              {currentStep > PurchasingStep.AllLines && (
                <Button onClick={() => goToStep(currentStep - 1)}>
                  <LeftOutlined />
                  Previous
                </Button>
              )}
              {currentStep < PurchasingStep.Finalize && (
                <Button data-test-id="nextStepButton" type="primary" onClick={() => goToStep(currentStep + 1)}>
                  Next
                  <RightOutlined />
                </Button>
              )}
              {currentStep === PurchasingStep.Finalize && (
                <Tooltip
                  title={`${posTosSubmitTitle} will be sent to ${getErpName(
                    activeTenant.erpType,
                  )} and will no longer be editable in Recurrency.`}
                >
                  <AsyncButton
                    type="primary"
                    onClick={handlePOsAndTOsSubmit}
                    disabled={
                      !shouldShowFeatureFlag(
                        activeTenant,
                        activeUser,
                        TenantFeatureFlag.PurchasingCreatePurchaseOrder,
                      ) ||
                      (finalizedPOSummaries.length === 0 && transferSummaries.length === 0)
                    }
                  >
                    Send to {getErpName(activeTenant.erpType)}
                  </AsyncButton>
                </Tooltip>
              )}
            </>
          }
        />
      )}

      <Steps
        className={css`
          margin-bottom: 16px;
        `}
        current={currentStep}
        onChange={(newStep) => updateHashState({ step: newStep })}
      >
        <Steps.Step
          key={PurchasingStep.AllLines}
          title={
            <InfoTooltip
              placement="bottom"
              useInfoIcon
              title='This step lists all stockable and backorded Nonstock items by default. To view all non-backordered, Nonstock items, use the Status dropdown and select "Nonstock."'
            >
              All Lines
            </InfoTooltip>
          }
        />
        <Steps.Step
          key={PurchasingStep.PurchaseOrders}
          title={
            getTenantSetting(TenantSettingKey.FeatureCreateTransfers) ? 'Purchase & Transfer Orders' : 'Purchase Orders'
          }
        />
        <Steps.Step key={PurchasingStep.Finalize} title="Finalize" />
      </Steps>

      {currentStep === PurchasingStep.AllLines && (
        <PurchaseTargetLinesTable
          isPurchasingHubAndSpoke={isPurchasingHubAndSpoke}
          isPlanningHubAndSpoke={isPlanningHubAndSpoke}
          purchaseGroups={purchaseGroups}
          supplierLocations={supplierLocations}
          targetLines={targetLinesData.items}
          purchasingLinesById={purchasingLinesById}
          onPurchasingLinesChange={(newPurchasingLinesById) => handleUpdatePurchaseLines(newPurchasingLinesById)}
          onMinMaxChange={reloadTargetLines}
        />
      )}
      {currentStep === PurchasingStep.PurchaseOrders && (
        <PurchasingOrdersStepPage
          supplierLocations={supplierLocations}
          targetLines={allLines}
          purchasingLinesById={purchasingLinesById}
          transferSummaries={transferSummaries}
          groupBuy={groupBuy}
          isPurchasingHubAndSpoke={isPurchasingHubAndSpoke}
          isPlanningHubAndSpoke={isPlanningHubAndSpoke}
          purchaseGroups={purchaseGroups}
          onTransferLineChange={handleTransferLinesChange}
          onPurchasingLinesChange={(newPurchasingLinesById) => {
            updateHashState({ purchasingLinesById: newPurchasingLinesById });
          }}
          onSupplierLocationsChange={(newSupplierLocations) => {
            updateHashState({ supplierLocations: newSupplierLocations });
            reloadSupplierLocationSettings();
          }}
          onGroupBuyChange={handleGroupBuyChange}
          uniqueSuppliers={uniqueSuppliers}
          poSummaries={finalizedPOSummaries}
          supplierLocationSettingsData={supplierLocationSettingsData}
        />
      )}
      {currentStep === PurchasingStep.Finalize && (
        <PurchasingFinalizeStep
          poFinalizeInputByKey={poFinalizeInputByKey}
          toFinalizeInputByKey={toFinalizeInputByKey}
          poSummaries={finalizedPOSummaries}
          transferSummaries={transferSummaries}
          onPOFinalizeInputChange={(newFinalizeInputById) => {
            updateHashState({ poFinalizeInputByKey: newFinalizeInputById });
          }}
          onTOFinalizeInputChange={(newFinalizeInputById) => {
            updateHashState({ toFinalizeInputByKey: newFinalizeInputById });
          }}
          uniqueSuppliers={uniqueSuppliers}
          targetLines={allLines}
        />
      )}
    </Container>
  );
}
