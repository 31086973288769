import React from 'react';

import { Form, Checkbox } from 'antd';
import { FormItemProps } from 'antd/lib/form/FormItem';

export interface CheckboxFormItemProps extends FormItemProps {
  checkboxLabel?: React.ReactNode;
  disabled?: boolean;
}

export const CheckboxFormItem = ({ checkboxLabel, disabled = false, ...props }: CheckboxFormItemProps): JSX.Element => (
  <Form.Item {...props} valuePropName="checked">
    <Checkbox disabled={disabled}>{checkboxLabel}</Checkbox>
  </Form.Item>
);
