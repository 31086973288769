import React from 'react';

import { useHistory } from 'react-router';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { ValidationRuleTemplateDTO } from '@recurrency/core-api-schema/dist/validationRules/getValidationRuleTemplates';
import { Typography } from 'antd';
import { theme } from 'theme';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { FlexSpace } from 'components/FlexSpace';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { routes } from 'utils/routes';
import { createSubmissionNotification } from 'utils/submissionNotification';

export interface NewValidationRuleFromTemplateModalProps {
  onClose: () => void;
}

export const NewValidationRuleFromTemplateModal = ({ onClose }: NewValidationRuleFromTemplateModalProps) => {
  const [template, setTemplate] = React.useState<ValidationRuleTemplateDTO>();
  const [error, setError] = React.useState<string | undefined>();
  const { data: templates, isLoading } = useCoreApi(schemas.validationRules.getRuleTemplates, {});
  const history = useHistory();

  const submitTemplate = async () => {
    if (!template) {
      setError('A template selection is required');
      return;
    }
    const ruleCreationNotification = createSubmissionNotification({
      entityName: 'Rule',
      submittingMessage: `Creating draft rule from template ${template.name}`,
      expectedWaitSeconds: 5,
    });
    const result = await coreApiFetch(schemas.validationRules.createValidationRule, {
      queryParams: { templateId: template.templateId },
    });

    if (result.status !== 200) {
      ruleCreationNotification.error({ message: 'Failed to create rule' });
    } else {
      ruleCreationNotification.inProgress({ description: () => `Rule created, testing rule` });
      try {
        await coreApiFetch(schemas.validationRules.postValidationRuleDeployment, {
          pathParams: {
            ruleId: result.data.ruleId,
          },
          bodyParams: {
            test: true,
          },
        });
        ruleCreationNotification.success({ successMessage: 'Rule test successful' });
      } catch (err) {
        ruleCreationNotification.error(err);
      } finally {
        history.push(routes.validationRules.validationRuleDetails(result.data.ruleId));
      }
    }
    onClose();
  };

  return (
    <Modal
      title="Create new validation rule"
      footer={[
        <Button key="cancel" onClick={() => onClose()}>
          Cancel
        </Button>,
        <Button type="primary" key="create" onClick={() => submitTemplate()}>
          Create Rule
        </Button>,
      ]}
      visible
      onCancel={() => onClose()}
      centered
      width={900}
    >
      <Container>
        <FlexSpace direction="row">
          <Container
            className={css`
              width: 60%;
              border-right: 1px solid ${theme.colors.neutral[200]};
              max-height: 400px;
              overflow-y: auto;
            `}
          >
            <Table
              isLoading={isLoading}
              data={templates?.items ?? []}
              columns={[{ title: 'Templates', dataIndex: 'name' }]}
              onRow={(record) => ({
                onClick: () => setTemplate(record),
              })}
              rowClassName={(record) =>
                `focusable-row ${record.templateId === template?.templateId ? 'focused-row' : ''}`
              }
            />
          </Container>
          <Container
            className={css`
              width: 40%;
              height: 100%;
              padding: 16px;
              margin-bottom: auto;
            `}
          >
            <FlexSpace
              direction="column"
              gap={24}
              className={css`
                width: 100%;
                height: 100%;
              `}
            >
              {template ? (
                <>
                  <Typography.Text
                    strong
                    className={css`
                      font-size: 18px;
                    `}
                  >
                    {template.name}
                  </Typography.Text>
                  <Typography.Paragraph>{template.description}</Typography.Paragraph>
                </>
              ) : (
                <>
                  <Typography.Text
                    className={css`
                      font-size: 20px;
                    `}
                  >
                    Please select a template
                  </Typography.Text>
                  <Typography.Text type="danger">{error}</Typography.Text>
                </>
              )}
            </FlexSpace>
          </Container>
        </FlexSpace>
      </Container>
    </Modal>
  );
};
