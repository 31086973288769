import React, { useRef } from 'react';

import { Link } from 'react-router-dom';

import {
  AppstoreOutlined,
  CalendarOutlined,
  CodeOutlined,
  EnvironmentOutlined,
  MonitorOutlined,
  ShopOutlined,
  StockOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { SearchForecastDTO } from '@recurrency/core-api-schema/dist/search/getSearchForecasts';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import moment from 'moment';

import { Button } from 'components/Button';
import { BulkForecastOverridesModal } from 'components/recipes/BulkForecastOverridesModal';
import { DemandPredictabilityTag } from 'components/recipes/DemandPredictabilityTag';
import { DownloadButton } from 'components/recipes/DownloadButton';
import { LastUpdatedMomentForecast } from 'components/recipes/LastUpdatedMoment';
import { SearchFrame, ValueFacet } from 'components/recipes/SearchFrame';
import { SearchFrameContext } from 'components/recipes/SearchFrame/types';
import { Sparkline } from 'components/Sparkline';
import { DemandPattern, DemandPatternTooltip } from 'components/Tooltip/DemandPatternTooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { formatNumber } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes, useHashState } from 'utils/routes';
import { asKeyOf, ExportColumn, sortDirections } from 'utils/tables';
import { isTenantErpTypeP21, isTenantErpTypeNetSuite } from 'utils/tenants';
import { getTenantSetting } from 'utils/tenantSettings';

import { SearchFrameHashState } from 'types/hash-state';

import { DemandPlanningContextModal } from '../PlanningPage/modals/DemandPlanningContextModal';
import { FORECAST_COLOR, USAGE_COLOR, useDemandPlanningFilterSave } from '../utils';
import { ForecastingSidePane } from './ForecastingSidePane';

export const TimeHorizon: Record<string, number> = {
  '1 Month': 1,
  '3 Month': 3,
  '6 Month': 6,
  '12 Month': 12,
  '18 Month': 18,
};

export const ForecastingPage = () => {
  const [hashState] = useHashState<SearchFrameHashState>();
  const { activeTenant, activeUser } = useGlobalApp();
  const searchFrameContextRef = useRef<SearchFrameContext<SearchForecastDTO>>();

  useDemandPlanningFilterSave();

  const selectedTimeHorizon = hashState.extFilter?.timeHorizon?.[0] ?? Object.keys(TimeHorizon)[1];

  const columns: (ExportColumn<SearchForecastDTO> | null)[] = [
    {
      title: 'Item',
      dataIndex: asKeyOf<SearchForecastDTO>('item_id'),
      fixed: 'left',
      sorter: true,
      sortDirections,
      render: (_: string, record) => (
        <div>
          <Link to={routes.purchasing.itemDetails(record.item_id)}>{record.item_id}</Link>
          <div>{record.item_name}</div>
        </div>
      ),
    },
    {
      title: 'Location',
      dataIndex: asKeyOf<SearchForecastDTO>('location'),
      fixed: 'left',
    },
    getTenantSetting(TenantSettingKey.UiShowShortCode)
      ? {
          title: 'Short Code',
          dataIndex: asKeyOf<SearchForecastDTO>('short_code'),
        }
      : null,
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'ABC Class',
          dataIndex: asKeyOf<SearchForecastDTO>('purchase_class'),
          sorter: true,
          sortDirections,
        }
      : null,
    ...Array.from(Array(12).keys()).map((month) => ({
      exportOnly: true,
      exportValue: (demand: number[]) => demand[demand.length - 12 + month],
      title: moment()
        .subtract(12 - month, 'month')
        .format('MMM YYYY'),
      dataIndex: asKeyOf<SearchForecastDTO>('historical_demand'),
    })),
    ...Array.from(Array(TimeHorizon[selectedTimeHorizon]).keys()).map((month) => ({
      exportOnly: true,
      exportValue: (forecast: number[]) => forecast[month],
      title: `Forecast ${moment().add(month, 'month').format('MMM YYYY')}`,
      dataIndex: asKeyOf<SearchForecastDTO>('forecast_demand'),
    })),
    {
      title: <b>Demand</b>,
      children: [
        {
          title: `Usage & Forecast`,
          render: (forecast: SearchForecastDTO) => {
            const historicalYearDates = forecast.historical_dates.slice(-12);
            const historicalYearDemand = forecast.historical_demand.slice(-12);

            return !forecast.item_id ? null : (
              <div
                onClick={() => {
                  showAsyncModal(DemandPlanningContextModal, {
                    location: forecast.location,
                    itemId: forecast.item_id,
                    itemUid: forecast.item_uid,
                    onForecastOverridesUpdated: () => {
                      searchFrameContextRef.current?.searchIndexReload();
                    },
                  });
                }}
              >
                <Sparkline
                  className={css`
                    cursor: zoom-in;
                  `}
                  height={50}
                  toolipSubtitle="Click to View"
                  series={[
                    {
                      data: historicalYearDates.map((_, i) => ({
                        date: historicalYearDates[i],
                        value: historicalYearDemand[i] ?? 0,
                      })),
                      formatFn: (value: number) => `${value} ${forecast.unit_of_measure}`,
                      title: 'Usage',
                      color: USAGE_COLOR,
                      showGradient: true,
                    },
                    {
                      data: forecast.forecast_dates.slice(0, TimeHorizon[selectedTimeHorizon]).map((_, i) => ({
                        date: forecast.forecast_dates[i],
                        value: forecast.forecast_demand[i] ?? 0,
                        dotted: true,
                      })),
                      formatFn: (value: number) => `${value} ${forecast.unit_of_measure}`,
                      title: 'Forecast',
                      color: FORECAST_COLOR,
                      showGradient: true,
                    },
                  ]}
                />
              </div>
            );
          },
        },
        {
          title: (
            <InfoTooltip title="Demand patterns group items based on consistency of orders and variance in demand quantity to forecast in the optimal manner">
              Pattern
            </InfoTooltip>
          ),
          dataIndex: asKeyOf<SearchForecastDTO>('demand_pattern'),
          render: (demandPattern) => <DemandPatternTooltip demandPattern={demandPattern} />,
        },
        // using forecast_confidence as field for sorting, but showing predictability_tag in UI
        {
          title: (
            <InfoTooltip title="Estimated predictability of an item based on historical trends. Higher predictability leads to more accurate forecasts">
              Predictability
            </InfoTooltip>
          ),
          dataIndex: asKeyOf<SearchForecastDTO>('forecast_confidence'),
          sorter: true,
          sortDirections,
          render: (_: string, record) => record.predictability_tag,
        },
      ],
    },
    // this is an exportOnly field Predictability in UI uses 'forecast_confidence' as the field
    {
      title: 'Predictability Tag',
      dataIndex: asKeyOf<SearchForecastDTO>('predictability_tag'),
      exportOnly: true,
    },
    {
      title: <InfoTooltip title="Average monthly demand over the last 1 year">Past Monthly Avg</InfoTooltip>,
      dataIndex: asKeyOf<SearchForecastDTO>('historical_demand'),
      exportValue: (historicalDemand: number[]) =>
        historicalDemand.slice(-12).reduce((prev, curr) => prev + curr ?? 0, 0) / 12,
      render: (historicalDemand: number[]) =>
        formatNumber(historicalDemand.slice(-12).reduce((prev, curr) => prev + curr ?? 0, 0) / 12, 0),
      align: 'right',
    },
    {
      title: (
        <InfoTooltip title={`Forecasted monthly average demand over the next ${selectedTimeHorizon}s`}>
          FC Monthly Avg
        </InfoTooltip>
      ),
      dataIndex: asKeyOf<SearchForecastDTO>('forecast_demand'),
      exportValue: (forecastDemand: number[]) =>
        forecastDemand.slice(0, TimeHorizon[selectedTimeHorizon]).reduce((prev, curr) => prev + curr ?? 0, 0) /
        TimeHorizon[selectedTimeHorizon],
      render: (forecastDemand: number[]) =>
        formatNumber(
          forecastDemand.slice(0, TimeHorizon[selectedTimeHorizon]).reduce((prev, curr) => prev + curr ?? 0, 0) /
            TimeHorizon[selectedTimeHorizon],
          0,
        ),
      align: 'right',
    },
    {
      title: 'UOM',
      dataIndex: asKeyOf<SearchForecastDTO>('unit_of_measure'),
    },
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.FeatureItemUsageInheritance)
      ? {
          exportOnly: true,
          title: 'Usage Ancestors',
          dataIndex: asKeyOf<SearchForecastDTO>('ancestor_item_codes'),
        }
      : null,
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.FeatureItemUsageInheritance)
      ? {
          exportOnly: true,
          title: 'Usage Descendants',
          dataIndex: asKeyOf<SearchForecastDTO>('descendant_item_codes'),
        }
      : null,
  ];

  const valueFacets: (ValueFacet<SearchForecastDTO> | null)[] = [
    {
      title: 'Location',
      field: asKeyOf<SearchForecastDTO>('location'),
      queryPlaceholder: 'Search locations',
      icon: <EnvironmentOutlined />,
    },
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'ABC Class',
          field: asKeyOf<SearchForecastDTO>('purchase_class'),
          sortBy: 'alpha',
          icon: <TagOutlined />,
        }
      : null,
    {
      title: 'Primary Supplier',
      field: asKeyOf<SearchForecastDTO>('primary_supplier'),
      queryPlaceholder: 'Search primary suppliers',
      icon: <ShopOutlined />,
    },
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'Vendor',
          field: asKeyOf<SearchForecastDTO>('primary_vendor'),
          queryPlaceholder: 'Search primary vendors',
          icon: <ShopOutlined />,
        }
      : null,
    isTenantErpTypeNetSuite(activeTenant.erpType)
      ? null
      : {
          title: 'Product Group',
          field: asKeyOf<SearchForecastDTO>('item_group'),
          queryPlaceholder: 'Search product groups',
          icon: <AppstoreOutlined />,
        },
    getTenantSetting(TenantSettingKey.UiShowShortCode)
      ? {
          title: 'Short Code',
          field: asKeyOf<SearchForecastDTO>('short_code'),
          queryPlaceholder: 'Search short codes',
          icon: <CodeOutlined />,
        }
      : null,
    getTenantSetting(TenantSettingKey.UiShowBuyerFilter)
      ? {
          title: 'Buyer',
          field: asKeyOf<SearchForecastDTO>('buyer'),
          queryPlaceholder: 'Search buyers',
          icon: <UserOutlined />,
        }
      : null,
    {
      title: 'Demand Pattern',
      field: asKeyOf<SearchForecastDTO>('demand_pattern'),
      icon: <StockOutlined />,
      options: [
        {
          label: DemandPattern.SMOOTH,
          value: DemandPattern.SMOOTH,
        },
        {
          label: DemandPattern.SPARSE,
          value: DemandPattern.SPARSE,
        },
        {
          label: DemandPattern.ERRATIC,
          value: DemandPattern.ERRATIC,
        },
        {
          label: DemandPattern.SPORADIC,
          value: DemandPattern.SPORADIC,
        },
        {
          label: DemandPattern.UNSTABLE,
          value: DemandPattern.UNSTABLE,
        },
        {
          label: DemandPattern.NEW_ITEM,
          value: DemandPattern.NEW_ITEM,
        },
        {
          label: DemandPattern.NO_DEMAND,
          value: DemandPattern.NO_DEMAND,
        },
      ],
      sortBy: 'none',
    },
    {
      title: 'Predictability',
      field: asKeyOf<SearchForecastDTO>('predictability_tag'),
      icon: <MonitorOutlined />,
      options: [
        {
          label: DemandPredictabilityTag.VeryHigh,
          value: DemandPredictabilityTag.VeryHigh,
        },
        {
          label: DemandPredictabilityTag.High,
          value: DemandPredictabilityTag.High,
        },
        {
          label: DemandPredictabilityTag.Medium,
          value: DemandPredictabilityTag.Medium,
        },
        {
          label: DemandPredictabilityTag.Low,
          value: DemandPredictabilityTag.Low,
        },
      ],
      sortBy: 'none',
    },
  ];

  return (
    <SearchFrame<SearchForecastDTO>
      title={
        <InfoTooltip title="View forecasts for stockable items at each location. Non-stockable items are excluded.">
          Forecast Review
        </InfoTooltip>
      }
      subtitle={<LastUpdatedMomentForecast />}
      indexName={SearchIndexName.Forecasts}
      contextRef={searchFrameContextRef}
      queryPlaceholder="Search by item"
      columns={columns.filter(truthy)}
      valueFacets={valueFacets.filter(truthy)}
      defaultSortBy={{ field: asKeyOf<SearchForecastDTO>('forecast_confidence'), order: 'desc' }}
      externalFacets={[
        {
          title: '',
          icon: <CalendarOutlined />,
          field: 'timeHorizon',
          defaultValue: '3 Month',
          options: Object.keys(TimeHorizon).map((value) => ({ label: value, value })),
          filterType: 'single',
        },
      ]}
      headerActions={(searchFrameContext) => (
        <>
          {shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.FeatureForecastOverrides) && (
            <Button onClick={() => showAsyncModal(BulkForecastOverridesModal, { filters: hashState.where })}>
              Upload Forecasts
            </Button>
          )}
          <DownloadButton
            getDownloadData={searchFrameContext.getDownloadData}
            recordType="Forecast"
            disabled={!searchFrameContext.canDownload}
          />
        </>
      )}
      sidePane={({ record }) => (
        <ForecastingSidePane
          record={record}
          onForecastOverridesUpdated={() => searchFrameContextRef.current?.searchIndexReload()}
        />
      )}
    />
  );
};
