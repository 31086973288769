import React, { useState } from 'react';

import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SavedViewType } from '@recurrency/core-api-schema/dist/common/enums';
import { SavedViewDTO } from '@recurrency/core-api-schema/dist/savedViews/getSavedView';
import { message, notification, Modal } from 'antd';
import { fuzzyFilter } from 'fuzzbunny';

import { Container } from 'components/Container';
import { Input } from 'components/Input';
import { CenteredError } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { Table } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { isAdmin } from 'utils/roleAndTenant';
import { track, TrackEvent } from 'utils/track';

import { DefaultReportCard } from './DefaultReportCard';
import { defaultAdminReports, defaultSalesReports } from './defaultReports';
import { getSavedReportListColumns } from './tableColumns';

export const SavedReportListPage = () => {
  const { activeErpRole } = useGlobalApp();
  const { data, error, isLoading, reload, setData } = useCoreApi(schemas.savedViews.getSavedViews, {
    queryParams: { type: SavedViewType.SalesReport },
  });

  const [query, setQuery] = useState('');

  let filteredData: SavedViewDTO[] = [];
  if (data) {
    filteredData = fuzzyFilter(data.items, query, {
      fields: ['name'],
    }).map(({ item }) => item);
  }

  const onSetPinned = async (id: string, newStatus: boolean) => {
    try {
      if (data) {
        setData({
          ...data,
          items: data.items.map((record) => (record.id === id ? { ...record, isPinned: newStatus } : record)),
        });
      }
      const { data: newReport } = await coreApiFetch(schemas.savedViews.updatePartialSavedView, {
        pathParams: { savedViewId: id },
        bodyParams: { isPinned: newStatus },
      });
      track(TrackEvent.Reporting_UpdateReport, {
        changedIsPinned: true,
        changedName: false,
        changedData: false,
        isPinned: newStatus,
      });
      reload();
      message.success(
        `${newStatus ? 'Pinned' : 'Unpinned'} report "${newReport.name}" ${newStatus ? 'to' : 'from'} dashboard`,
      );
    } catch (err) {
      captureAndShowError(err, `Unable to ${newStatus ? 'pin' : 'unpin'} report`);
    }
  };

  const onDelete = (report: SavedViewDTO) => {
    Modal.confirm({
      title: `Are you sure you want to delete report "${report.name}"?`,
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        postDelete(report);
      },
      onCancel() {},
    });
  };

  const postDelete = async (report: SavedViewDTO) => {
    try {
      await coreApiFetch(schemas.savedViews.deleteSavedView, {
        pathParams: { savedViewId: report.id },
      });
      track(TrackEvent.Reporting_DeleteReport, {});
      reload();
      notification.success({ message: `Deleted report "${report.name}"` });
    } catch (err) {
      captureAndShowError(err, `Unable to delete report "${report.name}"`);
    }
  };

  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <Container>
      <PageHeader
        title="Reports"
        headerActions={
          <Input
            placeholder="Search reports"
            onChange={(ev) => setQuery(ev.target.value)}
            prefix={<SearchOutlined />}
            className={css`
              max-width: 400px;
            `}
          />
        }
      />
      <div
        className={css`
          display: flex;
          align-items: stretch;
          width: 100%;
          gap: 24px;
          margin-bottom: 24px;
          overflow-x: scroll;
        `}
      >
        {(isAdmin(activeErpRole.foreignId, activeErpRole.name) ? defaultAdminReports : defaultSalesReports).map(
          (report) => (
            <DefaultReportCard key={report.id} report={report} />
          ),
        )}
      </div>
      <Table
        isLoading={isLoading || !data}
        columns={getSavedReportListColumns(onSetPinned, onDelete)}
        data={filteredData}
        rowKey="id"
        size="small"
      />
    </Container>
  );
};
