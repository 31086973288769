import React from 'react';

import {
  CalculatorOutlined,
  CalendarOutlined,
  CheckCircleOutlined,
  DownloadOutlined,
  DownOutlined,
  ReconciliationOutlined,
  StopOutlined,
  SyncOutlined,
  ToolOutlined,
  InsertRowBelowOutlined,
} from '@ant-design/icons';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';
import { Menu } from 'antd';

import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { notifyAndDownload } from 'components/recipes/DownloadButton';
import { ValueFacet } from 'components/recipes/SearchFrame';
import { SearchFrameContext } from 'components/recipes/SearchFrame/types';
import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { isSAPB1Tenant } from 'utils/routes';
import { getTenantSetting } from 'utils/tenantSettings';

import { changeRecordExcludedStatus } from './modals/IncludeExcludeStatusUpdater';
import { MultiLeadTimeModal } from './modals/MultiLeadTimeModal';
import { MultiSafetyStockModal } from './modals/MultiSafetyStockModal';
import { MultiStockFloorModal } from './modals/MultiStockFloorModal';
import { MultiUpdateReplenishmentSettingsModal } from './modals/MultiUpdateReplenishmentSettingsModal';
import { SAPB1PlanningExportMinMax } from './modals/SAPB1PlanningExportMinMax';

const MIN_MAX_EXPORT_LIMIT = 100;

export const PlanningSearchFrameHeader = ({
  searchFrameContext,
  searchIndexReload,
  clearSelection,
  valueFacets,
  showOpOqVariant = false,
  isOnboardingVariant = false,
}: {
  searchFrameContext: SearchFrameContext<SearchPlanningDTO>;
  searchIndexReload: () => void;
  clearSelection: () => void;
  valueFacets: (ValueFacet<SearchPlanningDTO> | null)[];
  showOpOqVariant?: boolean;
  isOnboardingVariant?: boolean;
}) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const { selection } = searchFrameContext;

  // for P21 txn-api only tenant, we limit the min/max export to 100 records at a time
  const hasAsyncMinMaxExportsSetting = getTenantSetting(TenantSettingKey.FeatureAsyncMinMaxExports);
  const isTxnApiTenantWithSynchronousExports =
    !hasAsyncMinMaxExportsSetting &&
    shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.ItemLocationMinMaxExportUsingTransactionApi);
  // This setting allows the CS team to manually limit a tenant to exports of MIN_MAX_EXPORT_LIMIT or less during onboarding
  const isRestrictedToSmallExportsOnly = !getTenantSetting(TenantSettingKey.FeatureBulkMinMaxExports);
  const isOverMinMaxLimit =
    (isTxnApiTenantWithSynchronousExports || isRestrictedToSmallExportsOnly) &&
    selection &&
    selection.count > MIN_MAX_EXPORT_LIMIT;

  return (
    <>
      {isSAPB1Tenant() && hasAsyncMinMaxExportsSetting && (
        <Tooltip title="Update all Min/Max values in SAP B1 with the current values in Recurrency" placement="left">
          <Button onClick={() => showAsyncModal(SAPB1PlanningExportMinMax, {})} icon={<SyncOutlined />}>
            Sync Min/Maxes
          </Button>
        </Tooltip>
      )}

      {isOnboardingVariant && (
        <Button
          disabled={!selection}
          type="primary"
          onClick={() => {
            // this will never happen, but we appease tsc to ensure it is defined
            if (!selection) return;

            // add filterTitleMapping so we can show Filters used in the modal
            if (selection.type === 'filter') {
              selection.filterTitleMapping = Object.fromEntries(
                valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
              );
            }
            showAsyncModal(MultiUpdateReplenishmentSettingsModal, {
              selection,
              searchIndexReload,
              clearSelection,
              isOpOqVariant: showOpOqVariant,
              isOnboardingVariant: true,
            });
          }}
        >
          <ToolOutlined /> Bulk Set Up
        </Button>
      )}

      <Dropdown
        disabled={!selection}
        overlay={
          <Menu>
            {isOnboardingVariant ? null : (
              <Tooltip
                placement="left"
                title={
                  isOverMinMaxLimit
                    ? isRestrictedToSmallExportsOnly
                      ? `Exports of >${MIN_MAX_EXPORT_LIMIT} records are available once onboarding is complete`
                      : `Export is limited to ${MIN_MAX_EXPORT_LIMIT} records at a time`
                    : undefined
                }
              >
                <div>
                  <Menu.Item
                    key="minMax"
                    icon={<CalculatorOutlined />}
                    disabled={isOverMinMaxLimit}
                    onClick={() => {
                      // this will never happen, but we appease tsc to ensure it is defined
                      if (!selection) return;

                      // add filterTitleMapping so we can show Filters used in the modal
                      if (selection.type === 'filter') {
                        selection.filterTitleMapping = Object.fromEntries(
                          valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
                        );
                      }
                      showAsyncModal(MultiUpdateReplenishmentSettingsModal, {
                        selection,
                        searchIndexReload,
                        clearSelection,
                        isOpOqVariant: showOpOqVariant,
                        isOnboardingVariant: false,
                      });
                    }}
                  >
                    {isOnboardingVariant ? 'Item Setup' : showOpOqVariant ? 'OP/OQ' : 'Min/Max'}
                  </Menu.Item>
                </div>
              </Tooltip>
            )}
            <Menu.Item
              key="safetyStock"
              icon={<ReconciliationOutlined />}
              onClick={() => {
                // this will never happen, but we appease tsc to ensure it is defined
                if (!selection) return;

                // add filterTitleMapping so we can show Filters used in the modal
                if (selection.type === 'filter') {
                  selection.filterTitleMapping = Object.fromEntries(
                    valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
                  );
                }
                showAsyncModal(MultiSafetyStockModal, {
                  selection,
                  searchIndexReload,
                  clearSelection,
                });
              }}
            >
              Safety Stock
            </Menu.Item>
            <Menu.Item
              key="leadTime"
              icon={<CalendarOutlined />}
              onClick={() => {
                // this will never happen, but we appease tsc to ensure it is defined
                if (!selection) return;

                // add filterTitleMapping so we can show Filters used in the modal
                if (selection.type === 'filter') {
                  selection.filterTitleMapping = Object.fromEntries(
                    valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
                  );
                }
                showAsyncModal(MultiLeadTimeModal, {
                  selection,
                  searchIndexReload,
                  clearSelection,
                });
              }}
            >
              Lead Time
            </Menu.Item>
            <Menu.Item
              key="minStockFloor"
              icon={<InsertRowBelowOutlined />}
              onClick={() => {
                // this will never happen, but we appease tsc to ensure it is defined
                if (!selection) return;

                // add filterTitleMapping so we can show Filters used in the modal
                if (selection.type === 'filter') {
                  selection.filterTitleMapping = Object.fromEntries(
                    valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
                  );
                }
                showAsyncModal(MultiStockFloorModal, {
                  selection,
                  searchIndexReload,
                  clearSelection,
                });
              }}
            >
              Add Stock Floor
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="exclude"
              icon={<StopOutlined />}
              onClick={async () => {
                // this will never happen, but we appease tsc to ensure it is defined
                if (!selection) return;

                // add filterTitleMapping so we can show Filters used in the modal
                if (selection.type === 'filter') {
                  selection.filterTitleMapping = Object.fromEntries(
                    valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
                  );
                }
                const didChange = await changeRecordExcludedStatus(selection, true);
                if (didChange) {
                  clearSelection();
                  searchIndexReload();
                }
              }}
            >
              Exclude
            </Menu.Item>
            <Menu.Item
              key="include"
              icon={<CheckCircleOutlined />}
              onClick={async () => {
                // this will never happen, but we appease tsc to ensure it is defined
                if (!selection) return;

                // add filterTitleMapping so we can show Filters used in the modal
                if (selection.type === 'filter') {
                  selection.filterTitleMapping = Object.fromEntries(
                    valueFacets.filter(truthy).map((facet) => [facet.field, facet.title]),
                  );
                }
                const didChange = await changeRecordExcludedStatus(selection, false);
                if (didChange) {
                  clearSelection();
                  searchIndexReload();
                }
              }}
            >
              Include
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              key="download"
              icon={<DownloadOutlined />}
              onClick={() => notifyAndDownload(searchFrameContext.getDownloadData, 'Planning')}
            >
              Download
            </Menu.Item>
          </Menu>
        }
      >
        <Button
          type={isOnboardingVariant ? 'default' : 'primary'}
          title={!selection ? `Select table records to enable bulk actions` : undefined}
        >
          Bulk Actions <DownOutlined />
        </Button>
      </Dropdown>
    </>
  );
};
