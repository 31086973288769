import React, { useState } from 'react';

import { EnvironmentOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchForecastDTO } from '@recurrency/core-api-schema/dist/search/getSearchForecasts';
import { Empty } from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { Button } from 'components/Button';
import { ForecastChart, ForecastDataType } from 'components/Charts/ForecastChart';
import { FilterBarBox } from 'components/FilterBarBox';
import { SmallLoader } from 'components/Loaders';
import { Table } from 'components/Table';
import { Tabs } from 'components/Tabs';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { DateFilter, getDefaultDateFilter } from 'utils/date';
import { splitIdNameStr, splitIfIdNameStr } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { sortableNumberColumn } from 'utils/tables';

import { IdNameObj } from 'types/legacy-api';

import { DemandUsageLinesTable, UsageLineOverride } from './DemandUsageLinesTable';
import {
  ForecastOverrideTable,
  getMappedUsageAndForecastRecords,
  MappedUsageForecastRecord,
  RecordType,
} from './ForecastOverrideTable';
import { LastUpdatedMomentForecast } from './LastUpdatedMoment';
import { getMergedForecastRecords } from './sidePane/sidePane.utils';

interface InventoryUsageRecord {
  date: string;
  usage: number;
  inheritedUsage: number;
  unitOfMeasure: string;
}

export const InventoryUsageForecastView = ({
  forecastRecords,
  itemUid,
  locations,
  isLoading,
  onUsageOverridesUpdated,
  onForecastOverridesUpdated,
}: {
  forecastRecords?: SearchForecastDTO[];
  itemUid: string;
  locations?: IdNameObj[];
  isLoading: boolean;
  onUsageOverridesUpdated: () => void;
  onForecastOverridesUpdated: () => void;
}) => {
  const { activeTenant, activeUser } = useGlobalApp();
  const [filteredLocationIds, setFilteredLocationIds] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>();
  const [dateFilter, setDateFilter] = useState<DateFilter>(getDefaultDateFilter());
  const [forecastOverrideInputs, setForecastOverrideInputs] = useState<MappedUsageForecastRecord[]>([]);
  const [usageLineOverrideInputs, setUsageLineOverrideInputs] = useState<UsageLineOverride[]>([]);

  function onChangeUsageLine(usageLineOverride: UsageLineOverride) {
    setUsageLineOverrideInputs((prevInputs) => {
      const updatedInputs = [...prevInputs];
      const existingIndex = updatedInputs.findIndex((input) => input.usageId === usageLineOverride.usageId);

      if (existingIndex !== -1) {
        updatedInputs[existingIndex] = usageLineOverride;
      } else {
        updatedInputs.push(usageLineOverride);
      }

      return updatedInputs;
    });
  }

  const forecastOverridesEnabled = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.FeatureForecastOverrides,
  );

  const filteredHits = forecastRecords?.filter((hit) => {
    const hitLocationId = splitIdNameStr(hit.location).foreignId;
    return filteredLocationIds.length > 0 ? filteredLocationIds.includes(hitLocationId) : true;
  });

  const record = getMergedForecastRecords(filteredHits);

  const { data: forecastOverridesData } = useCoreApi(schemas.ml.getDemandForecastOverrides, {
    queryParams: {
      filter: {
        itemIds: [itemUid],
        ...(record && record?.location ? { locationIds: [splitIdNameStr(record?.location).foreignId] } : {}),
      },
    },
  });

  function handleChangeForecastOverride(updatedRecord: MappedUsageForecastRecord) {
    const updatedForecastOverrides = [...forecastOverrideInputs];
    const existingRecordIdx = forecastOverrideInputs.findIndex((r) => r.date === updatedRecord.date);

    if (existingRecordIdx > -1) {
      updatedForecastOverrides[existingRecordIdx] = updatedRecord;
    } else {
      updatedForecastOverrides.push(updatedRecord);
    }
    setForecastOverrideInputs(updatedForecastOverrides);
  }

  const tableColumns: ColumnType<InventoryUsageRecord>[] = [
    {
      title: 'Year',
      dataIndex: 'date',
      defaultSortOrder: 'descend',
      render: (value) => moment(value).format('YYYY'),
    },
    {
      title: (
        <div
          className={css`
            padding-left: 50%;
          `}
        >
          Month
        </div>
      ),
      dataIndex: 'date',
      render: (value) => (
        <div
          className={css`
            padding-left: 50%;
          `}
        >
          {moment(value).format('MMMM')}
        </div>
      ),
    },
    sortableNumberColumn({
      title: 'Total Usage',
      dataIndex: 'usage',
    }),
    ...(record?.historical_inherited_demand?.some((demand) => demand !== 0)
      ? [
          sortableNumberColumn({
            title: 'Inherited Usage',
            dataIndex: 'inheritedUsage',
          }),
        ]
      : []),
    {
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    },
    {
      title: 'Actions',
      dataIndex: 'date',
      width: '100px',
      render: (value) => (
        <Button
          size="small"
          onClick={() => {
            setDateFilter({
              from: moment(value).startOf('month'),
              to: moment(value).endOf('month'),
            });
            setActiveTab('usage-lines');
          }}
        >
          Lines <RightOutlined />
        </Button>
      ),
    },
  ];

  const forecastOverrides: MappedUsageForecastRecord[] = (forecastOverridesData?.items || []).map((item) => ({
    date: item.forecastDate,
    quantity: item.overrideQty,
    type: RecordType.Forecast,
    note: item.overrideNote,
    updatedBy: item.updatedByUserName,
    updatedAt: item.updatedAt,
  }));

  const usageAndForecastRecords = getMappedUsageAndForecastRecords(record, forecastOverrides);

  return (
    <>
      {locations && locations.length > 1 && (
        <FilterBarBox>
          <AsyncMultiSelect
            selectProps={{
              options: locations.map((l) => ({
                value: l.foreignId,
                label: l.name,
              })),
            }}
            label="Location"
            queryPlaceholder="Search locations"
            selectedValues={filteredLocationIds}
            onSelectedValuesChange={(values) => setFilteredLocationIds(values)}
            icon={<EnvironmentOutlined />}
          />
        </FilterBarBox>
      )}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '12px' }}>
        <LastUpdatedMomentForecast />
      </div>
      {isLoading || record ? (
        <>
          {record ? (
            <ForecastChart
              data={[
                ...record.historical_dates.map((_, i) => ({
                  date: moment(record.historical_dates[i]).valueOf(),
                  usage: record.historical_demand[i],
                  inherited: record.historical_inherited_demand[i],
                  type: ForecastDataType.Usage,
                  connection: record.historical_demand.length - 1 === i ? record.historical_demand[i] : undefined,
                  unitOfMeasure: record.unit_of_measure,
                })),
                ...record.forecast_dates.map((_, i) => ({
                  date: moment(record.forecast_dates[i]).valueOf(),
                  forecast:
                    forecastOverrideInputs.find((fo) => fo.date === record.forecast_dates[i])?.quantity ??
                    record.forecast_demand[i],
                  type: ForecastDataType.Forecast,
                  // TODO @noj: refactor ForecastChart to handle connection values
                  connection:
                    i === 0
                      ? forecastOverrideInputs.find((fo) => fo.date === record.forecast_dates[i])?.quantity ??
                        record.forecast_demand[i]
                      : undefined,
                  unitOfMeasure: record.unit_of_measure,
                })),
              ]}
            />
          ) : (
            <SmallLoader />
          )}
          {/* TODO: Add inheritance component once fixed in https://recurrency.atlassian.net/browse/PE-4295 */}
          <Tabs
            activeTab={activeTab}
            onChange={(index) => setActiveTab(index)}
            tabs={[
              forecastOverridesEnabled
                ? {
                    header: 'Usage & Forecast',
                    content: (
                      <ForecastOverrideTable
                        isLoading={isLoading}
                        record={record}
                        usageAndForecastRecords={usageAndForecastRecords}
                        forecastOverrideInputs={forecastOverrideInputs}
                        onForecastChange={handleChangeForecastOverride}
                        onForecastOverridesUpdated={onForecastOverridesUpdated}
                      />
                    ),
                  }
                : null,
              {
                header: 'Monthly Usage',
                content: (
                  <Table
                    pageSize={10}
                    isLoading={isLoading}
                    data={getUsageTableRowsFromForecastRecord(record)}
                    columns={tableColumns}
                  />
                ),
              },
              {
                header: 'Usage Lines',
                content: (
                  <DemandUsageLinesTable
                    itemUid={itemUid}
                    locationId={splitIfIdNameStr(record?.location)?.foreignId ?? ''}
                    dateFilter={dateFilter}
                    setDateFilter={setDateFilter}
                    usageLineOverrideInputs={usageLineOverrideInputs}
                    onChangeUsageLine={onChangeUsageLine}
                    onUsageOverridesUpdated={onUsageOverridesUpdated}
                  />
                ),
              },
            ].filter(truthy)}
          />
        </>
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

export function getUsageTableRowsFromForecastRecord(record: SearchForecastDTO | undefined) {
  return record
    ? record.historical_dates
        .map((value, index) => ({
          date: value,
          usage: record.historical_demand[index],
          inheritedUsage: record.historical_inherited_demand[index],
          unitOfMeasure: record.unit_of_measure,
        }))
        .sort((a, b) => (a.date < b.date ? 1 : -1))
    : [];
}
