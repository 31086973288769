import React, { useEffect, useState } from 'react';

import { CloseOutlined, FileOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Divider, Steps, Upload } from 'antd';

import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { Container } from 'components/Container';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { Modal } from 'components/Modal';
import {
  BulkImportModalProps,
  downloadHeaderTemplate,
  parseUploadedData,
} from 'components/recipes/BulkImportModal/BulkImportDropdown/utils';
import { Table } from 'components/Table';
import { Tooltip } from 'components/Tooltip';

import { createSubmissionNotification } from 'utils/submissionNotification';
import { PersistedColumn } from 'utils/tableAndSidePaneSettings/types';

export enum UploadStep {
  Upload,
  Review,
}

export function BulkImportModal<BulkImportRequest>({
  onClose,
  inputs,
  onUpdate,
  name,
}: BulkImportModalProps<BulkImportRequest>) {
  const [currentStep, setCurrentStep] = useState<UploadStep>(UploadStep.Upload);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>('');
  const [uploadedData, setUploadedData] = useState<BulkImportRequest[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getColumns = (): PersistedColumn<any>[] =>
    inputs.map((input) => ({
      title: input.name,
      dataIndex: input.name,
      settingKey: input.name,
      render: (value) => String(value),
    }));

  useEffect(() => {
    if (error !== null) {
      setIsUploading(false);
      setCurrentStep(UploadStep.Upload);
    }
  }, [error]);

  const removeFile = () => {
    setFileName('');
    setError(null);
  };

  return (
    <Modal
      title={`Upload ${name}`}
      visible
      onCancel={() => onClose()}
      centered
      width={1200}
      footer={
        <>
          <Button onClick={() => setCurrentStep(UploadStep.Upload)} disabled={currentStep === UploadStep.Upload}>
            Previous
          </Button>
          <Button
            type="primary"
            disabled={fileName === '' || error !== null}
            loading={isSubmitting}
            onClick={async () => {
              if (currentStep === UploadStep.Upload) {
                setCurrentStep(UploadStep.Review);
              } else if (currentStep === UploadStep.Review) {
                setIsSubmitting(true);
                const submitNotification = createSubmissionNotification({
                  entityName: 'Bulk Upload',
                  // 30 sec + 30 sec per 1k updates
                  expectedWaitSeconds: 30 + Math.round(uploadedData.length / 1_000) * 30,
                });
                try {
                  await onUpdate(uploadedData);
                  submitNotification.success({ successMessage: 'Updated', duration: 10 });
                  onClose();
                } catch (err) {
                  submitNotification.error(err);
                }
                setIsSubmitting(false);
              }
            }}
          >
            {currentStep === UploadStep.Review ? 'Save Updates' : 'Next'}
          </Button>
        </>
      }
    >
      <Steps current={currentStep}>
        <Steps.Step title={`Upload ${name}`} />
        <Steps.Step title="Review" />
      </Steps>
      {currentStep === UploadStep.Upload && (
        <Container>
          <FlexSpace direction="column" gap={16} alignItems="center">
            <FlexSpacer />
            This will allow for updating data in bulk via CSV. Please be careful, as there are different formats for
            each export. Data will be generally validated, but please double check the csv before making any changes.
            Additionally, a non Recurrency account is required for most export types.
            <Button
              onClick={() =>
                downloadHeaderTemplate(
                  // Header values from inputs
                  inputs.map((val) => val.name),
                  name,
                )
              }
            >
              Download Template
            </Button>
            {fileName === '' ? (
              <Upload
                accept=".csv"
                maxCount={1}
                showUploadList={false}
                beforeUpload={async (file) => {
                  await parseUploadedData(file, inputs, setIsUploading, setError, setUploadedData, setFileName);
                }}
              >
                <Button type="primary" loading={isUploading}>
                  Upload Data
                </Button>
              </Upload>
            ) : (
              <FlexSpace gap={8} justify="left" alignItems="center">
                <Tooltip title={fileName}>
                  <FileOutlined />
                </Tooltip>
                <p
                  className={css`
                    margin-top: auto;
                    margin-bottom: auto;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 250px;
                  `}
                >
                  {fileName}
                </p>
                <ActionButton label={<CloseOutlined />} onClick={() => removeFile()} />
              </FlexSpace>
            )}
            <div
              className={css`
                margin-left: auto;
                margin-right: auto;
                color: red;
              `}
            >
              {error}
            </div>
          </FlexSpace>
        </Container>
      )}
      {currentStep === UploadStep.Review && (
        <>
          <Divider />
          <Table pageSize={10} isLoading={isUploading} data={uploadedData} columns={getColumns()} size="large" />
        </>
      )}
    </Modal>
  );
}
