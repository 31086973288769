import React from 'react';

import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';

import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { formatDate } from 'utils/formatting';

import { BadgeStatus, StatusBadge } from './StatusBadge';

export type PlanningStatusBadgeProps = {
  status: BadgeStatus;
  record: SearchPlanningDTO;
  isOnboarding?: boolean;
};

export function PlanningStatusBadge({ status, record, isOnboarding = false }: PlanningStatusBadgeProps) {
  return isOnboarding ? (
    <StatusBadge status={BadgeStatus.Review} labelText="Needs Setup" />
  ) : (
    <StatusBadge
      status={status}
      labelText={
        // override labels for Exclude until date
        record.excluded && record.excluded_until ? (
          <>
            <InfoTooltip title={`Excluded until ${formatDate(record.excluded_until)}`}>Excluded</InfoTooltip>
          </>
        ) : undefined
      }
    />
  );
}
