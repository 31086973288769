import { schemas } from '@recurrency/core-api-schema';
import { TenantDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { GetTenantSettingsResponseDTO } from '@recurrency/core-api-schema/dist/tenantSettings/getTenantSettings';

import { globalAppStore } from 'hooks/useGlobalApp';

import { coreApiFetch } from './api';
import { getActiveCompanyIds } from './roleAndTenant';
import { ViewSettingKey } from './tableAndSidePaneSettings/types';
import { isTenantErpTypeP21, isTenantErpTypeNetSuite } from './tenants';

export async function loadActiveCompanyIds(activeTenant: TenantDTO): Promise<void> {
  if (
    activeTenant.isActive &&
    (isTenantErpTypeP21(activeTenant.erpType) || isTenantErpTypeNetSuite(activeTenant.erpType))
  ) {
    // add userAllowedCompanies and activeCompanyIds to globalAppStore
    // coreApiFetch needs activeTenant to be on globalAppStore
    const companiesResponse = await coreApiFetch(schemas.companies.getCompanies);
    const userAllowedCompanies = (companiesResponse.data?.items || []).sort((a, b) =>
      a.companyId.localeCompare(b.companyId),
    );
    const activeCompanyIds = getActiveCompanyIds(userAllowedCompanies);

    globalAppStore.update({
      activeCompanyIds,
      userAllowedCompanies,
    });
  } else {
    // sapb1 tenant has no concept of companies, we avoid making an unnecessary getCompanies request
    globalAppStore.update({
      activeCompanyIds: null,
      userAllowedCompanies: [],
    });
  }
}

export async function loadActiveTenantSettings(): Promise<GetTenantSettingsResponseDTO> {
  const { data } = await coreApiFetch(schemas.tenantSettings.getTenantSettings);

  globalAppStore.update({
    activeTenantSettings: data.settings,
    pendingTenantSettings: data.pendingSettings,
  });

  return data;
}

export async function loadActiveTenantUserViewSettings(): Promise<Record<ViewSettingKey, string[]>> {
  const { data } = await coreApiFetch(schemas.userViewSettings.getUserViewSettings);

  const userViewVisibleKeys = Object.fromEntries(
    data.items.map((setting) => [setting.settingKey, setting.settingValue.visibleKeys]),
  ) as Record<ViewSettingKey, string[]>;

  globalAppStore.update({
    userViewVisibleKeys,
  });

  return userViewVisibleKeys;
}
