import { schemas } from '@recurrency/core-api-schema';
import { Paging } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchForecastListDTO } from '@recurrency/core-api-schema/dist/search/getSearchForecasts';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { EndpointSchema } from '@recurrency/core-api-schema/dist/utils/apiSchema';

import { coreApiFetch } from 'utils/api';

import {
  DEFAULT_HITS_PER_PAGE,
  FacetValues,
  GetAllRecordsRequest,
  MAX_VALUES_PER_FACET,
  SearchRequest,
  SearchResponse,
} from './types';

const searchIndexToCoreApiSchema: Partial<Record<SearchIndexName, EndpointSchema>> = {
  [SearchIndexName.Planning]: schemas.search.getSearchPlanning,
  [SearchIndexName.InventoryStatus]: schemas.search.getSearchInventoryStatus,
  [SearchIndexName.Forecasts]: schemas.search.getSearchForecasts,
  [SearchIndexName.ShipTos]: schemas.search.getSearchShipTos,
  [SearchIndexName.TransferOrders]: schemas.search.getSearchTransferOrders,
};

export async function searchPostgresCollection<ObjectT>(
  searchRequest: SearchRequest,
): Promise<SearchResponse<ObjectT>> {
  // Consider making hitsPerPage and page non-optional in the future
  searchRequest = {
    ...searchRequest,
    hitsPerPage: searchRequest.hitsPerPage ?? DEFAULT_HITS_PER_PAGE,
    page: searchRequest.page ?? 0,
    maxValuesPerFacet: searchRequest.maxValuesPerFacet ?? MAX_VALUES_PER_FACET,
  };
  const searchSchema = searchIndexToCoreApiSchema[searchRequest.indexName];
  if (!searchSchema) {
    throw new Error(`Unsupported index for postgres collection '${searchRequest.indexName}'`);
  }
  const queryParams = {
    query: searchRequest.query,
    facetFields: searchRequest.facetFields,
    filters: searchRequest.filters,
    select: {
      limit: searchRequest.hitsPerPage!,
      offset: searchRequest.page! * searchRequest.hitsPerPage!,
      sortBy: searchRequest.sortBy?.field,
      sortDir: searchRequest.sortBy?.order,
      secondarySortBy: searchRequest.secondarySortBy?.field,
      secondarySortDir: searchRequest.secondarySortBy?.order,
      fields: searchRequest.fieldsToRetrieve,
    },
    maxValuesPerFacet: searchRequest.maxValuesPerFacet,
  };
  const response = await coreApiFetch(searchSchema, { queryParams });

  // Convert the raw response to a structured search response and return it
  return convertToSearchResponse(searchRequest, response.data);
}

function convertToSearchResponse<ObjectT>(
  searchRequest: SearchRequest,
  postgresResponse: SearchForecastListDTO,
): SearchResponse<ObjectT> {
  const hitsPerPage = searchRequest.hitsPerPage!;
  const searchResponse: SearchResponse<ObjectT> = {
    hits: postgresResponse.items as unknown as ObjectT[],
    nbHits: postgresResponse.totalCount,
    page: searchRequest.page!,
    nbPages: Math.ceil(postgresResponse.totalCount / hitsPerPage),
    hitsPerPage,
    facets: postgresResponse.facets,
  };
  return searchResponse;
}

export async function searchPostgresCollectionForFacetValues(
  facetField: string,
  facetQuery: string,
  searchRequest: SearchRequest,
): Promise<FacetValues> {
  const searchSchema = searchIndexToCoreApiSchema[searchRequest.indexName];
  if (!searchSchema) {
    throw new Error(`Unsupported index for postgres collection '${searchRequest.indexName}'`);
  }
  searchRequest = {
    ...searchRequest,
    facetFields: [facetField],
    hitsPerPage: 0,
    page: 0,
  };
  const queryParams = {
    query: searchRequest.query,
    facetQuery,
    facetFields: searchRequest.facetFields,
    filters: searchRequest.filters,
    maxValuesPerFacet: searchRequest.maxValuesPerFacet ?? MAX_VALUES_PER_FACET,
  };
  const response = await coreApiFetch(searchSchema, { queryParams });
  return response.data.facets[facetField] || [];
}

export async function getAllRecordsInPostgresCollection<RecordT>(request: GetAllRecordsRequest): Promise<RecordT[]> {
  const hits: RecordT[] = [];

  // ask api for pages with limit of Paging.MaxLimit
  const searchRequest = {
    ...request,
    hitsPerPage: Paging.MaxLimit,
    page: 0,
  };

  // get first response to get total pages
  const firstResponse = await searchPostgresCollection<RecordT>(searchRequest);
  hits.push(...firstResponse.hits);

  // iterate through rest of pages
  for (let page = 1; page < firstResponse.nbPages; ++page) {
    const response = await searchPostgresCollection<RecordT>({ ...searchRequest, page });
    hits.push(...response.hits);
  }

  return hits;
}
