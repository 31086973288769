import { schemas } from '@recurrency/core-api-schema';
import { UpdateItemLocationInfoType } from '@recurrency/core-api-schema/dist/purchasing/postUpdateItemLocationInfo';
import { notification } from 'antd';

import { getGlobalAppState } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { captureAndShowError } from 'utils/error';
import { splitIdNameStr } from 'utils/formatting';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { track, TrackEvent, PlanningModalType } from 'utils/track';

import { IncludeExcludeModal } from './IncludeExcludeModal';
import { MultiModalSelection, getPlanningListSelectionFromFilterSelection } from './utils';

export async function changeRecordExcludedStatus(selection: MultiModalSelection, exclude: boolean): Promise<boolean> {
  const { activeTenant } = getGlobalAppState();
  // confirm with user before applying action
  const confirmData = await showAsyncModal(IncludeExcludeModal, {
    selection,
    exclude,
  });
  if (!confirmData) {
    return false;
  }

  const shouldShowSlowNotification = selection.count > 1000;
  let submitNotification;

  if (shouldShowSlowNotification) {
    submitNotification = createSubmissionNotification({
      entityName: 'Excluded status',
      submittingMessage: 'Changing excluded status',
      expectedWaitSeconds: 30,
      erpType: activeTenant.erpType,
    });
  }

  try {
    if (selection.type === 'filter') {
      selection = await getPlanningListSelectionFromFilterSelection(selection);
    }

    await coreApiFetch(schemas.purchasing.postUpdateItemLocationInfo, {
      pathParams: {
        updateType: UpdateItemLocationInfoType.Exclude,
      },
      bodyParams: {
        updates: selection.items.map((record) => ({
          itemUid: record.item_uid,
          locationId: splitIdNameStr(record.location).foreignId,
          excluded: exclude,
          excludedUntil: confirmData?.excludedUntil,
        })),
      },
    });

    if (shouldShowSlowNotification && submitNotification) {
      submitNotification.success({
        successMessage: 'Successfully changed excluded status',
        duration: 10,
      });
    } else {
      notification.success({
        message: 'Successfully changed excluded status',
        duration: 3,
      });
    }

    track(TrackEvent.DemandPlanning_MinMaxInfo_Submission, {
      modalType: exclude ? PlanningModalType.Exclude : PlanningModalType.Include,
      itemCount: selection.count,
    });
  } catch (err) {
    if (shouldShowSlowNotification && submitNotification) {
      submitNotification.error(err);
    } else {
      captureAndShowError(err, `Unable to change excluded status`);
    }
  }

  return true;
}
