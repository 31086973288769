import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';

import {
  FilterOutlined,
  SearchOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  FilterTwoTone,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SortDirection, SavedViewType } from '@recurrency/core-api-schema/dist/common/enums';
import {
  GetSalesHistoryReportSortByField,
  GetSalesInvoiceLinesReportGroupBy,
  GetSalesInvoiceLinesReportQueryParams,
  SalesInvoiceLinesReportItemDTO,
} from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';
import { SavedViewDataPayload } from '@recurrency/core-api-schema/dist/savedViews/getSavedView';
import { UpdatePartialSavedViewBody } from '@recurrency/core-api-schema/dist/savedViews/patchUpdatePartialSavedView';
import { Radio, Menu, notification, message, Modal } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { ColumnType } from 'antd/lib/table';
import { theme } from 'theme';
import { useDebounce } from 'use-debounce/lib';
import { ZipCelXSheet } from 'zipcelx';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { DividerLine } from 'components/DividerLine';
import { Dropdown } from 'components/Dropdown';
import { FlexSpacer } from 'components/FlexSpacer';
import { Input } from 'components/Input';
import { CenteredError } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { RadioGroup } from 'components/Radio';
import { DownloadButton, DownloadXSheetColumn, recordsToXSheet } from 'components/recipes/DownloadButton';
import { LastUpdatedWithFrequency, LastUpdatedWithFrequencyEntity } from 'components/recipes/LastUpdatedMoment';
import { NewTaskButton } from 'components/recipes/NewTaskButton';
import {
  FilterByField,
  filterFieldByGroupByField,
  filterNameByField,
  getLastTwoYearLabels,
  getMonthlyFilledSparklineSeries,
  nameByGroupByField,
  ReportingUnitType,
} from 'components/recipes/salesReport/salesReportUtils';
import { getCurrentTimeZone } from 'components/recipes/salesReport/utils';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { ResultCount } from 'components/ResultCount';
import { Select } from 'components/Select';
import { Sparkline } from 'components/Sparkline';
import { Table } from 'components/Table';
import { FilterTag } from 'components/Tag/FilterTag';
import { Tooltip } from 'components/Tooltip';
import { Typography } from 'components/Typography';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch, fetchAllRecordsInBatches } from 'utils/api';
import { optArrAddVal, optArrFromVal, optArrRemoveVal } from 'utils/array';
import { truthy as isTruthy } from 'utils/boolean';
import { captureAndShowError } from 'utils/error';
import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatNumber, formatUSD, joinIdNameObj, splitIdNameStr, splitIfIdNameStr } from 'utils/formatting';
import { isObjEmpty, objMapKeys, objMapValues, objOmitKeys } from 'utils/object';
import { IdPathParams, routes, useHashState, usePathParams } from 'utils/routes';
import {
  sortableChangeDollarColumn,
  sortableChangeNumberColumn,
  sortableDollarColumn,
  sortableNumberColumn,
  sortableStringColumn,
} from 'utils/tables';
import { track, TrackEvent } from 'utils/track';

import { ReportingHashState } from 'types/hash-state';

import { ReportModal, ReportModalType } from '../ReportModal';

const pageSize = 50;
const defaultNumPrevYearsForSalesMonthly = 1;

export function ReportingFlow() {
  const history = useHistory();
  const [hashState, updateHashState] = useHashState<ReportingHashState>();
  const [searchQuery, setSearchQuery] = useState(hashState.query || '');
  const [reportModalType, setReportModalType] = useState(ReportModalType.Closed);
  const isDesktopView: boolean = useBreakpoint()?.lg ?? false;

  let sortOrder = hashState.sortDir === SortDirection.Asc ? ('ascend' as const) : ('descend' as const);
  let sortField = hashState.sortBy;

  const { id: reportId } = usePathParams<IdPathParams>();

  const {
    data: report,
    error: reportError,
    isLoading: isReportLoading,
    reload,
  } = useCoreApi(
    schemas.savedViews.getSavedView,
    reportId
      ? {
          pathParams: { savedViewId: reportId },
        }
      : null,
  );

  // If loading a saved report, update reporting hash state and search query on load
  useEffect(() => {
    if (reportId && report && !hashState.groupBy) {
      updateHashState(report.data);
      if (report.data.query) {
        setSearchQuery(report.data.query);
      }
    }
  }, [reportId, isReportLoading, report, updateHashState, hashState.groupBy]);

  // user opened blank page, add defaults to hash state
  if (!hashState.groupBy) {
    requestAnimationFrame(async () => {
      if (!reportId) {
        updateHashState({
          groupBy: GetSalesInvoiceLinesReportGroupBy.Location,
          sortBy: GetSalesHistoryReportSortByField.SalesYtd,
          sortDir: SortDirection.Desc,
          page: 1,
          filter: {},
          query: '',
          yearToDate: true,
          unitType: ReportingUnitType.Amount,
        });
      }
    });
  }

  // not using hashState.query directly because every hash state change causes
  // nav history change, which causes entire app to re-render
  const [debouncedQuery] = useDebounce(searchQuery, 500);
  useEffect(() => {
    updateHashState({ query: debouncedQuery, page: 1 });
  }, [debouncedQuery, updateHashState]);

  useEffect(() => {
    // groupBy is undefined when page loads, ignore pre-initialization hashState
    if (hashState.groupBy) {
      track(TrackEvent.Reporting_ReportingQuery, {
        ...objOmitKeys(hashState, 'filter'),
        ...objMapKeys(hashState.filter || {}, (k) => `filter.${k}`),
        numFilters: Object.keys(hashState?.filter || {}).length,
      });
    }
  }, [hashState]);

  const numPrevYearsForSalesMonthly = hashState.numPrevYearsForSalesMonthly ?? defaultNumPrevYearsForSalesMonthly;
  // hide GM YTD/LYTD/% columns to make space for wider sparklines
  const shouldShowGMColumns = numPrevYearsForSalesMonthly === defaultNumPrevYearsForSalesMonthly;
  const sparklineWidth = shouldShowGMColumns ? 200 : 500;

  // only keep the ids for api filtering
  const apiFilter = objMapValues(hashState.filter || {}, (value) =>
    (typeof value === 'string' ? optArrFromVal(value) : value)?.map((v) => splitIfIdNameStr(v)?.foreignId),
  );
  // don't filter on the if field is grouped, so we can allow multiple filtering by showing all options
  delete apiFilter[filterFieldByGroupByField[hashState.groupBy!]];

  const apiQueryParams: GetSalesInvoiceLinesReportQueryParams = {
    query: hashState.query,
    groupBy: hashState.groupBy,
    sortBy: hashState.sortBy,
    sortDir: hashState.sortDir,
    filter: apiFilter,
    limit: pageSize,
    offset: ((hashState.page ?? 1) - 1) * pageSize,
    yearToDate: hashState.yearToDate,
    numPrevYearsForSalesMonthly,
    requestTimezone: getCurrentTimeZone(),
  };

  const {
    isLoading: isDataLoading,
    data,
    error,
  } = useCoreApi(
    schemas.reports.getSalesInvoiceLinesReport,
    hashState.groupBy ? { queryParams: apiQueryParams } : null,
  );

  const isLoading = isReportLoading || isDataLoading;

  const hasUnsavedChanges =
    !isReportLoading &&
    report?.data &&
    !Object.keys(report.data)
      .map(
        (key) =>
          JSON.stringify(report.data[key as keyof SavedViewDataPayload]) ===
          JSON.stringify(hashState[key as keyof ReportingHashState]),
      )
      .every(isTruthy);

  if (error || reportError) {
    return <CenteredError error={error || reportError} />;
  }

  // if groupBy is month, then we force sort by month.
  // months being out of order look quite weird.
  if (hashState.groupBy === 'month' && data?.items) {
    data.items.sort((a, b) => a.foreignId.localeCompare(b.foreignId));
    sortField = GetSalesHistoryReportSortByField.ForeignId;
    sortOrder = 'ascend';
  }

  const { curYearLabel, lastYearLabel } = getLastTwoYearLabels(!!hashState.yearToDate);
  const showUnits = hashState.unitType === ReportingUnitType.Units;

  const tableColumns: (ColumnType<SalesInvoiceLinesReportItemDTO> | null)[] = [
    hashState.groupBy
      ? sortableStringColumn({
          title: hashState.groupBy === 'month' ? `#` : `ID`,
          dataIndex: 'foreignId',
          sorter: true,
          sortOrder: sortField === GetSalesHistoryReportSortByField.ForeignId ? sortOrder : null,
          render: (foreignId) =>
            hashState.groupBy === 'customer' ? (
              <Link to={routes.sales.customerDetails(foreignId)}>{foreignId}</Link>
            ) : hashState.groupBy === 'item' ? (
              <Link to={routes.sales.itemDetails(foreignId)}>{foreignId}</Link>
            ) : (
              foreignId
            ),
        })
      : null,
    hashState.groupBy
      ? sortableStringColumn({
          title: nameByGroupByField[hashState.groupBy],
          dataIndex: 'name',
          sorter: true,
          sortOrder: sortField === 'name' ? sortOrder : null,
        })
      : null,
    ...(showUnits
      ? [
          sortableNumberColumn({
            title: `Units ${curYearLabel}`,
            dataIndex: 'unitsYtd',
            sorter: true,
            sortOrder: sortField === 'unitsYtd' ? sortOrder : null,
          }),
          sortableNumberColumn({
            title: `Units ${lastYearLabel}`,
            dataIndex: 'unitsLYtd',
            sorter: true,
            sortOrder: sortField === 'unitsLYtd' ? sortOrder : null,
          }),
          sortableChangeNumberColumn({
            title: 'Units Δ',
            dataIndex: 'unitsDelta',
            sorter: true,
            sortOrder: sortField === 'unitsDelta' ? sortOrder : null,
          }),
          {
            title: `Units (Jan ${new Date().getFullYear() - numPrevYearsForSalesMonthly} - Present)`,
            dataIndex: 'unitsMonthly',
            width: `${sparklineWidth}px`,
            render: (unitsMonthly: Obj<number>) => (
              <Sparkline
                width={sparklineWidth}
                height={50}
                series={getMonthlyFilledSparklineSeries(
                  unitsMonthly,
                  new Date(new Date().getFullYear() - numPrevYearsForSalesMonthly, 0, 1),
                  new Date(),
                  formatNumber,
                )}
              />
            ),
          },
        ]
      : [
          sortableDollarColumn({
            title: `Sales ${curYearLabel}`,
            dataIndex: 'salesYtd',
            sorter: true,
            sortOrder: sortField === 'salesYtd' ? sortOrder : null,
          }),
          sortableDollarColumn({
            title: `Sales ${lastYearLabel}`,
            dataIndex: 'salesLYtd',
            sorter: true,
            sortOrder: sortField === 'salesLYtd' ? sortOrder : null,
          }),
          sortableChangeDollarColumn({
            title: 'Sales Δ',
            dataIndex: 'salesDelta',
            sorter: true,
            sortOrder: sortField === 'salesDelta' ? sortOrder : null,
          }),
          {
            title: `Sales (Jan ${new Date().getFullYear() - numPrevYearsForSalesMonthly} - Present)`,
            dataIndex: 'salesMonthly',
            width: `${sparklineWidth}px`,
            render: (salesMonthly: Obj<number>) => (
              <Sparkline
                width={sparklineWidth}
                height={50}
                series={getMonthlyFilledSparklineSeries(
                  salesMonthly,
                  new Date(new Date().getFullYear() - numPrevYearsForSalesMonthly, 0, 1),
                  new Date(),
                  formatUSD,
                )}
              />
            ),
          },
          shouldShowGMColumns
            ? sortableDollarColumn({
                title: `GM ${curYearLabel}`,
                dataIndex: 'gmYtd',
                sorter: true,
                sortOrder: sortField === 'gmYtd' ? sortOrder : null,
              })
            : null,
          shouldShowGMColumns
            ? sortableDollarColumn({
                title: `GM ${lastYearLabel}`,
                dataIndex: 'gmLYtd',
                sorter: true,
                sortOrder: sortField === 'gmLYtd' ? sortOrder : null,
              })
            : null,
          shouldShowGMColumns
            ? sortableChangeDollarColumn({
                title: 'GM Δ',
                dataIndex: 'gmDelta',
                sorter: true,
                sortOrder: sortField === 'gmDelta' ? sortOrder : null,
              })
            : null,
        ]),
    {
      title: 'Filter',
      align: 'left' as const,
      render: (record: SalesInvoiceLinesReportItemDTO) => {
        const filterField = filterFieldByGroupByField[hashState.groupBy!];
        const hasFilter = hashState.filter?.[filterField]?.includes(joinIdNameObj(record));

        return (
          // Action button toggles between 'Filter' and 'Clear' states.
          <Tooltip key={record.foreignId} title={!hasFilter ? `Filter on ${joinIdNameObj(record)}` : 'Remove Filter'}>
            <Button
              key={record.foreignId}
              icon={hasFilter ? <FilterTwoTone /> : <FilterOutlined />}
              onClick={() => {
                updateHashState({
                  filter: {
                    ...hashState.filter,
                    [filterField]: hasFilter
                      ? optArrRemoveVal(hashState.filter?.[filterField], joinIdNameObj(record))
                      : optArrAddVal(hashState.filter?.[filterField], joinIdNameObj(record)),
                  },
                  page: 1,
                  query: '',
                });
              }}
            >
              {hasFilter ? 'Remove' : 'Apply'}
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  const onCreateReport = async (name: string) => {
    if (hashState) {
      try {
        const { data: newReport } = await coreApiFetch(schemas.savedViews.createSavedView, {
          bodyParams: { name, isPinned: false, type: SavedViewType.SalesReport, data: hashState },
        });
        track(TrackEvent.Reporting_CreateReport, {
          ...objOmitKeys(hashState, 'filter'),
          numFilters: Object.keys(hashState?.filter || {}).length,
        });
        history.push(routes.reporting.report(newReport.id));
        onCloseModal();
        notification.success({ message: `New report "${newReport.name}" created` });
      } catch (err) {
        captureAndShowError(err, `Unable to create report "${name}"`);
      }
    } else {
      notification.error({ message: 'No report data' });
    }
  };

  const onUpdateReport = async (updates: UpdatePartialSavedViewBody) => {
    if (report) {
      try {
        if (report.isPinned && updates.isPinned === undefined) {
          updates.isPinned = report.isPinned;
        }
        const { data: newReport } = await coreApiFetch(schemas.savedViews.updatePartialSavedView, {
          pathParams: { savedViewId: report.id },
          bodyParams: updates,
        });
        track(TrackEvent.Reporting_UpdateReport, {
          changedIsPinned: report.isPinned !== updates.isPinned,
          changedName: report.name !== updates.name,
          changedData: Boolean(updates.data),
          ...objOmitKeys(hashState, 'filter'),
          numFilters: Object.keys(hashState?.filter || {}).length,
          isPinned: updates.isPinned ?? report.isPinned,
        });
        reload();
        onCloseModal();
        message.success(`Report "${newReport.name}" updated`);
      } catch (err) {
        captureAndShowError(err, `Unable to update report "${report.name}"`);
      }
    } else {
      notification.error({ message: 'No report loaded' });
    }
  };

  const onDeleteReport = () => {
    if (report) {
      Modal.confirm({
        title: `Are you sure you want to delete report "${report.name}"?`,
        icon: <ExclamationCircleOutlined />,
        content: 'This action cannot be undone.',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          postDelete();
        },
        onCancel() {},
      });
    } else {
      notification.error({ message: 'No report loaded' });
    }
  };

  const postDelete = async () => {
    if (report) {
      try {
        await coreApiFetch(schemas.savedViews.deleteSavedView, {
          pathParams: { savedViewId: report.id },
        });
        track(TrackEvent.Reporting_DeleteReport, {});
        history.push(routes.reporting.reports());
        notification.success({ message: `Deleted report "${report.name}"` });
      } catch (err) {
        captureAndShowError(err, `Unable to delete report "${report.name}"`);
      }
    } else {
      notification.error({ message: 'No report loaded' });
    }
  };

  const onCloseModal = () => {
    setReportModalType(ReportModalType.Closed);
  };

  const availableGroupByFields = Object.entries(nameByGroupByField).filter(
    ([groupByField]) => groupByField !== 'company',
  );

  return (
    <Container>
      <ReportModal
        type={reportModalType}
        onCancel={onCloseModal}
        onUpdateReport={onUpdateReport}
        onCreateReport={onCreateReport}
        report={report}
      />
      <PageHeader
        title={report?.name || 'Explorer'}
        subtitle={
          <LastUpdatedWithFrequency
            dateString={data?.lastUpdatedAt}
            lastUpdateEntity={LastUpdatedWithFrequencyEntity.SalesReport}
          />
        }
        entity={
          report
            ? {
                kind: report.name,
                badge: report.isPinned && <StatusBadge status={BadgeStatus.Pinned} />,
              }
            : undefined
        }
        headerActions={
          <>
            {report ? (
              <>
                {hasUnsavedChanges && (
                  <Typography type="small" style={{ color: theme.colors.primary[400] }}>
                    Unsaved changes
                  </Typography>
                )}
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => onUpdateReport({ data: hashState })} disabled={!hasUnsavedChanges}>
                        Save
                      </Menu.Item>
                      <Menu.Item onClick={() => setReportModalType(ReportModalType.New)}>Save As</Menu.Item>
                      <Menu.Item onClick={() => setReportModalType(ReportModalType.Rename)}>Rename</Menu.Item>
                      <Menu.Item onClick={() => onUpdateReport({ isPinned: !report?.isPinned })}>
                        {report?.isPinned ? 'Unpin from Dashboard' : 'Pin to Dashboard'}
                      </Menu.Item>
                      <Menu.Item onClick={onDeleteReport}>Delete</Menu.Item>
                    </Menu>
                  }
                >
                  <Button type="primary">
                    Save
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </>
            ) : (
              <>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => setReportModalType(ReportModalType.New)}>
                  New Report
                </Button>
                <NewTaskButton />
              </>
            )}
            <DownloadButton recordType="SalesHistory" getDownloadData={() => getDownloadData(apiQueryParams)} />
          </>
        }
      />

      <div
        className={css`
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-top: 16px;
          align-items: center;
        `}
      >
        {isDesktopView ? (
          <RadioGroup
            onChange={(ev) => {
              updateHashState({ groupBy: ev.target.value, query: '', page: 1 });
              setSearchQuery('');
            }}
            value={hashState.groupBy}
          >
            {availableGroupByFields.map(([groupByField, name]) => (
              <Radio.Button key={groupByField} value={groupByField}>
                {name}s
              </Radio.Button>
            ))}
          </RadioGroup>
        ) : (
          <Select
            className={css`
              width: 150px;
            `}
            size="small"
            value={hashState.groupBy}
            onChange={(value) => {
              updateHashState({ groupBy: value, query: '', page: 1 });
              setSearchQuery('');
            }}
            options={availableGroupByFields.map(([groupByField, name]) => ({
              label: `${name}s`,
              value: groupByField,
            }))}
          />
        )}
        <FlexSpacer />
        <Input
          prefix={<SearchOutlined />}
          type="search"
          value={searchQuery}
          placeholder={`Search${hashState.groupBy ? ` ${nameByGroupByField[hashState.groupBy]}s` : ''}`}
          onChange={(ev) => setSearchQuery(ev.target.value)}
          size="small"
          className={css`
            width: 200px;
          `}
        />
        <RadioGroup
          onChange={(ev) => {
            updateHashState({ unitType: ev.target.value, query: '', page: 1 });
            setSearchQuery('');
          }}
          value={hashState.unitType}
        >
          <Radio.Button value={ReportingUnitType.Amount}>Dollars</Radio.Button>
          <Radio.Button value={ReportingUnitType.Units}>Units</Radio.Button>
        </RadioGroup>
        <Select
          className={css`
            width: 100px;
          `}
          size="small"
          value={numPrevYearsForSalesMonthly}
          onChange={(value) => updateHashState({ numPrevYearsForSalesMonthly: value })}
          options={[
            { label: '2 Years', value: defaultNumPrevYearsForSalesMonthly },
            { label: '5 Years', value: 5 },
            { label: '10 Years', value: 10 },
          ]}
        />
        <Select
          className={css`
            width: 120px;
          `}
          size="small"
          value={!!hashState.yearToDate}
          onChange={(value) => updateHashState({ yearToDate: value })}
          options={[
            // as Any to appease typescript
            { label: 'Year to Date', value: true as Any },
            { label: 'Calendar Year', value: false as Any },
          ]}
        />
        <ResultCount count={data?.totalCount ?? 0} />
      </div>
      <div
        className={css`
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 16px;
          padding-top: 16px;
          gap: 8px;
        `}
      >
        <span>Filters:</span>
        {!isObjEmpty(hashState.filter ?? {}) ? (
          <Button
            type="link"
            onClick={() => {
              updateHashState({ filter: {}, query: '', page: 1 });
              setSearchQuery('');
            }}
            size="small"
          >
            Clear
          </Button>
        ) : (
          <span style={{ color: theme.colors.neutral[400] }}>No filters selected</span>
        )}
        {Object.entries(hashState.filter || {}).map(([filterField, filterValues]) => (
          <FilterTag
            key={filterField}
            onClose={() => {
              updateHashState({ filter: { ...hashState.filter, [filterField]: undefined } });
            }}
            filterName={filterNameByField[filterField as FilterByField]}
            filterValues={filterValues.map((v) => splitIdNameStr(v).name)}
            filterType="and"
          />
        ))}
      </div>
      <DividerLine />
      <Table
        columns={tableColumns.filter(filterCostAndGM)}
        data={data?.items || []}
        isLoading={isLoading}
        size="small"
        rowKey="foreignId"
        verticalAlign="center"
        onChange={(_pagination, _filters, sorter, { action }) => {
          // !Array.isArray is there to typeguard into one sorter field
          // stats table only uses single sorting, so this is okay
          if (action === 'sort' && !Array.isArray(sorter)) {
            updateHashState({
              sortBy: sorter.field as GetSalesHistoryReportSortByField,
              sortDir: sorter.order === 'ascend' ? SortDirection.Asc : SortDirection.Desc,
              page: 1,
            });
          }
        }}
        pagination={
          !isLoading &&
          (data?.totalCount ?? 0) > pageSize && {
            onChange: (page) => updateHashState({ page }),
            pageSize,
            simple: true,
            current: hashState.page,
            total: data?.totalCount,
          }
        }
      />
    </Container>
  );
}

async function getDownloadData(apiQueryParams: GetSalesInvoiceLinesReportQueryParams): Promise<ZipCelXSheet> {
  const records = await fetchAllRecordsInBatches((offset, limit) =>
    coreApiFetch(schemas.reports.getSalesInvoiceLinesReport, {
      queryParams: {
        ...apiQueryParams,
        offset,
        limit,
      },
    }),
  );

  const { curYearLabel, lastYearLabel } = getLastTwoYearLabels(!!apiQueryParams.yearToDate);

  const exportColumns: Array<DownloadXSheetColumn<SalesInvoiceLinesReportItemDTO>> = [
    { title: 'ID', type: 'string', value: (row) => row.foreignId },
    { title: 'Name', type: 'string', value: (row) => row.name },
    { title: `Sales ${curYearLabel}`, type: 'number', value: (row) => row.salesYtd },
    { title: `Sales ${lastYearLabel}`, type: 'number', value: (row) => row.salesLYtd },
    { title: `Sales Δ`, type: 'number', value: (row) => row.salesDelta },
    { title: `GM ${curYearLabel}`, type: 'number', value: (row) => row.gmYtd },
    { title: `GM ${lastYearLabel}`, type: 'number', value: (row) => row.gmLYtd },
    { title: `GM Δ`, type: 'number', value: (row) => row.gmDelta },
    { title: `Units ${curYearLabel}`, type: 'number', value: (row) => row.unitsYtd },
    { title: `Units ${lastYearLabel}`, type: 'number', value: (row) => row.unitsLYtd },
    { title: `Units Δ`, type: 'number', value: (row) => row.unitsDelta },
  ];

  return recordsToXSheet(records, exportColumns);
}
