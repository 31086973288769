import React from 'react';

import { ViewAllModal } from 'components/recipes/sidePane/ViewAllModal';

import { TransferTargetSummary, UpdatedTransferLine } from 'types/hash-state';

import { TransferTargetLinesAllLines } from './TransferTargetLinesAllLines';

export function TransferTargetLinesModal({
  isLoading,
  transferSummaries,
  lines,
  record,
  onClose,
}: {
  isLoading: boolean;
  transferSummaries: TransferTargetSummary[];
  lines: UpdatedTransferLine[];
  record: TransferTargetSummary;
  onClose: () => void;
}) {
  return (
    <ViewAllModal
      title={`Transfer Lines between Source Location ${record.sourceLocation.foreignId}: ${record.sourceLocation.name} 
      and Destination Location ${record.destinationLocation.foreignId}: ${record.destinationLocation.name}`}
      wrapClassName="transfer-target-lines-modal"
      content={
        <TransferTargetLinesAllLines
          isModal
          focusedSummary={record}
          isReadOnly
          isLoading={isLoading}
          transferSummaries={transferSummaries}
          lines={lines}
        />
      }
      onClose={onClose}
    />
  );
}
