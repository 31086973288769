import React from 'react';

import { css } from '@emotion/css';
import moment from 'moment';
import { theme } from 'theme';

import { FlexSpace } from 'components/FlexSpace';
import { ParsedInput, ParsedInputProps } from 'components/Input/ParsedInput';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { floatOrDefault } from 'utils/units';

interface InputOverrideProps extends Omit<ParsedInputProps<number>, 'valueParser'> {
  override?: {
    note?: string;
    updatedBy?: string;
    updatedAt?: string;
    quantity?: number; // This is the override value passed by the API. It's used as a default value for the input.
    baseQuantity?: number;
    uom?: string;
  };
}

export const InputOverride = ({ override, value, ...props }: InputOverrideProps) => {
  const tooltipTitle =
    override && override.updatedBy ? (
      <>
        Overridden {override.baseQuantity ? `from ${override.baseQuantity}` : ''} to {override.quantity} {override.uom}{' '}
        by {override.updatedBy} on {moment(override.updatedAt).format('MMM D YYYY')}.<br />
        {override.note ? `Note: ${override.note}.` : ''}
      </>
    ) : null;

  return (
    <FlexSpace>
      <ParsedInput<number>
        size="small"
        value={value}
        valueParser={(val) => floatOrDefault(val, 0)}
        className={css`
          min-width: 70px;
          text-align: right;
          padding-right: ${tooltipTitle ? '25px' : 'default'}; // Set padding when InfoTooltip is rendered
          background-color: ${value !== override?.quantity ? theme.colors.primary[100] : 'default'};
        `}
        {...props}
      />
      {!!tooltipTitle && (
        <span
          className={css`
            position: absolute;
            right: 15px;
            top: 14px;
          `}
        >
          <InfoTooltip title={tooltipTitle} useInfoIcon />
        </span>
      )}
    </FlexSpace>
  );
};
