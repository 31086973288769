import {
  P21LeadTimeSource,
  P21SafetyStockType,
  RecurrencyReplenishmentMethod,
  TransferTargetReviewMethod,
} from '@recurrency/core-api-schema/dist/common/enums';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';

import { formatPercent, pluralize } from 'utils/formatting';
import { getAllRecordsFromSearchIndex } from 'utils/search/search';
import { MinMaxSubmissionType } from 'utils/track';

export const safetyStockSourceNames = {
  [P21SafetyStockType.classDays]: 'ERP Purchase Class',
  [P21SafetyStockType.classServiceLevel]: 'ERP Purchase Class (Service Level)',
  [P21SafetyStockType.defaultDays]: 'Default',
  [P21SafetyStockType.itemDays]: 'ERP Item',
  [P21SafetyStockType.itemServiceLevel]: 'ERP Item (Service Level)',
  [P21SafetyStockType.overrideDays]: 'Override',
  [P21SafetyStockType.serviceLevel]: 'Default  (Service Level)',
  [P21SafetyStockType.supplierDays]: 'ERP Supplier',
  [P21SafetyStockType.supplierServiceLevel]: 'ERP Supplier (Service Level)',
};

export const transferTargetReviewMethodNames: Record<TransferTargetReviewMethod, string> = {
  [TransferTargetReviewMethod.Cycle]: 'Cycle',
  [TransferTargetReviewMethod.Schedule]: 'Schedule',
};

export const leadTimeSourceNames: Record<P21LeadTimeSource, string> = {
  [P21LeadTimeSource.smart]: 'Smart',
  [P21LeadTimeSource.override]: 'Override',
};

export interface ListItemsSelection {
  type: 'list';
  items: SearchPlanningDTO[];
  count: number;
}

export interface FilterItemsSelection {
  type: 'filter';
  filters?: Obj<string[]>;
  /** This corresponds to the user-friendly title of the filter keys */
  filterTitleMapping?: Obj<string | undefined>;
  count: number;
}

export type MultiModalSelection = ListItemsSelection | FilterItemsSelection;

export type PlanningBulkUpdateType =
  | 'minMax'
  | 'safetyStock'
  | 'leadTime'
  | 'includeExclude'
  | 'flagged'
  | 'minStockFloor';

export interface MinMaxBulkUpdate {
  type: 'minMax';
  customMin: number | undefined;
  customMax: number | undefined;
  isOpOqVariant: boolean;
  submissionType: MinMaxSubmissionType;
}

export interface SafetyStockUpdate {
  type: 'safetyStock';
  safetyStock: number | undefined;
}

export interface LeadTimeUpdate {
  type: 'leadTime';
  leadTime: number | undefined;
}

export interface IncludeExcludeUpdate {
  type: 'includeExclude';
  exclude: boolean;
}

export interface FlaggedUpdate {
  type: 'flagged';
  flagged: boolean;
  comment: string;
}

export interface MinStockFloorUpdate {
  type: 'minStockFloor';
  minStockFloor: number | undefined;
}

export type PlanningBulkUpdate =
  | MinMaxBulkUpdate
  | SafetyStockUpdate
  | LeadTimeUpdate
  | IncludeExcludeUpdate
  | FlaggedUpdate
  | MinStockFloorUpdate;

export async function getPlanningListSelectionFromFilterSelection(
  selection: FilterItemsSelection,
  fieldsToRetrieve: Array<keyof SearchPlanningDTO> = ['item_uid', 'location'],
): Promise<ListItemsSelection> {
  // turn filter selection into list selection by fetching relevant records
  const items = await getAllRecordsFromSearchIndex<SearchPlanningDTO>({
    indexName: SearchIndexName.Planning,
    filters: selection.filters,
    fieldsToRetrieve,
  });

  const listSelection: ListItemsSelection = {
    type: 'list',
    items,
    count: items.length,
  };

  return listSelection;
}

export type ExportValues = {
  exportedMin: number;
  exportedMax: number;
  exportedReplenishmentMethod: RecurrencyReplenishmentMethod;
  exportedSubmissionType: MinMaxSubmissionType;
};

export function isSafetyStockTypeServiceLevel(safetyStockType: P21SafetyStockType) {
  return /_SERVICE_LEVEL$/.test(safetyStockType);
}

export function formatSafetyStock(safetyStockValue: number | undefined, safetyStockType: P21SafetyStockType) {
  if (safetyStockValue === undefined) {
    return '-';
  }

  if (isSafetyStockTypeServiceLevel(safetyStockType)) {
    return formatPercent(safetyStockValue);
  }
  return pluralize(safetyStockValue, 'day', 'days', true);
}

export enum ExportOptions {
  Current = 'Current',
  CustomOpOq = 'Custom OP/OQ',
  CustomMinMax = 'Custom Min/Max',
  RecommendedOpOq = 'Recommended OP/OQ',
  RecommendedMinMax = 'Recommended Min/Max',
}

// This is used to keep track of the `Include` and `Exclude` type verbiage for the Item Location Setting Change Modal
export interface ItemLocationSettingChangeVerbiage {
  set: string;
  unset: string;
}

export const PlanningBulkUpdateToVerbiage: Record<PlanningBulkUpdateType, ItemLocationSettingChangeVerbiage> = {
  minMax: {
    set: 'Set',
    unset: 'Unset',
  },
  safetyStock: {
    set: 'Set',
    unset: 'Unset',
  },
  leadTime: {
    set: 'Set',
    unset: 'Unset',
  },
  includeExclude: {
    set: 'Exclude',
    unset: 'Include',
  },
  flagged: {
    set: 'Flag',
    unset: 'Resolve Flag',
  },
  minStockFloor: {
    set: 'Add',
    unset: 'Remove',
  },
};

export const PlanningBulkUpdateToFields: Record<PlanningBulkUpdateType, string[]> = {
  minMax: ['min_stock', 'max_stock', 'replenishment_method'],
  safetyStock: ['safety_stock_days'],
  leadTime: ['lead_time'],
  includeExclude: ['excluded', 'excluded_until'],
  flagged: ['flagged', 'flag_reason'],
  minStockFloor: ['min_stock_floor'],
};
