import React, { useEffect } from 'react';

import { useHistory } from 'react-router';

import { DashboardOutlined, EnvironmentOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { PurchaseTargetStatus, TransferTargetReviewMethod } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import {
  DEFAULT_TRANSFER_TARGET_DAYS_OF_WEEK,
  DEFAULT_TRANSFER_TARGET_REVIEW_METHOD,
  DEFAULT_TRANSFER_TARGET_WEEK_CADENCE,
  TransferTargetDTO,
} from '@recurrency/core-api-schema/dist/transferOrders/common';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { MultiSelectOption } from 'components/AsyncSelect/types';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { Container } from 'components/Container';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { PageHeader } from 'components/PageHeader';
import { ColumnChooserSection } from 'components/recipes/ColumnChooserSection';
import { EditTargetActionButton } from 'components/recipes/EditTargetActionButton';
import { LocationTransferSettingsModal } from 'components/recipes/LocationTransferSettingsModal';
import { StatusBadge } from 'components/recipes/StatusBadge';
import { ResultCount } from 'components/ResultCount';
import { SelectionCountAndClear } from 'components/SelectionCountAndClear';
import { Tooltip } from 'components/Tooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { capitalize, formatHumanDate, formatNumber, formatUSD } from 'utils/formatting';
import { objPickKeys } from 'utils/object';
import { routes, useHashState } from 'utils/routes';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { useUserViewSettingsState } from 'utils/tableAndSidePaneSettings/useUserViewSettingsState';
import {
  asKeyOf,
  sortableDateColumn,
  sortableIdColumn,
  sortableNumberColumn,
  sortDirections,
  withSortedColumn,
} from 'utils/tables';
import { getTenantSetting } from 'utils/tenantSettings';

import { TransferTargetsHashState } from 'types/hash-state';

import { reviewScheduleToReadable } from '../PurchaseTargetsPage/PurchaseTargetLinesPage/utils';
import { MarkSourceDestinationsAsReviewedButton } from './TransferReplenishmentLinesPage/MarkSourceDestinationsAsReviewedButton';
import { getOffsetDate } from './utils';

const statusOptions: MultiSelectOption[] = [
  { label: 'All', value: '' },
  { label: 'Ready', value: PurchaseTargetStatus.Ready },
  { label: 'Not Ready', value: PurchaseTargetStatus.NotReady },
];

export function TransferReplenishmentPage() {
  const history = useHistory();
  const locationsSelectProps = useLocationsSelectProps({});

  const [hashState, updateHashState] = useHashState<TransferTargetsHashState>();
  const { sourceLocationIds = [], destinationLocationIds = [], selectedTransferTargets = [] } = hashState;

  const defaultTransferCycleDays = getTenantSetting(TenantSettingKey.CalculationDefaultTransferCycleDays);

  const tableProps = useCoreApiTableProps({
    schema: schemas.transferOrders.getTransferTargets,
    queryParams: {
      filter: {
        sourceLocationIds,
        destinationLocationIds,
        statuses: hashState.status ? [hashState.status] : [],
      },
    },
    initialState: {
      sortBy: 'nextReviewDate',
      sortDir: 'asc',
    },
  });
  const { setPage, reload: reloadTransferTargets } = tableProps;

  /** the purpose of this string is to memoize these values so that the useEffect below doesn't trigger when the values are the same.
   * The arrays in hashState are recreated per render and considered new objects, causing re-renders if added directly to the useEffect dependency array */
  const stringifyHashState = JSON.stringify(
    objPickKeys(hashState, 'status', 'sourceLocationIds', 'destinationLocationIds'),
  );

  useEffect(() => {
    setPage(1);
  }, [stringifyHashState, setPage]);

  const tableColumns: PersistedColumn<TransferTargetDTO>[] = [
    sortableIdColumn({
      title: 'Source Location',
      dataIndex: asKeyOf<TransferTargetDTO>('sourceLocationId'),
      sorter: true,
      settingKey: 'sourceLocationId',
      required: true,
      render: (_, record: TransferTargetDTO) => `${record.sourceLocationId}: ${record.sourceLocationName}`,
    }),
    sortableIdColumn({
      title: 'Destination Location',
      dataIndex: asKeyOf<TransferTargetDTO>('destinationLocationId'),
      sorter: true,
      settingKey: 'destinationLocationId',
      required: true,
      render: (_, record: TransferTargetDTO) => `${record.destinationLocationId}: ${record.destinationLocationName}`,
    }),
    sortableDateColumn({
      title: 'Last Transfered',
      dataIndex: asKeyOf<TransferTargetDTO>('lastTransferDate'),
      sorter: true,
      align: 'right',
      settingKey: 'lastTransferDate',
    }),
    {
      title: 'Review Schedule',
      align: 'right' as const,
      settingKey: 'reviewSchedule',
      render: (_, record: TransferTargetDTO) => {
        const recordReviewMethod =
          (record.reviewMethod as TransferTargetReviewMethod) || DEFAULT_TRANSFER_TARGET_REVIEW_METHOD;
        const displayString = reviewScheduleToReadable(
          record.cycleDays || defaultTransferCycleDays,
          record.scheduleDaysOfWeek || DEFAULT_TRANSFER_TARGET_DAYS_OF_WEEK,
          record.scheduleWeekCadence || DEFAULT_TRANSFER_TARGET_WEEK_CADENCE,
          recordReviewMethod,
        );

        return (
          <EditTargetActionButton
            value={
              recordReviewMethod === TransferTargetReviewMethod.Cycle ? (
                displayString
              ) : (
                <Tooltip
                  title={reviewScheduleToReadable(
                    record.cycleDays || defaultTransferCycleDays,
                    record.scheduleDaysOfWeek || DEFAULT_TRANSFER_TARGET_DAYS_OF_WEEK,
                    record.scheduleWeekCadence || DEFAULT_TRANSFER_TARGET_WEEK_CADENCE,
                    recordReviewMethod,
                    true,
                  )}
                >
                  {displayString}
                </Tooltip>
              )
            }
            onClick={() => {
              showAsyncModal(LocationTransferSettingsModal, {
                sourceLocationId: record.sourceLocationId,
                destinationLocationId: record.destinationLocationId,
                awaitPlanningResync: false,
                reload: reloadTransferTargets,
              });
            }}
          />
        );
      },
    },
    sortableDateColumn({
      title: 'Last Reviewed',
      dataIndex: asKeyOf<TransferTargetDTO>('lastReviewDate'),
      sorter: true,
      align: 'right' as const,
      settingKey: 'lastReviewDate',
      render: (lastDate: string) => {
        const offsetDate = getOffsetDate(lastDate);
        return (
          <Tooltip title={offsetDate.format('MM/DD/YY')}>{capitalize(formatHumanDate(offsetDate.toString()))}</Tooltip>
        );
      },
    }),
    sortableDateColumn({
      title: 'Next Review',
      dataIndex: asKeyOf<TransferTargetDTO>('nextReviewDate'),
      sorter: true,
      align: 'right' as const,
      settingKey: 'nextReviewDate',
      render: (nextDate: string) => {
        if (nextDate) {
          const offsetDate = getOffsetDate(nextDate);
          return (
            <Tooltip title={offsetDate.format('MM/DD/YY')}>
              {capitalize(formatHumanDate(offsetDate.toString()))}
            </Tooltip>
          );
        }
        return 'Today';
      },
    }),
    sortableNumberColumn({
      title: 'Lines',
      dataIndex: asKeyOf<TransferTargetDTO>('transferLineCount'),
      sorter: true,
      settingKey: 'transferLineCount',
      render: (transferLineCount: number) => formatNumber(transferLineCount),
    }),
    {
      title: 'Total Line Cost',
      dataIndex: asKeyOf<TransferTargetDTO>('totalCost'),
      sorter: true,
      sortDirections,
      align: 'right' as const,
      settingKey: 'totalCost',
      render: (currentValue: number) => formatUSD(currentValue),
    },
    {
      title: 'Backordered Line Cost',
      dataIndex: asKeyOf<TransferTargetDTO>('totalBackorderedCost'),
      sorter: true,
      sortDirections,
      align: 'right' as const,
      settingKey: 'totalBackorderedCost',
      optional: true,
      render: (totalBackorderedCost: number) => formatUSD(totalBackorderedCost),
    },
    {
      title: 'Status',
      dataIndex: asKeyOf<TransferTargetDTO>('status'),
      settingKey: 'status',
      render: (status) => {
        const tooltip =
          status === PurchaseTargetStatus.Ready
            ? 'This transfer is ready to be reviewed based on the review schedule'
            : 'This transfer does not need to be reviewed based on the review schedule. It can still be viewed and created';
        return (
          <Tooltip title={tooltip}>
            <StatusBadge
              status={status}
              className={css`
                cursor: help;
              `}
            />
          </Tooltip>
        );
      },
    },
    {
      title: 'Actions',
      width: '100px',
      align: 'left',
      settingKey: 'actions',
      render: (record: TransferTargetDTO) => (
        <div
          className={css`
            display: flex;
            flex-direction: row;
            gap: 4px;
          `}
        >
          <MarkSourceDestinationsAsReviewedButton
            sourceDestinations={[record]}
            smallVariant
            onComplete={() => reloadTransferTargets()}
          />
          <Button
            size="small"
            data-test-id="transferTargetLinesButton"
            disabled={selectedTransferTargets.length > 0}
            onClick={() => {
              history.push(
                routes.purchasing.transferTargetLines({
                  selectedTransferTargets: [record],
                }),
              );
            }}
          >
            Lines <RightOutlined />
          </Button>
        </div>
      ),
    },

    // optional
    sortableNumberColumn({
      title: 'Current Weight',
      dataIndex: asKeyOf<TransferTargetDTO>('totalWeight'),
      settingKey: 'totalWeight',
      sorter: true,
      optional: true,
      render: (currentWeight: number) => formatNumber(currentWeight),
    }),
    sortableNumberColumn({
      title: 'Current Volume',
      dataIndex: asKeyOf<TransferTargetDTO>('totalVolume'),
      settingKey: 'totalVolume',
      sorter: true,
      optional: true,
      render: (currentVolume: number) => formatNumber(currentVolume),
    }),
    sortableNumberColumn({
      title: 'Transfer Days',
      dataIndex: asKeyOf<TransferTargetDTO>('transferDays'),
      settingKey: 'transferDays',
      sorter: true,
      optional: true,
      render: (transferDays: number) => formatNumber(transferDays),
    }),
  ];

  const [visibleColumnKeys, setVisibleColumnKeys] = useUserViewSettingsState(
    ViewSettingKey.TransferTargetsTable,
    tableColumns.filter((column) => !column.optional).map((column) => column.settingKey),
  );

  return (
    <Container>
      <PageHeader
        title={
          <InfoTooltip
            title={[
              'Transfer replenishment recommends transfers from replenishment locations down to requirement locations following the replenishment paths defined within Recurrency.',
            ].join(' ')}
          >
            Transfer Replenishment
          </InfoTooltip>
        }
        headerActions={
          <Button
            type="primary"
            disabled={selectedTransferTargets.length === 0}
            onClick={() => {
              history.push(
                routes.purchasing.transferTargetLines({
                  selectedTransferTargets,
                }),
              );
            }}
          >
            Transfer Builder
          </Button>
        }
      />
      <FilterBarBox dividerLine>
        <FlexSpace direction="column" gap={16} fullWidth>
          <FlexSpace wrap fullWidth>
            <span>Filter</span>
            <AsyncMultiSelect
              label="Status"
              mode="single"
              icon={<DashboardOutlined />}
              selectProps={{ options: statusOptions }}
              selectedValues={[hashState.status || statusOptions[0].value]}
              onSelectedValuesChange={(values) =>
                updateHashState({ status: (values[0] || '') as PurchaseTargetStatus })
              }
            />
            <AsyncMultiSelect
              selectProps={convertToMultiSelectProps(locationsSelectProps)}
              label="Source Location"
              queryPlaceholder="Search locations"
              selectedValues={sourceLocationIds}
              onSelectedValuesChange={(values) => updateHashState({ sourceLocationIds: values })}
              icon={<EnvironmentOutlined />}
            />
            <AsyncMultiSelect
              selectProps={convertToMultiSelectProps(locationsSelectProps)}
              label="Destination Location"
              queryPlaceholder="Search locations"
              selectedValues={destinationLocationIds}
              onSelectedValuesChange={(values) => updateHashState({ destinationLocationIds: values })}
              icon={<EnvironmentOutlined />}
            />
            {hashState.status || hashState.destinationLocationIds || hashState.sourceLocationIds ? (
              <ActionButton
                onClick={() =>
                  updateHashState({
                    status: undefined,
                    sourceLocationIds: undefined,
                    destinationLocationIds: undefined,
                  })
                }
                label="Clear All"
              />
            ) : null}
            <FlexSpacer />
            <ResultCount count={tableProps.totalCount} />
          </FlexSpace>
        </FlexSpace>
      </FilterBarBox>

      <SelectionCountAndClear
        numSelected={selectedTransferTargets.length}
        entitySingle="row"
        onClear={() => updateHashState({ selectedTransferTargets: undefined })}
      />

      <ColumnChooserSection
        tableKey={ViewSettingKey.TransferTargetsTable}
        columns={tableColumns}
        visibleColumnKeys={visibleColumnKeys}
        setVisibleColumnKeys={setVisibleColumnKeys}
      />

      <AsyncTable
        stickyHeader
        tableProps={tableProps}
        columns={withSortedColumn(
          visibleColumnKeys.map((key) => tableColumns.find((column) => column.settingKey === key)).filter(truthy),
          tableProps.sortBy,
          tableProps.sortDir,
        )}
        rowKey={getRowKey}
        rowSelection={{
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedTransferTargets.map(getRowKey),
          onChange: (newSelectedRowKeys: React.Key[]) => {
            const transferTargetsData = [...selectedTransferTargets, ...tableProps.items];
            const newSelection = newSelectedRowKeys
              .map((key) => transferTargetsData.find((st) => getRowKey(st) === key))
              .filter(truthy);
            updateHashState({ selectedTransferTargets: newSelection });
          },
        }}
      />
    </Container>
  );
}

const getRowKey = (record: TransferTargetDTO) => `${record.sourceLocationId}|${record.destinationLocationId}`;
