import React, { useCallback, useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { RecurrencyRole } from '@recurrency/core-api-schema/dist/common/enums';
import {
  TenantSettings,
  TenantSettingsFields,
  TenantSettingsForm,
} from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { TenantSettingsSection } from '@recurrency/core-api-schema/dist/common/tenantSettingsTypes';
import { Form, FormInstance, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { VERTICAL_NAVIGATION_HEIGHT } from 'App/frames/Navigation/navConstants';
import { fuzzyFilter } from 'fuzzbunny';
import { colors } from 'theme/colors';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { DividerLine } from 'components/DividerLine';
import { FixedFooter } from 'components/FixedFooter';
import { FixedTableOfContents } from 'components/FixedTableOfContents';
import { CenteredLoader } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { BulkImportDropdown } from 'components/recipes/BulkImportModal/BulkImportDropdown/BulkImportDropdown';
import { SettingsField } from 'components/recipes/SettingsField';
import { SearchInput } from 'components/SearchInput';
import { Typography } from 'components/Typography';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { loadActiveTenantSettings } from 'utils/globalAppLoaders';
import { shouldShowProduct } from 'utils/roleAndTenant';
import { useHashState } from 'utils/routes';
import { convertTenantSettingsForDisplay, convertTenantSettingsForSubmit } from 'utils/tenantSettings';
import { track, TrackEvent } from 'utils/track';

import { SettingsPageHashState } from 'types/hash-state';

import { PendingSettingsAlert } from './PendingSettingsAlert';
import { shouldDisplayField } from './utils';

const defaultSettingsRecurrencyRole = RecurrencyRole.TenantAdmin;

// Aggregate all roles that can see at least one field to determine access to settings page in nav and routing
export const recurrencyRolesToShowSettingsPage = [
  defaultSettingsRecurrencyRole,
  ...Array.from(
    new Set(
      Object.values(TenantSettingsFields)
        .map((field) => field.roles ?? [])
        .flat(),
    ),
  ),
];

export const SettingsPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [form] = Form.useForm();
  const [hashState] = useHashState<SettingsPageHashState>();
  const { activeTenant, activeUser, activeTenantSettings } = useGlobalApp();
  const [pendingSettings, setPendingSettings] = useState<Partial<TenantSettings>>({});
  const isDesktopView = useBreakpoint()?.lg ?? false;
  const hasValidActiveTenantSettings = !!activeTenantSettings;
  const [isLoading, setIsLoading] = useState(false);

  // Handle scrolling to hash element
  useEffect(() => {
    const settingElement = hashState?.setting && document.getElementById(hashState.setting);
    // Prevent scrolling if settings aren't loaded
    if (hasValidActiveTenantSettings && settingElement) {
      settingElement.scrollIntoView({ behavior: 'auto', block: 'start' });
      window.scrollBy(0, -1 * VERTICAL_NAVIGATION_HEIGHT);
    }
  }, [hashState, hasValidActiveTenantSettings]);

  const loadSettingsAndResetForm = useCallback(async () => {
    const result = await loadActiveTenantSettings();
    setPendingSettings(result.pendingSettings);
    form.resetFields();
  }, [form]);

  useEffect(() => {
    loadSettingsAndResetForm();
  }, [loadSettingsAndResetForm]);

  if (!activeTenantSettings) {
    return <CenteredLoader />;
  }

  const displayTenantSettings = convertTenantSettingsForDisplay(activeTenantSettings, pendingSettings);

  const onSubmit = async (settings: Partial<TenantSettings>) => {
    try {
      setIsLoading(true);
      track(TrackEvent.Settings_UpdateSettings, {});
      const response = await coreApiFetch(schemas.tenantSettings.patchTenantSettings, {
        bodyParams: {
          settings: convertTenantSettingsForSubmit(settings),
        },
      });
      notification.success({
        message: 'Settings updated',
        description:
          response.data.requiresTransformRun || response.data.requiresOvernightSync
            ? `${response.data.requiresTransformRun ? 'Some changes may take a few minutes to apply. ' : ''}${
                response.data.requiresOvernightSync
                  ? 'Some changes require significant recalculations and will be applied overnight.'
                  : ''
              }`
            : 'Changes applied successfully.',
      });
      loadSettingsAndResetForm();
    } catch (e) {
      captureAndShowError(e, 'Error saving tenant settings, please try again later.', {
        duration: 0,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const sections = TenantSettingsForm.sections
    // Filter sections based on whether the tenant has access to any of the products in the section
    // Check based on whether backend is enabled so we can configure settings while new products are being demoed
    .filter((section) =>
      section.products ? section.products.some((product) => shouldShowProduct(activeTenant, product, true)) : true,
    )
    // Filter fields
    .map((section) => ({
      ...section,
      fields: section.fields.filter(
        (field) =>
          // Filter by erp type
          (field.erpTypes ? field.erpTypes.includes(activeTenant.erpType) : true) &&
          // Filter by Recurrency role
          activeTenant.tenantUser.recurrencyRoles.some(
            (role) => role.name === defaultSettingsRecurrencyRole || field.roles?.includes(role.name),
          ) &&
          // Filter out internal only fields if user is not internal
          (!field.internalOnly || activeUser.isRecurrencyInternalUser) &&
          // Are there conditional display rules. All must evaluate to true.
          // i.e. new Feature determines visibility of new calculation field
          // or new version of existing setting.
          (activeUser.isRecurrencyInternalUser || shouldDisplayField(field, activeTenantSettings, pendingSettings)),
      ),
    }))
    // Only show sections with at least one field
    .filter((section) => section.fields.length > 0);

  const searchFilteredSections = sections
    .map((section) => ({
      ...section,
      fields: fuzzyFilter(section.fields, searchQuery, {
        fields: ['title', 'description'],
      }).map(({ item }) => item),
    }))
    .filter((section) => section.fields.length > 0);

  return (
    <Container>
      <PendingSettingsAlert settings={activeTenantSettings} pendingSettings={pendingSettings} />
      <PageHeader
        title="Settings"
        headerActions={
          <>
            {activeUser.isRecurrencyAdmin && <BulkImportDropdown />}
            <SearchInput
              placeholder="Search settings"
              query={searchQuery}
              onQueryChange={(newSearchQuery) => setSearchQuery(newSearchQuery)}
            />
          </>
        }
      />
      <DividerLine />
      <Form
        className={css`
          margin: auto;
          display: flex;
          justify-content: left;
          gap: 16px;
          overflow: auto;
        `}
        form={form}
        onChange={() => form.validateFields()}
        onFinish={() => {
          onSubmit(
            // Provide an explicit list of keys to submit all fields in the form, regardless of searchQuery filter
            form.getFieldsValue(sections.map((section) => section.fields.map((field) => field.key)).flat()),
          );
        }}
        onFinishFailed={() =>
          notification.error({ message: 'Changes not saved, resolve all validation errors first.' })
        }
        initialValues={displayTenantSettings}
        layout="vertical"
      >
        {searchFilteredSections.length > 0 ? (
          <>
            {isDesktopView && <FixedTableOfContents ids={searchFilteredSections.map((section) => section.title)} />}
            <div
              className={css`
                max-width: 700px;
              `}
            >
              <div>
                {searchFilteredSections.map((section) => (
                  <SettingsSection section={section} key={section.title} form={form} />
                ))}
              </div>
              <FixedFooter>
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  Save Settings
                </Button>
              </FixedFooter>
            </div>
          </>
        ) : (
          <div
            className={css`
              text-align: center;
            `}
          >
            {searchQuery
              ? `No settings found for "${searchQuery}".`
              : `No settings found. Request additional permissions from your administrator.`}
          </div>
        )}
      </Form>
    </Container>
  );
};

const SettingsSection = ({ section, form }: { section: TenantSettingsSection; form: FormInstance }) => (
  <div
    className={css`
      padding-bottom: 10px;
      padding-top: 10px;
      :not(:last-child) {
        margin-bottom: 30px;
        border-bottom: 1px solid ${colors.neutral[300]};
      }
    `}
    id={section.title}
  >
    <div
      className={css`
        margin-bottom: 20px;
      `}
    >
      <Typography type="large">{section.title}</Typography>
    </div>
    {section.fields.map((field) => (
      <SettingsField field={field} key={field.key} form={form} />
    ))}
  </div>
);
