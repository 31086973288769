import React from 'react';

import { WarningOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { theme } from 'theme';

import { MiniTableRowSkeleton } from 'components/MiniTable';
import { Tooltip } from 'components/Tooltip';

import { useCoreApi } from 'hooks/useApi';

import { formatNumber } from 'utils/formatting';
import { qtyUnitConverter } from 'utils/units';

import { SearchIndexItemPartial } from 'types/search-collections';

import { LabelValueProperties, LabelValueProperty } from './LabelValueProperties';

export function LocationAvailabilityProperties({
  algoliaItem,
  locationId,
}: {
  algoliaItem: SearchIndexItemPartial;
  locationId: string;
}) {
  const { uom: unitOfMeasure } = algoliaItem;
  const { data: locationAvailabilityData } = useCoreApi(schemas.items.getItemAvailabilityV2, {
    // fallback to item_id for SAPB1
    pathParams: { itemUid: algoliaItem.inv_mast_uid || algoliaItem.item_id },
  });

  if (!locationAvailabilityData) {
    return (
      <div>
        <MiniTableRowSkeleton />
        <MiniTableRowSkeleton />
      </div>
    );
  }

  const locationAvailability = locationAvailabilityData.items.find((avail) => avail.locationId === locationId);
  const locationStock = locationAvailability
    ? qtyUnitConverter(locationAvailability.qtyAvailable, locationAvailability.unitSize)
    : 0;

  const companyStock = locationAvailabilityData.items.reduce(
    (stock, availability) => stock + qtyUnitConverter(availability.qtyAvailable, availability.unitSize),
    0,
  );

  const nonBuyable = algoliaItem.buyable_location_ids && algoliaItem.buyable_location_ids?.includes(locationId);
  const nonSellable = algoliaItem.sellable_location_ids && algoliaItem.sellable_location_ids?.includes(locationId);
  const nonStockable = algoliaItem.stockable_location_ids && algoliaItem.stockable_location_ids?.includes(locationId);

  // if the item is (1) not buyable (2). not (nonStockable and can sell) at location,
  // no backorders from this location are allowed. user should only sell remaining stock.
  // SAPB1 doesn't populate buyable_location_ids yet, so we assume item is buyable everywhere
  const noBackorder = nonBuyable && !(nonStockable && !nonSellable);

  const props: Array<LabelValueProperty | null> = [
    {
      label: 'Available',
      value: (
        <>
          {`${formatNumber(locationStock)} ${unitOfMeasure || ``}`}
          {noBackorder ? (
            <>
              &nbsp;
              <Tooltip title="Unable to exceed quantity available as this item is not buyable at this location">
                <WarningOutlined style={{ color: theme.colors.danger[500] }} />
              </Tooltip>
            </>
          ) : null}
        </>
      ),
    },
    { label: 'Company Qty Available', value: `${formatNumber(companyStock)} ${unitOfMeasure || ``}` },
  ];

  return <LabelValueProperties properties={props} />;
}
