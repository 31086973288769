import React, { useRef } from 'react';

import { css } from '@emotion/css';
import { Table as AntdTable, Grid } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { TablePaginationConfig, TableProps as AntTableProps } from 'antd/lib/table';
import { ColumnType } from 'antd/lib/table/interface';
import { VERTICAL_NAVIGATION_HEIGHT } from 'App/frames/Navigation/navConstants';
import { mediaQueries } from 'theme/breakpoints';
import { colors } from 'theme/colors';

import { loaderSpinProps } from 'components/Loaders';

import { addClassNamesToColumns, getArrayOfClassNames, getColumnWidth } from './utils';

export const DEFAULT_PAGE_SIZE = 20;
export const MODAL_HEADER_OFFSET = -25;

// Each variant maps to a different offset for the sticky header
export enum StickyHeaderVariant {
  Default = VERTICAL_NAVIGATION_HEIGHT,
  Modal = MODAL_HEADER_OFFSET,
}

export interface TableProps extends Omit<AntTableProps<Any>, 'loading'> {
  data: Any[];
  isLoading?: boolean;
  size?: SizeType;
  columns: ColumnType<Any>[];
  /** Take full control of pagination to for api powered tables where data.length is not same as totalCount  */
  pagination?: false | TablePaginationConfig;
  /** For frontend only pagination where totalCount === data.length. If data.length <= pageSize, then pagination isn't shown. */
  pageSize?: number;
  verticalAlign?: string;
  stickyHeaderVariant?: StickyHeaderVariant;
  stickyHeader?: boolean;
}

export const Table = (props: TableProps) => {
  const {
    columns,
    data,
    isLoading,
    size = 'small',
    verticalAlign = 'top',
    pagination,
    pageSize = DEFAULT_PAGE_SIZE,
    stickyHeader = false,
    stickyHeaderVariant = StickyHeaderVariant.Default,
    scroll,
    ...others
  } = props;

  // for frontend only pagination, don't show pagination if data.length <= pageSize
  let tablePagination = pagination;
  if (pagination === undefined) {
    tablePagination = data?.length <= pageSize ? false : { simple: true, pageSize };
  }

  // When Antd Table switches between loading -> data -> loading states
  // data goes empty, which makes columns have a big layout shift, which is jarring to the user.
  // We preserve last value of data and use that when table is re-loading.
  // User can't interact with old table, but still sees a grayed out version of old data.
  let loading: AntTableProps<Any>['loading'] = isLoading;
  let dataSource = data;
  const prevDataRef = useRef<Any[]>();
  const isDataNotEmpty = Array.isArray(data) && data.length > 0;
  if (isDataNotEmpty) prevDataRef.current = data;
  if (isLoading && prevDataRef.current) {
    dataSource = isDataNotEmpty ? data : prevDataRef.current;
    loading = loaderSpinProps;
  }

  const isTabletOrMobileView = !(Grid.useBreakpoint()?.xxl ?? false);

  const adjustFixedColumns = (cols: ColumnType<Any>[]) =>
    isTabletOrMobileView || dataSource.length === 0
      ? cols.map((column) => {
          if (column.fixed) {
            return { ...column, fixed: undefined };
          }
          return column;
        })
      : cols;

  const addMinWidthStyles = (cols: { className: string; isSortable: boolean }[]) =>
    cols
      .map(
        ({ className, isSortable }) =>
          // Adjust the min-width based on the length of the column title
          `.${className} { min-width: ${getColumnWidth({ className, tableScroll: scroll, isSortable })}px; }`,
      )
      .join('\n');

  const minWidthStyles = addMinWidthStyles(getArrayOfClassNames(columns));

  return (
    <div
      className={css`
        tbody {
          vertical-align: ${verticalAlign};
        }

        .ant-table.ant-table-small {
          font-size: 10px;
          ${mediaQueries.sm} {
            font-size: 14px;
          }

          .ant-table-title {
            padding: 0;
          }

          .ant-table-thead .ant-table-column-sorters,
          .ant-table-cell {
            padding: 2px 2px;
            ${mediaQueries.sm} {
              padding: 8px 8px;
            }

            &:first-child {
              padding-left: 8px;
            }
            &:last-child {
              padding-right: 8px;
            }
          }
        }

        tr.ant-table-row {
          &.focusable-row {
            cursor: pointer;

            &.focused-row > td {
              background-color: ${colors.primary[200]};
            }
          }
        }

        .ant-pagination {
          justify-content: center;
          .ant-pagination-item,
          .ant-select-selector,
          .ant-pagination-item-link {
            border-radius: 8px;
          }
        }

        ${stickyHeader
          ? css`
              // Add min-width to columns based on column title size
              .ant-table-header,
              .ant-table-body {
                th,
                td {
                  min-width: 50px;
                  // When we have a scrollable table, we limit max column width
                  max-width: ${scroll?.x === true ? '190' : '350'}px;
                }
                ${minWidthStyles}
              }

              // Avoid table header opacity on hover
              th.ant-table-column-has-sorters:hover {
                background: #fafafa;
              }

              // When table has no data, Adjust the header column widths
              ${dataSource.length === 0
                ? css`
                    .ant-table-container table {
                      width: max-content;
                      min-width: 100%;
                    }

                    .ant-table-body table {
                      width: 100% !important;
                    }
                  `
                : ''}

              // When table is loading, adjust sticky header position and table width
              .ant-spin-container.ant-spin-blur {
                overflow: visible;

                .ant-table-container table {
                  width: max-content;
                  min-width: 100%;
                  table-layout: auto !important; // Fix loading state for Safari
                }

                .ant-table-header.ant-table-sticky-holder {
                  top: 90px !important;
                  overflow: visible;
                }
              }
            `
          : css`
              width: 100%;
              overflow-x: auto;

              ${mediaQueries.sm} {
                width: auto;
              }
            `}
      `}
    >
      <AntdTable
        columns={stickyHeader ? addClassNamesToColumns(adjustFixedColumns(columns)) : adjustFixedColumns(columns)}
        size={size}
        dataSource={dataSource}
        loading={loading}
        pagination={tablePagination}
        showSorterTooltip={false}
        sticky={stickyHeader ? { offsetHeader: stickyHeaderVariant } : false}
        scroll={stickyHeader ? { x: 'max-content' } : scroll}
        {...others}
      />
    </div>
  );
};
