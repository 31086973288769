import React, { useState } from 'react';

import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';

import { EquationPopover } from 'components/recipes/equation/EquationPopover';

import { ProjectedInventoryChangeEquation } from './equations/ProjectedInventoryChangeEquation';

export const ProjectedInventoryChangePopover = ({ record }: { record: SearchPlanningDTO }) => {
  const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
  // This is to reset the visibility to undefined so it can be opened on hover after it is closed
  const onVisibleChange = (isVisible: boolean) => {
    const visibility = isVisible === false ? undefined : isVisible;
    setIsOpen(visibility);
  };
  return (
    <EquationPopover title="Projected Inventory Change Calculation" visible={isOpen} onVisibleChange={onVisibleChange}>
      <span>
        The projected inventory change is calculated from the distance between the midpoints (average of min and max) of
        the current and suggested recommendations
      </span>
      <ProjectedInventoryChangeEquation record={record} />
    </EquationPopover>
  );
};
