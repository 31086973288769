import React from 'react';

import { Link } from 'react-router-dom';

import { TransferOrderLineDTO } from '@recurrency/core-api-schema/dist/transferOrders/getTransferOrderDetails';
import { ColumnType } from 'antd/lib/table';

import { ButtonLink } from 'components/Links';
import { Table } from 'components/Table';

import { formatUSD } from 'utils/formatting';
import { routes } from 'utils/routes';
import {
  asKeyOf,
  sortableDollarWithCentsColumn,
  sortableIdColumn,
  sortableNumberColumn,
  sortableStringColumn,
} from 'utils/tables';

export const TransferOrderLinesTable = ({ lines }: { lines: TransferOrderLineDTO[] }) => {
  const columns: ColumnType<TransferOrderLineDTO>[] = [
    sortableIdColumn({
      title: 'Item ID',
      dataIndex: asKeyOf<TransferOrderLineDTO>('itemCode'),
      render: (id: string) => <Link to={routes.purchasing.itemDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: asKeyOf<TransferOrderLineDTO>('itemName'),
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: asKeyOf<TransferOrderLineDTO>('unitOfMeasure'),
    }),
    sortableNumberColumn({
      title: 'Qty Requested',
      dataIndex: asKeyOf<TransferOrderLineDTO>('qtyToTransfer'),
      render: (qtyToTransfer: number, record: TransferOrderLineDTO) => qtyToTransfer / (record.unitSize || 1),
    }),
    sortableNumberColumn({
      title: 'Qty Shipped',
      dataIndex: asKeyOf<TransferOrderLineDTO>('qtyShipped'),
      render: (qtyShipped: number, record: TransferOrderLineDTO) => qtyShipped / (record.unitSize || 1),
    }),
    sortableNumberColumn({
      title: 'Qty Received',
      dataIndex: asKeyOf<TransferOrderLineDTO>('qtyReceived'),
      render: (qtyReceived: number, record: TransferOrderLineDTO) => qtyReceived / (record.unitSize || 1),
    }),
    sortableNumberColumn({
      title: 'Qty Open',
      render: ({
        qtyToTransfer,
        qtyReceived,
        unitSize,
      }: {
        qtyToTransfer: number;
        qtyReceived: number;
        unitSize: number;
      }) => (qtyToTransfer - qtyReceived) / (unitSize || 1),
    }),
    sortableDollarWithCentsColumn({
      title: 'Unit Cost',
      dataIndex: asKeyOf<TransferOrderLineDTO>('unitCost'),
    }),
    sortableDollarWithCentsColumn({
      title: 'Total Cost',
      render: ({ unitCost, qtyToTransfer }: { unitCost: number; qtyToTransfer: number }) =>
        formatUSD(unitCost * qtyToTransfer, true),
    }),
    sortableNumberColumn({
      title: 'Unit Weight',
      dataIndex: asKeyOf<TransferOrderLineDTO>('unitWeight'),
      render: (unitWeight: number) => (unitWeight ?? 0).toFixed(2),
    }),
    sortableNumberColumn({
      title: 'Total Weight',
      render: ({ unitWeight, qtyToTransfer }: { unitWeight: number; qtyToTransfer: number }) =>
        ((unitWeight ?? 0) * qtyToTransfer).toFixed(2),
    }),
    {
      render: ({ itemCode }: { itemCode: string }) => ButtonLink(routes.purchasing.itemDetails(itemCode)),
    },
  ];
  return <Table columns={columns} dataSource={lines} rowKey={(line) => line.lineId} pagination={false} data={[]} />;
};
