import React, { useEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TransferTargetReviewMethod } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import {
  DEFAULT_TRANSFER_TARGET_DAYS_OF_WEEK,
  DEFAULT_TRANSFER_TARGET_REVIEW_METHOD,
  DEFAULT_TRANSFER_TARGET_WEEK_CADENCE,
} from '@recurrency/core-api-schema/dist/transferOrders/common';
import { PatchLocationTransferSettingsBodyParams } from '@recurrency/core-api-schema/dist/transferOrders/patchLocationTransferSettings';
import { Form, notification, Radio, Typography } from 'antd';
import { theme } from 'theme';

import { Button } from 'components/Button';
import { FlexSpace } from 'components/FlexSpace';
import { Input } from 'components/Input';
import { SmallLoader } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { PropertyListItem } from 'components/PropertyListItem';
import { RadioGroup } from 'components/Radio';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { getTenantSetting } from 'utils/tenantSettings';
import { track, TrackEvent } from 'utils/track';

import { RepeatingScheduleSelector } from '../RepeatingScheduleSelector/RepeatingScheduleSelector';

export interface LocationTransferSettingsModalProps {
  sourceLocationId: string;
  destinationLocationId: string;
  awaitPlanningResync: boolean;
  onClose: (newSettings?: PatchLocationTransferSettingsBodyParams) => void;
  reload?: () => void;
}

interface FormData {
  reviewMethod: TransferTargetReviewMethod;
  reviewDays: number;
  scheduleDaysOfWeek: string;
  scheduleWeekCadence: number;
}

export function LocationTransferSettingsModal({
  sourceLocationId,
  destinationLocationId,
  awaitPlanningResync,
  onClose,
  reload,
}: LocationTransferSettingsModalProps) {
  const { activeTenant } = useGlobalApp();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationTransferSettingForm] = Form.useForm<FormData>();
  const [reviewType, setReviewType] = useState<TransferTargetReviewMethod>(DEFAULT_TRANSFER_TARGET_REVIEW_METHOD);
  const [scheduleError, setScheduleError] = useState('');

  const defaultTransferCycleDays = getTenantSetting(TenantSettingKey.CalculationDefaultTransferCycleDays);

  const {
    data: prevSettingsData,
    isLoading,
    reload: reloadLocationTransferSettings,
  } = useCoreApi(schemas.transferOrders.getLocationTransferSettings, {
    queryParams: { sourceLocationId, destinationLocationId },
  });

  const settingsData = useMemo(
    () =>
      prevSettingsData ?? {
        sourceLocationId,
        destinationLocationId,
        reviewMethod: DEFAULT_TRANSFER_TARGET_REVIEW_METHOD,
        cycleDays: defaultTransferCycleDays,
        scheduleDaysOfWeek: DEFAULT_TRANSFER_TARGET_DAYS_OF_WEEK,
        scheduleWeekCadence: DEFAULT_TRANSFER_TARGET_WEEK_CADENCE,
      },
    [destinationLocationId, prevSettingsData, sourceLocationId, defaultTransferCycleDays],
  );

  function handleScheduleValidation(): boolean {
    const data = locationTransferSettingForm.getFieldsValue();
    const weeks =
      typeof data.scheduleWeekCadence === 'string' ? parseInt(data.scheduleWeekCadence, 10) : data.scheduleWeekCadence;
    if (!data.scheduleDaysOfWeek || data.scheduleDaysOfWeek.length === 0) {
      setScheduleError('Please select at least 1 day of the week');
      return false;
    }
    if (weeks < 1) {
      setScheduleError('Weeks must be greater than 0');
      return false;
    }
    if (data.scheduleDaysOfWeek.length > 1 && weeks > 1) {
      setScheduleError(`Select a single day of the week to repeat every ${weeks} weeks`);
      return false;
    }
    setScheduleError('');
    return true;
  }

  async function handleSubmit(data: FormData) {
    const weeks =
      typeof data.scheduleWeekCadence === 'string' ? parseInt(data.scheduleWeekCadence, 10) : data.scheduleWeekCadence;
    if (reviewType === TransferTargetReviewMethod.Schedule && !handleScheduleValidation()) {
      notification.error({
        message: 'Resolve validation errors before saving.',
      });
      return;
    }

    setIsSubmitting(true);
    const submitNotification = createSubmissionNotification({
      entityName: 'Transfer Cycle changes',
      submittingMessage: 'Updating Transfer Cycle',
      expectedWaitSeconds: 10,
      erpType: activeTenant.erpType,
    });

    try {
      const newSettings: PatchLocationTransferSettingsBodyParams = {
        // This is so the endpoint can handle updating multiple location pairs at once
        sourceDestinationIds: [`${sourceLocationId}|${destinationLocationId}`],
        reviewMethod: reviewType,
        cycleDays: typeof data.reviewDays === 'string' ? parseInt(data.reviewDays, 10) : data.reviewDays, // This is needed because the input formats the number as a string if changed with the direction arrows on the number input.
        scheduleDaysOfWeek: data.scheduleDaysOfWeek,
        scheduleWeekCadence: weeks,
        awaitPlanningResync,
      };

      // Close modal but keep loading state in submission notification
      onClose(newSettings);
      await coreApiFetch(schemas.transferOrders.patchLocationTransferSettings, { bodyParams: newSettings });

      reloadLocationTransferSettings();
      reload?.();
      submitNotification.success({
        successMessage: `Transfer Cycle updated for Source #${sourceLocationId} and Destination #${destinationLocationId}`,
        duration: 5,
      });
      // update api response data after patch
    } catch (err) {
      submitNotification.error(err);
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    if (settingsData) {
      const initialValues = {
        reviewMethod:
          (settingsData.reviewMethod as TransferTargetReviewMethod) ?? DEFAULT_TRANSFER_TARGET_REVIEW_METHOD,
        reviewDays: settingsData.cycleDays ?? defaultTransferCycleDays,
        scheduleDaysOfWeek: settingsData.scheduleDaysOfWeek ?? DEFAULT_TRANSFER_TARGET_DAYS_OF_WEEK,
        scheduleWeekCadence: settingsData.scheduleWeekCadence ?? DEFAULT_TRANSFER_TARGET_WEEK_CADENCE,
      };
      locationTransferSettingForm.setFieldsValue(initialValues);
      setReviewType(settingsData.reviewMethod as TransferTargetReviewMethod);
    }
  }, [settingsData, locationTransferSettingForm, defaultTransferCycleDays]);

  return (
    <Modal
      visible
      title={`Set Transfer Review Schedule from Source Location #${sourceLocationId} to Destination Location #${destinationLocationId}`}
      onCancel={() => onClose()}
      centered
      width={900}
      footer={
        <>
          <Button key="cancel" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            form="locationTransferSettingForm"
            htmlType="submit"
            disabled={isLoading}
            loading={isSubmitting}
            onClick={() => track(TrackEvent.Transfer_Cycle_Setting, { type: reviewType })}
          >
            Save
          </Button>
        </>
      }
    >
      {isLoading ? (
        <SmallLoader />
      ) : (
        <Form.Provider>
          <FlexSpace
            className={css`
              margin-bottom: 16px;
            `}
          >
            Recurrency uses the transfer review schedule to determine when to show transfers as ready to review. In
            addition, the transfer review schedule is used when planning for non sparse items to determine the minimum.
          </FlexSpace>
          <Form
            name="locationTransferSettingForm"
            form={locationTransferSettingForm}
            onFinish={handleSubmit}
            onChange={handleScheduleValidation}
            onError={console.error}
            onFinishFailed={console.error}
          >
            <RadioGroup
              value={reviewType}
              onChange={(ev) => {
                const newScheduleType = ev.target.value as TransferTargetReviewMethod;
                locationTransferSettingForm.setFieldsValue({
                  ...locationTransferSettingForm.getFieldsValue(),
                  reviewMethod: newScheduleType,
                });
                setReviewType(newScheduleType);
              }}
            >
              <FlexSpace gap={20} direction="column" fullWidth>
                <Radio value={TransferTargetReviewMethod.Schedule}>
                  <FlexSpace gap={10} direction="column" fullWidth>
                    <div className={boldText}>Transfer Review Schedule</div>
                    <Typography.Paragraph>
                      Recurrency will mark a transfer as “Ready for Review” based on this configurable transfer review
                      schedule. Every time a transfer is created in Recurrency or a location pair is “Marked as
                      Reviewed,” the next transfer will be prompted on the next day that matches this schedule. This
                      transfer review schedule will also be used in planning to determine the minimum for items
                      replenished through this path, by taking the average number of days between reviews (rounded
                      down).
                    </Typography.Paragraph>
                    <FlexSpace direction="column" alignItems="center" fullWidth>
                      <Form.Item
                        name="scheduleDaysOfWeek"
                        className={css`
                          margin-bottom: 2px;
                        `}
                      >
                        <RepeatingScheduleSelector
                          onChange={handleScheduleValidation}
                          value={locationTransferSettingForm.getFieldValue('scheduleDaysOfWeek')}
                          disabled={reviewType !== TransferTargetReviewMethod.Schedule}
                        />
                      </Form.Item>
                      <div
                        className={css`
                          display: flex;
                          flex-direction: row;
                          gap: 4px;
                          align-items: center;
                          justify-content: center;
                        `}
                      >
                        Repeat every{' '}
                        <Form.Item
                          name="scheduleWeekCadence"
                          className={css`
                            margin-bottom: 0;
                          `}
                        >
                          <Input
                            style={{ width: 70 }}
                            min={1}
                            type="number"
                            step={1}
                            disabled={reviewType !== TransferTargetReviewMethod.Schedule}
                          />
                        </Form.Item>
                        week
                        {locationTransferSettingForm.getFieldValue('scheduleWeekCadence') > 1 ? 's' : ''}
                      </div>
                      {scheduleError && (
                        <div
                          className={css`
                            color: ${reviewType === TransferTargetReviewMethod.Schedule
                              ? theme.colors.danger[500]
                              : theme.colors.neutral[500]};
                          `}
                        >
                          {scheduleError}
                        </div>
                      )}
                    </FlexSpace>
                  </FlexSpace>
                </Radio>
                <Radio value={TransferTargetReviewMethod.Cycle}>
                  <FlexSpace gap={10} direction="column" fullWidth>
                    <div className={boldText}>Transfer Review Cycle</div>
                    <Typography.Paragraph>
                      Recurrency will mark a transfer as “Ready for Review” based on this configurable transfer review
                      schedule. Every time a transfer is created in Recurrency or a location pair is “Marked as
                      Reviewed,” the next review will be prompted this many days later. This transfer review cycle will
                      also be used in planning to determine the minimum for items replenished through this path.
                    </Typography.Paragraph>
                    <PropertyListItem label="Current Transfer Review Cycle (days)" value={settingsData.cycleDays} />
                    <PropertyListItem
                      label="Transfer Review Cycle (days)"
                      value={
                        <Form.Item name="reviewDays">
                          <Input type="number" min={0} disabled={reviewType !== TransferTargetReviewMethod.Cycle} />
                        </Form.Item>
                      }
                    />
                  </FlexSpace>
                </Radio>
              </FlexSpace>
            </RadioGroup>
          </Form>
        </Form.Provider>
      )}
    </Modal>
  );
}

const boldText = css`
  font-weight: bold;
`;
