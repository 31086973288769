import { TenantSettings } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { TenantSettingsField } from '@recurrency/core-api-schema/dist/common/tenantSettingsTypes';

/**
 * Check for displayCondition function and test.
 * Internal Recurrency Users always see all fields.
 */
export const shouldDisplayField = (
  field: TenantSettingsField,
  activeTenantSettings: Partial<TenantSettings>,
  pendingTenantSettings: Partial<TenantSettings>,
) => {
  if (typeof field.displayCondition !== 'function') {
    return true;
  }

  return (
    activeTenantSettings && pendingTenantSettings && field.displayCondition(activeTenantSettings, pendingTenantSettings)
  );
};
