import React from 'react';

import { Container } from 'components/Container';

import { TariffManagementTable } from './TariffManagementTable';

export const TariffManagementPage = () => (
  <Container>
    <TariffManagementTable />
  </Container>
);
