import React from 'react';

import { Link } from 'react-router-dom';

import { RecurrencyRole } from '@recurrency/core-api-schema/dist/common/enums';
import { RuleFailuresListDTO } from '@recurrency/core-api-schema/dist/validationRules/getCurrentValidationRuleFailures';
import { AxiosError } from 'axios';

import { MiniTable } from 'components/MiniTable';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { HttpError } from 'utils/error';
import { formatDate } from 'utils/formatting';
import { isRecurrencyRole } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';

interface FailedValidationRulesPanelContentProps {
  rulesData?: RuleFailuresListDTO;
  error?: AxiosError<HttpError>;
  isLoading?: boolean;
}

export function FailedValidationRulesPanelContent({
  rulesData,
  error,
  isLoading,
}: FailedValidationRulesPanelContentProps) {
  const { activeTenant } = useGlobalApp();
  const isTenantAdmin = isRecurrencyRole(activeTenant, RecurrencyRole.TenantAdmin);
  return (
    <>
      <p>
        <i>Latest Run Time: {rulesData?.items[0] ? formatDate(rulesData?.items[0].runTime, true, true) : 'None'}</i>
      </p>
      <MiniTable
        data={rulesData?.items || []}
        columns={[
          {
            render: (record) =>
              isTenantAdmin ? (
                <Link to={routes.validationRules.validationRuleDetails(record.ruleId)}>{record.ruleName}</Link>
              ) : (
                record.ruleName
              ),
            align: `left`,
          },
        ]}
        isLoading={isLoading}
        error={error}
      />
    </>
  );
}
