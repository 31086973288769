import React, { useRef } from 'react';

import { useHistory } from 'react-router-dom';

import { css } from '@emotion/css';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Empty } from 'antd';

import { PlanningSearchFrameHeader } from 'pages/purchasing/PlanningPage/PlanningSearchFrameHeader';
import { PlanningSidePane } from 'pages/purchasing/PlanningPage/PlanningSidePane';

import { Alert } from 'components/Alert';
import { ActionButton } from 'components/Button/ActionButton';
import { LastUpdatedMomentForecast } from 'components/recipes/LastUpdatedMoment';
import { SearchFrame } from 'components/recipes/SearchFrame';
import { SearchFrameContext } from 'components/recipes/SearchFrame/types';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { truthy } from 'utils/boolean';
import { routes } from 'utils/routes';
import { ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';

import { getPlanningColumns, getPlanningValueFacets } from '../utils';
import { OnboardingProgress } from './OnboardingProgress';

export const OnboardingPage = () => {
  const { activeTenant, activeUser } = useGlobalApp();
  const searchFrameContextRef = useRef<SearchFrameContext<SearchPlanningDTO>>();
  const searchIndexReload = () => searchFrameContextRef.current?.searchIndexReload();
  const clearSelection = () => searchFrameContextRef.current?.clearSelection();

  const columns = getPlanningColumns({
    activeTenant,
    activeUser,
    isOnboarding: true,
    isOpOqVariant: false,
    searchIndexReload,
  });

  const valueFacets = getPlanningValueFacets({
    activeTenant,
    activeUser,
    isOnboarding: true,
  });

  return (
    <SearchFrame<SearchPlanningDTO>
      title={
        <InfoTooltip title="Set up items to use Recurrency by moving them from their old Replenishment methods onto using Recurrency's dynamic Min/Max">
          Item Setup
        </InfoTooltip>
      }
      headerDescription={
        <>
          <LastUpdatedMomentForecast includeForecastPrefix />
          <OnboardingProgress />
          <Alert
            className={css`
              margin-top: 10px;
            `}
            message={`Use the Item Setup page to migrate stockable items from your legacy, ERP-driven replenishment method to Recurrency's recommended or custom Min/Max. Once you have "Set Up" an item, it will move from Item Setup to the Planning tab.`}
            banner
            type="info"
            onClose={() => {}}
          />
        </>
      }
      indexName={SearchIndexName.Planning}
      queryPlaceholder="Search by item"
      columns={columns.filter(truthy)}
      valueFacets={valueFacets.filter(truthy)}
      tableColumnsSettingKey={ViewSettingKey.PlanningOnboardingTable}
      sidePane={({ record }) => (
        <PlanningSidePane record={record} isOnboardingVariant searchIndexReload={searchIndexReload} />
      )}
      tableRowSelection="selectAll"
      contextRef={searchFrameContextRef}
      headerActions={(searchFrameContext) => (
        <PlanningSearchFrameHeader
          valueFacets={valueFacets}
          searchFrameContext={searchFrameContext}
          searchIndexReload={searchIndexReload}
          clearSelection={clearSelection}
          isOnboardingVariant
        />
      )}
      locale={{
        emptyText: <EmptyText />,
        notFoundText: <EmptyText useNotFoundText />,
      }}
    />
  );
};

function EmptyText({ useNotFoundText = false }: { useNotFoundText?: boolean }) {
  const history = useHistory();

  const planningPageLink = (
    <ActionButton
      className={css`
        display: inline;
      `}
      label="planning page"
      onClick={() => {
        history.push(routes.demandPlanning.planning());
      }}
    />
  );

  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <div>
          {useNotFoundText ? (
            <>We couldn't find the item you're looking for, check out the {planningPageLink} to see if it's there</>
          ) : (
            <>There are no more items that need to be setup, check out the {planningPageLink} to review items</>
          )}
        </div>
      }
    />
  );
}
