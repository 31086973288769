import React, { useState } from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { UpdateItemLocationInfoType } from '@recurrency/core-api-schema/dist/purchasing/postUpdateItemLocationInfo';

import { Button } from 'components/Button';
import { DividerLine } from 'components/DividerLine';
import { FlexSpace } from 'components/FlexSpace';
import { Modal } from 'components/Modal';
import { PropertyListItem } from 'components/PropertyListItem';
import { ItemLocationSettingsAuditLog } from 'components/recipes/AuditLog/ItemLocationSettingsAuditLog';
import { Tabs } from 'components/Tabs';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { pluralize, splitIdNameStr } from 'utils/formatting';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { PlanningModalType, track, TrackEvent } from 'utils/track';

import { MultiUpdateReplenishmentSettingsTable } from './MultiUpdateReplenishmentSettingsTable';
import { PositiveNumberInput } from './PositiveNumberInput';
import { getPlanningListSelectionFromFilterSelection, MultiModalSelection, PlanningBulkUpdateToFields } from './utils';

export function MultiStockFloorModal({
  selection,
  onClose,
  searchIndexReload,
  clearSelection,
}: {
  selection: MultiModalSelection;
  onClose: (isSubmit: boolean) => void;
  searchIndexReload?: () => void;
  clearSelection?: () => void;
}) {
  const { activeTenant } = useGlobalApp();
  const singleRecord = selection.type === 'list' && selection.items.length === 1 ? selection.items[0] : undefined;
  const [minStockFloor, setMinStockFloor] = useState<number | undefined>(
    () => singleRecord?.min_stock_floor ?? undefined,
  );

  const handleMinStockFloorUpdate = async () => {
    onClose(true);

    const submitNotification = createSubmissionNotification({
      entityName: 'Stock floor changes',
      submittingMessage: 'Updating stock floor changes',
      // 30 sec + 30 sec per 5k records
      expectedWaitSeconds: 30 + Math.round(selection.count / 5_000) * 30,
      erpType: activeTenant.erpType,
    });
    try {
      if (selection.type === 'filter') {
        selection = await getPlanningListSelectionFromFilterSelection(selection);
      }

      await coreApiFetch(schemas.purchasing.postUpdateItemLocationInfo, {
        pathParams: {
          updateType: UpdateItemLocationInfoType.MinStockFloor,
        },
        bodyParams: {
          updates: selection.items.map((record) => ({
            itemUid: record.item_uid,
            locationId: splitIdNameStr(record.location).foreignId,
            minStockFloor: minStockFloor ?? null,
          })),
        },
      });

      track(TrackEvent.DemandPlanning_MinMaxInfo_Submission, {
        modalType: PlanningModalType.MultiMinStockFloor,
        itemCount: selection.count,
      });
      clearSelection?.();
      searchIndexReload?.();
      submitNotification.success({
        successMessage: `Successfully updated stock ${pluralize(selection.count, 'floor', 'floors')}`,
        duration: 10,
      });
    } catch (err) {
      submitNotification.error(err);
    }
  };

  return (
    <Modal
      visible
      title={
        singleRecord
          ? `Update Stock Floor for Item #${singleRecord.item_id} at Location ${singleRecord.location}`
          : `Update Stock Floor for ${pluralize(selection.count, 'Item', 'Items', true)}`
      }
      onCancel={() => onClose(false)}
      destroyOnClose
      footer={
        <>
          <Button onClick={() => onClose(false)}>Cancel</Button>
          <Button
            onClick={handleMinStockFloorUpdate}
            type="primary"
            disabled={minStockFloor !== undefined && (!Number.isInteger(minStockFloor) || minStockFloor < 1)}
          >
            Update
          </Button>
        </>
      }
      width={900}
    >
      <FlexSpace direction="column" gap={16}>
        <FlexSpace direction="column" gap={8}>
          Setting a floor ensures that certain items always maintain a minimum stock level, regardless of demand. This
          is useful for essential parts, showroom items, or supplier-required inventory that must always be available.
          If Recurrency's recommendation falls below the floor, the floor value will be used instead.
          <PropertyListItem
            label="Stock Floor"
            value={<PositiveNumberInput min={1} requiresInteger onChange={setMinStockFloor} value={minStockFloor} />}
          />
        </FlexSpace>
      </FlexSpace>
      <DividerLine />
      <MultiUpdateReplenishmentSettingsTable
        selection={selection}
        bulkUpdate={{
          type: 'minStockFloor',
          minStockFloor,
        }}
      />
      {singleRecord && (
        <>
          <DividerLine marginTop={42} />
          <Tabs
            tabs={[
              {
                header: 'Audit Log',
                infoTooltip: `Stock floor updates for Item #${singleRecord.item_id} at Location #${singleRecord.location}`,
                content: (
                  <ItemLocationSettingsAuditLog
                    itemUid={singleRecord.item_uid}
                    locationId={splitIdNameStr(singleRecord.location).foreignId}
                    fields={PlanningBulkUpdateToFields.minStockFloor}
                  />
                ),
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
}
