import React, { useMemo } from 'react';

import { CheckOutlined, CloseOutlined, EditOutlined, ReloadOutlined, SwapOutlined } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import { PurchaseTargetLineStatus, SupplierTargetTypes } from '@recurrency/core-api-schema/dist/common/enums';
import { ISODateStr } from '@recurrency/core-api-schema/dist/common/types';
import { AllocatedOrderLinePayload } from '@recurrency/core-api-schema/dist/purchaseOrders/createPurchaseOrder';
import { Tooltip } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import moment from 'moment';
import { colors } from 'theme/colors';

import { TooltipStatusBadge } from 'pages/purchasing/common/TooltipStatusBadge';

import { Button } from 'components/Button';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { DatePicker } from 'components/DatePicker/DatePicker.style';
import { FlexSpace } from 'components/FlexSpace';
import { ParsedInput } from 'components/Input/ParsedInput';
import { AllocateOrderModal } from 'components/recipes/AllocateOrderModal/AllocateOrderModal';
import { AllocateOrderModalResult } from 'components/recipes/AllocateOrderModal/types';
import { ColumnChooserSection } from 'components/recipes/ColumnChooserSection';
import { EditPurchaseLineTransfersModal } from 'components/recipes/EditPurchaseLineTransfersModal';
import { NetStockPopover } from 'components/recipes/equation/NetStockPopover';
import { QtyToOrderPopover } from 'components/recipes/equation/QtyToOrderPopover';
import { PurchaseTargetGroupNameToolTip } from 'components/recipes/PurchaseTargetGroupNameToolTip';
import { StickyHeaderVariant, Table } from 'components/Table';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { capitalize, formatNumber, formatUSD } from 'utils/formatting';
import { routes, useHashState } from 'utils/routes';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { useUserViewSettingsState } from 'utils/tableAndSidePaneSettings/useUserViewSettingsState';
import { asKeyOf, sortDirections } from 'utils/tables';
import { isTenantErpTypeP21 } from 'utils/tenants';
import { TrackEvent, track } from 'utils/track';
import { floatOrDefault } from 'utils/units';

import { PurchaseTargetLinesHashState, PurchasingLineTransfer } from 'types/hash-state';

import { GenericTargetLineRow, PurchaseTargetLineRow } from '../types';
import { TargetFieldSumMap } from '../utils';
import { additionalPurchasingQty, focusedRowClassName, usePurchasingStatuses } from './utils';

type PurchaseTargetLinesTableColumnsProps = {
  isPurchasingHubAndSpoke?: boolean;
  isPlanningHubAndSpoke?: boolean;
  filteredLines: GenericTargetLineRow[];
  focusedLine?: GenericTargetLineRow;
  uniqueSuppliers: string[];
  uniqueLocations: string[];
  targetType: SupplierTargetTypes;
  linesModalMode?: boolean;
  setSortDir: (order: SortOrder) => void;
  setSortField: (field: string) => void;
  setFocusedLine: (line: GenericTargetLineRow) => void;
  handleQtyToOrderChange: (value: number, line: GenericTargetLineRow) => void;
  getTotalUserTransfersQty: (transfers?: PurchasingLineTransfer[] | undefined) => number;
  handleTransferUpdate: (newTransfers: PurchasingLineTransfer[], line: PurchaseTargetLineRow) => void;
  handleUnitCostChange: (value: number, line: GenericTargetLineRow) => void;
  handleRequiredDateChange: (value: ISODateStr | undefined, update: GenericTargetLineRow) => void;
  handleSpecialOrderSelection: (
    record: PurchaseTargetLineRow,
    userQtyForSpecialOrders: number,
    orderEntries?: AllocatedOrderLinePayload[] | undefined,
  ) => void;
};

export function PurchaseTargetLinesTableColumns({
  isPurchasingHubAndSpoke,
  isPlanningHubAndSpoke,
  filteredLines,
  focusedLine,
  uniqueSuppliers,
  uniqueLocations,
  targetType,
  linesModalMode,
  setSortDir,
  setSortField,
  setFocusedLine,
  handleQtyToOrderChange,
  getTotalUserTransfersQty,
  handleTransferUpdate,
  handleUnitCostChange,
  handleRequiredDateChange,
  handleSpecialOrderSelection,
}: PurchaseTargetLinesTableColumnsProps) {
  const [hashState] = useHashState<PurchaseTargetLinesHashState>();
  const { poFinalizeInputByKey = {} } = hashState;
  const { activeTenant } = useGlobalApp();
  const { shouldCreateSpecialOrderLines } = usePurchasingStatuses();
  const isQuantityZero = (record: PurchaseTargetLineRow) =>
    record.userQtyToOrder === 0 && (shouldCreateSpecialOrderLines ? record.userQtyForSpecialOrders === 0 : true);

  const linesWithPlaceholders = useMemo(() => {
    const oneWeekFromNow = moment().add(1, 'week').startOf('day');
    return filteredLines.map((item: GenericTargetLineRow | PurchaseTargetLineRow) => ({
      ...item,
      placeholderRequiredDate: (item.poKey && poFinalizeInputByKey[item.poKey]?.dueDate
        ? moment(poFinalizeInputByKey[item.poKey].dueDate)
        : oneWeekFromNow
      ).format('YYYY/MM/DD'),
    }));
  }, [filteredLines, poFinalizeInputByKey]);
  const isWeightOrVolumeTarget = [SupplierTargetTypes.Weight, SupplierTargetTypes.Volume].includes(targetType);

  const purchaseTargetLineColumns: PersistedColumn<PurchaseTargetLineRow>[] = [
    {
      title: 'Item',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('itemId'),
      fixed: true,
      sorter: true,
      sortDirections,
      settingKey: 'itemId',
      required: true,
      render: (_: string, record: PurchaseTargetLineRow) => (
        <div>
          <span
            className={css`
              position: absolute;
              width: 3px;
              top: 0;
              left: 0;
              bottom: 0;
              background-color: ${record === focusedLine ? colors.primary[600] : `transparent`};
            `}
          />
          <a href={routes.purchasing.itemDetails(record.itemId)} target="_blank" rel="noreferrer">
            {record.itemId}
          </a>
          <div>{record.itemName}</div>
        </div>
      ),
    },
    uniqueSuppliers.length > 1
      ? {
          title: 'Supplier',
          dataIndex: asKeyOf<PurchaseTargetLineRow>('supplierId'),
          fixed: true,
          sorter: true,
          sortDirections,
          settingKey: 'supplierId',
          render: (_: string, record: PurchaseTargetLineRow) => (
            <div>
              <div>{record.supplierId}</div>
              <div>{record.supplierName}</div>
            </div>
          ),
        }
      : null,
    uniqueLocations.length > 1
      ? {
          title: 'Location',
          dataIndex: asKeyOf<PurchaseTargetLineRow>('locationId'),
          fixed: true,
          sorter: true,
          sortDirections,
          settingKey: 'locationId',
          required: true,
          render: (_: string, record: PurchaseTargetLineRow) => (
            <div>
              <div>{record.locationId}</div>
              <div>{record.locationName}</div>
            </div>
          ),
        }
      : null,
    {
      title: 'Replenishment Location',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('replenishmentLocationId'),
      fixed: uniqueLocations.length === 1,
      sorter: true,
      sortDirections,
      settingKey: 'replenishmentLocationId',
      optional: !isPlanningHubAndSpoke,
      render: (_: string, record: PurchaseTargetLineRow) => (
        <div>
          <div>{record.replenishmentLocationId}</div>
          <div>{record.replenishmentLocationName}</div>
        </div>
      ),
    },
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'ABC Class',
          dataIndex: asKeyOf<PurchaseTargetLineRow>('purchaseClass'),
          width: '80px',
          sorter: true,
          sortDirections,
          settingKey: 'purchaseClass',
        }
      : null,
    {
      title: 'Net Stock',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('netStock'),
      align: 'right' as const,
      sorter: true,
      sortDirections,
      settingKey: 'netStock',
      render: (value: number, record: PurchaseTargetLineRow) => (
        <>
          {formatNumber(value)} {record.unitOfMeasure}
          <NetStockPopover record={record} />
        </>
      ),
    },
    {
      title: 'Qty Available',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('qtyAvailable'),
      align: 'right' as const,
      sorter: true,
      sortDirections,
      settingKey: 'qtyAvailable',
      render: (value: number, record: PurchaseTargetLineRow) => `${formatNumber(value)} ${record.unitOfMeasure || ''}`,
    },
    {
      title: 'Stockable',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('stockable'),
      align: 'center' as const,
      settingKey: 'stockable',
      render: (value: boolean) => (value ? <CheckOutlined /> : null),
    },
    {
      title: 'Status',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('status'),
      settingKey: 'status',
      sorter: true,
      defaultSortOrder: 'descend' as const,
      render: (_: PurchaseTargetLineStatus, record: PurchaseTargetLineRow) => <TooltipStatusBadge record={record} />,
    },
    {
      title: 'Stock and Backorders',
      settingKey: 'quantity',
      required: true,
      children: [
        {
          title: 'Required',
          dataIndex: asKeyOf<PurchaseTargetLineRow>('qtyToOrder'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          render: (value: number, record: PurchaseTargetLineRow) => (
            <div
              className={css`
                display: inline-flex;
              `}
            >
              {formatNumber(value)} {record.unitOfMeasure}
              <QtyToOrderPopover record={record} />
            </div>
          ),
        },
        {
          title: `To Order`,
          dataIndex: asKeyOf<PurchaseTargetLineRow>('userQtyToOrder'),
          width: '140px',
          sorter: true,
          sortDirections,
          render: (_: number, record: PurchaseTargetLineRow) => (
            <FlexSpace>
              <ParsedInput<number>
                className={css`
                  min-width: 70px;
                `}
                size="small"
                valueParser={(value) => floatOrDefault(value, 0)}
                value={record.userQtyToOrder}
                onValueChange={(value) => {
                  handleQtyToOrderChange(value, record);
                }}
              />
              <Button
                size="small"
                disabled={record.qtyToOrder === 0 && record.userQtyToOrder === 0}
                onClick={() => {
                  track(TrackEvent.Purchasing_Optimization_ResetButtonClick, {});
                  handleQtyToOrderChange(record.userQtyToOrder > 0 ? 0 : record.qtyToOrder, record);
                }}
              >
                {record.userQtyToOrder > 0 ? (
                  <CloseOutlined />
                ) : record.qtyToOrder > 0 ? (
                  <ReloadOutlined />
                ) : (
                  <CloseOutlined />
                )}
              </Button>
            </FlexSpace>
          ),
        },
        {
          title: `To Transfer`,
          dataIndex: asKeyOf<PurchaseTargetLineRow>('userTransfers'),
          width: '80px',
          sorter: true,
          sortDirections,
          render: (transfers: PurchasingLineTransfer[], record: PurchaseTargetLineRow) => (
            <FlexSpace
              alignItems="center"
              justify="center"
              gap={0}
              className={css`
                height: 32px;
              `}
            >
              {/* total amount currently set for transfer */}
              {getTotalUserTransfersQty(transfers)}
              <ConditionalWrapper
                condition={record.potentialTransfer}
                wrapper={(children: React.ReactElement) => (
                  <Tooltip title="Potential transfers available" placement="right">
                    <div
                      className={css`
                        position: relative;
                      `}
                    >
                      {children}
                      <span
                        className={css`
                          color: ${colors.malibu[600]};
                          margin-left: -3px;
                          top: 5px;
                          font-size: 18px;
                          position: absolute;
                        `}
                      >
                        <SwapOutlined />
                      </span>
                    </div>
                  </Tooltip>
                )}
              >
                <Button
                  type="link"
                  onClick={async () => {
                    const newTransfers = await showAsyncModal(EditPurchaseLineTransfersModal, {
                      targetLine: record,
                    });
                    if (newTransfers) {
                      handleTransferUpdate(newTransfers, record);
                    }
                  }}
                  icon={<EditOutlined />}
                />
              </ConditionalWrapper>
            </FlexSpace>
          ),
        },
      ].filter(truthy),
    },
    shouldCreateSpecialOrderLines && {
      title: `To Special Order`,
      dataIndex: asKeyOf<PurchaseTargetLineRow>('userQtyForSpecialOrders'),
      width: '120px',
      sorter: true,
      sortDirections,
      settingKey: 'qtyForSpecialOrders',
      render: (userQtyForSpecialOrders = 0, record: PurchaseTargetLineRow) => (
        <FlexSpace alignItems="center" justify="center" gap={6}>
          {userQtyForSpecialOrders}
          <Button
            size="small"
            disabled={record.qtyForSpecialOrders === 0}
            onClick={() => {
              handleSpecialOrderSelection(record, userQtyForSpecialOrders > 0 ? 0 : record.qtyForSpecialOrders, []);
            }}
          >
            {(record.userQtyForSpecialOrders ?? 0) > 0 ? (
              <CloseOutlined />
            ) : record.qtyForSpecialOrders > 0 ? (
              <ReloadOutlined />
            ) : (
              <CloseOutlined />
            )}
          </Button>
          <ConditionalWrapper
            condition={record.qtyForSpecialOrders > 0}
            wrapper={(children: React.ReactElement) => (
              <Tooltip title="Select special orders to purchase for" placement="top">
                <div
                  className={css`
                    position: relative;
                  `}
                >
                  {children}
                </div>
              </Tooltip>
            )}
          >
            <FlexSpace
              alignItems="center"
              justify="center"
              className={css`
                height: 32px;
              `}
            >
              <Button
                type="link"
                onClick={async () => {
                  const result: AllocateOrderModalResult | undefined = await showAsyncModal(AllocateOrderModal, {
                    targetLine: record,
                  });
                  if (result?.changesMade && result.updatedAllocation) {
                    handleSpecialOrderSelection(record, result.totalQtySelected || 0, result.updatedAllocation);
                  }
                }}
                disabled={record.qtyForSpecialOrders === 0}
                icon={<EditOutlined />}
              />
            </FlexSpace>
          </ConditionalWrapper>
        </FlexSpace>
      ),
    },
    {
      title: `Unit Cost`,
      dataIndex: asKeyOf<PurchaseTargetLineRow>('userUnitCost'),
      width: '110px',
      align: 'right' as const,
      sorter: true,
      sortDirections,
      settingKey: 'userUnitCost',
      render: (_: number, record: PurchaseTargetLineRow) => (
        <ParsedInput<number>
          className={css`
            min-width: 90px;
          `}
          size="small"
          prefix="$"
          disabled={isQuantityZero(record)}
          valueParser={(value) => floatOrDefault(value, 0)}
          value={record.userUnitCost}
          onValueChange={(value) => {
            handleUnitCostChange(value, record);
          }}
        />
      ),
    },
    isWeightOrVolumeTarget
      ? {
          title: `Unit ${capitalize(targetType)}`,
          dataIndex: asKeyOf<PurchaseTargetLineRow>(TargetFieldSumMap[targetType]),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'unitTarget',
          render: (value: number) => formatNumber(value, 2),
        }
      : null,
    {
      title: `Ext Cost`,
      align: 'right' as const,
      dataIndex: 'extCost',
      sorter: true,
      settingKey: 'extCost',
      render: (_: null, record: PurchaseTargetLineRow) =>
        formatUSD((record.userQtyToOrder + additionalPurchasingQty(record)) * record.userUnitCost, true, 2),
    },
    isWeightOrVolumeTarget
      ? {
          title: `Ext ${capitalize(targetType)}`,
          align: 'right' as const,
          settingKey: 'extTarget',
          render: (_: number, record: PurchaseTargetLineRow) => {
            const field = TargetFieldSumMap[targetType];
            const multiple = record[field] as number;
            return formatNumber(multiple * record.userQtyToOrder);
          },
        }
      : null,
    // optional columns
    {
      title: 'Minimum',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('invMin'),
      settingKey: 'invMin',
      optional: true,
    },
    {
      title: 'Maximum',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('invMax'),
      settingKey: 'invMax',
      optional: true,
    },
    {
      title: 'Product Group',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('itemGroupName'),
      settingKey: 'itemGroupName',
      render: (_: string, record: PurchaseTargetLineRow) => (
        <div>
          <div>{record.itemGroupId}</div>
          <div>{record.itemGroupName}</div>
        </div>
      ),
      optional: true,
    },
    {
      title: 'Qty on PO',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('qtyOnPurchaseOrder'),
      settingKey: 'qtyOnPurchaseOrder',
      optional: true,
    },
    {
      title: 'Unit Volume',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('unitVolume'),
      settingKey: 'unitVolume',
      optional: true,
    },
    {
      title: 'Unit Weight',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('unitWeight'),
      settingKey: 'unitWeight',
      optional: true,
    },
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'Required Date',
          dataIndex: 'userRequiredDate',
          width: '125px',
          sorter: true,
          sortDirections,
          settingKey: 'requiredDate',
          optional: true,
          render: (userRequiredDate: string, record: PurchaseTargetLineRow) => (
            <DatePicker
              onChange={(newVal) => handleRequiredDateChange(newVal?.startOf('day').toISOString(), record)}
              format="YYYY/MM/DD"
              picker="date"
              value={userRequiredDate ? moment(userRequiredDate) : undefined}
              showTime={false}
              placeholder={record.placeholderRequiredDate}
              disabled={isQuantityZero(record) || record.status === PurchaseTargetLineStatus.Special}
              size="small"
              className={cx(
                'RequiredDateCell',
                css`
                  width: 140px;
                `,
              )}
            />
          ),
        }
      : null,
    {
      title: 'Item Name',
      settingKey: 'itemName',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('itemName'),
      sorter: true,
      sortDirections,
      optional: true,
    },
  ].filter(truthy);

  const purchaseGroupTargetLineColumns: PersistedColumn<GenericTargetLineRow>[] = [
    // Item Info
    {
      title: 'Item',
      dataIndex: asKeyOf<GenericTargetLineRow>('itemId'),
      width: `300px`,
      fixed: true,
      sorter: true,
      sortDirections,
      settingKey: 'itemId',
      render: (_: string, record: GenericTargetLineRow) => (
        <div>
          <span
            className={css`
              position: absolute;
              width: 3px;
              top: 0;
              left: 0;
              bottom: 0;
              background-color: ${record === focusedLine ? colors.primary[600] : `transparent`};
            `}
          />
          <a href={routes.purchasing.itemDetails(record.itemId)} target="_blank" rel="noreferrer">
            {record.itemId}
          </a>
          <div>{record.itemName}</div>
        </div>
      ),
    },
    // Group Name
    {
      title: 'Group Name',
      dataIndex: asKeyOf<GenericTargetLineRow>('groupName'),
      width: `200px`,
      fixed: true,
      sorter: true,
      sortDirections,
      settingKey: 'groupName',
      render: (name: string, record: GenericTargetLineRow) => (
        <PurchaseTargetGroupNameToolTip
          groupName={name}
          purchaseLocationId={record.purchaseLocationId}
          purchaseLocationName={record.purchaseLocationName}
          spokeLocations={record.spokeLocations}
        />
      ),
    },
    // Supplier Name
    uniqueSuppliers.length > 1
      ? {
          title: 'Supplier',
          dataIndex: asKeyOf<GenericTargetLineRow>('supplierId'),
          width: `200px`,
          fixed: true,
          sorter: true,
          sortDirections,
          settingKey: 'supplierId',
          render: (_: string, record: GenericTargetLineRow) => (
            <div>
              <div>{record.supplierId}</div>
              <div>{record.supplierName}</div>
            </div>
          ),
        }
      : null,
    // Purchase Location Name
    uniqueLocations.length > 1
      ? {
          title: 'Purchase Location',
          dataIndex: asKeyOf<GenericTargetLineRow>('purchaseLocationId'),
          width: `200px`,
          fixed: true,
          sorter: true,
          sortDirections,
          settingKey: 'purchaseLocationId',
          render: (_: string, record: GenericTargetLineRow) => (
            <div>
              <div>{record.purchaseLocationId}</div>
              <div>{record.purchaseLocationName}</div>
            </div>
          ),
        }
      : null,
    {
      title: 'Replenishment Location',
      dataIndex: asKeyOf<GenericTargetLineRow>('replenishmentLocationId'),
      width: `200px`,
      fixed: true,
      sorter: true,
      sortDirections,
      settingKey: 'replenishmentLocationId',
      render: (_: string, record: GenericTargetLineRow) => (
        <div>
          <div>{record.replenishmentLocationId}</div>
          <div>{record.replenishmentLocationName}</div>
        </div>
      ),
    },
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'ABC Class',
          dataIndex: asKeyOf<GenericTargetLineRow>('purchaseClass'),
          width: '80px',
          sorter: true,
          sortDirections,
          settingKey: 'purchaseClass',
        }
      : null,
    {
      title: 'Net Stock',
      dataIndex: asKeyOf<GenericTargetLineRow>('netStock'),
      align: 'right' as const,
      sorter: true,
      sortDirections,
      settingKey: 'netStock',
      render: (value: number, record: GenericTargetLineRow) => (
        <>
          {formatNumber(value)} {record.unitOfMeasure}
          <NetStockPopover record={record} />
        </>
      ),
    },
    {
      title: 'Qty Available',
      dataIndex: asKeyOf<GenericTargetLineRow>('qtyAvailable'),
      align: 'right' as const,
      sorter: true,
      sortDirections,
      settingKey: 'qtyAvailable',
      render: (value: number, record: GenericTargetLineRow) => `${formatNumber(value)} ${record.unitOfMeasure || ''}`,
    },
    {
      title: 'Stockable',
      dataIndex: asKeyOf<GenericTargetLineRow>('stockable'),
      align: 'center' as const,
      settingKey: 'stockable',
      render: (value: boolean) => (value ? <CheckOutlined /> : null),
    },
    {
      title: 'Status',
      dataIndex: asKeyOf<GenericTargetLineRow>('status'),
      settingKey: 'status',
      render: (_: PurchaseTargetLineStatus, record: GenericTargetLineRow) => <TooltipStatusBadge record={record} />,
    },
    {
      title: 'Stock and Backorders',
      settingKey: 'quantity',
      children: [
        {
          title: 'Required',
          dataIndex: asKeyOf<GenericTargetLineRow>('qtyToOrder'),
          align: 'right' as const,
          sorter: true,
          defaultSortOrder: 'descend' as const,
          sortDirections,
          render: (value: number, record: GenericTargetLineRow) => (
            <div
              className={css`
                display: inline-flex;
              `}
            >
              {formatNumber(value)} {record.unitOfMeasure}
              <QtyToOrderPopover record={record} />
            </div>
          ),
        },
        {
          title: `To Order`,
          dataIndex: asKeyOf<GenericTargetLineRow>('userQtyToOrder'),
          width: '140px',
          sorter: true,
          sortDirections,
          render: (_: number, record: GenericTargetLineRow) => (
            <FlexSpace>
              <ParsedInput<number>
                className={css`
                  min-width: 70px;
                `}
                size="small"
                valueParser={(value) => floatOrDefault(value, 0)}
                value={record.userQtyToOrder}
                onValueChange={(value) => {
                  handleQtyToOrderChange(value, record);
                }}
              />
              <Button
                size="small"
                disabled={record.qtyToOrder === 0 && record.userQtyToOrder === 0}
                onClick={() => {
                  track(TrackEvent.Purchasing_Optimization_ResetButtonClick, {});
                  handleQtyToOrderChange(record.userQtyToOrder > 0 ? 0 : record.qtyToOrder, record);
                }}
              >
                {record.userQtyToOrder > 0 ? (
                  <CloseOutlined />
                ) : record.qtyToOrder > 0 ? (
                  <ReloadOutlined />
                ) : (
                  <CloseOutlined />
                )}
              </Button>
            </FlexSpace>
          ),
        },
      ],
    },
    {
      title: `Unit Cost`,
      dataIndex: asKeyOf<GenericTargetLineRow>('userUnitCost'),
      width: '110px',
      align: 'right' as const,
      sorter: true,
      sortDirections,
      settingKey: 'userUnitCost',
      render: (_: number, record: GenericTargetLineRow) => (
        <ParsedInput<number>
          className={css`
            min-width: 90px;
          `}
          size="small"
          prefix="$"
          disabled={record.userQtyToOrder === 0}
          valueParser={(value) => floatOrDefault(value, 0)}
          value={record.userUnitCost}
          onValueChange={(value) => {
            handleUnitCostChange(value, record);
          }}
        />
      ),
    },
    isWeightOrVolumeTarget
      ? {
          title: `Unit ${capitalize(targetType)}`,
          dataIndex: asKeyOf<GenericTargetLineRow>(TargetFieldSumMap[targetType]),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'unitTarget',
          render: (value: number) => formatNumber(value, 2),
        }
      : null,
    {
      title: `Ext Cost`,
      align: 'right' as const,
      dataIndex: 'extCost',
      sorter: true,
      settingKey: 'extCost',
      render: (_: null, record: GenericTargetLineRow) =>
        formatUSD(record.userQtyToOrder * record.userUnitCost, true, 2),
    },
    isWeightOrVolumeTarget
      ? {
          title: `Ext ${capitalize(targetType)}`,
          align: 'right' as const,
          settingKey: 'extTarget',
          render: (_: number, record: GenericTargetLineRow) => {
            const field = TargetFieldSumMap[targetType];
            const multiple = record[field] as number;
            return formatNumber(multiple * record.userQtyToOrder);
          },
        }
      : null,
    // optional columns
    {
      title: 'Minimum',
      dataIndex: asKeyOf<GenericTargetLineRow>('invMin'),
      settingKey: 'invMin',
      optional: true,
    },
    {
      title: 'Maximum',
      dataIndex: asKeyOf<GenericTargetLineRow>('invMax'),
      settingKey: 'invMax',
      optional: true,
    },
    {
      title: 'Product Group',
      dataIndex: asKeyOf<GenericTargetLineRow>('itemGroupName'),
      settingKey: 'itemGroupName',
      optional: true,
    },
    {
      title: 'Qty on PO',
      dataIndex: asKeyOf<GenericTargetLineRow>('qtyOnPurchaseOrder'),
      settingKey: 'qtyOnPurchaseOrder',
      optional: true,
    },
    {
      title: 'Unit Volume',
      dataIndex: asKeyOf<GenericTargetLineRow>('unitVolume'),
      settingKey: 'unitVolume',
      optional: true,
    },
    {
      title: 'Unit Weight',
      dataIndex: asKeyOf<GenericTargetLineRow>('unitWeight'),
      settingKey: 'unitWeight',
      optional: true,
    },
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'Required Date',
          dataIndex: 'userRequiredDate',
          width: '140px',
          sorter: true,
          sortDirections,
          settingKey: 'requiredDate',
          optional: true,
          render: (userRequiredDate: ISODateStr, record: GenericTargetLineRow) => (
            <DatePicker
              onChange={(newVal) => handleRequiredDateChange(newVal?.startOf('day').toISOString(), record)}
              format="YYYY/MM/DD"
              picker="date"
              value={userRequiredDate ? moment(userRequiredDate) : undefined}
              showTime={false}
              placeholder={record.placeholderRequiredDate}
              disabled={record.userQtyToOrder === 0}
              className={cx(
                'RequiredDateCell',
                css`
                  width: 140px;
                `,
              )}
            />
          ),
        }
      : null,
    {
      title: 'Item Name',
      settingKey: 'itemName',
      dataIndex: asKeyOf<PurchaseTargetLineRow>('itemName'),
      sorter: true,
      sortDirections,
      optional: true,
    },
  ].filter(truthy);

  const tableColumns: PersistedColumn<any>[] = isPurchasingHubAndSpoke
    ? purchaseGroupTargetLineColumns
    : purchaseTargetLineColumns;

  const [visibleColumnKeys, setVisibleColumnKeys] = useUserViewSettingsState(
    ViewSettingKey.PurchaseTargetLinesTable,
    tableColumns.filter((column) => !column.optional).map((column) => column.settingKey),
  );

  return (
    <>
      <ColumnChooserSection
        tableKey={ViewSettingKey.PurchaseTargetLinesTable}
        columns={tableColumns}
        visibleColumnKeys={visibleColumnKeys}
        setVisibleColumnKeys={setVisibleColumnKeys}
      />
      <Table
        stickyHeader
        stickyHeaderVariant={linesModalMode ? StickyHeaderVariant.Modal : undefined}
        className={css`
          tr.ant-table-row.purchasing-row > td {
            background-color: ${colors.primary[100]};
          }
        `}
        scroll={{ x: true }}
        columns={visibleColumnKeys
          .map((key) => tableColumns.find((column) => column.settingKey === key))
          .filter(truthy)}
        data={linesWithPlaceholders}
        onChange={(_pagination, _filters, _sorter, ev) => {
          if (ev.action === 'sort' && !Array.isArray(_sorter)) {
            setSortDir(_sorter.order as SortOrder);
            setSortField(_sorter.field as string);
          }
        }}
        size="small"
        pagination={{
          pageSize: 20,
          simple: true,
        }}
        rowClassName={(record: GenericTargetLineRow) => focusedRowClassName(record, focusedLine)}
        onRow={(record) => ({
          onClick: () => {
            if (focusedLine?.key !== record.key) {
              setFocusedLine({ ...record, placeholderRequiredDate: undefined });
            }
          },
        })}
        // NOTE: focus cleared via useEventListener hook
      />
    </>
  );
}
