import { schemas } from '@recurrency/core-api-schema';
import {
  ItemLocationUpdate,
  UpdateItemLocationInfoType,
} from '@recurrency/core-api-schema/dist/purchasing/postUpdateItemLocationInfo';
import { MinMaxUpdate } from '@recurrency/core-api-schema/dist/purchasing/postUpdateItemLocationMinMax';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';

import { BulkImportModal } from 'components/recipes/BulkImportModal/BulkImportModal';

import { coreApiFetch } from 'utils/api';
import { csvRowToArray } from 'utils/array';
import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';

export interface BulkImportInput {
  name: string;
  isPrimaryKey: boolean;
  /** Defaults to non optional */
  optional?: boolean;
  validation: (val: string) => null | string;
  /** To format the value after it has been read from the csv (used for converting strings to numbers, etc) */
  format?: (val: string) => any;
}

export interface BulkImportModalProps<BulkImportRequest> {
  onClose: () => void;
  inputs: BulkImportInput[];
  /** Name of import */
  name: string;
  onUpdate: (updates: BulkImportRequest[]) => Promise<any>;
}

export function validateIsNumber(str: string): string | null {
  if (!isNaN(Number(str)) && str.trim() !== '') {
    return null;
  }
  return `${str} is not a number`;
}

export function validateIsBoolean(str: string): string | null {
  if (str.toLowerCase() === 'true' || str.toLowerCase() === 'false') return null;
  return `${str} is not a boolean`;
}

export function toBoolean(str: string): boolean | null {
  if (str.toLowerCase() === 'true') return true;
  if (str.toLowerCase() === 'false') return false;
  return null; // Invalid input that should be validated against
}

export function validateIsISODate(str: string): string | null {
  if (moment(str, moment.ISO_8601, true).isValid()) {
    return null;
  }
  return `${str} is not an ISO Date`;
}

export function showExcludeIncludeCSVModal() {
  return showAsyncModal(
    (props) =>
      BulkImportModal<ItemLocationUpdate>({
        name: 'Exclude/Include',
        inputs: [
          {
            name: 'itemUid',
            isPrimaryKey: true,
            validation: validateIsNumber,
          },
          {
            name: 'locationId',
            isPrimaryKey: true,
            validation: validateIsNumber,
          },
          {
            name: 'excluded',
            isPrimaryKey: false,
            validation: validateIsBoolean,
            format: toBoolean,
          },
          {
            name: 'excludedUntil',
            isPrimaryKey: false,
            optional: true,
            validation: validateIsISODate,
          },
        ],
        onUpdate: async (updates: ItemLocationUpdate[]) =>
          coreApiFetch(schemas.purchasing.postUpdateItemLocationInfo, {
            pathParams: {
              updateType: UpdateItemLocationInfoType.Exclude,
            },
            bodyParams: {
              updates,
            },
          }),

        ...props,
      }),
    {},
  );
}

export function showSafetyStockCSVModal() {
  return showAsyncModal(
    (props) =>
      BulkImportModal<ItemLocationUpdate>({
        name: 'Safety Stock',
        inputs: [
          {
            name: 'itemUid',
            isPrimaryKey: true,
            validation: validateIsNumber,
          },
          {
            name: 'locationId',
            isPrimaryKey: true,
            validation: validateIsNumber,
          },
          {
            name: 'safetyStockDays',
            isPrimaryKey: false,
            validation: validateIsNumber,
            // Empty value means resetting to the default
            optional: true,
            format: (val) => (val ? Number(val) : undefined),
          },
        ],
        onUpdate: async (updates: ItemLocationUpdate[]) =>
          coreApiFetch(schemas.purchasing.postUpdateItemLocationInfo, {
            pathParams: {
              updateType: UpdateItemLocationInfoType.SafetyStock,
            },
            bodyParams: {
              updates,
            },
          }),
        ...props,
      }),
    {},
  );
}

export function showLeadTimeCSVModal() {
  return showAsyncModal(
    (props) =>
      BulkImportModal<ItemLocationUpdate>({
        name: 'Lead Time',
        inputs: [
          {
            name: 'itemUid',
            isPrimaryKey: true,
            validation: validateIsNumber,
          },
          {
            name: 'locationId',
            isPrimaryKey: true,
            validation: validateIsNumber,
          },
          {
            name: 'leadTimeDays',
            isPrimaryKey: false,
            validation: validateIsNumber,
            // Empty value means resetting to the default
            optional: true,
            format: (val) => (val ? Number(val) : undefined),
          },
        ],
        onUpdate: async (updates: ItemLocationUpdate[]) =>
          coreApiFetch(schemas.purchasing.postUpdateItemLocationInfo, {
            pathParams: {
              updateType: UpdateItemLocationInfoType.LeadTime,
            },
            bodyParams: {
              updates,
            },
          }),
        ...props,
      }),
    {},
  );
}

export function showStockFloorModal() {
  return showAsyncModal(
    (props) =>
      BulkImportModal<ItemLocationUpdate>({
        name: 'Stock Floor',
        inputs: [
          {
            name: 'itemUid',
            isPrimaryKey: true,
            validation: validateIsNumber,
          },
          {
            name: 'locationId',
            isPrimaryKey: true,
            validation: validateIsNumber,
          },
          {
            name: 'minStockFloor',
            isPrimaryKey: false,
            validation: validateIsNumber,
            // Empty value means resetting to the default
            optional: true,
            format: (val) => (val ? Number(val) : undefined),
          },
        ],
        onUpdate: async (updates: ItemLocationUpdate[]) =>
          coreApiFetch(schemas.purchasing.postUpdateItemLocationInfo, {
            pathParams: {
              updateType: UpdateItemLocationInfoType.MinStockFloor,
            },
            bodyParams: {
              updates,
            },
          }),
        ...props,
      }),
    {},
  );
}

export function showMinMaxCSVModal() {
  return showAsyncModal(
    (props) =>
      BulkImportModal<MinMaxUpdate>({
        name: 'Min/Max',
        inputs: [
          {
            name: 'itemId',
            isPrimaryKey: true,
            validation: (_) => null,
          },
          {
            name: 'itemUid',
            isPrimaryKey: true,
            validation: validateIsNumber,
          },
          {
            name: 'locationId',
            isPrimaryKey: true,
            validation: validateIsNumber,
          },
          {
            name: 'newMin',
            isPrimaryKey: false,
            validation: validateIsNumber,
            format: (val) => Number(val),
          },
          {
            name: 'newMax',
            isPrimaryKey: false,
            validation: validateIsNumber,
            format: (val) => Number(val),
          },
        ],
        onUpdate: async (updates: MinMaxUpdate[]) =>
          coreApiFetch(schemas.purchasing.postUpdateItemLocationMinMax, {
            bodyParams: {
              updates,
            },
          }),
        ...props,
      }),
    {},
  );
}

export const downloadHeaderTemplate = async (headers: string[], name: string) => {
  const blob = new Blob([headers.join(',')], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `bulk_upload_${name}_template.csv`;
  a.click();
};

export async function parseUploadedData<BulkImportRequest>(
  file: RcFile,
  inputs: BulkImportInput[],
  setIsUploading: (isUploading: boolean) => void,
  setError: (error: string) => void,
  setUploadedData: (data: BulkImportRequest[]) => void,
  setFileName: (fileName: string) => void,
): Promise<BulkImportRequest[] | void> {
  try {
    setIsUploading(true);
    const reader = new FileReader();
    const allowedHeaders = inputs.map((val) => val.name);
    const primaryKeys = inputs.filter((val) => val.isPrimaryKey).map((val) => val.name);

    reader.onload = async (e) => {
      const text = e.target?.result as string;
      const lines = text.split('\n');
      const headers = lines[0].replace('\r', '').split(',');
      const unknownColumns = [];
      const headerSet = new Set<string>();
      for (const header of headers) {
        if (headerSet.has(header)) {
          setError(`Duplicate header "${header}" included in csv`);
          return;
        }
        headerSet.add(header);
        // expected columns are just the ones in the set
        if (!allowedHeaders.includes(header)) {
          unknownColumns.push(header);
        }
      }
      if (unknownColumns.length > 0) {
        setError(`Unknown columns "${unknownColumns.join(', ')}" included in csv`);
        return;
      }
      allowedHeaders.forEach((header) => {
        if (!headerSet.has(header)) {
          setError(`Missing column "${header}" in csv`);
        }
      });
      const deduplicationSet = new Set<string>();
      const newData = lines
        .slice(1) // Remove header row
        .filter((s) => s.trim() !== '') // Remove empty lines
        .map((line, rowIndex) => {
          const values = csvRowToArray(line);
          if (values.length !== headers.length) {
            setError(`Invalid csv format, line ${rowIndex + 1} does not have the same number of columns as the header`);
          }
          values.forEach((v, columnIndex) => {
            // if empty and not optional, then error
            if (v === '' && !inputs.find((v) => v.name === headers[columnIndex])?.optional) {
              setError(
                `Invalid csv format, line ${rowIndex + 1} has an empty value for column ${headers[columnIndex]}`,
              );
            } else if (v !== '') {
              // If not empty, then validate. Empty, optional values are implicitly allowed
              const validationString = inputs.find((v) => v.name === headers[columnIndex])?.validation(v);
              if (validationString) {
                setError(validationString);
              }
            }
          });
          const row: { [key: string]: string } = {};
          let primaryRow = '';
          headers.forEach((header, index) => {
            primaryKeys.forEach((val) => {
              if (header === val) {
                primaryRow += `${values[index]}|`;
              }
            });
            const formattingFunction = inputs.find((v) => v.name === headers[index])?.format;
            row[header] = formattingFunction ? formattingFunction(values[index]) : values[index] || undefined;
          });
          if (deduplicationSet.has(primaryRow)) {
            setError(`Duplicate value "${primaryRow}" included in csv`);
          }
          deduplicationSet.add(primaryRow);
          return row;
        })
        .filter(truthy) as unknown as BulkImportRequest[];
      setUploadedData(newData);
    };
    reader.readAsText(file);
    setFileName(file.name);
  } catch (error) {
    setError('Error uploading data');
  } finally {
    setIsUploading(false);
  }
}
