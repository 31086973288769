import React from 'react';

import { CloudUploadOutlined, DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';

import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { Tooltip } from 'components/Tooltip';

import {
  showExcludeIncludeCSVModal,
  showLeadTimeCSVModal,
  showMinMaxCSVModal,
  showSafetyStockCSVModal,
  showStockFloorModal,
} from './utils';

export const BulkImportDropdown = () => (
  <Dropdown
    overlay={
      <Menu>
        <Menu.Item
          key="Exclude"
          onClick={() => {
            showExcludeIncludeCSVModal();
          }}
        >
          Exclude/Include
        </Menu.Item>
        <Menu.Item
          key="Safety Stock"
          onClick={() => {
            showSafetyStockCSVModal();
          }}
        >
          Safety Stock
        </Menu.Item>
        <Menu.Item
          key="Lead Time"
          onClick={() => {
            showLeadTimeCSVModal();
          }}
        >
          Lead Time
        </Menu.Item>
        <Menu.Item
          key="Stock Floor"
          onClick={() => {
            showStockFloorModal();
          }}
        >
          Stock Floor
        </Menu.Item>
        <Menu.Item
          key="Min/Max"
          onClick={() => {
            showMinMaxCSVModal();
          }}
        >
          Min/Max
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item disabled key="Item Inheritance">
          <Tooltip
            title="This is not implemented, ask engineering for help if you need to upload this type"
            placement="left"
          >
            Item Inheritance
          </Tooltip>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item disabled key="Supplier Targets">
          <Tooltip
            title="This is not implemented, ask engineering for help if you need to upload this type"
            placement="left"
          >
            Supplier Targets
          </Tooltip>
        </Menu.Item>
        <Menu.Item disabled key="Supplier Minimums">
          <Tooltip
            title="This is not implemented, ask engineering for help if you need to upload this type"
            placement="left"
          >
            Supplier Minimums
          </Tooltip>
        </Menu.Item>
        <Menu.Item disabled key="Supplier Review Cycle">
          <Tooltip
            title="This is not implemented, ask engineering for help if you need to upload this type"
            placement="left"
          >
            Supplier Review Cycle
          </Tooltip>
        </Menu.Item>
        <Menu.Item disabled key="Supplier Order Cycle">
          <Tooltip
            title="This is not implemented, ask engineering for help if you need to upload this type"
            placement="left"
          >
            Supplier Order Cycle
          </Tooltip>
        </Menu.Item>
      </Menu>
    }
  >
    <Button icon={<CloudUploadOutlined />}>
      CSV Upload <DownOutlined />
    </Button>
  </Dropdown>
);
