import { SearchRequest, SearchResponse } from 'utils/search/types';

import { SearchFrameHashState } from 'types/hash-state';

import { GetDownloadDataFn } from '../DownloadButton';

export interface ListItemsSelection<HitT> {
  type: 'list';
  items: HitT[];
  count: number;
}

export interface FilterItemsSelection {
  type: 'filter';
  filters?: Obj<string[]>;
  /** This corresponds to the user-friendly title of the filter keys */
  filterTitleMapping?: Obj<string | undefined>;
  count: number;
}

export type SearchFrameSelection<HitT> = ListItemsSelection<HitT> | FilterItemsSelection;

export interface SearchFrameContext<HitT> {
  hashState: SearchFrameHashState;
  searchRequest: SearchRequest;
  selection?: SearchFrameSelection<HitT>;
  searchIndexResponse?: SearchResponse<HitT>;
  searchIndexReload: () => void;
  clearSelection: () => void;
  /** true if there is a row selection either via selectAll or selectedRows */
  canDownload: boolean;
  getDownloadData: GetDownloadDataFn;
}

export enum FacetRenderType {
  /** Facet rendered as a dropdown */
  Dropdown = 'dropdown',
  /** Facet rendered as a pill */
  Pill = 'pill',
}
