import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { ValidationRuleRunItemLocationDTO } from '@recurrency/core-api-schema/dist/validationRules/getValidationRuleRunsByRuleId';
import { ColumnType } from 'antd/lib/table';

import { PlanningSidePane } from 'pages/purchasing/PlanningPage/PlanningSidePane';

import { AsyncTable } from 'components/AsyncTable';
import { UseAsyncTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { PlanningStatusBadge } from 'components/recipes/PlanningStatusBadge';
import { BadgeStatus } from 'components/recipes/StatusBadge';
import { TableSplitPage } from 'components/recipes/TableSplitPage';
import { DemandPatternTooltip } from 'components/Tooltip/DemandPatternTooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { formatDate } from 'utils/formatting';
import { makePath, routePaths } from 'utils/routes';
import { asKeyOf } from 'utils/tables';
import { getTenantSetting } from 'utils/tenantSettings';

export const RuleDetailRunTable = ({
  tableProps,
}: {
  tableProps: UseAsyncTableProps<ValidationRuleRunItemLocationDTO>;
}) => {
  const [focusedRecord, setFocusedRecord] = useState<ValidationRuleRunItemLocationDTO | null>(null);
  const useOldDemandPatternDefinition =
    getTenantSetting(TenantSettingKey.CalculationUseOldDemandPatternDefinition) ?? false;

  const columns: ColumnType<ValidationRuleRunItemLocationDTO>[] = [
    {
      title: 'Item ID',
      render: (_, item: ValidationRuleRunItemLocationDTO) => (
        <div>
          <Link
            to={makePath(routePaths.demandPlanning.planning, null, {
              query: item.item_id,
              where: {
                location: [item.location],
              },
            })}
          >
            {item.item_id}
          </Link>
          <div>{item.item_name}</div>
        </div>
      ),
      dataIndex: asKeyOf<ValidationRuleRunItemLocationDTO>('item_id'),
    },
    {
      title: 'Location',
      dataIndex: asKeyOf<ValidationRuleRunItemLocationDTO>('location'),
    },
    {
      title: 'Status',
      dataIndex: asKeyOf<ValidationRuleRunItemLocationDTO>('planning_status'),
      render: (status: BadgeStatus, record) => <PlanningStatusBadge status={status} record={record} />,
    },
    {
      title: (
        <InfoTooltip title="Demand patterns group items based on consistency of orders and variance in demand quantity to forecast in the optimal manner">
          Pattern
        </InfoTooltip>
      ),
      dataIndex: asKeyOf<ValidationRuleRunItemLocationDTO>('demand_pattern'),
      render: (demandPattern) => (
        <DemandPatternTooltip
          demandPattern={demandPattern}
          useOldDemandPatternDefinition={useOldDemandPatternDefinition}
        />
      ),
    },
    {
      title: 'Last Run Time',
      dataIndex: asKeyOf<ValidationRuleRunItemLocationDTO>('lastRunTime'),
      render: (lastRunTime: string) => formatDate(lastRunTime, true, true),
      sorter: true,
    },
  ];

  return (
    <TableSplitPage
      left={
        <AsyncTable
          stickyHeader
          columns={columns}
          tableProps={tableProps}
          rowClassName={(record: ValidationRuleRunItemLocationDTO) =>
            ['focusable-row', record === focusedRecord ? 'focused-row' : ''].join(' ')
          }
          onRow={(record) => ({
            onClick: () => setFocusedRecord(record),
          })}
        />
      }
      right={
        <PlanningSidePane record={focusedRecord} isOnboardingVariant={false} searchIndexReload={tableProps.reload} />
      }
    />
  );
};
