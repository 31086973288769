import React from 'react';

import { Link } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { LeadTimeLineDTO } from '@recurrency/core-api-schema/dist/v2/purchasing';
import { ColumnType } from 'antd/lib/table';

import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';

import { filterCostAndGM } from 'utils/filterCostAndGM';
import { routes } from 'utils/routes';
import { sortableIdColumn, sortableNumberColumn, sortableDateColumn, asKeyOf } from 'utils/tables';

export const LeadTimeHistoryTable = ({
  itemId,
  itemCode,
  supplierId,
  locationId,
}: {
  itemId: string | undefined;
  itemCode: string | undefined;
  supplierId: string;
  locationId: string;
}) => {
  const columns: (ColumnType<LeadTimeLineDTO> | null)[] = [
    sortableIdColumn({
      title: 'PO No.',
      dataIndex: asKeyOf<LeadTimeLineDTO>('purchaseOrderNumber'),
      render: (id: string) => <Link to={routes.purchasing.purchaseOrderDetails(id)}>{id}</Link>,
      sorter: false,
    }),
    sortableDateColumn({
      title: 'Order Date',
      dataIndex: asKeyOf<LeadTimeLineDTO>('orderedAt'),
      sorter: false,
      defaultSortOrder: 'descend',
    }),
    sortableDateColumn({
      title: 'Receipt Date',
      dataIndex: asKeyOf<LeadTimeLineDTO>('receivedAt'),
      sorter: false,
    }),
    !itemCode
      ? sortableIdColumn({
          title: 'Item',
          dataIndex: asKeyOf<LeadTimeLineDTO>('itemCode'),
          render: (id: string, record: LeadTimeLineDTO) => (
            <Link to={routes.purchasing.itemDetails(id)}>
              {id}: {record.itemName}
            </Link>
          ),
          sorter: false,
        })
      : null,
    sortableNumberColumn({
      title: 'Lead Time (days)',
      dataIndex: asKeyOf<LeadTimeLineDTO>('leadTime'),
      // render: (_, record: LeadTimeLineDTO) => `${record.locationId}: ${record.locationName}`,
      sorter: false,
    }),
  ];

  const tableProps = useCoreApiTableProps({
    schema: schemas.purchasing.getLeadTimeHistoryV2,
    queryParams: {
      filter: {
        itemId,
        locationId,
        supplierId,
      },
    },
    pageSize: 5,
  });

  return <AsyncTable tableProps={tableProps} columns={columns.filter(filterCostAndGM)} />;
};
