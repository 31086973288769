import React, { useRef } from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { TaskDTO } from '@recurrency/core-api-schema/dist/tasks/common';
import { GetTasksQueryParams } from '@recurrency/core-api-schema/dist/tasks/getTasks';
import { ZipCelXSheet } from 'zipcelx';

import { Container } from 'components/Container';
import { PageHeader } from 'components/PageHeader';
import { DownloadButton, DownloadXSheetColumn, recordsToXSheet } from 'components/recipes/DownloadButton';
import { NewTaskButton } from 'components/recipes/NewTaskButton';
import { TasksContextRef, TasksTable } from 'components/recipes/TasksTable';

import { coreApiFetch, fetchAllRecordsInBatches } from 'utils/api';
import { formatName } from 'utils/formatting';

export function TasksListPage() {
  const contextRef = useRef<TasksContextRef>();

  return (
    <Container>
      <PageHeader
        title="Tasks"
        headerActions={
          <>
            <DownloadButton
              recordType="Tasks"
              getDownloadData={() => getDownloadData(contextRef.current!.queryParams)}
            />
            <NewTaskButton onSubmit={contextRef.current?.reload} />
          </>
        }
      />
      <TasksTable showNewTaskButton={false} contextRef={contextRef} />
    </Container>
  );
}

async function getDownloadData(apiQueryParams: GetTasksQueryParams): Promise<ZipCelXSheet> {
  const tasks = await fetchAllRecordsInBatches((offset, limit) =>
    coreApiFetch(schemas.tasks.getTasks, { queryParams: { ...apiQueryParams, offset, limit } }),
  );

  const exportColumns: Array<DownloadXSheetColumn<TaskDTO>> = [
    { title: 'ID', type: 'string', value: (record) => record.id },
    { title: 'Status', type: 'string', value: (record) => record.status },
    { title: 'Topic', type: 'string', value: (record) => record.title },
    { title: 'Comments', type: 'string', value: (record) => record.body || '' },
    { title: 'Customer ID', type: 'string', value: (record) => record.metadata.customer?.foreignId || '' },
    { title: 'Customer Name', type: 'string', value: (record) => record.metadata.customer?.name || '' },
    {
      title: 'Created By',
      type: 'string',
      value: (record) => formatName(record.user.firstName, record.user.lastName),
    },
    {
      title: 'Assigned To',
      type: 'string',
      value: (record) => formatName(record.assignee?.firstName, record.assignee?.lastName),
    },
    {
      title: 'Date Due',
      type: 'string',
      value: (record) => new Date(record.dueAt).toLocaleDateString(),
    },
    {
      title: 'Last Modified At',
      type: 'string',
      value: (record) => new Date(record.updatedAt).toLocaleDateString(),
    },
    {
      title: 'Created At',
      type: 'string',
      value: (record) => new Date(record.createdAt).toLocaleDateString(),
    },
  ];

  return recordsToXSheet(tasks, exportColumns);
}
