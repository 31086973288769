import React from 'react';

import { Router, useLocation } from 'react-router-dom';

import { css, cx } from '@emotion/css';
import { Grid } from 'antd';

import { Alert } from 'components/Alert';
import { InventiveButton } from 'components/Inventive/InventiveButton';
import { CenteredAccountError } from 'components/Loaders';
import { UpgradeBanner } from 'components/recipes/Upgrade/UpgradeBanner';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { browserHistory } from 'utils/browserHistory';
import { env } from 'utils/env';
import { parsePath } from 'utils/routes';
import { tenantIds } from 'utils/tenants';

import { AppRoutes } from './frames/AppRoutes';
import { AppVersionPoller } from './frames/AppVersionPoller';
import { Auth0AppStateLoader } from './frames/Auth0AppStateLoader';
import { ErrorBoundary } from './frames/ErrorBoundary';
import { IntegrationPausedBanner } from './frames/IntegrationPausedBanner';
import { MaintenancePage } from './frames/MaintenancePage';
import { Navigation } from './frames/Navigation';
import { NoConnectionBanner } from './frames/NoConnectionBanner';

export function App() {
  const showMaintenancePage = false;

  return (
    <Router history={browserHistory}>
      <ErrorBoundary>
        {showMaintenancePage ? (
          <MaintenancePage />
        ) : (
          <Auth0AppStateLoader
            domain={env.AUTH0_DOMAIN}
            client_id={env.AUTH0_CLIENT_ID}
            audience={env.AUTH0_AUDIENCE}
            redirect_uri={window.location.origin}
            scope="openid profile email recurrency:user recurrency:admin"
            cacheLocation="localstorage"
            // only setting this for staging and dev environments to guage user experience impact
            useRefreshTokens={env.APP_ENV !== 'production'}
          >
            <AppVersionPoller>
              <AppContainer>
                <AppRoutes />
              </AppContainer>
            </AppVersionPoller>
          </Auth0AppStateLoader>
        )}
      </ErrorBoundary>
    </Router>
  );
}

function AppContainer({ children }: { children: JSX.Element }) {
  const { activeUser, activeTenant } = useGlobalApp();

  const isDesktopView: boolean = Grid.useBreakpoint()?.md ?? false;

  // This is a temporary solution to show the Inventive chatbot only for the POC in the CWC Demo instance.
  // TODO: @hunter - Remove or reassess after PWWUG demo 8/25/24
  const location = useLocation();
  const curPagePath = location.pathname;
  const curPagePathParseResult = parsePath(curPagePath);
  const { moduleSlug } = curPagePathParseResult;
  // Hardcode this for CWC Demo instance only. Not using feature flag due to poor multi-tenancy handling on Inventive's side.
  // Don't want to accidentally give a tenant access to query CWC's data if a feature flag were mistakenly turned on.
  // Currently only returns results from demand planning endpoints, so only show it for that module.
  const shouldShowInventive = activeTenant.id === tenantIds.staging.cwc && moduleSlug === 'demand-planning';

  // don't render app if user doesn't load or has no tenants
  if (!activeUser || !activeTenant) {
    return <CenteredAccountError />;
  }

  const showWarningBanner = false;
  const warningBannerText = `Epicor Cloud is currently experiencing issues and we are unable to sync data for these customers.  If you are an Epicor Cloud customer, you can still view data in Recurrency, but data may be stale and you will not be able to export data back to P21.  For more details, see Epicor's status page at https://status.epicor.com/?componentId=80992. Our team is actively monitoring the situation.`;
  const showErrorBanner = false;
  const errorBannerText = `Our team is aware of an issue impacting syncs after exports. We are working to resolve this as soon as possible. We apologize for the inconvenience.`;
  const showSolutionsBanner = activeUser.email === 'solutions@recurrency.com';
  const solutionsBannerText =
    'This solutions account has full write permissions, be very careful when performing updates and sign out when you are not making them.';

  return (
    <div
      className={cx(
        'AppContainer',
        css`
          min-height: 100vh;
          position: relative;
          display: flex;
          background: white;
        `,
      )}
    >
      <Navigation isDesktopView={isDesktopView}>
        <div
          className={css`
            position: relative;
            background-color: white;
            margin-top: ${isDesktopView ? '0px' : '80px'};
            margin-bottom: 20px;
          `}
        >
          {showErrorBanner && <Alert noRadius message={errorBannerText} banner type="error" />}
          {showSolutionsBanner && <Alert noRadius message={solutionsBannerText} banner type="error" />}
          {showWarningBanner && <Alert noRadius closable message={warningBannerText} banner type="warning" />}
          <IntegrationPausedBanner />
          <NoConnectionBanner />
          <UpgradeBanner />
          {children}
          {shouldShowInventive && <InventiveButton />}
        </div>
      </Navigation>
    </div>
  );
}
