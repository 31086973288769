import React from 'react';

import { ValidationRuleDetailDTO } from '@recurrency/core-api-schema/dist/validationRules/getRuleDetails';
import { theme } from 'theme';

import { Button } from 'components/Button';
import { DividerLine } from 'components/DividerLine';
import { Modal } from 'components/Modal';

import { formatDate } from 'utils/formatting';

export function FlagRuleRunsModal({
  rule,
  onClose,
  onSubmit,
}: {
  rule?: ValidationRuleDetailDTO;
  onClose: () => void;
  onSubmit: () => void;
}) {
  return (
    <Modal
      visible
      title="Flag all items from this rule"
      onCancel={() => onClose()}
      width={900}
      destroyOnClose
      footer={
        <>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button onClick={() => onSubmit()} type="primary">
            Flag all items
          </Button>
        </>
      }
    >
      <>
        <p>
          All items that failed the <b>{rule?.name}</b> rule will be set as Flagged, and will not be set as Needs Review
          in the future until the flag is resolved.
        </p>
        <DividerLine />
        <p
          style={{
            textAlign: 'center',
            borderRadius: 8,
            padding: '8px 15px',
            backgroundColor: theme.colors.primary[200],
          }}
        >
          Flagging reason will be set as the following: <br />
          <i>{`Flagged by validation rule [${rule?.name}] at ${formatDate(rule?.lastRunTime, true, true)}`}</i>
        </p>
      </>
    </Modal>
  );
}
