import React from 'react';

import { DeploymentUnitOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag, TenantProductName, UnitCostSource } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { schema } from '@recurrency/core-api-schema/dist/companies';
import { GetSalesInvoiceLinesReportGroupBy } from '@recurrency/core-api-schema/dist/reports/getSalesInvoiceLinesReport';
import {
  ItemAvailabilityListDTO,
  ItemAvailabilityDTO,
} from '@recurrency/core-api-schema/dist/v2/items/getItemAvailability';
import { AxiosResponse } from 'axios';
import { theme } from 'theme';

import { Container } from 'components/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { PageHeader } from 'components/PageHeader';
import { AssemblyComponentsTable } from 'components/recipes/AssemblyComponentsTable';
import { AvailableItemLotsTable } from 'components/recipes/AvailableItemLotsTable';
import { DetailPagePropertyListItem } from 'components/recipes/detailPage/DetailPagePropertyList';
import { DetailPageSection, DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import { NewOrderButton } from 'components/recipes/NewOrderButton';
import { NewQuoteButton } from 'components/recipes/NewQuoteButton';
import { PurchaseOrderLinesTable } from 'components/recipes/PurchaseOrderLinesTable';
import { SalesInvoiceLinesTable } from 'components/recipes/SalesInvoiceLinesTable';
import { SalesOrderLinesTable } from 'components/recipes/SalesOrderLinesTable';
import { SalesQuoteLinesTable } from 'components/recipes/SalesQuoteLinesTable';
import { salesHistoryReportSections } from 'components/recipes/salesReport/salesHistoryReportSections';
import { SalesReportTable } from 'components/recipes/salesReport/SalesReportTable';
import { FilterByField } from 'components/recipes/salesReport/salesReportUtils';
import { useSalesHistoryReport } from 'components/recipes/salesReport/useSalesHistoryReport';
import { UOMTable } from 'components/recipes/UOMTable';
import { VesselLinesTable } from 'components/recipes/VesselLinesTable';
import { Tooltip } from 'components/Tooltip/Tooltip';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';
import { usePromise } from 'hooks/usePromise';

import { coreApiFetch, legacyApiFetch } from 'utils/api';
import { optArrFromVal } from 'utils/array';
import { truthy } from 'utils/boolean';
import { dashesIfEmpty, formatNumber, formatUSD, isStringEqual } from 'utils/formatting';
import { shouldHideCostAndGm, shouldShowFeatureFlag, shouldShowProduct } from 'utils/roleAndTenant';
import { usePathParams, IdPathParams } from 'utils/routes';
import { isTenantErpTypeP21 } from 'utils/tenants';
import { getTenantSetting } from 'utils/tenantSettings';
import { qtyUnitConverter } from 'utils/units';

import { SalesRepLocationResponse } from 'types/legacy-api';

import { unitCostSettingLabelMap } from './common';
import { AvailabilityTable } from './tabs/AvailabilityTable';
import { OpenTransferLinesTable } from './tabs/OpenTransferLinesTable';
import { RecommendedCustomersTable } from './tabs/RecommendedCustomersTable';

export const ItemDetailsPage = () => {
  const { id: itemCode } = usePathParams<IdPathParams>();
  const { activeTenant, activeUser } = useGlobalApp();
  const hideCostAndGM = shouldHideCostAndGm(activeTenant, activeUser);
  const shouldShowQuoteOrderButtons = shouldShowProduct(activeTenant, TenantProductName.SalesQuoting);

  /** only show qty available if there's one or no companies
   * this is because the qty is an aggregation of all companies and users may only be able to view a subset of these
   */
  const allCompanyData = useCoreApi(schema.getCompanies, {
    queryParams: { allCompanies: true },
  });
  const showCompanyQty = allCompanyData.data ? allCompanyData.data.items.length < 2 : false;

  const {
    data: itemDetailsData,
    isLoading: itemDetailsIsLoading,
    error,
  } = useCoreApi(schemas.items.getItemDetailsV2, {
    pathParams: { itemId: itemCode },
  });

  const {
    data: salesHistoryData,
    error: salesHistoryError,
    isLoading: salesHistoryIsLoading,
  } = useSalesHistoryReport({
    groupBy: GetSalesInvoiceLinesReportGroupBy.Item,
    filter: { [FilterByField.Item]: optArrFromVal(itemCode) },
    ignoreRoleSalesRepIds: true,
  });

  const { data: companyCostData } = usePromise(async () => {
    const [availabilityDataResult, salesRepLocationDataResult] =
      showCompanyQty && itemDetailsData?.itemId
        ? await fetchCompanyQtyData(itemDetailsData.itemId)
        : [{ data: null } as AxiosResponse, { data: null } as AxiosResponse];

    return {
      availability: availabilityDataResult.data?.items,
      salesRepLocation: salesRepLocationDataResult.data,
    };
  }, [showCompanyQty, itemDetailsData]);

  if (error || salesHistoryError) {
    return <CenteredError error={error} />;
  }

  if (itemDetailsIsLoading || !itemDetailsData) {
    return <CenteredLoader />;
  }

  const headerSections: (DetailPageSection | null)[] = [
    {
      title: 'Item Info',
      rows: [
        [
          { label: 'Extended Description', value: dashesIfEmpty(itemDetailsData.itemDescription) },
          getTenantSetting(TenantSettingKey.UiShowShortCode)
            ? {
                label: 'Short Code',
                value: dashesIfEmpty(itemDetailsData.itemShortCode),
              }
            : null,
        ].filter(truthy),
      ],
    },
    showCompanyQty && companyCostData?.availability
      ? {
          title: 'Stock',
          rows: [
            getLocationStockPropertyList(
              companyCostData.availability,
              companyCostData.salesRepLocation,
              !hideCostAndGM,
            ),
          ],
        }
      : null,
    ...salesHistoryReportSections({
      stats: salesHistoryData?.items[0],
      isLoading: salesHistoryIsLoading,
    }),
  ];

  return (
    <Container>
      <PageHeader
        title={itemDetailsData.itemName}
        copyable
        entity={{
          kind: 'Item',
          id: itemCode,
          badge: itemDetailsData.isAssembly ? (
            <Tooltip title="Assembly item has one or more components" placement="right">
              <DeploymentUnitOutlined
                style={{ display: 'inline-block', marginLeft: '-5px', color: theme.colors.neutral[700] }}
              />
            </Tooltip>
          ) : null,
        }}
        headerActions={
          shouldShowQuoteOrderButtons && (
            <>
              <NewQuoteButton hashState={{ items: [{ foreignId: itemCode, name: itemDetailsData.itemName }] }} />
              <NewOrderButton hashState={{ items: [{ foreignId: itemCode, name: itemDetailsData.itemName }] }} />
            </>
          )
        }
      />
      <DetailPageSections sections={headerSections.filter(truthy)} />
      <NavTabs
        tabs={[
          { header: 'Availability & Cost', content: <AvailabilityTable itemUid={itemDetailsData.itemId} /> },
          { header: 'Sales History', content: <SalesInvoiceLinesTable itemId={itemCode} /> },
          shouldShowProduct(activeTenant, TenantProductName.SalesSearchAndLookup) && {
            header: 'Quote Lines',
            content: <SalesQuoteLinesTable itemId={itemCode} />,
          },
          { header: 'Order Lines', content: <SalesOrderLinesTable itemId={itemCode} /> },
          {
            header: 'PO Lines',
            content: <PurchaseOrderLinesTable itemId={itemCode} />,
          },
          shouldShowProduct(activeTenant, TenantProductName.SalesSearchAndLookup) &&
          shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SalesItemAvailabilityWithQtyOnVessel)
            ? {
                header: 'Vessel Lines',
                content: <VesselLinesTable itemUid={itemDetailsData.itemId} />,
              }
            : undefined,
          isTenantErpTypeP21(activeTenant.erpType)
            ? {
                header: 'Open Transfer Lines',
                content: <OpenTransferLinesTable invMastUid={itemDetailsData.itemId} />,
              }
            : undefined,
          {
            header: 'Top Customers',
            content: (
              <SalesReportTable
                groupBy={GetSalesInvoiceLinesReportGroupBy.Customer}
                filter={{ [FilterByField.Item]: optArrFromVal(itemCode) }}
              />
            ),
          },
          {
            header: 'Top Ship Tos',
            content: (
              <SalesReportTable
                groupBy={GetSalesInvoiceLinesReportGroupBy.ShipTo}
                filter={{ [FilterByField.Item]: optArrFromVal(itemCode) }}
              />
            ),
          },
          shouldShowProduct(activeTenant, TenantProductName.SalesRecommendations) && {
            header: 'Recommended New Customers',
            content: <RecommendedCustomersTable itemUid={itemDetailsData.itemId} />,
          },
          { header: 'UOMs', content: <UOMTable itemId={itemCode} /> },
          shouldShowProduct(activeTenant, TenantProductName.SalesSearchAndLookup) && itemDetailsData.isAssembly
            ? { header: 'Components', content: <AssemblyComponentsTable itemId={itemCode} /> }
            : undefined,
          shouldShowProduct(activeTenant, TenantProductName.SalesSearchAndLookup) && itemDetailsData.lotsAssignable
            ? { header: 'Lots', content: <AvailableItemLotsTable itemId={itemCode} /> }
            : undefined,
        ].filter(truthy)}
      />
    </Container>
  );
};

function fetchCompanyQtyData(
  itemUid: string,
): Promise<[AxiosResponse<ItemAvailabilityListDTO>, AxiosResponse<SalesRepLocationResponse>]> {
  const availabilityData = coreApiFetch(schemas.items.getItemAvailabilityV2, {
    pathParams: { itemUid },
  });

  const salesRepLocationData = legacyApiFetch<SalesRepLocationResponse>('/v3/salesrep', {
    method: 'GET',
  });

  return Promise.all([availabilityData, salesRepLocationData]);
}

function getLocationStockPropertyList(
  availabilityData: ItemAvailabilityDTO[] | undefined,
  salesRepLocationData: SalesRepLocationResponse | undefined,
  shouldShowCostAndGM: boolean,
): DetailPagePropertyListItem[] {
  if (!availabilityData || !salesRepLocationData) {
    return [
      {
        label: 'Company Qty Available',
        isLoading: true,
        value: '',
      },
    ];
  }

  const { locationId } = salesRepLocationData;

  let totalStock = 0;
  let salesRepLocationInfo = {
    locationName: '',
    quantityAvailable: 0,
    unitCost: 0,
    unitCostSetting: UnitCostSource.MovingAverage,
  };
  let defaultUnit = '';

  for (const location of availabilityData) {
    const qtyAvailable = qtyUnitConverter(location.qtyAvailable, location.unitSize);
    totalStock += qtyAvailable;
    if (isStringEqual(locationId, location.locationId)) {
      salesRepLocationInfo = {
        locationName: location.locationName,
        quantityAvailable: location.qtyAvailable,
        unitCost: location.unitCost ?? location.averageUnitCost,
        unitCostSetting: location.unitCostSetting ?? UnitCostSource.MovingAverage,
      };
    }
    if (defaultUnit === '' && location.inventoryUnitOfMeasure) {
      defaultUnit = location.inventoryUnitOfMeasure;
    }
  }

  return [
    {
      label: 'Company Qty Available',
      value: `${formatNumber(totalStock)} ${defaultUnit}`,
    },
    salesRepLocationInfo.locationName
      ? {
          label: `${salesRepLocationInfo.locationName} Stock`,
          value: `${formatNumber(salesRepLocationInfo.quantityAvailable)} ${defaultUnit}`,
        }
      : null,
    salesRepLocationInfo.locationName && shouldShowCostAndGM
      ? {
          label: `${salesRepLocationInfo.locationName} ${unitCostSettingLabelMap(
            salesRepLocationInfo.unitCostSetting ?? UnitCostSource.MovingAverage,
          )}`,
          value: formatUSD(salesRepLocationInfo.unitCost, true),
        }
      : null,
  ].filter(truthy);
}
