// keep types in separate file to prevent cyclic dependencies

import type { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import type { SearchClient as TypesenseSearchClient } from 'typesense';

export { TypesenseSearchClient };

export const MAX_VALUES_PER_FACET = 100;
// using 20 so it divides cleanly with 100 and 1000
export const DEFAULT_HITS_PER_PAGE = 20;

export interface SearchRequest {
  indexName: SearchIndexName;
  query?: string;
  /** string[] means OR, facet filters themselves are AND */
  filters?: Obj<string[]>;
  fieldsToRetrieve?: string[];
  /** fields to return counts of */
  facetFields?: string[];
  facetSortValuesBy?: 'count' | 'alpha';
  maxValuesPerFacet?: number;
  /** sort results by field */
  sortBy?: { field: string; order: 'asc' | 'desc' };
  secondarySortBy?: { field: string; order: 'asc' | 'desc' };
  /** number of results per page */
  hitsPerPage?: number;
  /** page number */
  page?: number;
}

export type FacetValues = Array<{
  value: string;
  count: number;
}>;

export interface SearchResponse<ObjectT> {
  hits: ObjectT[];
  nbHits: number;
  /** 0 index based current page */
  page: number;
  nbPages: number;
  hitsPerPage: number;
  facets: Obj<FacetValues>;
}

export interface GetAllRecordsRequest {
  indexName: SearchIndexName;
  filters?: Obj<string[]>;
  fieldsToRetrieve?: string[];
}
