import React from 'react';

import {
  BankOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LockOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag, TenantProductName } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantUserDTO, TenantUserErpRoleDTO } from '@recurrency/core-api-schema/dist/tenants/tenantUserDTO';
import { ErpRoleMetadataMap } from '@recurrency/core-api-schema/dist/users/common';
import { Badge, Menu, Modal as antdModal, notification } from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { colors } from 'theme/colors';

import { ResultsCounter } from 'pages/purchasing/PurchasingDashboardPage/shared/ResultsCounter';

import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { DividerLine } from 'components/DividerLine';
import { Dropdown } from 'components/Dropdown';
import { FlexSpace } from 'components/FlexSpace';
import { PageHeader } from 'components/PageHeader';
import { SearchInput } from 'components/SearchInput';
import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { truthy } from 'utils/boolean';
import { captureAndShowError } from 'utils/error';
import { formatDate, formatName } from 'utils/formatting';
import { shouldShowFeatureFlag, shouldShowProduct } from 'utils/roleAndTenant';
import { useHashState } from 'utils/routes';
import { sortableDateColumn, sortableStringColumn } from 'utils/tables';

import { UserManagementHashState } from 'types/hash-state';

import { InviteUserModal } from './InviteUserModal';
import { SsoAlert } from './SsoAlert';
import { UserPermissionsModal } from './UserPermissions';

export const UsersPage = () => {
  const { activeUser, activeTenant } = useGlobalApp();
  const [hashState, updateHashState] = useHashState<UserManagementHashState>();

  const isSsoTenant = shouldShowFeatureFlag(activeTenant, activeUser, TenantFeatureFlag.SsoEnabled);

  const { query } = hashState;

  const showUserPermissionsModal = async (tenantUser: TenantUserDTO) => {
    const shouldReload = await showAsyncModal(UserPermissionsModal, { tenantUser });
    if (shouldReload) {
      tableProps.reload();
    }
  };

  const tableProps = useCoreApiTableProps({
    schema: schemas.tenants.getSearchTenantUsers,
    queryParams: {
      query,
      isActive: true,
      includeInternal: false,
      includeAuth: true,
    },
  });

  const onResendInvite = async (tenantUser: TenantUserDTO) => {
    const userName = formatName(tenantUser.user.firstName, tenantUser.user.lastName);
    try {
      await coreApiFetch(schemas.users.sendWelcomeEmail, {
        bodyParams: { id: tenantUser.userId, invitedBy: activeUser.fullName },
      });
      tableProps.reload();
      notification.success({
        message: `Sent a new invitation email to ${userName}.`,
      });
    } catch (err) {
      captureAndShowError(err, `Error while sending invitation email to ${userName}, please try again.`);
    }
  };

  const onResetPassword = async (tenantUser: TenantUserDTO) => {
    const userName = formatName(tenantUser.user.firstName, tenantUser.user.lastName);
    try {
      await coreApiFetch(schemas.users.changePassword, {
        bodyParams: { id: tenantUser.userId },
      });
      notification.success({
        message: `Sent password reset email to ${userName}.`,
      });
    } catch (err) {
      captureAndShowError(err, `Error while sending password reset email to ${userName}, please try again.`);
    }
  };

  const onRemoveTenantUser = async (tenantUser: TenantUserDTO) => {
    const userName = formatName(tenantUser.user.firstName, tenantUser.user.lastName);
    try {
      await coreApiFetch(schemas.tenants.deleteUser, {
        pathParams: { userId: tenantUser.userId },
      });
      tableProps.reload();
      notification.success({
        message: `Removed access for ${userName}.`,
      });
    } catch (err) {
      captureAndShowError(err, `Error while removing access for ${userName}, please try again.`);
    }
  };

  const onConfirmRemoveTenantUser = (tenantUser: TenantUserDTO) => {
    if (tenantUser.userId === activeUser?.id) {
      notification.error({
        message: 'Cannot remove access from the current logged-in user. Please try again from a different account.',
      });
    } else {
      antdModal.confirm({
        title: `Confirm Access Removal`,
        icon: <ExclamationCircleOutlined />,
        content: `Please confirm that you would like to remove ${formatName(
          tenantUser.user.firstName,
          tenantUser.user.lastName,
        )}'s access to ${activeTenant.name}.`,
        okText: 'Remove Access',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          onRemoveTenantUser(tenantUser);
        },
      });
    }
  };

  const columns: ColumnType<TenantUserDTO>[] = [
    sortableStringColumn({ title: 'Email', dataIndex: ['user', 'email'], sorter: true, defaultSortOrder: 'ascend' }),
    sortableStringColumn({ title: 'First Name', dataIndex: ['user', 'firstName'], sorter: true }),
    sortableStringColumn({ title: 'Last Name', dataIndex: ['user', 'lastName'], sorter: true }),
    shouldShowProduct(activeTenant, TenantProductName.SalesSearchAndLookup)
      ? {
          title: 'Roles',
          dataIndex: 'roles',
          render: (roles: TenantUserErpRoleDTO[], record: TenantUserDTO) => (
            <div>
              {roles?.[0] ? ErpRoleMetadataMap[roles[0].name].name : ''}
              {roles?.length > 1 ? (
                <>
                  {' '}
                  <Tooltip
                    title={roles
                      .map(
                        (role) =>
                          `${ErpRoleMetadataMap[role.name].name}${
                            role.foreignId ? ` (${role.foreignId.replace(',', ', ')})` : ''
                          }`,
                      )
                      .join(', ')}
                  >
                    <Badge
                      style={{ backgroundColor: colors.primary[200], color: 'black' }}
                      count={`+${roles.length - 1}`}
                    />
                  </Tooltip>
                </>
              ) : (
                ''
              )}
              <Button
                icon={<EditOutlined />}
                type="link"
                size="small"
                onClick={() => showUserPermissionsModal(record)}
              />
            </div>
          ),
        }
      : null,
    sortableDateColumn({
      title: (
        <div>
          Last Login{' '}
          <Tooltip title="Users can remain logged in for up to a week (unless access is removed). A user who logged in within the past week may still be using Recurrency today.">
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: ['user', 'lastLogin'],
      // TODO: consider add sorting on last login
      sorter: false,
      render: (date: string, record: TenantUserDTO) =>
        date
          ? formatDate(date)
          : `Never${
              (record.user.emailEvents?.length ?? 0) > 0
                ? ` (last invited ${formatDate(
                    record.user.emailEvents?.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))?.[0]
                      .createdAt,
                  )})`
                : ''
            }`,
    }),
    sortableDateColumn({ title: 'Created At', dataIndex: ['user', 'createdAt'], sorter: true }),
    {
      title: 'Actions',
      width: 70,
      render: (_: unknown, record: TenantUserDTO) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="managePermissions"
                icon={<BankOutlined />}
                onClick={() => showUserPermissionsModal(record)}
              >
                Manage Permissions
              </Menu.Item>
              {record.user.lastLogin ? (
                <Menu.Item
                  key="resetPassword"
                  icon={<LockOutlined />}
                  onClick={() => onResetPassword(record)}
                  disabled={isSsoTenant}
                >
                  Reset Password
                </Menu.Item>
              ) : (
                <Menu.Item
                  key="resendInvite"
                  icon={<SendOutlined />}
                  onClick={() => {
                    onResendInvite(record);
                  }}
                >
                  Resend Invite
                </Menu.Item>
              )}
              <Menu.Item
                danger
                key="removeAccess"
                icon={<CloseOutlined />}
                onClick={() => onConfirmRemoveTenantUser(record)}
              >
                Remove Access
              </Menu.Item>
            </Menu>
          }
        >
          <Button
            size="small"
            className={css`
              width: 50px;
            `}
          >
            <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ].filter(truthy);

  return (
    <Container>
      <PageHeader
        title="Users"
        headerActions={
          <Button
            type="primary"
            onClick={async () => {
              const shouldReload = await showAsyncModal(InviteUserModal, {});
              if (shouldReload) {
                tableProps.reload();
              }
            }}
          >
            <PlusOutlined /> Invite User
          </Button>
        }
      />
      {isSsoTenant && <SsoAlert />}
      <FlexSpace justify="flex-end">
        <SearchInput
          placeholder="Search users"
          query={query}
          onQueryChange={(newQuery) => {
            updateHashState({ query: newQuery });
            tableProps.setPage(1);
          }}
        />
        <ResultsCounter isLoading={tableProps.isLoading} count={tableProps.totalCount} />
      </FlexSpace>
      <DividerLine />
      <AsyncTable
        isLoading={tableProps.isLoading || tableProps.isReloading}
        tableProps={tableProps}
        columns={columns}
      />
    </Container>
  );
};
