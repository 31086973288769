import React, { useEffect } from 'react';

import { DashboardOutlined, GlobalOutlined, ShopOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SupplierTariffDTO, TariffStatus } from '@recurrency/core-api-schema/dist/suppliers/getTariffs';
import notification from 'antd/lib/notification';
import { breakpoints } from 'theme/breakpoints';
import { ZipCelXSheet } from 'zipcelx';

import { AsyncSelect } from 'components/AsyncSelect';
import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { MultiSelectOption } from 'components/AsyncSelect/types';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { useSuppliersSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { BetaTag } from 'components/BetaTag';
import { ActionButton } from 'components/Button/ActionButton';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { ExternalLink } from 'components/Links';
import { PageHeader } from 'components/PageHeader';
import { ColumnChooserSection } from 'components/recipes/ColumnChooserSection';
import { DownloadButton, DownloadXSheetColumn, recordsToXSheet } from 'components/recipes/DownloadButton';
import { WarningIconTooltip } from 'components/recipes/sidePane/WarningIconTooltip';
import { ResultCount } from 'components/ResultCount';
import { Select } from 'components/Select';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { truthy } from 'utils/boolean';
import { captureAndShowError } from 'utils/error';
import { splitIdNameStr } from 'utils/formatting';
import { objPickKeys } from 'utils/object';
import { useHashState } from 'utils/routes';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { useUserViewSettingsState } from 'utils/tableAndSidePaneSettings/useUserViewSettingsState';
import { asKeyOf, sortableDollarColumn, sortableIdColumn, withSortedColumn } from 'utils/tables';

import { TariffManagementHashState } from 'types/hash-state';

import { TariffPieChart } from './TariffPieChart';
import { useCountriesSelectProps } from './utils';

const statusOptions: MultiSelectOption[] = [
  { label: 'All', value: '' },
  { label: 'Potential Tariff', value: TariffStatus.PotentialTariff },
  { label: 'Not Affected', value: TariffStatus.NotAffected },
];

const mitigationOptions = [
  { value: 'newSupplier', label: 'New Supplier' },
  { value: 'pauseBuying', label: 'Pause Buying' },
  { value: 'continueBuying', label: 'Continue Buying' },
  { value: 'changePricing', label: 'Change Pricing' },
  { value: 'other', label: 'Other' },
];

const mitigationFilterOptions = [
  { value: '', label: 'All' },
  { value: 'needsReview', label: 'Needs Review' },
  ...mitigationOptions,
];

export const TariffManagementTable = () => {
  const [hashState, updateHashState] = useHashState<TariffManagementHashState>();
  const { supplierIds = [], countries = [], tariffStatus, mitigationStatus } = hashState;
  const suppliersSelectProps = useSuppliersSelectProps();
  const countriesSelectProps = useCountriesSelectProps();

  const queryParams = {
    filter: {
      supplierIds,
      countries,
      tariffStatus,
      remediationStatus: mitigationStatus,
    },
  };
  const tableProps = useCoreApiTableProps({
    schema: schemas.suppliers.getTariffs,
    queryParams,
  });

  const { data, reload: chartReload } = useCoreApi(schemas.suppliers.getTariffs, { queryParams });

  const { setPage, reload } = tableProps;

  /** the purpose of this string is to memoize these values so that the useEffect below doesn't trigger when the values are the same.
   * The arrays in hashState are recreated per render and considered new objects, causing re-renders if added directly to the useEffect dependency array */
  const stringifyHashState = JSON.stringify(
    objPickKeys(hashState, 'supplierIds', 'countries', 'supplierIds', 'tariffStatus', 'mitigationStatus'),
  );

  useEffect(() => {
    setPage(1);
  }, [stringifyHashState, setPage]);

  async function handleSupplierUpdate(
    supplierId: string,
    countryName?: string,
    countryCode?: string,
    mitigationStatus?: string,
  ) {
    notification.info({
      message: `Updating Row`,
      key: 'countryUpdate',
    });
    try {
      await coreApiFetch(schemas.suppliers.patchTariffManagement, {
        pathParams: { supplierId },
        bodyParams: {
          overrideCountryCode: countryCode,
          overrideCountryName: countryName,
          status: mitigationStatus,
        },
      });
      notification.success({
        message: `Updated Row`,
        key: 'countryUpdate',
      });
    } catch (err) {
      captureAndShowError(err, `Error while updating row.`);
    } finally {
      reload();
      chartReload();
    }
  }

  const tableColumns: PersistedColumn<SupplierTariffDTO>[] = [
    sortableIdColumn({
      title: 'Supplier',
      dataIndex: asKeyOf<SupplierTariffDTO>('supplierId'),
      sorter: true,
      settingKey: 'supplierId',
      required: true,
      render: (_, record: SupplierTariffDTO) => `${record.supplierId}: ${record.supplierName}`,
    }),
    sortableDollarColumn({
      title: 'Last 12M Purchases ($)',
      dataIndex: asKeyOf<SupplierTariffDTO>('poDollars12M'),
      sorter: true,
      settingKey: 'poDollars12M',
    }),
    sortableDollarColumn({
      title: 'Open PO Amount',
      dataIndex: asKeyOf<SupplierTariffDTO>('onPoDollars'),
      sorter: true,
      settingKey: 'onPoDollars',
    }),
    {
      title: `ERP Country`,
      dataIndex: asKeyOf<SupplierTariffDTO>('erpCountryCode'),
      settingKey: 'erpCountryCode',
    },
    {
      title: (
        <InfoTooltip title="Automatically populated by Recurrency based on the supplier's address information. You can edit this field if needed.">
          Smart Country
        </InfoTooltip>
      ),
      dataIndex: asKeyOf<SupplierTariffDTO>('overriddenCountryCode'),
      sorter: true,
      settingKey: 'overriddenCountryCode',
      render: (overriddenCountryCode, record) => (
        <AsyncSelect
          selectProps={countriesSelectProps}
          entityPlural="countries"
          defaultValue={overriddenCountryCode ? `${overriddenCountryCode}: ${record.overriddenCountryName}` : null}
          onChange={(value) =>
            handleSupplierUpdate(record.supplierId, splitIdNameStr(value).name, splitIdNameStr(value).foreignId)
          }
          className={css`
            width: 150px;
          `}
        />
      ),
    },
    {
      title: (
        <div
          className={css`
            text-wrap: nowrap;
          `}
        >
          <InfoTooltip title="Countries with potential tariffs. Currently Canada, Mexico, and China.">
            Tariff Status
          </InfoTooltip>
        </div>
      ),
      dataIndex: asKeyOf<SupplierTariffDTO>('tariffStatus'),
      sorter: true,
      settingKey: 'tariffStatus',
      render: (tariffStatus) =>
        tariffStatus === TariffStatus.PotentialTariff ? (
          <div
            className={css`
              font-size: 20px;
            `}
          >
            <WarningIconTooltip
              wrapText
              tooltipText="There is potential tariff for this supplier. Consider a way to remediate it."
            />
          </div>
        ) : undefined,
    },
    {
      title: (
        <InfoTooltip title="Select a mitigation status to specify how you plan to address tariffs for this supplier.">
          Mitigation Status
        </InfoTooltip>
      ),
      dataIndex: asKeyOf<SupplierTariffDTO>('remediationStatus'),
      settingKey: 'remediationStatus',
      render: (mitigationStatus, record) =>
        record.tariffStatus === TariffStatus.PotentialTariff ? (
          <Select
            options={mitigationOptions}
            defaultValue={mitigationStatus ?? 'Needs Review'}
            onChange={(value) => handleSupplierUpdate(record.supplierId, undefined, undefined, value)}
            className={css`
              width: 150px;
            `}
          />
        ) : null,
    },
    // Optional Columns:
    sortableDollarColumn({
      title: 'Last 1M Purchases ($)',
      dataIndex: asKeyOf<SupplierTariffDTO>('poDollars1M'),
      sorter: true,
      settingKey: 'poDollars1M',
      optional: true,
    }),
    sortableDollarColumn({
      title: 'Last 3M Purchases ($)',
      dataIndex: asKeyOf<SupplierTariffDTO>('poDollars3M'),
      sorter: true,
      settingKey: 'poDollars3M',
      optional: true,
    }),
    sortableDollarColumn({
      title: 'Last 6M Purchases ($)',
      dataIndex: asKeyOf<SupplierTariffDTO>('poDollars6M'),
      sorter: true,
      settingKey: 'poDollars6M',
      optional: true,
    }),
  ];

  async function getDownloadData(): Promise<ZipCelXSheet> {
    const response = await coreApiFetch(schemas.suppliers.getTariffs, {
      queryParams: {
        ...queryParams,
        // override pagination for download
        offset: 0,
        limit: 100000,
      },
    });

    const exportColumns: Array<DownloadXSheetColumn<SupplierTariffDTO>> = [
      {
        title: 'Supplier Id',
        type: 'string',
        value: (record) => record.supplierId ?? 'None',
      },
      {
        title: 'Supplier Name',
        type: 'string',
        value: (record) => record.supplierName ?? 'None',
      },
      {
        title: 'Open PO Amount ($)',
        type: 'number',
        value: (record) => record.onPoDollars.toFixed(2) ?? 0,
      },
      {
        title: 'Last 1M Purchases ($)',
        type: 'number',
        value: (record) => record.poDollars1M.toFixed(2) ?? 0,
      },
      {
        title: 'Last 3M Purchases ($)',
        type: 'number',
        value: (record) => record.poDollars3M.toFixed(2) ?? 0,
      },
      {
        title: 'Last 6M Purchases ($)',
        type: 'number',
        value: (record) => record.poDollars6M.toFixed(2) ?? 0,
      },
      {
        title: 'Last 12M Purchases ($)',
        type: 'number',
        value: (record) => record.poDollars12M.toFixed(2) ?? 0,
      },
      {
        title: 'ERP Country',
        type: 'string',
        value: (record) => record.erpCountryCode ?? 'None',
      },
      {
        title: 'Recurrency Country',
        type: 'string',
        value: (record) => record.overriddenCountryCode ?? 'None',
      },
      {
        title: 'Tariff Status',
        type: 'string',
        value: (record) => record.tariffStatus ?? 'None',
      },
      {
        title: 'Mitigation Status',
        type: 'string',
        value: (record) => record.remediationStatus ?? 'None',
      },
    ];

    return recordsToXSheet(response.data.items, exportColumns);
  }

  const [visibleColumnKeys, setVisibleColumnKeys] = useUserViewSettingsState(
    ViewSettingKey.TariffManagementPage,
    tableColumns.filter((column) => !column.optional).map((column) => column.settingKey),
  );

  return (
    <>
      <PageHeader
        title={
          <FlexSpace direction="row">
            Tariff Management <BetaTag />
          </FlexSpace>
        }
        subtitle={
          <span>
            Use tariff management to identify and mitigate suppliers in potentially tariff impacted countries.{' '}
            <ExternalLink to="https://help.recurrency.com/hc/en-us/articles/35150615123099-Tariff-Management-Dashboard">
              Learn more
            </ExternalLink>
          </span>
        }
        headerActions={<DownloadButton getDownloadData={getDownloadData} recordType="TariffManagement" />}
      />
      <FilterBarBox>
        <FlexSpace direction="column" gap={16} fullWidth>
          <FlexSpace wrap fullWidth>
            <span>Filter</span>
            <AsyncMultiSelect
              label="Tariff Status"
              mode="single"
              icon={<DashboardOutlined />}
              selectProps={{ options: statusOptions }}
              selectedValues={[hashState.tariffStatus || statusOptions[0].value]}
              onSelectedValuesChange={(values) =>
                updateHashState({ tariffStatus: (values[0] || undefined) as TariffStatus })
              }
            />
            <AsyncMultiSelect
              selectProps={convertToMultiSelectProps(suppliersSelectProps)}
              label="Supplier"
              queryPlaceholder="Search suppliers"
              selectedValues={supplierIds}
              onSelectedValuesChange={(values) => updateHashState({ supplierIds: values })}
              icon={<ShopOutlined />}
            />
            <AsyncMultiSelect
              selectProps={convertToMultiSelectProps(countriesSelectProps)}
              label="Country"
              queryPlaceholder="Search countries"
              selectedValues={countries}
              onSelectedValuesChange={(values) => updateHashState({ countries: values })}
              icon={<GlobalOutlined />}
            />
            <AsyncMultiSelect
              label="Mitigation Status"
              mode="single"
              icon={<DashboardOutlined />}
              selectProps={{ options: mitigationFilterOptions }}
              selectedValues={[hashState.mitigationStatus || statusOptions[0].value]}
              onSelectedValuesChange={(values) =>
                updateHashState({ mitigationStatus: (values[0] || undefined) as string })
              }
            />

            {(hashState.supplierIds?.length ?? 0) !== 0 ||
            (hashState.countries?.length ?? 0) !== 0 ||
            hashState.mitigationStatus ||
            hashState.tariffStatus ? (
              <ActionButton
                onClick={() =>
                  updateHashState({
                    supplierIds: undefined,
                    countries: undefined,
                    mitigationStatus: undefined,
                    tariffStatus: undefined,
                  })
                }
                label="Clear All"
              />
            ) : null}
            <FlexSpacer />
          </FlexSpace>
        </FlexSpace>
      </FilterBarBox>

      <div
        className={css`
          display: grid;
          gap: 8px;
          margin-bottom: 20px;
          grid-template-columns: repeat(4, 1fr); /* Default: 4 columns */

          ${breakpoints.smallDesktop} {
            grid-template-columns: repeat(2, 1fr); /* Tablet: 2 columns */
          }

          ${breakpoints.mobile} {
            grid-template-columns: 1fr; /* Mobile: 1 column */
          }
        `}
      >
        <TariffPieChart
          data={data?.tariffStatusAggregations}
          highlightStatus="potentialTariff"
          title="Potentially Tariffed Suppliers"
          description="By Last 12 Months POs"
          historicalMonths={12}
        />
        <TariffPieChart
          data={data?.tariffStatusAggregations}
          highlightStatus="potentialTariff"
          title="Potentially Tariffed Suppliers"
          description="By Open POs"
        />
        <TariffPieChart
          data={data?.remediationStatusAggregations}
          highlightStatus="needsReview"
          title="Suppliers Needing Mitigation"
          description="By Last 12 Months POs"
          historicalMonths={12}
        />
        <TariffPieChart
          data={data?.remediationStatusAggregations}
          highlightStatus="needsReview"
          title="Suppliers Needing Mitigation"
          description="By Open POs"
        />
      </div>
      <FlexSpace
        direction="row"
        fullWidth
        className={css`
          align-items: center;
        `}
      >
        <FlexSpacer />
        <ColumnChooserSection
          tableKey={ViewSettingKey.TariffManagementPage}
          columns={tableColumns}
          visibleColumnKeys={visibleColumnKeys}
          setVisibleColumnKeys={setVisibleColumnKeys}
        />
        <ResultCount count={tableProps.totalCount} />
      </FlexSpace>
      <AsyncTable
        stickyHeader
        tableProps={tableProps}
        columns={withSortedColumn(
          visibleColumnKeys.map((key) => tableColumns.find((column) => column.settingKey === key)).filter(truthy),
          tableProps.sortBy,
          tableProps.sortDir,
        )}
        rowKey="supplierId"
      />
    </>
  );
};
