import React from 'react';

import {
  CalendarOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  DollarCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { TransferOrderStatus } from '@recurrency/core-api-schema/dist/common/enums';

import { Container } from 'components/Container';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { NoDataMessage } from 'components/NoDataMessage';
import { PageHeader } from 'components/PageHeader';
import { DetailPageSection, DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import { StatusBadge } from 'components/recipes/StatusBadge';

import { useCoreApi } from 'hooks/useApi';

import { truthy } from 'utils/boolean';
import { dashesIfEmpty, formatDate, formatUSD } from 'utils/formatting';
import { IdPathParams, usePathParams } from 'utils/routes';

import { TransferStatusBadge } from '../TransferOrderListPage';
import { TransferOrderLinesTable } from './tabs/TransferOrderLinesTable';

export const TransferOrderDetailsPage = () => {
  const { id: transferOrderNumber } = usePathParams<IdPathParams>();
  const {
    data: transferOrder,
    error,
    isLoading,
  } = useCoreApi(schemas.transferOrders.getTransferOrderDetails, {
    pathParams: { transferOrderId: transferOrderNumber },
  });

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !transferOrder) {
    return <CenteredLoader />;
  }

  if (!transferOrder.details) {
    return <NoDataMessage />;
  }

  const headerSections: DetailPageSection[] = [
    {
      title: 'Transfer Order Configuration',
      rows: [
        [
          {
            icon: <CalendarOutlined />,
            label: 'Transfer Date',
            value: formatDate(transferOrder.details.transferDate),
          },
          transferOrder.details.plannedReceiptDate
            ? {
                icon: <CalendarOutlined />,
                label: 'Planned Receipt Date',
                value: formatDate(transferOrder.details.plannedReceiptDate),
              }
            : null,
          {
            icon: transferOrder.details.isApproved ? <CheckCircleOutlined /> : <CloseOutlined />,
            label: 'Approved',
            value: transferOrder.details.isApproved ? 'Yes' : 'No',
          },
          {
            icon: <DollarCircleOutlined />,
            label: 'Total Cost',
            value: formatUSD(
              transferOrder.items.reduce((acc, item) => acc + item.unitCost * item.qtyToTransfer, 0),
              true,
            ),
          },
        ].filter(truthy),
      ],
    },
    {
      title: 'Logistics',
      rows: [
        [
          {
            icon: <UserOutlined />,
            label: 'Source Location',
            value: transferOrder.details.sourceLocationName,
            id: transferOrder.details.sourceLocationId,
          },
          {
            icon: <UserOutlined />,
            label: 'Destination Location',
            value: transferOrder.details.destinationLocationName,
            id: transferOrder.details.destinationLocationId,
          },
          {
            label: 'Carrier',
            value: dashesIfEmpty(transferOrder.details.carrierName),
            id: transferOrder.details.carrierId,
          },
        ],
      ],
    },
  ];

  return (
    <Container>
      <PageHeader
        title={transferOrderNumber}
        copyable
        entity={{
          kind: 'Transfer Order',
          badge: <StatusBadge status={TransferStatusBadge[transferOrder.details.status || TransferOrderStatus.Open]} />,
        }}
      />
      <DetailPageSections sections={headerSections} />
      <NavTabs
        tabs={[
          {
            header: 'Transfer Order Lines',
            content: <TransferOrderLinesTable lines={transferOrder.items} />,
          },
        ]}
      />
    </Container>
  );
};
