import React from 'react';

import { css } from '@emotion/css';
import { breakpoints } from 'theme/breakpoints';

import { LocalStorageKey, useLocalStorage } from 'utils/localStorage';

export interface TableSplitPageProps {
  left: React.ReactNode;
  right: React.ReactNode;
}

export const TableSplitPage = ({ left, right }: TableSplitPageProps): JSX.Element => {
  const parentRef = React.createRef<HTMLDivElement>();
  const [isSidePaneExpandedLocalStorage] = useLocalStorage(LocalStorageKey.SidePane_Expanded, true);
  const [isSidePaneExpanded, setIsSidePaneExpanded] = React.useState(isSidePaneExpandedLocalStorage);

  // Listen for changes to the side pane and update isSidePaneExpanded
  React.useLayoutEffect(() => {
    const observer = new MutationObserver(() => {
      setIsSidePaneExpanded(!!parentRef.current?.querySelector('.SidePane-expanded'));
    });

    observer.observe(parentRef.current as Node, {
      subtree: true,
      childList: true,
    });
    return () => observer.disconnect();
  }, [parentRef]);

  const sidePaneWidth = isSidePaneExpanded ? 400 : 60;

  return (
    <div
      ref={parentRef}
      className={css`
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
      `}
    >
      <div
        className={css`
          width: calc(100% - ${sidePaneWidth + 20}px);
          ${breakpoints.tablet} {
            width: 100%;
          }
        `}
      >
        {left}
      </div>
      <div
        className={css`
          width: ${sidePaneWidth}px;

          ${breakpoints.tablet} {
            ${isSidePaneExpanded ? 'width: 100%;' : ''}
            .SidePane-expanded {
              width: 100%;
            }
          }
        `}
      >
        {right}
      </div>
    </div>
  );
};
