import React, { useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { DemandForecastOverrideUpdate } from '@recurrency/core-api-schema/dist/ml/updateDemandForecastOverrides';
import { SearchForecastDTO } from '@recurrency/core-api-schema/dist/search/getSearchForecasts';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { theme } from 'theme';

import { AsyncButton } from 'components/Button/AsyncButton';
import { FlexSpace } from 'components/FlexSpace';
import { Input } from 'components/Input';
import { ParsedInput } from 'components/Input/ParsedInput';
import { Table } from 'components/Table';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { coreApiFetch } from 'utils/api';
import { splitIdNameStr } from 'utils/formatting';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { floatOrDefault } from 'utils/units';

export enum RecordType {
  Usage = 'usage',
  Forecast = 'forecast',
}

export interface MappedUsageForecastRecord {
  date: string;
  quantity: number;
  type: RecordType;
  note?: string;
  updatedBy?: string;
  updatedAt?: string;
}

export interface UsageForecastRow {
  year: string;
  monthlyRecords: MappedUsageForecastRecord[];
}

export const ForecastOverrideTable = ({
  isLoading = false,
  record,
  forecastOverrideInputs,
  usageAndForecastRecords,
  onForecastChange,
  onForecastOverridesUpdated,
}: {
  isLoading?: boolean;
  record?: SearchForecastDTO;
  forecastOverrideInputs: MappedUsageForecastRecord[];
  usageAndForecastRecords: MappedUsageForecastRecord[];
  onForecastChange: (record: MappedUsageForecastRecord) => void;
  onForecastOverridesUpdated: () => void;
}) => {
  const [forecastOverrideNote, setForecastOverrideNote] = useState<string>('');

  function getMonthlyForcastColumns(): ColumnType<UsageForecastRow>[] {
    const months = moment.monthsShort();
    return months.map((month) => ({
      title: month,
      align: 'right',
      render: (_, row) => {
        const usageForecast = row.monthlyRecords.find((r) => moment(r.date).format('MMM') === month);

        if (!usageForecast) return '-';
        if (usageForecast.type === RecordType.Usage) return usageForecast.quantity;

        const override = forecastOverrideInputs.find((fo) => fo.date === usageForecast.date);
        const note = (
          <>
            Overridden to {usageForecast?.quantity} {record?.unit_of_measure} by {usageForecast?.updatedBy} on{' '}
            {moment(usageForecast?.updatedAt).format('MMM D YYYY')}.<br />
            {usageForecast?.note ? `Note: ${usageForecast?.note}.` : ''}
          </>
        );

        return (
          <FlexSpace>
            <ParsedInput<number>
              size="small"
              valueParser={(value) => floatOrDefault(value, 0)}
              value={override?.quantity ?? usageForecast.quantity}
              onValueChange={(qty) => onForecastChange({ ...usageForecast, quantity: qty })}
              className={css`
                min-width: 70px;
                text-align: right;
                // Set padding when InfoTooltip is rendered
                padding-right: ${usageForecast.updatedBy ? '25px' : 'default'};
                background-color: ${!!override && override.quantity !== usageForecast.quantity
                  ? theme.colors.primary[100]
                  : 'default'};
              `}
            />
            {!!usageForecast.updatedBy && (
              <span
                className={css`
                  position: absolute;
                  right: 15px;
                  top: 14px;
                `}
              >
                <InfoTooltip title={note} useInfoIcon />
              </span>
            )}
          </FlexSpace>
        );
      },
    }));
  }

  async function handleUpdateForecast(overrides: MappedUsageForecastRecord[]) {
    if (!record || !usageAndForecastRecords) return;

    const submitNotification = createSubmissionNotification({
      entityName: 'Forecast Override',
      expectedWaitSeconds: 30,
    });

    const updates: DemandForecastOverrideUpdate[] = overrides
      .filter((fo) => fo.quantity !== usageAndForecastRecords.find((r) => r.date === fo.date)?.quantity)
      .map((fo) => ({
        itemId: record.item_uid,
        locationId: splitIdNameStr(record.location).foreignId,
        date: fo.date,
        quantity: fo.quantity,
        note: forecastOverrideNote,
      }));

    try {
      await coreApiFetch(schemas.ml.updateDemandForecastOverrides, {
        bodyParams: {
          updates,
        },
      });
      submitNotification.success({ successMessage: 'Forecast overrides applied', duration: 10 });
      onForecastOverridesUpdated();
    } catch (err) {
      submitNotification.error(err);
    }
  }

  const tableColumns: ColumnType<UsageForecastRow>[] = [
    {
      title: 'Year',
      dataIndex: 'year',
      defaultSortOrder: 'descend',
    },
    ...getMonthlyForcastColumns(),
    {
      title: 'UOM',
      render: () => record?.unit_of_measure,
    },
  ];

  const tableData = getForecastRecordsByYear(usageAndForecastRecords);

  const overridenForecastRecords = usageAndForecastRecords.filter((r) => !!r.updatedAt && r.type === 'forecast');
  const isForecastOverridesChanged = forecastOverrideInputs.some(
    (fo) => fo.quantity !== usageAndForecastRecords.find((r) => r.date === fo.date)?.quantity,
  );

  return (
    <>
      <Table isLoading={isLoading} pageSize={10} data={tableData} columns={tableColumns} />
      <FlexSpace
        justify="space-between"
        className={css`
          margin-top: 32px;
        `}
      >
        <Input
          value={forecastOverrideNote}
          disabled={!isForecastOverridesChanged}
          onChange={(ev) => setForecastOverrideNote(ev.target.value)}
          placeholder="Optional override note"
          size="small"
          className={css`
            max-width: 250px;
          `}
        />
        <FlexSpace justify="flex-end">
          <AsyncButton
            disabled={overridenForecastRecords.length === 0}
            size="small"
            onClick={() =>
              handleUpdateForecast(
                overridenForecastRecords.map((fo) => ({
                  ...fo,
                  quantity: null as Any, // null implies override should be cleared
                })),
              )
            }
          >
            Clear Overrides
          </AsyncButton>
          <AsyncButton
            disabled={!isForecastOverridesChanged}
            type="primary"
            size="small"
            onClick={() => handleUpdateForecast(forecastOverrideInputs)}
          >
            Override Forecast
          </AsyncButton>
        </FlexSpace>
      </FlexSpace>
    </>
  );
};

export function getMappedUsageAndForecastRecords(
  records: SearchForecastDTO | undefined,
  overrideValues: MappedUsageForecastRecord[] = [],
): MappedUsageForecastRecord[] {
  if (!records) return [];

  /*
    Historical Records
    Filter historical records up to and including January of year before last
  */
  const janOfYearBeforeLast = moment().subtract(2, 'year').startOf('year').format('YYYY-MM-DD');
  const janOfYearBeforeLastIdx = Math.max(
    records.historical_dates.findIndex((date) => date === janOfYearBeforeLast),
    0,
  );

  const historicalDates = records.historical_dates.slice(janOfYearBeforeLastIdx);
  const historicalDemand = records.historical_demand.slice(janOfYearBeforeLastIdx);

  const usageValues: MappedUsageForecastRecord[] = historicalDates.map((value, index) => ({
    date: value,
    type: RecordType.Usage,
    quantity: historicalDemand[index],
  }));

  /*
    Forecast Records
    Filter forecast records up to the next 18 months
  */
  const next18MonthsDates = records.forecast_dates.slice(0, 18);
  const next18MonthsDemand = records.forecast_demand.slice(0, 18);
  const next18MonthsDemandBase = records.forecast_demand_base.slice(0, 18);

  const forecastValues: MappedUsageForecastRecord[] = next18MonthsDates.map((date, index) => {
    const override = overrideValues.find((ov) => ov.date === date);
    return {
      date,
      type: RecordType.Forecast,
      quantity: next18MonthsDemand[index],
      baseQuantity: next18MonthsDemandBase[index],
      ...override,
    };
  });

  return [...usageValues, ...forecastValues];
}

function getForecastRecordsByYear(mappedRecords: MappedUsageForecastRecord[]): UsageForecastRow[] {
  const yearlyObj = mappedRecords.reduce((acc, curr) => {
    const year = moment(curr.date).format('YYYY');
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(curr);
    return acc;
  }, {} as Record<string, MappedUsageForecastRecord[]>);

  return Object.entries(yearlyObj).map(([year, monthlyRecords]) => ({
    year,
    monthlyRecords,
  }));
}
