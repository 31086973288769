import React from 'react';

import { css } from '@emotion/css';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';

import { FlexSpace } from 'components/FlexSpace';
import { BorderedPropertyItem } from 'components/recipes/equation/BorderedPropertyItem';
import { Typography } from 'components/Typography';

import { formatUSD } from 'utils/formatting';

export const ProjectedInventoryChangeEquation = ({ record }: { record: SearchPlanningDTO }) => (
  <div>
    <FlexSpace direction="row">
      <BorderedPropertyItem
        label="Unit Cost"
        value={formatUSD(record.unit_cost, true)}
        className={css`
          margin-top: auto;
          margin-bottom: auto;
        `}
      />
      <Typography type="large">×</Typography>
      <Typography type="h2">(</Typography>
      <Typography type="h2">(</Typography>
      <FlexSpace
        direction="column"
        className={css`
          align-items: center;
          justify-items: center;
        `}
      >
        <FlexSpace
          direction="row"
          className={css`
            border-bottom: 2px solid black;
            padding-bottom: 8px;
          `}
        >
          <BorderedPropertyItem label="Recommended Min" value={`${record.recommended_min} ${record.unit_of_measure}`} />
          <div
            className={css`
              align-self: stretch;
              padding-top: 16px;
            `}
          >
            <Typography type="large">+</Typography>
          </div>
          <BorderedPropertyItem label="Recommended Max" value={`${record.recommended_max} ${record.unit_of_measure}`} />
        </FlexSpace>
        <Typography type="large">2</Typography>
      </FlexSpace>
      <Typography type="h2">)</Typography>
      <Typography type="large">-</Typography>
      <Typography type="h2">(</Typography>
      <FlexSpace
        direction="column"
        className={css`
          align-items: center;
          justify-items: center;
        `}
      >
        <FlexSpace
          direction="row"
          className={css`
            border-bottom: 2px solid black;
            padding-bottom: 8px;
          `}
        >
          <BorderedPropertyItem label="Current Min" value={`${record.current_min} ${record.unit_of_measure}`} />
          <div
            className={css`
              align-self: stretch;
              padding-top: 16px;
            `}
          >
            <Typography type="large">+</Typography>
          </div>
          <BorderedPropertyItem label="Current Max" value={`${record.current_max} ${record.unit_of_measure}`} />
        </FlexSpace>
        <Typography type="large">2</Typography>
      </FlexSpace>
      <Typography type="h2">)</Typography>
      <Typography type="h2">)</Typography>
      <Typography type="large">=</Typography>
      <BorderedPropertyItem
        label="Projected Inventory Change"
        value={`${formatUSD(record.projected_inventory_change, true)}`}
        className={css`
          margin-top: auto;
          margin-bottom: auto;
        `}
      />
    </FlexSpace>
  </div>
);
