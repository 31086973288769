import React, { useEffect, useMemo } from 'react';

import { css } from '@emotion/css';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { Checkbox } from 'antd';
import { ColumnType } from 'antd/lib/table';
import moment from 'moment';

import { AsyncSelect, AsyncSelectVariant } from 'components/AsyncSelect';
import { useCarriersSelectProps, usePurchasingBuyersSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { IsoDatePicker } from 'components/DatePicker/IsoDatePicker';
import { FilterBarBox } from 'components/FilterBarBox';
import { Table } from 'components/Table';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { formatNumber, formatUSD, joinIfIdNameObj, splitIdNameStr, splitIfIdNameStr } from 'utils/formatting';
import { sortDirections, typedColumn } from 'utils/tables';
import { getTenantSetting } from 'utils/tenantSettings';

import { PurchasingPOFinalizeInput } from 'types/hash-state';
import { IdNameObj } from 'types/legacy-api';

import { GroupedPOSummary } from '../../types';
import { getPOSummaryKey } from '../../utils';
import { CarrierCell } from './PurchasingFinalizeStepCarrierCell';

export function PurchasingFinalizePurchaseOrdersSection({
  finalizedPOSummaries,
  poFinalizeInputByKey,
  onPOFinalizeInputChange,
}: {
  finalizedPOSummaries: GroupedPOSummary[];
  poFinalizeInputByKey: Obj<PurchasingPOFinalizeInput>;
  onPOFinalizeInputChange: (newPOFinalizeInputByKey: Obj<PurchasingPOFinalizeInput>) => void;
}) {
  const { activeUser } = useGlobalApp();
  const buyerSelectProps = usePurchasingBuyersSelectProps();
  const carrierSelectProps = useCarriersSelectProps();

  const numTotalPOs = finalizedPOSummaries.length;
  const numApprovedPOs = useMemo(
    () => finalizedPOSummaries.filter((summary) => poFinalizeInputByKey[getPOSummaryKey(summary)]?.approved).length,
    [poFinalizeInputByKey, finalizedPOSummaries],
  );

  // buyer is set to the same value for all supplier locations
  const buyer = finalizedPOSummaries[0]?.buyer;

  const setBuyer = (newBuyer: IdNameObj) => {
    const newPOFinalizeInputByKey = { ...poFinalizeInputByKey };
    for (const poSummary of finalizedPOSummaries) {
      newPOFinalizeInputByKey[getPOSummaryKey(poSummary)].buyer = newBuyer;
    }
    onPOFinalizeInputChange(newPOFinalizeInputByKey);
  };

  const updatePOHeader = (record: GroupedPOSummary, update: Partial<PurchasingPOFinalizeInput>) => {
    const newPOFinalizeInputByKey = { ...poFinalizeInputByKey };
    const poKey = getPOSummaryKey(record);

    // Check if the update is specifically for the carrier
    if ('carrier' in update) {
      if (update.carrier === undefined) {
        newPOFinalizeInputByKey[poKey] = {
          ...newPOFinalizeInputByKey[poKey],
          carrier: undefined,
          carrierClearedByUser: true,
        };
      } else {
        newPOFinalizeInputByKey[poKey] = { ...newPOFinalizeInputByKey[poKey], carrier: update.carrier };
      }
    } else {
      // For all other updates, apply the update directly without touching the carrier fields
      newPOFinalizeInputByKey[poKey] = { ...newPOFinalizeInputByKey[poKey], ...update };
    }

    onPOFinalizeInputChange(newPOFinalizeInputByKey);
  };

  const handleBulkApproveChange = (isChecked: boolean) => {
    const newPOFinalizeInputByKey = { ...poFinalizeInputByKey };

    // Update the 'approved' state for each PO
    finalizedPOSummaries.forEach((poSummary) => {
      const poKey = getPOSummaryKey(poSummary);
      const poFinalizeInput = newPOFinalizeInputByKey[poKey] || {};
      poFinalizeInput.approved = isChecked;
      newPOFinalizeInputByKey[poKey] = poFinalizeInput;
    });

    onPOFinalizeInputChange(newPOFinalizeInputByKey);
  };

  useEffect(() => {
    let hasUpdate = false;
    const newPOFinalizeInputByKey = { ...poFinalizeInputByKey };
    const defaultDueDate = moment().add(7, 'd').toISOString();
    let defaultBuyer: IdNameObj | undefined;
    const defaultApproved = getTenantSetting(TenantSettingKey.FormPurchaseOrderDefaultApproved);

    // set default buyer to current user if user is in buyer list
    if (
      buyer === undefined &&
      !buyerSelectProps.isLoading &&
      !buyerSelectProps.searchQuery &&
      buyerSelectProps.options.length > 1
    ) {
      const defaultBuyerOption = buyerSelectProps.options.find((buyerOpt) => {
        const buyerVal = buyerOpt.value.toLowerCase();
        return (
          buyerVal.includes((activeUser.fullName || '').toLowerCase()) ||
          buyerVal.includes((activeUser.email || '').toLowerCase())
        );
      });
      if (defaultBuyerOption) {
        defaultBuyer = splitIfIdNameStr(defaultBuyerOption.value);
      }
    }

    for (const poSummary of finalizedPOSummaries) {
      const poKey = getPOSummaryKey(poSummary);
      const poFinalizeInput = poFinalizeInputByKey[poKey] || {};
      const update = {} as Partial<PurchasingPOFinalizeInput>;

      if (poFinalizeInput.buyer === undefined && defaultBuyer) {
        update.buyer = defaultBuyer;
      }
      if (poFinalizeInput.dueDate === undefined) {
        update.dueDate = defaultDueDate;
      }
      if (poFinalizeInput.approved === undefined) {
        update.approved = defaultApproved;
      }
      const firstDefaultCarrierId = poSummary.lines[0].supplierDefaultCarrierId;
      if (poFinalizeInput.carrier === undefined && firstDefaultCarrierId && !poFinalizeInput.carrierClearedByUser) {
        const defaultCarrierOption = carrierSelectProps.options.find((carrierOpt) =>
          carrierOpt.value.includes(firstDefaultCarrierId),
        );
        if (defaultCarrierOption) {
          update.carrier = splitIfIdNameStr(defaultCarrierOption.value);
        }
      }

      if (Object.keys(update).length > 0) {
        hasUpdate = true;
        newPOFinalizeInputByKey[poKey] = { ...poFinalizeInput, ...update };
      }
    }

    // if defaults have changed, then update supplierLocations
    if (hasUpdate) {
      onPOFinalizeInputChange(newPOFinalizeInputByKey);
    }
  }, [
    buyerSelectProps,
    activeUser,
    onPOFinalizeInputChange,
    poFinalizeInputByKey,
    buyer,
    finalizedPOSummaries,
    carrierSelectProps,
  ]);

  const tableColumns: ColumnType<GroupedPOSummary>[] = [
    typedColumn({
      title: 'Supplier',
      dataIndex: 'supplier',
      sortDirections,
      render: (supplier) => (
        <div>
          <div>{supplier.foreignId}</div>
          <div>{supplier.name}</div>
        </div>
      ),
    }),
    typedColumn({
      title: 'Purchasing Location',
      dataIndex: 'purchasingLocation',
      sortDirections,
      render: (purchasingLocation) => (
        <div>
          <div>{purchasingLocation.foreignId}</div>
          <div>{purchasingLocation.name}</div>
        </div>
      ),
    }),
    typedColumn({
      title: 'Carrier',
      dataIndex: 'carrier',
      sortDirections,
      render: (carrier, record) => (
        <CarrierCell value={carrier} onChange={(newCarrier) => updatePOHeader(record, { carrier: newCarrier })} />
      ),
    }),
    typedColumn({
      title: 'Due Date',
      dataIndex: 'dueDate',
      sortDirections,
      render: (dueDate, record) => (
        <IsoDatePicker value={dueDate} onChange={(newDueDate) => updatePOHeader(record, { dueDate: newDueDate })} />
      ),
    }),
    typedColumn({
      title: () => (
        <div>
          Approved
          <Checkbox
            style={{ marginLeft: 8 }}
            checked={numApprovedPOs > 0 && numApprovedPOs === numTotalPOs}
            indeterminate={numApprovedPOs > 0 && numApprovedPOs !== numTotalPOs}
            onChange={(e) => handleBulkApproveChange(e.target.checked)}
          />
        </div>
      ),
      dataIndex: 'approved',
      width: 120,
      sortDirections,
      render: (approved, record) => (
        <Checkbox checked={approved} onChange={() => updatePOHeader(record, { approved: !approved })} />
      ),
    }),
    typedColumn({
      title: 'Total Lines',
      dataIndex: 'totalLines',
      sortDirections,
      align: 'right',
      render: (totalLines) => formatNumber(totalLines),
    }),
    typedColumn({
      title: 'Total Cost',
      dataIndex: 'totalPOCost',
      sortDirections,
      align: 'right',
      render: (totalPOCost) => formatUSD(totalPOCost, true),
    }),
  ];

  return (
    <div>
      <FilterBarBox>
        <AsyncSelect
          className={css`
            width: 200px;
          `}
          selectProps={buyerSelectProps}
          entityPlural="buyer"
          variant={AsyncSelectVariant.Select}
          value={joinIfIdNameObj(buyer)}
          onSelect={(newValue: string) => setBuyer(splitIdNameStr(newValue))}
          dropdownMatchSelectWidth={550}
        />
      </FilterBarBox>
      <Table
        stickyHeader
        columns={tableColumns}
        data={finalizedPOSummaries}
        verticalAlign="middle"
        // we don't want to show children in finalize table
        childrenColumnName="_"
      />
    </div>
  );
}
