import React from 'react';

import { DoubleLeftOutlined, DoubleRightOutlined, SettingOutlined } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import { colors } from 'theme/colors';

import { ActionButton } from 'components/Button/ActionButton';
import { NavTabs, TabContent } from 'components/NavTabs';
import { Tooltip } from 'components/Tooltip';

import { arrFilterByValues } from 'utils/array';
import { truthy } from 'utils/boolean';
import { useLocalStorage, LocalStorageKey } from 'utils/localStorage';
import { PersistedPanel, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { useUserViewSettingsState } from 'utils/tableAndSidePaneSettings/useUserViewSettingsState';
import { track, TrackEvent } from 'utils/track';

import { ChooseViewSettingButton } from '../ChooseViewSettingButton';
import { CollapsePanels } from './CollapsePanels';

export function SidePane({
  title,
  settingKey,
  description,
  extendedDescription,
  content,
  panels,
  navTabs,
  dataTestId,
}: {
  title: React.ReactChild;
  /**
   * key used for persisting settings.
   * If settingsKey and panels are both defined,
   * then SidePane will show a customize button dropdown on top.
   */
  settingKey?: ViewSettingKey;
  description?: string;
  extendedDescription?: string | React.ReactChild;
  content?: React.ReactChild;
  panels?: Array<PersistedPanel | null>;
  /** @deprecated - used by sales Quote/Order side panels with tabs, do not extend pattern */
  navTabs?: TabContent[];
  dataTestId?: string;
}) {
  // persist expanded state in local storage so even if component is re-mounted, state will persist
  const [isExpanded, setIsExpanded] = useLocalStorage(LocalStorageKey.SidePane_Expanded, true);

  const truthyPanels = (panels || []).filter(truthy);
  const [visiblePanelKeys, setVisiblePanelKeys] = useUserViewSettingsState(
    settingKey,
    truthyPanels.map((panel) => panel.settingKey),
  );
  const visiblePanels = settingKey ? arrFilterByValues(truthyPanels, 'settingKey', visiblePanelKeys) : truthyPanels;
  const sidePaneRef = React.useRef<HTMLDivElement>(null);

  const onExpandCollapseButtonClick = () => {
    setIsExpanded?.(!isExpanded);
    // show sliding expand/collapse animation
    sidePaneRef.current?.animate(
      [{ transform: `translateX(${isExpanded ? '-100px' : '100px'})` }, { transform: 'translateX(0)' }],
      { duration: 100, easing: 'ease-in-out' },
    );
  };

  return (
    <div
      data-test-id={dataTestId}
      ref={sidePaneRef}
      className={cx(
        'SidePane',
        isExpanded && 'SidePane-expanded',
        css`
          position: sticky;
          ${isExpanded ? 'width: 400px' : ''};
          height: calc(100vh - 200px);
          overflow-y: auto;
          top: 120px;
          border: 1px solid ${colors.neutral[300]};
          border-radius: 8px;
          padding: 12px 16px;
        `,
      )}
    >
      <div
        className={css`
          display: flex;
          flex-direction: ${isExpanded ? 'row' : 'column-reverse'};
          font-size: 16px;
          gap: 8px;
          font-weight: 700;
          align-items: center;
        `}
      >
        <span
          className={css`
            writing-mode: ${isExpanded ? 'initial' : 'vertical-rl'};
            flex-grow: ${isExpanded ? '1' : '0'};
          `}
        >
          {title}
        </span>
        {isExpanded && settingKey ? (
          <ChooseViewSettingButton
            options={{
              options: truthyPanels.map((panel) => ({
                label: panel.title,
                value: panel.settingKey,
              })),
            }}
            selectedValues={visiblePanelKeys}
            onSelectedValuesChange={(newValues: string[]) => {
              track(TrackEvent.Customizable_Side_Pane_Update, {
                page: settingKey,
                sidePanes: newValues,
              });
              setVisiblePanelKeys(newValues);
            }}
            triggerButton={<ActionButton label={<SettingOutlined />} />}
            descriptor="Side Panel Sections"
            defaultValues={truthyPanels.map((panel) => panel.settingKey)}
          />
        ) : null}
        <Tooltip title={isExpanded ? 'Hide side pane' : 'Show side pane'}>
          <div
            className={css`
              cursor: pointer;
            `}
            onClick={onExpandCollapseButtonClick}
          >
            {isExpanded ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
          </div>
        </Tooltip>
      </div>
      {isExpanded ? (
        <>
          {description ? (
            <div
              className={css`
                margin-top: 16px;
                margin-bottom: 16px;
              `}
            >
              <div
                className={css`
                  font-size: 16px;
                  font-weight: 700;
                `}
              >
                {description}
              </div>
              {extendedDescription}
            </div>
          ) : null}
          {
            // don't show tabs if only one tab
            navTabs && (navTabs.length === 1 ? navTabs[0].content : <NavTabs tabs={navTabs} />)
          }
          {content}
          {visiblePanels.length ? <CollapsePanels arrowPosition="right" panels={visiblePanels} /> : null}
        </>
      ) : null}
    </div>
  );
}
