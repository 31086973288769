import React from 'react';

import { css } from '@emotion/css';
import { PieChart, Pie, Cell, Tooltip, Label, TooltipProps } from 'recharts';
import { theme } from 'theme';

import { CardLoader } from 'pages/purchasing/PurchasingDashboardPage/shared/CardLoader';

import { Card, CardHeader, CardSection } from 'components/Card';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { Typography } from 'components/Typography';

import { camelToTitleCase, formatUSD, formatUSDAbbreviated } from 'utils/formatting';

// Define a color map for statuses
const COLOR_MAP: Record<string, string> = {
  potentialTariff: theme.colors.warning[500],
  notAffected: theme.colors.chart.aloeGreen,
  needsReview: theme.colors.warning[500],
};

interface StatusAggregation {
  status: string | null;
  aggregatedPoDollars1M: number;
  aggregatedPoDollars3M: number;
  aggregatedPoDollars6M: number;
  aggregatedPoDollars12M: number;
  aggregatedOnPoDollars: number;
}

export const TariffPieChart = ({
  data,
  title,
  description,
  historicalMonths,
  highlightStatus,
  reverseHighlightPercentage = false,
}: {
  data: StatusAggregation[] | undefined;
  title: string;
  description: string;
  historicalMonths?: 1 | 3 | 6 | 12;
  highlightStatus: string;
  reverseHighlightPercentage?: boolean;
}) => {
  const height = 150;

  if (!data) {
    return <CardLoader height={height} />;
  }
  const chartValue = !historicalMonths
    ? 'aggregatedOnPoDollars'
    : historicalMonths === 1
    ? 'aggregatedPoDollars1M'
    : historicalMonths === 3
    ? 'aggregatedPoDollars3M'
    : historicalMonths === 6
    ? 'aggregatedPoDollars6M'
    : 'aggregatedPoDollars12M';

  const chartData = data.map((entry) => ({
    name: entry.status ?? 'needsReview',
    value: entry[chartValue],
  }));

  // Calculate total for percentage display
  const total = chartData.reduce((sum, entry) => sum + entry.value, 0);

  // Find the specific status value for the label
  const highlightedEntry = chartData.find((entry) => entry.name === highlightStatus);
  const highlightedPercentage = highlightedEntry
    ? (
        (reverseHighlightPercentage ? 1 - highlightedEntry.value / total : highlightedEntry.value / total) * 100
      ).toFixed(1)
    : '0.0';

  return (
    <Card
      className={css`
        min-width: 300px;
        border-color: ${theme.colors.neutral[300]};
      `}
    >
      <CardHeader
        className={css`
          background-color: white;
        `}
        title={title}
        description={description}
      />
      <CardSection>
        <FlexSpace gap={8}>
          <div
            className={css`
              display: flex;
              flex-direction: column;
              align-items: center;
            `}
          >
            <div
              className={css`
                font-size: 32px;
                font-weight: bold;
                line-height: 36px;
              `}
            >
              {formatUSDAbbreviated(Math.abs(highlightedEntry?.value ?? 0), 0)}
            </div>
            {historicalMonths ? (
              <div
                className={css`
                  white-space: nowrap;
                `}
              >
                In last {historicalMonths} months
              </div>
            ) : null}
          </div>
          <FlexSpacer />
          <PieChart width={height} height={height}>
            <Pie
              data={chartData}
              cx="50%"
              cy="50%"
              startAngle={90} // Starts at the top
              endAngle={-270} // Completes the circle clockwise
              innerRadius={height / 3}
              outerRadius={height / 2}
              fill="#8884d8"
              paddingAngle={3}
              dataKey="value"
              animationDuration={800} // Smooth animation
              animationEasing="ease-out"
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLOR_MAP[entry.name] || theme.colors.chart.aloeGreen} />
              ))}
              {/* Centered Percentage using Label */}
              <Label
                value={`${highlightedPercentage}%`}
                position="center"
                dy={-6}
                fontSize={24}
                fontWeight="bold"
                fill="#333"
              />
              <Label value={camelToTitleCase(highlightStatus)} position="center" dy={16} fontSize={12} fill="#666" />
            </Pie>

            <Tooltip
              formatter={(value) => formatUSD(Number(value))}
              content={(e: TooltipProps) => {
                const payload = e.payload?.filter((payloadx) => payloadx.name !== 'connection');
                if (e.active && payload && payload.length) {
                  const payloadData = payload[0];
                  return (
                    <div
                      className={css`
                        background-color: rgba(255, 255, 255, 0.9);
                        font-weight: 500;
                        border-radius: 8px;
                        border: 2px solid ${payloadData.payload.fill};
                        box-shadow: 0px 0px 2px 0px ${theme.colors.neutral[400]};
                      `}
                    >
                      <div
                        className={css`
                          padding: 6px;
                        `}
                      >
                        <Typography type="subtitle" style={{ fontWeight: 500 }}>
                          {camelToTitleCase(payloadData.payload.name)}
                        </Typography>
                        <Typography type="subtitle" style={{ fontWeight: 500 }}>
                          {formatUSD(Number(payloadData.value))}
                        </Typography>
                      </div>
                    </div>
                  );
                }

                return null;
              }}
            />
          </PieChart>
        </FlexSpace>
      </CardSection>
    </Card>
  );
};
