import React, { useState } from 'react';

import { schemas } from '@recurrency/core-api-schema';
import {
  TenantSettingFieldType,
  TenantSettingsField,
} from '@recurrency/core-api-schema/dist/common/tenantSettingsTypes';
import { SupplierTargetImportFrom } from '@recurrency/core-api-schema/dist/purchasing/postOnboardDfpaSupplierTargets';
import { Form, notification } from 'antd';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { SettingsField } from 'components/recipes/SettingsField';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { pluralize } from 'utils/formatting';
import { track, TrackEvent } from 'utils/track';

interface ImportFormData {
  resetData: boolean;
  importFrom: SupplierTargetImportFrom;
  importMinimumFrom: SupplierTargetImportFrom;
}

const formFields: TenantSettingsField[] = [
  {
    key: 'importFrom' as Any,
    type: TenantSettingFieldType.Select,
    title: 'Target Value',
    description: 'Configuration of targets setup in P21.',
    options: [
      { label: 'Control Value / Target Value', value: SupplierTargetImportFrom.TargetValue },
      { label: 'Freight Control Value / Freight Target Value', value: SupplierTargetImportFrom.FreightTargetValue },
    ],
  },
  {
    key: 'importMinimumFrom' as Any,
    type: TenantSettingFieldType.Select,
    title: 'Supplier Minimum',
    description: 'Configuration of supplier minimums setup in P21.',
    options: [
      { label: 'Control Value / Target Value', value: SupplierTargetImportFrom.TargetValue },
      { label: 'Freight Control Value / Freight Target Value', value: SupplierTargetImportFrom.FreightTargetValue },
    ],
  },
  {
    key: 'resetData' as Any,
    type: TenantSettingFieldType.Boolean,
    title: 'Reset & Import',
    description: 'Delete existing targets and import. By default only new targets are added, existing are not updated.',
  },
];

export function ImportSupplierLocationTargetsModal({
  tenantId,
  onClose,
}: {
  tenantId: string;
  onClose: (numRowsInserted?: number) => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm<ImportFormData>();

  const initialValues: ImportFormData = {
    resetData: false,
    importFrom: SupplierTargetImportFrom.FreightTargetValue,
    importMinimumFrom: SupplierTargetImportFrom.TargetValue,
  };

  const handleSubmit = async (values: ImportFormData) => {
    try {
      setIsSubmitting(true);
      const { data: result } = await coreApiFetch(schemas.purchasing.postOnboardDfpaSupplierTargets, {
        bodyParams: {
          resetData: values.resetData,
          importFrom: values.importFrom,
          importMinimumFrom: values.importMinimumFrom,
        },
        pathParams: { tenantId },
      });
      notification.success({
        message: 'Supplier-Location Targets Imported',
        description: `${pluralize(result?.numRowsInserted || 0, 'record', 'records', true)} inserted.`,
      });
      if (values.importMinimumFrom) {
        track(TrackEvent.SupplierMinimums_UpdateValues, { updateLocation: 'ImportSupplierLocationTargetsModal' });
      }
      onClose(result?.numRowsInserted);
    } catch (err) {
      captureAndShowError(err, 'Error importing supplier-location targets');
      onClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      visible
      footer={
        <>
          <Button key="cancel" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button key="submit" type="primary" form="form" htmlType="submit" loading={isSubmitting}>
            Import
          </Button>
        </>
      }
      title="Import Supplier-Location Targets"
      onCancel={() => onClose()}
      centered
      width={600}
    >
      <Form
        name="form"
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        onChange={() => form.validateFields()}
        layout="vertical"
      >
        {formFields.map((field) => (
          <SettingsField key={field.key} field={field} form={form} allowClear />
        ))}
      </Form>
    </Modal>
  );
}
