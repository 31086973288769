import React from 'react';

import { schemas } from '@recurrency/core-api-schema';

import { AvailabilityTable } from 'pages/items/ItemDetailsPage/tabs/AvailabilityTable';

import { DEFAULT_NUM_ROWS_SHOWN, MiniTable } from 'components/MiniTable';

import { useCoreApi } from 'hooks/useApi';

import { showAsyncModal } from 'utils/asyncModal';
import { formatNumber } from 'utils/formatting';
import { SidePanePanelType } from 'utils/track';
import { qtyUnitConverter } from 'utils/units';

import { panelTitleByType } from './types';
import { ViewAllModal } from './ViewAllModal';

export function LocationAvailabilityPanelContent({ itemUid }: { itemUid: string }) {
  const {
    data: locationAvailabilityData,
    isLoading: isLocationAvailabilityLoading,
    error: locationAvailabilityError,
  } = useCoreApi(schemas.items.getItemAvailabilityV2, {
    pathParams: { itemUid },
  });

  locationAvailabilityData?.items.sort(
    (a, b) =>
      qtyUnitConverter(b.qtyAvailable, b.inventoryUnitSize) - qtyUnitConverter(a.qtyAvailable, a.inventoryUnitSize),
  );

  return (
    <MiniTable
      numRowsShown={DEFAULT_NUM_ROWS_SHOWN}
      data={locationAvailabilityData?.items}
      columns={[
        {
          render: (record) => `${record.locationId}: ${record.locationName}`,
          maxWidth: '130px',
        },
        {
          render: (record) =>
            `${formatNumber(qtyUnitConverter(record.qtyAvailable, record.inventoryUnitSize))} ${
              record.inventoryUnitOfMeasure || ``
            }`,
          align: 'right',
        },
      ]}
      isLoading={isLocationAvailabilityLoading}
      error={locationAvailabilityError}
      onMoreButtonClick={() => {
        showAsyncModal(ViewAllModal, {
          title: panelTitleByType[SidePanePanelType.LocationAvailability],
          content: <AvailabilityTable itemUid={itemUid} />,
        });
      }}
    />
  );
}
