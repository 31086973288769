import React, { useEffect, useState } from 'react';

import { EnvironmentOutlined, UploadOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { EntityType } from '@recurrency/core-api-schema/dist/common/enums';
import {
  GetReplenishmentRulesLocationDTO,
  GetReplenishmentRulesRuleDTO,
} from '@recurrency/core-api-schema/dist/replenishmentRules/getReplenishmentRules';
import { Form, message, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { useItemsSelectProps, useSuppliersSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { responsiveFormLayout2 } from 'components/FormItems';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { Select } from 'components/Select';
import { Tooltip } from 'components/Tooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { capitalizeFirst } from 'utils/formatting';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { isValidReplenishmentRulesRequestDTO } from 'utils/validation';

import { EditReplenishmentPathsTree } from '../EditReplenishmentPathsTree';

export interface SaveReplenishmentRuleModalProps {
  onClose: () => void;
  onSubmit?: () => void;
  isVisible?: boolean;
  ruleGroupId?: string;
  rulesData: GetReplenishmentRulesRuleDTO[];
  initialFormValues?: Partial<GetReplenishmentRulesRuleDTO>;
  defaultReplenishmentPathTree?: GetReplenishmentRulesLocationDTO[];
}

export const SaveReplenishmentRuleModal = ({
  onClose,
  onSubmit,
  rulesData,
  initialFormValues = {},
  defaultReplenishmentPathTree = [],
}: SaveReplenishmentRuleModalProps) => {
  const [form] = Form.useForm<GetReplenishmentRulesRuleDTO>();
  const [entityType, setEntityType] = useState(form.getFieldValue('entityType'));
  const [isFormChanged, setIsFormChanged] = useState(false);
  const { activeUser } = useGlobalApp();

  // Populate form initial data when updating or duplicating entries
  useEffect(() => {
    if (initialFormValues.ruleGroupId || (initialFormValues.entityIds && initialFormValues.entityIds?.length > 0)) {
      form.setFieldsValue(initialFormValues);
      setEntityType(initialFormValues.entityType);
      // Enable save button for duplicates
      if (!initialFormValues.ruleGroupId) {
        setIsFormChanged(true);
      }
    }
  }, [form, initialFormValues]);

  const itemsSelectProps = useItemsSelectProps();
  const suppliersSelectProps = useSuppliersSelectProps();
  const convertedItemsSelectProps = convertToMultiSelectProps(itemsSelectProps, { valueKey: 'uid' });
  const convertedSuppliersSelectProps = convertToMultiSelectProps(suppliersSelectProps, { valueKey: 'foreignId' });
  const showUploadButton = activeUser.isRecurrencyAdmin;

  const defaultValues: GetReplenishmentRulesRuleDTO = {
    ruleGroupId: '',
    ruleName: '',
    entityIds: [],
    entityDisplayIds: [],
    entityType: EntityType.Supplier,
    replenishmentPathTree: defaultReplenishmentPathTree,
  };

  function handleFieldsChange() {
    setIsFormChanged(true);
    const newEntityType = form.getFieldValue('entityType');
    if (newEntityType !== entityType) {
      setEntityType(newEntityType);
      form.setFieldsValue({ entityIds: [] });
    }
  }

  function validateRuleName(_: unknown, ruleName: string) {
    const filteredRulesData = rulesData.filter((r) => r.ruleGroupId !== initialFormValues.ruleGroupId);
    const ruleNames = filteredRulesData.map((r) => r.ruleName?.toLowerCase());
    if (ruleNames.includes(ruleName.toLowerCase())) return Promise.reject(new Error('Rule name already taken'));
    return Promise.resolve();
  }

  function validateEntityIds(_: unknown, entityIdsInput: string[]) {
    const filteredRulesData = rulesData.filter((r) => r.ruleGroupId !== initialFormValues.ruleGroupId);

    // Generates a dictionary with {id: displayId} items
    const entityIdToDisplayIdMapper = filteredRulesData
      .flatMap((r) => r.entityIds.map((id, i) => ({ [id]: r.entityDisplayIds[i] })))
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});

    // Create a set of entity IDs that are already in the rules data
    const existingEntityIds = new Set<string>();
    filteredRulesData
      .filter((r) => r.entityType === entityType)
      .forEach((r) => {
        r.entityIds.forEach((id) => {
          existingEntityIds.add(id);
        });
      });

    // Check if any of the input entity IDs are in the set of existing entity IDs
    const repeatedEntityIds = entityIdsInput.filter((id) => existingEntityIds.has(id));

    const conflictingRuleNames = filteredRulesData
      .filter((r) => r.entityIds.some((id) => repeatedEntityIds.includes(id)))
      .map((r) => r.ruleName);

    const joinedIds = (
      entityType === EntityType.Item ? repeatedEntityIds.map((id) => entityIdToDisplayIdMapper[id]) : repeatedEntityIds
    ).join(', ');

    if (repeatedEntityIds.length > 0) {
      return Promise.reject(
        new Error(
          `These ${capitalizeFirst(entityType)} rule(s) [${conflictingRuleNames.join(
            ', ',
          )}] already contain the following IDs [${joinedIds}]`,
        ),
      );
    }

    return Promise.resolve();
  }

  async function handleSubmit(formBody: GetReplenishmentRulesRuleDTO) {
    const submitNotification = createSubmissionNotification({
      entityName: 'Replenishment Rule',
      submittingMessage: 'Saving Replenishment Rule',
      expectedWaitSeconds: 5,
    });

    try {
      onClose();
      const ruleGroupId = (initialFormValues as GetReplenishmentRulesRuleDTO)?.ruleGroupId;
      if (!isValidReplenishmentRulesRequestDTO(formBody)) {
        submitNotification.error(new Error('Invalid Replenishment Rule Object'));
        return;
      }

      if (ruleGroupId) {
        // Updating existing rule
        const updateFormBody = { ...formBody, ruleGroupId };
        await coreApiFetch(schemas.replenishmentRules.updateReplenishmentRule, {
          bodyParams: updateFormBody,
          pathParams: { ruleGroupId },
        });
      } else {
        // Creating new rule
        await coreApiFetch(schemas.replenishmentRules.createReplenishmentRule, {
          bodyParams: formBody,
        });
      }
      submitNotification.success({ successMessage: 'Replenishment Rule saved', duration: 10 });
      if (onSubmit) onSubmit();
      form.resetFields();
    } catch (err) {
      submitNotification.error(err);
    }
  }

  const parseUploadedFileClientside = (uploadedFile: RcFile) => {
    const isLargerThan1MB: boolean = uploadedFile.size / 1024 / 1024 > 1;
    if (isLargerThan1MB) {
      message.error('File must smaller than 1 MB.');
      return false;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContents: string = e.target?.result as string;
      if (fileContents) {
        const IDs = fileContents.split(',');
        // Overwrite the entity IDs field with file contents
        form.setFieldsValue({ entityIds: IDs });
      }
    };
    reader.readAsText(uploadedFile);
    // Return false to prevent submission so it is only parsed client side
    return false;
  };

  return (
    <Modal
      visible
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          disabled={!isFormChanged}
          key="submit"
          type="primary"
          form="saveReplenishmentRuleForm"
          htmlType="submit"
        >
          Save
        </Button>,
      ]}
      title={`${initialFormValues?.ruleGroupId ? 'Edit' : 'New'} Replenishment Rule`}
      onCancel={onClose}
      width={900}
    >
      <Container>
        <Form
          onFieldsChange={handleFieldsChange}
          initialValues={defaultValues}
          name="saveReplenishmentRuleForm"
          form={form}
          onFinish={handleSubmit}
          onError={console.error}
          onFinishFailed={console.error}
          scrollToFirstError
          {...responsiveFormLayout2}
          labelCol={{
            sm: {
              span: 5,
              offset: 1,
            },
            md: {
              span: 5,
              offset: 1,
            },
          }}
        >
          <Form.Item
            label="Rule Name"
            name="ruleName"
            rules={[{ required: true, message: 'Please specify a rule name' }, { validator: validateRuleName }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Entity Type"
            name="entityType"
            rules={[{ required: true, message: 'Please specify the entity type' }]}
          >
            <Select
              showSearch
              options={Object.values(EntityType)
                .filter((v) => v !== EntityType.Location)
                .map((v) => ({ label: capitalizeFirst(v), value: v }))}
              value={null}
              onSelect={(newVal) => form.setFieldsValue({ entityType: newVal })}
            />
          </Form.Item>

          {form.getFieldValue('entityType') === EntityType.Supplier && (
            <Form.Item
              label="Suppliers"
              name="entityIds"
              valuePropName="selectedValues"
              trigger="onSelectedValuesChange"
              getValueFromEvent={(e) => e}
              rules={[
                { required: true, message: 'You must choose at least 1 supplier' },
                { validator: validateEntityIds },
              ]}
            >
              <AsyncMultiSelect
                disableValuesTooltip
                containerClassName={css`
                  width: 100%;
                  height: 40px !important;
                `}
                popoverClassName={css`
                  max-height: 300px;
                `}
                selectProps={convertedSuppliersSelectProps}
                label="Suppliers"
                queryPlaceholder="Search suppliers"
                selectedValues={form.getFieldValue('entityIds')}
                onSelectedValuesChange={(selectedValues) => form.setFieldsValue({ entityIds: selectedValues })}
                icon={<EnvironmentOutlined />}
              />
            </Form.Item>
          )}

          {form.getFieldValue('entityType') === EntityType.Item && (
            <Form.Item
              label="Items"
              name="entityIds"
              valuePropName="selectedValues"
              trigger="onSelectedValuesChange"
              getValueFromEvent={(e) => e}
              rules={[
                { required: entityType === EntityType.Item, message: 'You must choose at least 1 item' },
                { validator: validateEntityIds },
              ]}
            >
              <AsyncMultiSelect
                disableValuesTooltip
                containerClassName={css`
                  width: 100%;
                  height: 40px !important;
                `}
                popoverClassName={css`
                  max-height: 300px;
                `}
                selectProps={convertedItemsSelectProps}
                label="Items"
                queryPlaceholder="Search items"
                selectedValues={form.getFieldValue('entityIds')}
                onSelectedValuesChange={(selectedValues) => form.setFieldsValue({ entityIds: selectedValues })}
                icon={<EnvironmentOutlined />}
              />
            </Form.Item>
          )}

          {showUploadButton && (
            <Tooltip title="Upload IDs through a CSV file. Only visible to Recurrency Admins currently.">
              <Form.Item name="entityIds" label="Upload List (INTERNAL)">
                <Upload accept=".csv" maxCount={1} showUploadList={false} beforeUpload={parseUploadedFileClientside}>
                  <Button icon={<UploadOutlined />}>Upload CSV File</Button>
                </Upload>
              </Form.Item>
            </Tooltip>
          )}

          <Form.Item
            label="Replenishment Path"
            name="replenishmentPathTree"
            valuePropName="replenishmentPathTree"
            trigger="setReplenishmentPathTree"
            getValueFromEvent={(e) => e}
          >
            <EditReplenishmentPathsTree
              showCardWrapper
              replenishmentPathTree={form.getFieldValue('replenishmentPathTree') ?? []}
              setReplenishmentPathTree={(replenishmentPathTree) => form.setFieldsValue({ replenishmentPathTree })}
            />
          </Form.Item>
        </Form>
      </Container>
    </Modal>
  );
};
