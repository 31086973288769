import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import {
  BugOutlined,
  CalendarOutlined,
  CloudDownloadOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  FileImageOutlined,
  IdcardOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import {
  IntegrationType,
  NgrokIntegrationType,
  TenantFeatureFlags,
  TenantIntegrationStatus,
} from '@recurrency/core-api-schema/dist/common/enums';
import { PatchUpdateTenantIntegrationBodyParams } from '@recurrency/core-api-schema/dist/integrations/patchUpdateTenantIntegration';
import { TenantIntegrationBodyParams } from '@recurrency/core-api-schema/dist/integrations/postCreateIntegration';
import { TenantIntegrationDTO } from '@recurrency/core-api-schema/dist/integrations/tenantIntegrationDTO';
import { TenantProductsDTO } from '@recurrency/core-api-schema/dist/tenants/tenantDTO';
import { Dropdown, Empty, Menu, Modal as antdModal, notification } from 'antd';
import { colors } from 'theme/colors';

import { Badge } from 'components/Badge';
import { Button } from 'components/Button';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { Container } from 'components/Container';
import { FlexSpace } from 'components/FlexSpace';
import { CenteredError, CenteredLoader } from 'components/Loaders';
import { NavTabs } from 'components/NavTabs';
import { PageHeader } from 'components/PageHeader';
import { DetailPageSection, DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import { UsersTable } from 'components/recipes/UsersTable';
import { Tooltip } from 'components/Tooltip';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { AppEnv, env } from 'utils/env';
import { captureAndShowError } from 'utils/error';
import { formatDate } from 'utils/formatting';
import { routes, IdPathParams, usePathParams } from 'utils/routes';
import { isTenantErpTypeP21 } from 'utils/tenants';

import { ImportSupplierLocationTargetsModal } from './ImportSupplierLocationTargetsModal';
import { TenantDataSyncConfigsTable } from './tabs/TenantDataSyncConfigsTable/TenantDataSyncConfigsTable';
import { TenantFeatureFlagsTable } from './tabs/TenantFeatureFlagsTable';
import { TenantIntegrationsAgentsTable } from './tabs/TenantIntegrationsAgentsTable';
import { TenantIntegrationsTable } from './tabs/TenantIntegrationsTable';
import { TenantMetadataTable } from './tabs/TenantMetadataTable';
import { TenantProductsTable } from './tabs/TenantProductsTable';
import { TenantSyncStatusTab } from './tabs/TenantSyncStatusTable';
import { TenantIntegrationEditForm } from './TenantIntegrationEditForm';
import { getConnectionStatuses } from './utils';

export const TenantDetailsPage = () => {
  const history = useHistory();
  const { id } = usePathParams<IdPathParams>();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingIntegration, setEditingIntegration] = useState<TenantIntegrationDTO | null>(null);
  const appEnv = env.APP_ENV;

  const {
    data: tenant,
    error: tenantError,
    isLoading: tenantIsLoading,
    reload: tenantReload,
  } = useCoreApi(schemas.tenants.getTenantById, {
    pathParams: { tenantId: id },
  });

  const {
    data: integrations,
    error: integrationsError,
    isLoading: integrationsIsLoading,
    reload: integrationsReload,
  } = useCoreApi(schemas.integrations.getTenantIntegrations, {
    pathParams: { tenantId: id },
  });

  const {
    data: connectionStatusData,
    error: connectionStatusError,
    isLoading: connectionStatusIsLoading,
  } = useCoreApi(schemas.tenants.getTenantConnectionStatusDetails, {
    pathParams: { tenantId: id },
  });

  const {
    data: usersData,
    error: usersError,
    isLoading: usersIsLoading,
  } = useCoreApi(schemas.tenants.getTenantUsers, {
    pathParams: { tenantId: id },
  });

  const error = integrationsError || tenantError || usersError;
  const isLoading = integrationsIsLoading || tenantIsLoading;

  if (error) {
    return <CenteredError error={error} />;
  }

  if (isLoading || !tenant || !integrations) {
    return <CenteredLoader />;
  }

  const handleIntegrationSubmit = async (data: TenantIntegrationBodyParams) => {
    try {
      if (!tenant.id) {
        throw new Error('No tenant ID found');
      }
      if (editingIntegration) {
        await coreApiFetch(schemas.integrations.patchUpdatePartialIntegration, {
          bodyParams: data as PatchUpdateTenantIntegrationBodyParams,
          pathParams: { tenantId: tenant.id, integrationId: editingIntegration.id },
        });
        notification.success({ message: 'Integration updated.' });
      } else {
        await coreApiFetch(schemas.integrations.postCreateIntegration, {
          bodyParams: data,
          pathParams: { tenantId: tenant.id },
        });
        notification.success({ message: 'New integration added.' });
      }
      closeModal();
      integrationsReload();
    } catch (err) {
      captureAndShowError(err, `Error while updating integration`);
    }
  };

  const postRemove = async (integrationId: string) => {
    try {
      await coreApiFetch(schemas.integrations.deleteIntegration, {
        pathParams: { tenantId: tenant.id, integrationId },
      });
      notification.success({ message: 'Deleted integration.' });
      setIsEditModalOpen(false);
      integrationsReload();
    } catch (err) {
      captureAndShowError(err, `Error while deleting integration`);
    }
  };

  const patchIntegrationStatus = async (integration: TenantIntegrationDTO, status: TenantIntegrationStatus) => {
    try {
      await coreApiFetch(schemas.integrations.patchUpdatePartialIntegration, {
        bodyParams: { status },
        pathParams: { tenantId: tenant.id, integrationId: integration.id },
      });
      notification.success({ message: 'Updated integration.' });
      setIsEditModalOpen(false);
      integrationsReload();
    } catch (err) {
      captureAndShowError(err, `Error while updating integration`);
    }
  };

  const handleRemoveIntegration = (integrationId: string) => {
    antdModal.confirm({
      title: 'Are you sure you want to delete this integration?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        postRemove(integrationId);
      },
      onCancel() {},
    });
  };

  const handleEditIntegration = async (integration: TenantIntegrationDTO) => {
    setEditingIntegration(integration);
    setIsEditModalOpen(true);
  };

  const handleConnectIntegration = async (integrationType: IntegrationType) => {
    const ngrokType =
      integrationType === IntegrationType.Database ? NgrokIntegrationType.Database : NgrokIntegrationType.Web;
    antdModal.confirm({
      title: `Are you sure you want to provision new ngrok resources for this tenant? This will overwrite the existing connection string.`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okType: 'primary',
      onOk() {
        connectIntegration(ngrokType);
      },
      onCancel() {},
    });
  };

  const connectIntegration = async (type: NgrokIntegrationType) => {
    try {
      await coreApiFetch(schemas.integrations.postConnectIntegration, {
        pathParams: { tenantId: tenant.id },
        bodyParams: { type },
      });
      notification.success({ message: 'Connected integration and ngrok resources created.' });
    } catch (err) {
      captureAndShowError(err, `Error while creating ngrok resources`);
    }
  };

  const handlePatchIntegrationStatus = async (record: TenantIntegrationDTO, status: TenantIntegrationStatus) => {
    antdModal.confirm({
      title: `Are you sure you want to set integration status to ${status}?`,
      icon: <ExclamationCircleOutlined />,
      content: '',
      okType: 'primary',
      onOk() {
        patchIntegrationStatus(record, status);
      },
      onCancel() {},
    });
  };

  const handleAddIntegration = () => {
    setIsEditModalOpen(true);
  };

  const closeModal = () => {
    setIsEditModalOpen(false);
    setEditingIntegration(null);
  };

  const onProductsChange = async (products: TenantProductsDTO) => {
    try {
      await coreApiFetch(schemas.tenants.putUpdateTenantProducts, {
        bodyParams: { products },
        pathParams: { tenantId: tenant.id },
      });
      notification.success({ message: 'Products updated successfully, refresh page to see them in action.' });
      tenantReload();
    } catch (err) {
      captureAndShowError(err, `Error while updating products`);
    }
  };

  const onFeatureFlagsChange = async (newFeatureFlags: TenantFeatureFlags) => {
    const bodyParams = { featureFlags: newFeatureFlags };
    try {
      // validate SSO option
      if (bodyParams.featureFlags.sso_enabled && tenant?.metadata?.ssoConnectionName === undefined) {
        notification.error({ message: `Please set SSO Connection Name before enabling SSO` });
        return;
      }
      await coreApiFetch(schemas.tenants.putUpdateTenantFeatureFlags, {
        bodyParams,
        pathParams: { tenantId: tenant.id },
      });
      notification.success({ message: 'Feature flags updated successfully, refresh page to see them in action.' });
      tenantReload();
    } catch (err) {
      captureAndShowError(err, `Error while updating feature flags`);
    }
  };

  const createTypesenseKey = async () => {
    try {
      await coreApiFetch(schemas.tenants.postCreateTypesenseKey, {
        pathParams: { tenantId: tenant.id },
      });
      notification.success({ message: 'New Typesense key created.' });
      tenantReload();
    } catch (err) {
      captureAndShowError(err, `Error while creating Typesense key`);
    }
  };

  const detailSections: DetailPageSection[] = [
    {
      title: 'Tenant Info',
      rows: [
        [
          { icon: <IdcardOutlined />, label: 'Slug', value: tenant.slug },
          { icon: <IdcardOutlined />, label: 'ERP Type', value: tenant.erpType },
          { icon: <IdcardOutlined />, label: 'DB Schema', value: tenant.databaseSchema },
          {
            icon: <IdcardOutlined />,
            label: 'Connection Status',
            value: (
              <FlexSpace>
                {connectionStatusError ? (
                  <span
                    className={css`
                      color: ${colors.danger[500]};
                    `}
                  >
                    {connectionStatusError.message}
                  </span>
                ) : connectionStatusIsLoading || !connectionStatusData ? (
                  <LoadingOutlined />
                ) : (
                  getConnectionStatuses(tenant, connectionStatusData).map((connStatus, idx) => (
                    <ConditionalWrapper
                      key={idx}
                      condition={!!connStatus.error}
                      wrapper={(children) => <Tooltip title={connStatus.error}>{children}</Tooltip>}
                      children={
                        <Badge
                          label={connStatus.connection}
                          variant={connStatus.status === 'available' ? ('success' as const) : ('danger' as const)}
                        />
                      }
                    />
                  ))
                )}
              </FlexSpace>
            ),
          },
          {
            icon: <CalendarOutlined />,
            label: 'Created At',
            value: formatDate(tenant.createdAt),
          },
          {
            icon: <CalendarOutlined />,
            label: 'Updated At',
            value: formatDate(tenant.updatedAt),
          },
          {
            icon: <IdcardOutlined />,
            label: 'Typesense Search Key',
            value: tenant.typesense.searchApiKey || (
              <Button type="primary" size="small" onClick={createTypesenseKey}>
                Generate Typesense Key
              </Button>
            ),
          },
          {
            icon: <FileImageOutlined />,
            label: 'Logo',
            value: tenant.logoUrl ? (
              <img
                className={css`
                  width: 100px;
                `}
                src={tenant.logoUrl}
                alt="Tenant logo"
              />
            ) : (
              '---'
            ),
          },
          {
            icon: <BugOutlined />,
            label: 'Airflow UI',
            value: (
              <a
                href={
                  appEnv === AppEnv.Production
                    ? `https://b7987ffb-e0a0-430d-878c-c35948f821d9.c24.us-east-1.airflow.amazonaws.com/home?tags=${tenant.databaseSchema}`
                    : `https://6de83440-b195-4dba-a26c-0a25d0b2b5ca.c40.us-east-1.airflow.amazonaws.com/home?tags=${tenant.databaseSchema}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Airflow {appEnv} UI
              </a>
            ),
          },
        ],
      ],
    },
  ];

  return (
    <Container>
      <PageHeader
        title={tenant.name}
        copyable
        entity={{ kind: 'Tenant', id: tenant.id }}
        headerActions={
          <>
            <Button onClick={() => history.push(routes.internal.tenantEdit(tenant.id))}>Edit</Button>
            {isTenantErpTypeP21(tenant.erpType) ? (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="importTargets"
                      icon={<CloudDownloadOutlined />}
                      onClick={() => showAsyncModal(ImportSupplierLocationTargetsModal, { tenantId: tenant.id })}
                    >
                      Import Supplier-Location Targets
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  className={css`
                    width: 50px;
                  `}
                >
                  <DownOutlined />
                </Button>
              </Dropdown>
            ) : null}
          </>
        }
      />
      <DetailPageSections sections={detailSections} />
      <NavTabs
        tabs={[
          {
            header: 'Users',
            content: (
              <UsersTable
                users={usersData?.items?.map((userTenant) => userTenant.user) ?? []}
                isLoading={usersIsLoading}
              />
            ),
          },
          {
            header: 'Products',
            content: <TenantProductsTable products={tenant?.products} onProductsChange={onProductsChange} />,
          },
          {
            header: 'Feature Flags',
            content: (
              <TenantFeatureFlagsTable
                featureFlags={tenant?.featureFlags as TenantFeatureFlags}
                onFeatureFlagsChange={onFeatureFlagsChange}
              />
            ),
          },
          { header: 'Metadata', content: <TenantMetadataTable tenant={tenant} /> },
          {
            header: 'Integrations',
            content: (
              <Container>
                <TenantIntegrationsTable
                  integrations={integrations?.items}
                  onIntegrationStatusChange={handlePatchIntegrationStatus}
                  onEditButtonClick={handleEditIntegration}
                  onRemoveButtonClick={handleRemoveIntegration}
                  onAddIntegrationButtonClick={handleAddIntegration}
                  onConnectButtonClick={handleConnectIntegration}
                />
                <TenantDataSyncConfigsTable tenantId={tenant.id} />
              </Container>
            ),
          },
          {
            header: 'Sync Status',
            content: integrations?.items[0]?.status ? (
              <TenantSyncStatusTab tenantId={tenant.id} integrationStatus={integrations?.items[0]?.status} />
            ) : (
              <Empty description="This tenant does not have an existing integration" />
            ),
          },
          {
            header: 'Agents',
            content: <TenantIntegrationsAgentsTable tenant={tenant} />,
          },
        ]}
      />
      {isEditModalOpen && (
        <TenantIntegrationEditForm
          integration={editingIntegration}
          tenant={tenant}
          onSubmit={handleIntegrationSubmit}
          onClose={closeModal}
        />
      )}
    </Container>
  );
};
