import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SupplierTargetTypes } from '@recurrency/core-api-schema/dist/common/enums';
import { Form, notification, Typography } from 'antd';

import { TargetTypeSelectOptions } from 'pages/purchasing/utils';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { DividerLine } from 'components/DividerLine';
import { responsiveFormLayout } from 'components/FormItems';
import { Input } from 'components/Input';
import { NotificationLink } from 'components/Links';
import { SmallLoader } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { PropertyListItem } from 'components/PropertyListItem';
import { Select } from 'components/Select';
import { Tabs } from 'components/Tabs';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { EMPTY_VALUE_DASHES } from 'utils/formatting';
import { routes } from 'utils/routes';
import { track, TrackEvent } from 'utils/track';

import { SupplierLocationSettingsAuditLog } from './AuditLog/SupplierLocationSettingsAuditLog';
import { LastUpdated } from './LastUpdatedMoment';

export interface SupplierLocationTargetSettings {
  supplierId: string;
  locationId: string;
  targetType: SupplierTargetTypes;
  targetValue: number;
  reviewCycleDays: number;
}

export interface SupplierLocationSettingsModalProps {
  supplierId: string;
  locationId: string;
  onClose: (newSettings?: SupplierLocationTargetSettings) => void;
}

interface TargetFormData {
  targetType?: SupplierTargetTypes;
  targetValue?: number;
  reviewCycleDays?: number;
}

export function SupplierLocationSettingsModal({ supplierId, locationId, onClose }: SupplierLocationSettingsModalProps) {
  const [supplierLocationSettingForm] = Form.useForm<TargetFormData>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: settingsData,
    isLoading,
    setData: setSettingsData,
  } = useCoreApi(schemas.purchasing.getSupplierLocationSettings, {
    queryParams: { supplierId, locationId },
  });

  // fill in form data from api response
  useEffect(() => {
    if (settingsData) {
      // hide NOT_SET from selectable options
      supplierLocationSettingForm.setFieldsValue({
        targetType: settingsData.targetType !== SupplierTargetTypes.NotSet ? settingsData.targetType : undefined,
        targetValue: settingsData.targetValue,
        reviewCycleDays: settingsData.reviewCycleDays,
      });
    }
  }, [supplierLocationSettingForm, settingsData]);

  async function handleSubmit(data: TargetFormData) {
    try {
      setIsSubmitting(true);
      const newSettings: SupplierLocationTargetSettings = {
        supplierId,
        locationId,
        targetType: data.targetType as SupplierTargetTypes,
        targetValue: Number(data.targetValue),
        reviewCycleDays: Number(data.reviewCycleDays),
      };

      await coreApiFetch(schemas.purchasing.patchSupplierLocationSettings, { bodyParams: newSettings });
      notification.success({
        key: supplierId,
        message: (
          <>
            <div>Updated target & review settings</div>
            <NotificationLink
              notificationKey={supplierId}
              to={routes.purchasing.purchaseTargets({
                supplierIds: [supplierId],
                locationIds: [locationId],
              })}
            >
              View targets for Supplier #{supplierId} at Location #{locationId}
            </NotificationLink>
          </>
        ),
        duration: 5,
      });
      // update api response data after patch
      setSettingsData({ ...settingsData, ...newSettings });
      track(TrackEvent.Purchasing_SetSupplierLocationTarget_Submit, newSettings);
      onClose(newSettings);
    } catch (err) {
      captureAndShowError(err, 'Error updating settings', {
        notificationKey: supplierId,
        duration: 0,
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Modal
      visible
      title={`Set Target & Review Cycle for Supplier #${supplierId} at Location #${locationId}`}
      onCancel={() => onClose()}
      centered
      width={900}
      footer={
        <>
          <Button key="cancel" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            form="supplierLocationSettingForm"
            htmlType="submit"
            disabled={isLoading}
            loading={isSubmitting}
          >
            Set Target
          </Button>
        </>
      }
    >
      <>
        {isLoading ? (
          <SmallLoader />
        ) : (
          <Container>
            <LastUpdated
              entityName="Settings"
              dateString={settingsData?.updatedAt}
              showIcon
              className={css`
                margin-bottom: 24px;
              `}
            />

            <Form.Provider>
              <Form
                name="supplierLocationSettingForm"
                form={supplierLocationSettingForm}
                onFinish={handleSubmit}
                onError={console.error}
                onFinishFailed={console.error}
                {...responsiveFormLayout}
              >
                <>
                  <Typography.Title level={5}>Target Value</Typography.Title>
                  <Typography.Paragraph>
                    Recurrency will mark a supplier “Ready for Review” when the line item requirements sum to this
                    threshold. Recurrency also uses this target to recommend item quantity changes in PO Auto Fill and
                    Auto Trim. This target is typically used to reflect a supplier’s pre-paid freight threshold, minimum
                    order quantity, full container volume, or other purchasing goal.
                  </Typography.Paragraph>
                  <div className={optionWrapperCss}>
                    <div className={valuesWrapperCss}>
                      <PropertyListItem
                        label="Current Target Value"
                        value={
                          settingsData?.targetType && settingsData?.targetType !== SupplierTargetTypes.NotSet
                            ? settingsData?.targetValue
                            : EMPTY_VALUE_DASHES
                        }
                      />
                      <PropertyListItem
                        label="New Target Value"
                        value={
                          <Form.Item
                            className={noMarginCss}
                            name="targetValue"
                            rules={[
                              {
                                required: true,
                                message: 'Target value is required.',
                              },
                            ]}
                          >
                            <Input type="number" min={0} />
                          </Form.Item>
                        }
                      />
                    </div>
                    <div className={valuesWrapperCss}>
                      <PropertyListItem
                        label="Current Target Type"
                        value={
                          settingsData?.targetType && settingsData?.targetType !== SupplierTargetTypes.NotSet
                            ? settingsData.targetType.toUpperCase()
                            : EMPTY_VALUE_DASHES
                        }
                      />
                      <PropertyListItem
                        label="New Target Type"
                        value={
                          <Form.Item
                            className={noMarginCss}
                            name="targetType"
                            rules={[
                              {
                                required: true,
                                message: 'Target type is required.',
                              },
                            ]}
                          >
                            <Select options={TargetTypeSelectOptions} />
                          </Form.Item>
                        }
                      />
                    </div>
                  </div>

                  <DividerLine />

                  <Typography.Title level={5}>Review Cycle</Typography.Title>
                  <Typography.Paragraph>
                    Recurrency will mark a supplier “Ready for Review” based on this configurable review cadence. Every
                    time a PO is created in Recurrency or a supplier is “Marked as Reviewed,” the cycle will reset to
                    begin on that day.
                  </Typography.Paragraph>
                  <div className={valuesWrapperCss}>
                    <PropertyListItem label="Current Review Cycle (days)" value={settingsData?.reviewCycleDays} />
                    <PropertyListItem
                      label="New Review Cycle (days)"
                      value={
                        <Form.Item
                          className={noMarginCss}
                          name="reviewCycleDays"
                          rules={[
                            {
                              required: true,
                              message: 'Review cycle days required.',
                            },
                          ]}
                        >
                          <Input type="number" min={0} />
                        </Form.Item>
                      }
                    />
                  </div>
                </>
              </Form>
            </Form.Provider>
          </Container>
        )}
        <DividerLine />
        <Tabs
          tabs={[
            {
              header: 'Audit Log',
              infoTooltip: `All updates for Supplier #${supplierId} at Location #${locationId}`,
              content: <SupplierLocationSettingsAuditLog locationId={locationId} supplierId={supplierId} />,
            },
          ]}
        />
      </>
    </Modal>
  );
}

/// /// shared styles //////

const optionWrapperCss = css`
  display: flex;

  & > * {
    flex: 1;
  }
`;

const valuesWrapperCss = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const noMarginCss = css`
  margin: 0;
`;
