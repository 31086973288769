import React from 'react';

import { Link } from 'react-router-dom';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { GetItemUsageInheritanceDTO } from '@recurrency/core-api-schema/dist/items/getItemUsageInheritanceOverride';
import { Alert, Tooltip } from 'antd';
import { theme } from 'theme';

import { makePath, routePaths } from 'utils/routes';

export const UsageInheritanceDescendantBanner = ({
  validDescendants,
}: {
  validDescendants: GetItemUsageInheritanceDTO[];
}) =>
  validDescendants.length > 0 ? (
    <Tooltip title="Recurrency displays this item's full usage data, but its usage is also being included in descendant items that use this data as their own. Please be cautious during planning to avoid overstocking.">
      <Alert
        icon={<ExclamationCircleOutlined />}
        message={
          <>
            This item's usage is inherited by{' '}
            {validDescendants.map((item: GetItemUsageInheritanceDTO, index: number) => (
              <React.Fragment key={item.itemCode}>
                <Link to={makePath(routePaths.purchasing.itemDetails, { id: item.itemCode })}>{item.itemCode}</Link>
                {index < validDescendants.length - 1 ? ', ' : ''}
              </React.Fragment>
            ))}
            .
          </>
        }
        banner
        className={css`
          background-color: ${theme.colors.primary[200]};
          border-radius: 10px;
          .ant-alert-icon {
            color: ${theme.colors.neutral[900]};
          }
          .ant-alert-message {
            color: ${theme.colors.neutral[900]};
          }
        `}
      />
    </Tooltip>
  ) : null;
