import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { InheritanceOverrideBodyParams } from '@recurrency/core-api-schema/dist/items/getItemUsageInheritanceOverride';
import { SearchIndexName } from '@recurrency/core-api-schema/dist/searchIndex/common';
import { Empty, notification } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { colors } from 'theme/colors';

import { Alert } from 'components/Alert';
import { SearchSelect } from 'components/AsyncSelect/SearchSelect';
import { Button } from 'components/Button';
import { FlexSpace } from 'components/FlexSpace';
import { Table } from 'components/Table';
import { Tooltip } from 'components/Tooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { coreApiFetch } from 'utils/api';
import { truthy } from 'utils/boolean';
import { captureAndShowError } from 'utils/error';
import { splitIdNameStr } from 'utils/formatting';
import { makePath, routePaths } from 'utils/routes';
import { asKeyOf } from 'utils/tables';

import { ItemInheritanceData } from './sidePane/sidePane.utils';
import { UsageInheritanceDescendantBanner } from './sidePane/UsageInheritanceDescendantBanner';

interface UsageInheritanceTabContentProps {
  descendantItemId: string;
  descendantItemCode: string;
  itemInheritanceData: ItemInheritanceData;
}

interface ItemOption {
  value?: string | undefined;
  uid: string;
}

export const UsageInheritanceTabContent = ({
  descendantItemId,
  descendantItemCode,
  itemInheritanceData,
}: UsageInheritanceTabContentProps) => {
  const {
    isLoading,
    usingRecurrencyInheritance,
    validDescendants,
    pendingAncestorData,
    pendingRemovedAncestorData,
    allAncestorData,
    reloadInheritanceData,
    nextUpdateDays,
  } = itemInheritanceData;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedItems, setSelectedItems] = useState<ItemOption[]>(
    allAncestorData.items.map((override) => ({
      uid: override.itemId,
      value: `${override.itemCode}: ${override.itemName}`,
    })),
  );

  const nextUpdateString = nextUpdateDays !== null ? `${nextUpdateDays} day${nextUpdateDays !== 1 ? 's' : ''}` : '';

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const filteredItems = selectedItems.filter(
        (item) =>
          item.uid.trim() !== '' && // Filter empty rows
          item.uid !== descendantItemId, // Filter out the descendant item itself
      );
      const bodyParams: InheritanceOverrideBodyParams = {
        ancestors: filteredItems.map((item) => ({
          ancestorItemId: item.uid,
        })),
      };

      await coreApiFetch(schemas.items.putUpdateItemUsageInheritanceOverrides, {
        pathParams: { itemUid: descendantItemId },
        bodyParams,
      });
      notification.success({
        message: 'Usage Inheritance Updated',
        description: `Usage inheritance set for item ${descendantItemCode}`,
      });
      reloadInheritanceData();
      setSelectedItems(filteredItems);
    } catch (error) {
      captureAndShowError(error, 'Error updating usage inheritance');
    } finally {
      setIsSubmitting(false);
    }
  };

  const columns: ColumnType<ItemOption>[] = [
    {
      title: 'Ancestor Item',
      dataIndex: asKeyOf<ItemOption>('value'),
      render: (value: string | undefined, _row: ItemOption, index: number) =>
        usingRecurrencyInheritance ? (
          <SearchSelect
            size="small"
            className={css`
              width: 100%;
            `}
            searchParams={{
              searchIndex: SearchIndexName.Items,
            }}
            entityPlural="items"
            placeholder="Search items"
            value={value || undefined}
            onSelect={(newValue, option) => {
              const newSelectedItems = [...selectedItems];
              newSelectedItems[index] = {
                uid: option.uid,
                value: newValue,
              };
              setSelectedItems(newSelectedItems);
            }}
          />
        ) : (
          value
        ),
    },
    {
      title: 'Status',
      dataIndex: asKeyOf<ItemOption>('value'),
      width: '200px',
      render: (value: string) => {
        if (!value) {
          return '-';
        }
        const selectedItemCode = splitIdNameStr(value).foreignId;
        const ancestorData = allAncestorData.items.filter((ancestor) => ancestor.itemCode === selectedItemCode)[0];
        return ancestorData ? (
          ancestorData.hasMismatchedBaseUoms ? (
            <FlexSpace alignItems="center" gap={0}>
              Incompatible UOMs{' '}
              <Tooltip title="Cannot inherit usage from this item due to incompatible units of measure.">
                <WarningOutlined
                  className={css`
                    margin-left: 4px;
                    color: ${colors.danger[500]};
                  `}
                />
              </Tooltip>
            </FlexSpace>
          ) : pendingAncestorData.includes(selectedItemCode) ? (
            <FlexSpace alignItems="center" gap={0}>
              Pending
              <InfoTooltip
                title={`Usage will be inherited from this item the next time usage calculations are run in ${nextUpdateString}.`}
              />
            </FlexSpace>
          ) : (
            'Included in Usage'
          )
        ) : (
          'Unsaved'
        );
      },
    },
    usingRecurrencyInheritance && {
      title: 'Actions',
      dataIndex: asKeyOf<ItemOption>('value'),
      width: '80px',
      render: (_value: string, _row: ItemOption, index: number) => (
        <Button
          danger
          size="small"
          onClick={() => {
            const newSelectedItems = [...selectedItems];
            newSelectedItems.splice(index, 1);
            setSelectedItems(newSelectedItems);
          }}
        >
          <Tooltip
            title={`Stop including this item as an ancestor the next time usage calculations are run in ${nextUpdateString}.`}
            placement="left"
          >
            <DeleteOutlined />
          </Tooltip>
        </Button>
      ),
    },
  ].filter(truthy);

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 20px;
      `}
    >
      <div>
        The total usage of the item at this location will be the usage for this item and the combined usage of its
        ancestors.
        {!usingRecurrencyInheritance ? (
          <>
            <br /> Based on your settings, these values are synced from the P21 substitute field and should be managed
            there.
          </>
        ) : null}
      </div>

      <UsageInheritanceDescendantBanner validDescendants={validDescendants} />

      {pendingRemovedAncestorData.length > 0 ? (
        <Alert
          icon={<ExclamationCircleOutlined />}
          message={
            <>
              This item will stop inheriting from{' '}
              {pendingRemovedAncestorData.map((itemCode, index) => (
                <React.Fragment key={itemCode}>
                  <Link target="_blank" to={makePath(routePaths.purchasing.itemDetails, { id: itemCode })}>
                    {itemCode}
                  </Link>
                  {index < pendingRemovedAncestorData.length - 1 ? ', ' : ''}
                </React.Fragment>
              ))}{' '}
              the next time usage calculations are run in {nextUpdateString}.
            </>
          }
          type="warning"
          banner
        />
      ) : null}

      <Table
        isLoading={isLoading}
        columns={columns}
        data={selectedItems}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              description="This item does not inherit usage from any ancestors."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          ),
        }}
      />

      {usingRecurrencyInheritance && (
        <FlexSpace
          justify="flex-end"
          className={css`
            margin-top: 12px;
          `}
        >
          <Button
            size="small"
            icon={<PlusOutlined />}
            onClick={() => setSelectedItems([...selectedItems, { uid: '', value: '' }])}
          >
            Add Ancestor Item
          </Button>
          <Button type="primary" size="small" onClick={handleSubmit} loading={isSubmitting}>
            Save Changes
          </Button>
        </FlexSpace>
      )}
    </div>
  );
};
