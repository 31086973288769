import { ReactNode } from 'react';

import { ColumnType, TableProps as AntTableProps } from 'antd/lib/table';

import { reactNodeToText } from 'utils/react';

type ColumnWithChildren = ColumnType<Any> & { children?: ColumnWithChildren[] };

export const getClassNameFromTitle = (title: ReactNode): string => {
  const titleString = typeof title === 'string' ? title : reactNodeToText(title);
  return titleString
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[-_]+/g, '-')
    .replace(/[^\w-]+/g, '');
};

export const getArrayOfClassNames = (cols: ColumnWithChildren[]): { className: string; isSortable: boolean }[] =>
  cols.reduce<{ className: string; isSortable: boolean }[]>((titles, column) => {
    if (column.children) {
      return titles.concat(getArrayOfClassNames(column.children));
    }
    return titles.concat({
      className: getClassNameFromTitle(column.title),
      isSortable: !!column.sorter,
    });
  }, []);

export const addClassNamesToColumns = (cols: ColumnWithChildren[]): ColumnWithChildren[] =>
  cols.map((column) => {
    if (column.children) {
      return { ...column, children: addClassNamesToColumns(column.children) };
    }
    return {
      ...column,
      className: `${column.className ?? ''} ${getClassNameFromTitle(column.title)}`,
    };
  });

export const getMaxWordLength = (className: string): number =>
  className.split('-').reduce((maxLength, word) => Math.max(maxLength, word.length), 0);

export type GetColumnWidthParams = {
  className: string;
  tableScroll?: AntTableProps<Any>['scroll'];
  isSortable?: boolean;
};

export const getColumnWidth = ({ className, tableScroll, isSortable }: GetColumnWidthParams): number => {
  const maxWordLength = tableScroll?.x === true ? getMaxWordLength(className) : className.length;
  const width = Math.max(maxWordLength < 10 ? maxWordLength * 13 : maxWordLength * 10, 60);
  return tableScroll?.x === true && isSortable ? width + 12 : width;
};
