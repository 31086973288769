import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SupplierTargetTypes } from '@recurrency/core-api-schema/dist/common/enums';
import { Form, notification, Typography } from 'antd';

import { formatTargetValue } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/sidePane/POSummariesPanelContent';
import { TargetTypeSelectOptions } from 'pages/purchasing/utils';

import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { DividerLine } from 'components/DividerLine';
import { responsiveFormLayout } from 'components/FormItems';
import { Input } from 'components/Input';
import { NotificationLink } from 'components/Links';
import { SmallLoader } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { PropertyListItem } from 'components/PropertyListItem';
import { Select } from 'components/Select';
import { Tabs } from 'components/Tabs';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { EMPTY_VALUE_DASHES } from 'utils/formatting';
import { routes } from 'utils/routes';
import { track, TrackEvent } from 'utils/track';

import { SupplierLocationSettingsAuditLog } from './AuditLog/SupplierLocationSettingsAuditLog';
import { LastUpdated } from './LastUpdatedMoment';

export interface SupplierLocationTargetSettings {
  supplierId: string;
  locationId: string;
  targetType: SupplierTargetTypes;
  targetValue: number;
  minTargetType: SupplierTargetTypes;
  minTargetValue: number;
  reviewCycleDays: number;
}

export interface SupplierLocationSettingsModalProps {
  supplierId: string;
  locationId: string;
  onClose: (newSettings?: SupplierLocationTargetSettings) => void;
}

interface TargetFormData {
  targetType?: SupplierTargetTypes;
  targetValue?: number;
  minTargetType?: SupplierTargetTypes;
  minTargetValue?: number;
  reviewCycleDays?: number;
}

export function SupplierLocationSettingsModal({ supplierId, locationId, onClose }: SupplierLocationSettingsModalProps) {
  const [supplierLocationSettingForm] = Form.useForm<TargetFormData>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Form data is used for tracking to show warnings
  const [formData, setFormData] = useState<TargetFormData>();

  const {
    data,
    isLoading,
    setData: setSettingsData,
  } = useCoreApi(schemas.purchasing.getSupplierLocationSettingsV2, {
    queryParams: {
      supplierLocationIds: [`${supplierId}|${locationId}`],
    },
  });

  const settingsData = data?.items.find(
    (setting) => setting.supplierId === supplierId && setting.locationId === locationId,
  );

  // fill in form data from api response
  useEffect(() => {
    if (settingsData) {
      // hide NOT_SET from selectable options
      const filteredSettingsData = {
        targetType: settingsData.targetType !== SupplierTargetTypes.NotSet ? settingsData.targetType : undefined,
        targetValue: settingsData.targetValue,
        minTargetType: settingsData.minTargetType,
        minTargetValue: settingsData.minTargetValue,
        reviewCycleDays: settingsData.reviewCycleDays,
      };
      supplierLocationSettingForm.setFieldsValue(filteredSettingsData);
      setFormData(filteredSettingsData);
    }
  }, [settingsData, supplierLocationSettingForm]);

  async function handleSubmit(data: TargetFormData) {
    try {
      setIsSubmitting(true);
      const newSettings: SupplierLocationTargetSettings = {
        supplierId,
        locationId,
        targetType: (Number(data.targetValue) === 0
          ? SupplierTargetTypes.NotSet
          : data.targetType) as SupplierTargetTypes,
        targetValue: Number(data.targetValue),
        minTargetType: (data.minTargetValue && Number(data.minTargetValue) === 0
          ? SupplierTargetTypes.NotSet
          : data.minTargetType) as SupplierTargetTypes,
        minTargetValue: Number(data.minTargetValue),
        reviewCycleDays: Number(data.reviewCycleDays),
      };

      await coreApiFetch(schemas.purchasing.patchSupplierLocationSettings, { bodyParams: newSettings });
      notification.success({
        key: supplierId,
        message: (
          <>
            <div>Updated target & review settings</div>
            <NotificationLink
              notificationKey={supplierId}
              to={routes.purchasing.purchaseTargets({
                supplierIds: [supplierId],
                locationIds: [locationId],
              })}
            >
              View targets for Supplier #{supplierId} at Location #{locationId}
            </NotificationLink>
          </>
        ),
        duration: 5,
      });
      // update api response data after patch
      setSettingsData({ items: [{ ...settingsData, ...newSettings }], totalCount: 1 });
      if (!!newSettings.minTargetType && !!newSettings.minTargetValue) {
        track(TrackEvent.SupplierMinimums_UpdateValues, { updateLocation: 'SupplierLocationSettingsModal' });
      }
      track(TrackEvent.Purchasing_SetSupplierLocationTarget_Submit, newSettings);
      onClose(newSettings);
    } catch (err) {
      captureAndShowError(err, 'Error updating settings', {
        notificationKey: supplierId,
        duration: 0,
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  // Only show warning if all values are set and the flag is enabled
  const minLessThanTargetWarning =
    formData?.minTargetValue &&
    formData.minTargetType &&
    formData.targetType &&
    formData.targetValue &&
    formData.minTargetType === formData.targetType &&
    formData.minTargetValue > formData.targetValue;

  return (
    <Modal
      visible
      title={`Edit Settings for Supplier #${supplierId} at Location #${locationId}`}
      onCancel={() => onClose()}
      centered
      width={900}
      footer={
        <>
          <Button key="cancel" onClick={() => onClose()}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            form="supplierLocationSettingForm"
            htmlType="submit"
            disabled={isLoading}
            loading={isSubmitting}
          >
            Set Target
          </Button>
        </>
      }
    >
      <>
        {isLoading ? (
          <SmallLoader />
        ) : (
          <Container>
            <LastUpdated
              entityName="Settings"
              dateString={settingsData?.updatedAt}
              showIcon
              className={css`
                margin-bottom: 24px;
              `}
            />

            <Form.Provider>
              <Form
                name="supplierLocationSettingForm"
                form={supplierLocationSettingForm}
                onFinish={handleSubmit}
                onError={console.error}
                onFinishFailed={console.error}
                {...responsiveFormLayout}
              >
                <>
                  <Typography.Title level={5}>Target Value</Typography.Title>
                  <Typography.Paragraph>
                    Recurrency will mark a supplier “Ready for Review” when the line item requirements sum to this
                    threshold. Recurrency also uses this target to recommend item quantity changes in PO Auto Fill and
                    Auto Trim. This target is typically used to reflect a supplier’s pre-paid freight threshold, minimum
                    order quantity, full container volume, or other purchasing goal.
                  </Typography.Paragraph>
                  <div className={optionWrapperCss}>
                    <div className={valuesWrapperCss}>
                      <PropertyListItem
                        label="Current Target Value"
                        value={
                          settingsData?.targetType && settingsData?.targetType !== SupplierTargetTypes.NotSet
                            ? formatTargetValue(settingsData.targetType, settingsData.targetValue ?? 0)
                            : EMPTY_VALUE_DASHES
                        }
                      />
                      <PropertyListItem
                        label="New Target Value"
                        value={
                          <Form.Item
                            className={noMarginCss}
                            name="targetValue"
                            rules={[
                              {
                                required: true,
                                message: 'Target value is required.',
                              },
                            ]}
                            validateStatus={minLessThanTargetWarning ? 'warning' : undefined}
                            hasFeedback={!!minLessThanTargetWarning}
                            help={
                              minLessThanTargetWarning
                                ? 'Target value should be greater than or equal to minimum value.'
                                : undefined
                            }
                          >
                            <Input
                              type="number"
                              min={0}
                              onChange={(ev) => setFormData({ ...formData, targetValue: Number(ev.target.value) })}
                            />
                          </Form.Item>
                        }
                      />
                    </div>
                    <div className={valuesWrapperCss}>
                      <PropertyListItem
                        label="Current Target Type"
                        value={
                          settingsData?.targetType && settingsData?.targetType !== SupplierTargetTypes.NotSet
                            ? settingsData.targetType.toUpperCase()
                            : EMPTY_VALUE_DASHES
                        }
                      />
                      <PropertyListItem
                        label="New Target Type"
                        value={
                          <Form.Item
                            className={noMarginCss}
                            name="targetType"
                            rules={[
                              {
                                required: true,
                                message: 'Target type is required.',
                              },
                            ]}
                          >
                            <Select
                              options={TargetTypeSelectOptions}
                              onChange={(option) => setFormData({ ...formData, targetType: option })}
                            />
                          </Form.Item>
                        }
                      />
                    </div>
                  </div>

                  <DividerLine />

                  <Typography.Title level={5}>Supplier Minimum</Typography.Title>
                  <Typography.Paragraph>
                    When creating purchase orders for this supplier and location, Recurrency will show a warning if the
                    supplier minimum isn’t reached after changing the item quantities.
                  </Typography.Paragraph>
                  <div className={optionWrapperCss}>
                    <div className={valuesWrapperCss}>
                      <PropertyListItem
                        label="Current Minimum Value"
                        value={
                          settingsData?.minTargetValue
                            ? formatTargetValue(
                                settingsData?.minTargetType ?? SupplierTargetTypes.NotSet,
                                settingsData.minTargetValue,
                              )
                            : EMPTY_VALUE_DASHES
                        }
                      />
                      <PropertyListItem
                        label="New Minimum Value"
                        value={
                          <Form.Item
                            className={noMarginCss}
                            name="minTargetValue"
                            validateStatus={minLessThanTargetWarning ? 'warning' : undefined}
                            hasFeedback={!!minLessThanTargetWarning}
                            help={
                              minLessThanTargetWarning
                                ? 'Minimum value should be less than or equal to target value.'
                                : undefined
                            }
                          >
                            <Input
                              type="number"
                              min={0}
                              onChange={(ev) => setFormData({ ...formData, minTargetValue: Number(ev.target.value) })}
                            />
                          </Form.Item>
                        }
                      />
                    </div>
                    <div className={valuesWrapperCss}>
                      <PropertyListItem
                        label="Current Minimum Type"
                        value={
                          settingsData?.minTargetType ? settingsData.minTargetType.toUpperCase() : EMPTY_VALUE_DASHES
                        }
                      />
                      <PropertyListItem
                        label="New Minimum Type"
                        value={
                          <Form.Item className={noMarginCss} name="minTargetType">
                            <Select
                              options={TargetTypeSelectOptions}
                              onChange={(option) => setFormData({ ...formData, minTargetType: option })}
                            />
                          </Form.Item>
                        }
                      />
                    </div>
                  </div>

                  <DividerLine />

                  <Typography.Title level={5}>Review Cycle</Typography.Title>
                  <Typography.Paragraph>
                    Recurrency will mark a supplier “Ready for Review” based on this configurable review cadence. Every
                    time a PO is created in Recurrency or a supplier is “Marked as Reviewed,” the cycle will reset to
                    begin on that day.
                  </Typography.Paragraph>
                  <div className={valuesWrapperCss}>
                    <PropertyListItem label="Current Review Cycle (days)" value={settingsData?.reviewCycleDays} />
                    <PropertyListItem
                      label="New Review Cycle (days)"
                      value={
                        <Form.Item
                          className={noMarginCss}
                          name="reviewCycleDays"
                          rules={[
                            {
                              required: true,
                              message: 'Review cycle days required.',
                            },
                          ]}
                        >
                          <Input type="number" min={0} />
                        </Form.Item>
                      }
                    />
                  </div>
                </>
              </Form>
            </Form.Provider>
          </Container>
        )}
        <DividerLine />
        <Tabs
          tabs={[
            {
              header: 'Audit Log',
              infoTooltip: `All updates for Supplier #${supplierId} at Location #${locationId}`,
              content: <SupplierLocationSettingsAuditLog locationId={locationId} supplierId={supplierId} />,
            },
          ]}
        />
      </>
    </Modal>
  );
}

/// /// shared styles //////

const optionWrapperCss = css`
  display: flex;

  & > * {
    flex: 1;
  }
`;

const valuesWrapperCss = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const noMarginCss = css`
  margin: 0;
`;
