import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TransferThresholdSettings } from '@recurrency/core-api-schema/dist/common/enums';
import {
  LocationSettingsDTO,
  LocationSettingUpdate,
  UpdateLocationSettingsType,
} from '@recurrency/core-api-schema/dist/locations/locationSettings';
import { notification } from 'antd';
import { ColumnType } from 'antd/lib/table';

import { useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { AsyncButton } from 'components/Button/AsyncButton';
import { Container } from 'components/Container';
import { ExternalLink } from 'components/Links';
import { PageHeader } from 'components/PageHeader';
import { Select } from 'components/Select';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { splitIdNameStr } from 'utils/formatting';
import { asKeyOf, typedColumn } from 'utils/tables';

const TransferThresholdSettingsLabels: Record<TransferThresholdSettings, string> = {
  [TransferThresholdSettings.AboveMax]: 'Above Max',
  [TransferThresholdSettings.AboveMin]: 'Above Min',
  [TransferThresholdSettings.NetStock]: 'Net Stock',
};

export const LocationSettingsPage = () => {
  const [changedSettings, setChangedSettings] = useState<LocationSettingsDTO[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const tableProps = useCoreApiTableProps({
    schema: schemas.locations.getLocationSettings,
  });

  const locationsSelectProps = useLocationsSelectProps({ sortByValueProp: true });

  // combine the data from coreApi and the changed settings
  const tableData = useMemo(() => {
    if (locationsSelectProps.options && tableProps.items) {
      // use the options from the locations select to go through all locations
      return locationsSelectProps.options?.map((option) => {
        // first see if there are any changed settings for this location
        const thresholdValue = changedSettings.find(
          (setting) => setting.locationId === splitIdNameStr(option.value).foreignId,
        )?.transferThreshold;
        // if not, use the value from the coreApi data, or default to net stock
        return {
          locationId: splitIdNameStr(option.value).foreignId,
          transferThreshold:
            thresholdValue ??
            (tableProps.items.find((item) => item.locationId === splitIdNameStr(option.value).foreignId)
              ?.transferThreshold ||
              TransferThresholdSettings.NetStock),
        } as LocationSettingsDTO;
      });
    }
    return [];
  }, [locationsSelectProps.options, tableProps.items, changedSettings]);

  // either update the changed row or add a new changed row
  const onChange = (locationId: string, transferThreshold: TransferThresholdSettings) => {
    const index = changedSettings.findIndex((setting) => setting.locationId === locationId);
    if (index === -1) {
      setChangedSettings((prev) => [...prev, { locationId, transferThreshold }]);
    } else {
      setChangedSettings((prev) => {
        const newSettings = [...prev];
        newSettings[index] = { ...newSettings[index], transferThreshold };
        return newSettings;
      });
    }
  };

  // only save the changed rows to the server
  const onSave = async () => {
    try {
      setIsSaving(true);
      const updateSettings: LocationSettingUpdate[] = changedSettings.map((setting) => ({
        locationId: setting.locationId,
        updateValue: setting.transferThreshold,
      }));

      await coreApiFetch(schemas.locations.setLocationSettings, {
        pathParams: {
          updateType: UpdateLocationSettingsType.TransferThreshold,
        },
        bodyParams: {
          updates: updateSettings,
        },
      });

      notification.success({
        message: 'Settings Saved Successfully',
        description: 'Recurrency will recalculate transfers over the next 5-10 minutes.',
      });
    } catch (e) {
      captureAndShowError(e, 'Error saving settings, please try again later.', {
        duration: 0,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const lineItemColumns: ColumnType<LocationSettingsDTO>[] = [
    typedColumn({
      title: 'Location ID',
      dataIndex: asKeyOf<LocationSettingsDTO>('locationId'),
      render: (val) => (
        <div
          className={css`
            margin-right: 24px;
          `}
        >
          {locationsSelectProps.options?.find((option) => splitIdNameStr(option.value).foreignId === val)?.value ?? val}
        </div>
      ),
    }),
    typedColumn({
      title: (
        <InfoTooltip title="The threshold used to limit how much inventory should be transferred, ensuring a buffer remains at this location">
          Transfer Threshold
        </InfoTooltip>
      ),
      dataIndex: asKeyOf<LocationSettingsDTO>('transferThreshold'),
      render: (val, row: LocationSettingsDTO) => (
        <div
          className={css`
            margin-right: 24px;
          `}
        >
          <Select
            placeholder="Select a transfer threshold"
            className={css`
              width: 100%;
            `}
            options={Object.values(TransferThresholdSettings).map((settingKey: string) => ({
              label: TransferThresholdSettingsLabels[settingKey as TransferThresholdSettings],
              value: settingKey as TransferThresholdSettings,
            }))}
            value={val as TransferThresholdSettings}
            onChange={(newVal) => {
              onChange(row.locationId, newVal);
            }}
          />
        </div>
      ),
    }),
  ];

  return (
    <Container>
      <PageHeader
        title="Location Settings"
        headerActions={
          <AsyncButton type="primary" onClick={onSave} disabled={changedSettings.length === 0} loading={isSaving}>
            Save Settings
          </AsyncButton>
        }
      />
      <p>
        The following locations settings are used to determine the maximum amount that should be recommended to transfer
        when transferring from a location. Learn more about how this impacts transfer replenishment recommendations{' '}
        <ExternalLink to="https://help.recurrency.com/hc/en-us/articles/34688224177691-Transfer-Replenishment">
          here
        </ExternalLink>
        .
      </p>
      <AsyncTable
        tableProps={{ ...tableProps, items: tableData }}
        columns={lineItemColumns}
        pagination={false}
        size="small"
      />
    </Container>
  );
};
