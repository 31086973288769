import React, { useEffect, useState } from 'react';

import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import moment from 'moment';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceArea,
  TooltipProps,
} from 'recharts';

import { FORECAST_COLOR, USAGE_COLOR, INHERITED_COLOR } from 'pages/purchasing/utils';

import { Button } from 'components/Button';
import { FlexSpace } from 'components/FlexSpace';
import { Typography } from 'components/Typography';

import { capitalize, formatNumber } from 'utils/formatting';

export type PerfChartPoint = Obj<string | number>;

export enum ForecastDataType {
  Usage = 'usage',
  Forecast = 'forecast',
}
export interface ForecastChartData {
  date: number;
  unitOfMeasure: string;
  usage?: number;
  inherited?: number;
  forecast?: number;
  type: ForecastDataType;
  connection: number | undefined;
}

type ZoomState = {
  selectedLeft: number | null;
  selectedRight: number | null;
  left: number;
  right: number;
  data: ForecastChartData[];
};

function calculateChartDomain(zoomedData: ForecastChartData[]): [number, number] {
  const minValue = Math.min(
    0,
    ...zoomedData.map((val) => {
      if (val.type === ForecastDataType.Forecast) {
        return val.forecast ?? 0;
      }
      return Math.min(val.usage ?? 0, val.inherited ?? 0);
    }),
  );

  const maxValue = Math.max(
    ...zoomedData.map((val) => {
      if (val.type === ForecastDataType.Forecast) {
        return val.forecast ?? 0;
      }
      return Math.max(val.usage ?? 0, val.inherited ?? 0);
    }),
  );

  const paddedMaxValue = maxValue * 1.1;

  return [minValue, paddedMaxValue];
}

function hasInheritedUsage(data: ForecastChartData[]): boolean {
  return data.some((point) => point.inherited !== undefined && point.inherited !== 0);
}

export const ForecastChart = ({ data }: { data: ForecastChartData[] }) => {
  const initialState: ZoomState = {
    selectedLeft: null,
    selectedRight: null,
    // Because the data comes back always as at least 12 months of history and 18 months of
    // forecasts we index from the back of the array to get 3 months of forecast and 12 months
    // of history
    left: data[data.length - 30].date,
    right: data[data.length - 16].date,
    data,
  };

  const zoomedOutState: ZoomState = {
    selectedLeft: null,
    selectedRight: null,
    left: data[0].date,
    right: data[data.length - 1].date,
    data,
  };
  const [zoomState, setZoomState] = useState(initialState);

  useEffect(() => {
    setZoomState((oldState) => ({
      ...oldState,
      data,
    }));
  }, [data]);

  function zoom() {
    let { selectedLeft, selectedRight } = zoomState;

    if (selectedLeft === selectedRight || selectedLeft === null || selectedRight === null) {
      setZoomState(() => ({
        ...zoomState,
        selectedLeft: null,
        selectedRight: null,
      }));
      return;
    }

    // Switch left and right if they're on opposite sides
    if (selectedLeft > selectedRight) {
      [selectedLeft, selectedRight] = [selectedRight, selectedLeft];
    }

    setZoomState(() => ({
      selectedLeft: null,
      selectedRight: null,
      left: selectedLeft as number,
      right: selectedRight as number,
      data: zoomState.data.slice(),
    }));
  }

  let ticks;
  const zoomedData = zoomState.data.slice(
    zoomState.data.map((value) => value.date).indexOf(zoomState.left),
    zoomState.data.map((value) => value.date).indexOf(zoomState.right) + 1,
  );
  if (zoomedData.length <= 13) {
    ticks = zoomedData.map((value) => value.date);
  } else {
    const allowedMonths = zoomedData.length > 37 ? [0] : zoomedData.length > 25 ? [0, 6] : [0, 3, 6, 9];
    ticks = zoomedData
      .filter((value) => allowedMonths.includes(moment(value.date).get('M')))
      .map((value) => value.date);
  }

  return (
    <div>
      <div
        className={css`
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 32px;
          margin-bottom: 8px;
        `}
      >
        {zoomState.left === zoomedOutState.left && zoomState.right === zoomedOutState.right ? (
          <FlexSpace gap={4}>
            <ZoomInOutlined /> Click and drag to zoom in
          </FlexSpace>
        ) : (
          <Button onClick={() => setZoomState(zoomedOutState)} size="small">
            <ZoomOutOutlined /> Zoom Out
          </Button>
        )}
      </div>
      <ResponsiveContainer
        width="100%"
        height={250}
        debounce={1}
        className={css`
          user-select: none;
        `}
      >
        <AreaChart
          // @ts-expect-error Recharts typing doesn't include cursor even though it's supported
          cursor="crosshair"
          data={zoomState.data}
          onMouseDown={(e) =>
            // If there already is a selected left then the user has gone off the screen, so we should zoom
            zoomState.selectedLeft
              ? zoom()
              : e?.activeLabel && setZoomState({ ...zoomState, selectedLeft: e.activeLabel })
          }
          onMouseMove={(e) =>
            zoomState.selectedLeft && e.activeLabel && setZoomState({ ...zoomState, selectedRight: e.activeLabel })
          }
          onMouseUp={zoom}
        >
          <defs>
            <linearGradient id="usageGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" startOffset={50} stopColor={USAGE_COLOR} stopOpacity={0.35} />
              <stop offset="95%" stopColor={USAGE_COLOR} stopOpacity={0.05} />
            </linearGradient>
            <linearGradient id="forecastGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={FORECAST_COLOR} stopOpacity={0.35} />
              <stop offset="95%" stopColor={FORECAST_COLOR} stopOpacity={0.05} />
            </linearGradient>
            {hasInheritedUsage(zoomState.data) && (
              <linearGradient id="inheritedGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={INHERITED_COLOR} stopOpacity={0.35} />
                <stop offset="95%" stopColor={INHERITED_COLOR} stopOpacity={0.05} />
              </linearGradient>
            )}
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            dataKey="date"
            domain={[zoomState.left, zoomState.right]}
            tickFormatter={(value) => moment(value).format("MMM 'YY")}
            ticks={ticks}
            scale="time"
            allowDataOverflow
          />
          <YAxis
            type="number"
            tickFormatter={formatNumber}
            domain={calculateChartDomain(zoomedData)}
            allowDataOverflow
          />

          <Tooltip
            labelFormatter={(value) => moment(value).format('MMMM YYYY')}
            formatter={(value) => formatNumber(Number(value))}
            content={(e: TooltipProps) => {
              // We render a custom tooltip to remove the connection point between usage and forecasted data
              const payload = e.payload?.filter((payload) => payload.name !== 'connection');
              if (e.active && payload && payload.length) {
                const isUsage = payload[0].payload.type === ForecastDataType.Usage;

                return (
                  <div
                    className={css`
                      background-color: rgba(255, 255, 255, 0.9);
                      font-weight: 500;
                      border-radius: 8px;
                      border: 2px solid ${payload[0].color};
                    `}
                  >
                    <div
                      className={css`
                        padding: 6px;
                      `}
                    >
                      <Typography style={{ fontWeight: 'bold' }}>{isUsage ? 'Usage' : 'Forecast'}</Typography>
                      <Typography>{moment(payload[0].payload.date).format('MMM YYYY')}</Typography>
                      {isUsage && hasInheritedUsage(zoomState.data) ? (
                        <>
                          <Typography>
                            Total: {formatNumber(payload[0].payload.usage)} {payload[0].payload.unitOfMeasure}
                          </Typography>
                          <Typography>
                            Inherited: {formatNumber(payload[0].payload.inherited)} {payload[0].payload.unitOfMeasure}
                          </Typography>
                        </>
                      ) : (
                        <Typography>
                          {formatNumber(payload[0].value as number)} {payload[0].payload.unitOfMeasure}
                        </Typography>
                      )}
                    </div>
                  </div>
                );
              }

              return null;
            }}
          />
          <Legend formatter={capitalize} />

          <Area
            dataKey={(a) => a.connection}
            name="connection"
            activeDot={false}
            stroke={FORECAST_COLOR}
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#forecastGradient)"
            strokeDasharray="3 3"
            legendType="none"
            isAnimationActive={false}
          />
          <Area
            dataKey={ForecastDataType.Usage}
            stroke={USAGE_COLOR}
            strokeWidth={2}
            fillOpacity={1}
            fill="url(#usageGradient)"
            isAnimationActive={false}
          />
          <Area
            dataKey={ForecastDataType.Forecast}
            stroke={FORECAST_COLOR}
            strokeWidth={2}
            fillOpacity={1}
            strokeDasharray="3 3"
            fill="url(#forecastGradient)"
            isAnimationActive={false}
          />
          {hasInheritedUsage(zoomState.data) && (
            <Area
              dataKey="inherited"
              stroke={INHERITED_COLOR}
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#inheritedGradient)"
              isAnimationActive={false}
            />
          )}
          {zoomState.selectedLeft && zoomState.selectedRight ? (
            <ReferenceArea x1={zoomState.selectedLeft} x2={zoomState.selectedRight} strokeOpacity={0.3} />
          ) : null}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
