import React from 'react';

import { useHistory } from 'react-router-dom';

import { CheckOutlined } from '@ant-design/icons';
import { schemas } from '@recurrency/core-api-schema';
import { notification } from 'antd';

import { ActionButton } from 'components/Button/ActionButton';
import { AsyncButton } from 'components/Button/AsyncButton';
import { FlexSpace } from 'components/FlexSpace';
import { Tooltip } from 'components/Tooltip';

import { coreApiFetch } from 'utils/api';
import { captureAndShowError } from 'utils/error';
import { pluralize } from 'utils/formatting';

export function MarkSourceDestinationsAsReviewedButton({
  sourceDestinations,
  smallVariant = false,
  onComplete,
}: {
  sourceDestinations: Array<{ sourceLocationId: string; destinationLocationId: string }>;
  smallVariant?: boolean;
  onComplete?: () => void;
}) {
  const history = useHistory();
  const isSingleSourceDestination = sourceDestinations.length === 1;
  const notificationKey = 'reviewed-notif';
  const { sourceLocationId, destinationLocationId } = sourceDestinations[0];

  const handleMarkAsReviewedClick = async () => {
    try {
      const updateResults = await coreApiFetch(schemas.transferOrders.patchLocationTransferSettings, {
        bodyParams: {
          sourceDestinationIds: sourceDestinations.map(
            (element) => `${element.sourceLocationId}|${element.destinationLocationId}`,
          ),
          reviewedAt: new Date().toISOString(),
        },
      }).then(() => sourceDestinations);
      const notificationText =
        updateResults.length === 1
          ? `Marked Source Location #${sourceLocationId} to Destination Location #${destinationLocationId} as reviewed`
          : `Marked ${pluralize(updateResults.length, 'Source-Destination', 'Source-Destinations', true)} as reviewed`;
      notification.success({
        key: notificationKey,
        message: (
          <FlexSpace direction="column">
            <span>{notificationText}</span>
            <ActionButton
              onClick={() => {
                history.goBack();
                notification.close(notificationKey);
              }}
              label="Back to Transfer Replenishment"
            />
          </FlexSpace>
        ),
        duration: 5,
      });
      if (onComplete) {
        onComplete();
      }
    } catch (err) {
      captureAndShowError(err, 'Failed to mark source destinations as reviewed', {
        notificationKey,
      });
    }
  };
  return (
    <Tooltip
      title={
        isSingleSourceDestination
          ? `Mark Source Location #${sourceLocationId} to Destination Location #${destinationLocationId} as reviewed as of today`
          : `Mark ${pluralize(sourceDestinations.length, 'location pair', 'location pairs', true)} reviewed as of today`
      }
    >
      <AsyncButton
        icon={<CheckOutlined />}
        size={smallVariant ? 'small' : 'middle'}
        onClick={handleMarkAsReviewedClick}
      >
        {isSingleSourceDestination ? (smallVariant ? 'Mark Reviewed' : 'Mark as Reviewed') : 'Mark All as Reviewed'}
      </AsyncButton>
    </Tooltip>
  );
}
