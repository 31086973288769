import { WatchAsyncJobNotification } from '@recurrency/core-api-schema/dist/asyncJobs/common';
import { Paging } from '@recurrency/core-api-schema/dist/common/enums';
import { EndpointSchema } from '@recurrency/core-api-schema/dist/utils/apiSchema';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import queryString from 'query-string';
import urltron from 'urltron';

import { getGlobalAppState, globalAppStore } from 'hooks/useGlobalApp';

import { AppEnv, env } from 'utils/env';

import { arrLastElement } from './array';
import { captureError, HttpError } from './error';
import { objGet, objSet } from './object';
import { track, TrackEvent } from './track';

// Default request timeout, in milliseconds
// 7 minutes was chosen to be longer than core api timeout, so core api initiates the timeout with a useful error message when possible
axios.defaults.timeout = 1000 /* ms per second */ * 60 /* seconds per minute */ * 7 /* minutes */;

function getCoreApiHeaders() {
  const { sessionId, activeTenant, activeErpRole, accessToken } = globalAppStore.state;

  return activeTenant && activeErpRole
    ? {
        'x-session-id': sessionId,
        'x-current-tenant': activeTenant.id,
        'x-current-role-name': activeErpRole.name,
        'x-current-role-id': activeErpRole.foreignId,
        authorization: `Bearer ${accessToken}`,
      }
    : { 'x-session-id': sessionId, authorization: `Bearer ${accessToken}` };
}

function replacePathParams(urlPath: string, extraPathParams?: Obj<string>): string {
  const { activeTenant } = globalAppStore.state;
  /** extraPathParams must be last so that tenantId can be overwritten if needed */
  const pathParams: Obj<string> = {
    tenantId: activeTenant.id,
    ...extraPathParams,
  };

  for (const [paramName, paramValue] of Object.entries(pathParams)) {
    urlPath = urlPath.replace(`:${paramName}`, encodeURIComponent(paramValue));
  }

  return urlPath;
}

export type ApiFunction = (...args: any[]) => Promise<AxiosResponse<any>>;

/**
 * pendingApiPromises is a singleton that keeps track of pending network requests
 * AppVersionPoller uses this to ensure not to do a page refresh, until all network requests have completed
 */
export const pendingApiPromises: Set<Promise<AxiosResponse<any>>> = new Set();

function getReqTrackingProps(req: AxiosRequestConfig) {
  // we add GET/POST method to disambiguate which corresponding core-api endpoint was called
  const reqMethod = req.method?.toUpperCase();
  let reqUrl = req.url || '';
  const fullUrlMatch = reqUrl.match(/^https?:\/\/[^/]+\/(.*)/);
  if (fullUrlMatch) {
    // eslint-disable-next-line prefer-destructuring
    reqUrl = fullUrlMatch[1];
  }

  if (!reqUrl.startsWith('/')) {
    reqUrl = `/${reqUrl}`;
  }

  // strip out query params, and replace numeric and guid params with :id
  const reqRoutePath = reqUrl
    .replace(/\?.*/g, '')
    .replace(/^\/api\//g, '/')
    .replace(/\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}|[0-9]+)(\/|$)/g, '/:id$2')
    .replace(
      /\/tenants\/:id\/(items|customers|ml\/recommended-items-for-customer|ml\/recommended-customers-for-item)\/[^/]+(\/|$)/g,
      '/tenants/:id/$1/:id$2',
    )
    // item and customer ids can be url encoded strings, so handle those paths as special case
    .replace(/^\/legacy\/v3\/items\/item\/[^/]+(\/|$)/, '/legacy/v3/items/item/:id$1')
    .replace(/^\/legacy\/v3\/customers\/[^/]+(\/|$)/, '/legacy/v3/customers/:id$1')
    // payment-methods use stripe ids
    .replace(/\/tenants\/:id\/payment-methods\/[^/]+(\/|$)/, '/legacy/v3/customers/:id$1');

  return {
    reqUrl: `${reqMethod} ${reqUrl}`,
    reqRoutePath: `${reqMethod} ${reqRoutePath}`,
  };
}

function getResTrackingProps(res: AxiosResponse, reqStartTime: number) {
  const resDurationMs = Math.round(new Date().getTime() - reqStartTime);
  const serverTimeMsStr = res.headers['server-timing']?.match(/\btotal; dur=([0-9.]+);/)?.[1];
  const resServerRequestIdHeader = res.headers['x-request-id'];
  const resServerDurationMs = serverTimeMsStr ? Math.round(parseFloat(serverTimeMsStr)) : undefined;

  return {
    resStatusCode: String(res.status),
    resDurationMs,
    resServerDurationMs,
    resServerRequestIdHeader,
  };
}

function wrapErrorHandler<ApiFnT extends ApiFunction>(apiFn: ApiFnT) {
  return function callAndHandleError<DataT>(...args: Parameters<ApiFnT>): Promise<AxiosResponse<DataT>> {
    const reqStartTime = new Date().getTime();

    // @ts-expect-error - force cast unknown to DataT
    const apiPromise: Promise<AxiosResponse<DataT>> = apiFn(...args)
      .then((response: AxiosResponse<DataT>) => {
        track(TrackEvent.Api_Response, {
          ...getReqTrackingProps(response.config),
          ...getResTrackingProps(response, reqStartTime),
        });
        return response;
      })
      .catch((error: AxiosError) => {
        const resDurationMs = new Date().getTime() - reqStartTime;
        const reqTrackingProps = getReqTrackingProps(error.config);

        if (error.response) {
          const { response } = error;
          const resTrackingProps = getResTrackingProps(response, reqStartTime);
          const httpError = new HttpError(
            response.status,
            response.data?.message ?? error.message,
            resTrackingProps.resServerRequestIdHeader,
          );
          httpError.details = [
            [
              `request: ${reqTrackingProps.reqUrl}`,
              `status: ${response.status}`,
              `duration: ${resDurationMs}ms`,
              `x-request-id: ${resTrackingProps.resServerRequestIdHeader}`,
            ].join(', '),
            ...(Array.isArray(response?.data?.details) ? response.data.details : []),
          ];

          track(TrackEvent.Api_Error, {
            ...reqTrackingProps,
            ...resTrackingProps,
            resErrorMessage: [httpError.message, ...httpError.details].join('\n'),
          });

          // user's token expired, re-login
          if (httpError.statusCode === /* Unauthorized */ 401 && httpError.message === 'Unauthorized') {
            // re-login redirect is asynchronous. return never resolving promise so UI stays in loading state
            // otherwise UI will render with undefined data, and that will throw another error.
            globalAppStore.state.loginWithRedirect();
            return new Promise(() => {});
          }

          captureError(httpError);
          throw httpError;
        } else if (error.message === 'Network Error') {
          // 'Network Error' corresponds to net:ERR_INTERNET_DISCONNECTED
          const httpError = new HttpError(
            /* Request Timeout */ 408,
            'NetworkError: Failed to connect to Recurrency services, please try again.',
          );
          httpError.details = [`request: ${reqTrackingProps.reqUrl}, duration: ${resDurationMs}ms`];

          if (window.navigator.onLine) {
            track(TrackEvent.Api_Error, {
              ...reqTrackingProps,
              resStatusCode: String(httpError.statusCode),
              resDurationMs: Math.round(resDurationMs),
              resErrorMessage: [httpError.message, ...httpError.details].join('\n'),
            });
          }
          captureError(httpError);
          throw httpError;
        }

        throw error;
      })
      .finally(() => {
        pendingApiPromises.delete(apiPromise);
      });

    pendingApiPromises.add(apiPromise);
    return apiPromise;
  };
}

/** axios.request wrapped with error handling */
const axiosRequest = wrapErrorHandler(axios.request);

/**
 * pass GET requests thorugh /api to avoid CORS 100ms roundtrip.
 * but not POST/PATCH/DELETE requests since they can take longer than 28s which exceeds netlify CDN request limit.
 */
export function getBaseUrl(method: string) {
  const baseURL =
    method === 'GET' &&
    (env.APP_ENV === AppEnv.Staging || env.APP_ENV === AppEnv.Production || env.APP_ENV === AppEnv.PreProduction) &&
    /api(-staging|-preproduction)?.recurrency.com$/.test(env.CORE_API_URL)
      ? '/api'
      : env.CORE_API_URL;
  return baseURL;
}

export async function legacyApiFetch<DataT>(
  url: string,
  options: {
    method: 'GET' | 'POST';
    data?: Obj;
  },
): Promise<AxiosResponse<DataT>> {
  const { method = 'GET', data } = options || {};
  const headers = {
    ...getCoreApiHeaders(),
  };

  if (method === 'GET') {
    if (data && Object.keys(data).length > 0) {
      url = `${url}?${queryString.stringify(data)}`;
    }
  }

  const response = await axiosRequest<DataT>({
    baseURL: getBaseUrl(method),
    url: `legacy${url}`,
    method,
    headers,
    responseType: 'json',
    data: method !== 'GET' ? data : undefined,
  });

  // auto camelCase legacy-api responses
  if (response.data) {
    response.data = camelcaseKeys(response.data, { deep: true });
  }

  return response;
}

/**
 * As we slowly move away from using openapi schema generated client,
 * coreApiFetch serves as a thin layer over axios
 */
export function coreApiFetch<EndpointSchemaT extends EndpointSchema>(
  schema: EndpointSchemaT,
  options?: {
    queryParams?: InstanceType<EndpointSchemaT['queryParams']>;
    pathParams?: Omit<InstanceType<EndpointSchemaT['pathParams']>, 'tenantId' | 'erpType'> &
      Partial<Pick<InstanceType<EndpointSchemaT['pathParams']>, 'tenantId' | 'erpType'>>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    bodyParams?: EndpointSchemaT['requestBody'] extends object
      ? InstanceType<EndpointSchemaT['requestBody']>
      : undefined;
  },
): Promise<AxiosResponse<InstanceType<EndpointSchemaT['responseBody']>>> {
  const { activeCompanyIds } = getGlobalAppState();
  const { queryParams = {}, pathParams, bodyParams } = options || {};
  const { method, endpoint } = schema;
  let url = endpoint;
  const headers = getCoreApiHeaders();

  // don't add x-tenant-id header if :tenantId is part of url path params
  if (/\/:tenantId\b/.test(url)) {
    delete headers['x-current-tenant'];
  }

  // set companyIds filter if not already set and user has activeCompanyIds
  if (
    activeCompanyIds &&
    schema.companyIdsFilterPath &&
    arrLastElement(schema.companyIdsFilterPath) === 'companyIds' &&
    !objGet(queryParams, schema.companyIdsFilterPath)
  ) {
    objSet(queryParams, schema.companyIdsFilterPath, activeCompanyIds);
  }

  url = url.includes(':') ? replacePathParams(url, pathParams as Obj<string> | undefined) : url;
  if (queryParams && Object.keys(queryParams).length > 0) {
    url = `${url}?${urltron.stringify(queryParams)}`;
  }

  return axiosRequest<InstanceType<EndpointSchemaT['responseBody']>>({
    baseURL: getBaseUrl(method),
    url,
    method,
    headers,
    responseType: 'json',
    data: method !== 'GET' ? bodyParams : undefined,
  });
}

export async function watchAsyncJob(jobId: string, onNotification: (notification: WatchAsyncJobNotification) => void) {
  const { activeTenant } = globalAppStore.state;
  const url = `${env.CORE_API_URL}/tenants/${activeTenant.id}/async-jobs/${jobId}/watch`;
  const headers = getCoreApiHeaders() as Obj<string>;

  const response = await fetch(url, {
    headers: {
      Accept: 'text/event-stream',
      ...headers,
    },
  });

  if (response.status !== 200) {
    const jsonError = await response.json();
    const httpError = new HttpError(
      response.status,
      jsonError.message ?? response.statusText,
      response.headers.get('x-request-id') || '',
    );
    throw httpError;
  }

  const bodyReader = response.body!.getReader();
  const decoder = new TextDecoder();

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await bodyReader.read();
    if (done) {
      break;
    }

    const valStr = decoder.decode(value, { stream: true });
    const [, event, jsonPayload] = valStr.match(/event: ([^\n]+)\ndata: (.+)\n/m) || [];
    const payload = JSON.parse(jsonPayload);
    onNotification({ event, payload } as WatchAsyncJobNotification);
  }
}

export async function fetchAllRecordsInBatches<RecordT>(
  fetchBatch: (offset: number, limit: number) => Promise<AxiosResponse<{ items: RecordT[]; totalCount: number }>>,
  batchSize = Paging.MaxLimit,
): Promise<RecordT[]> {
  const records: RecordT[] = [];

  // get first batch to get totalCount
  const firstResponse = await fetchBatch(0, batchSize);
  const { totalCount, items } = firstResponse.data;
  records.push(...items);

  //  iterate through rest of batches
  for (let offset = batchSize; offset < totalCount; offset += batchSize) {
    const response = await fetchBatch(offset, batchSize);
    records.push(...response.data.items);
  }

  return records;
}
