import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import { EnvironmentOutlined, SkinOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { TenantFeatureFlag, UsageQuantitySource } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { DemandUsageLineRecordDTO } from '@recurrency/core-api-schema/dist/ml/getDemandUsageLines';
import { DemandUsageLinesOverrideUpdate } from '@recurrency/core-api-schema/dist/ml/updateDemandUsageLineOverrides';
import { ColumnType } from 'antd/lib/table';
import { colors } from 'theme/colors';

import { AsyncMultiSelect, filterMultiSelectOptions } from 'components/AsyncSelect/AsyncMultiSelect';
import { convertToMultiSelectProps } from 'components/AsyncSelect/useAsyncMultiSelectProps';
import { useItemsSelectProps, useLocationsSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { AsyncButton } from 'components/Button/AsyncButton';
import { DateRangePicker } from 'components/DatePicker';
import { DividerLine } from 'components/DividerLine';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { Input } from 'components/Input';
import { ResultCount } from 'components/ResultCount';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { DateFilter, formatMomentDateToSqlDate, getDefaultDateFilter } from 'utils/date';
import { filterCostAndGM } from 'utils/filterCostAndGM';
import { formatIdNameObj } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { routes } from 'utils/routes';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { sortableDateColumn, sortableNumberColumn } from 'utils/tables';
import { getTenantSetting } from 'utils/tenantSettings';

import { InputOverride } from './planning/InputOverride';

export interface UsageLineOverride {
  itemId: string;
  usageId: string;
  locationId: string;
  usageDate: string;
  usageQty: number;
}

export const DemandUsageLinesTable = ({
  itemUid,
  locationId,
  dateFilter,
  usageLineOverrideInputs,
  onUsageOverridesUpdated,
  onChangeUsageLine,
  setDateFilter,
}: {
  itemUid: string;
  locationId: string;
  dateFilter: DateFilter;
  usageLineOverrideInputs: UsageLineOverride[];
  onUsageOverridesUpdated: () => void;
  onChangeUsageLine: (usageLineOverride: UsageLineOverride) => void;
  setDateFilter: (dateFilter: DateFilter) => void;
}) => {
  const locationsSelectProps = useLocationsSelectProps({});
  const itemsSelectProps = useItemsSelectProps();
  const [filteredLocationIds, setFilteredLocationIds] = useState<string[]>([]);
  const [filteredItemCodes, setFilteredItemCodes] = useState<string[]>([]);
  const [overrideNote, setOverrideNote] = useState<string>('');
  const { activeTenant, activeUser } = useGlobalApp();

  const shouldShowUsageOverride = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.FeatureUsageOverrides,
  );

  const usageSourceSetting = getTenantSetting(TenantSettingKey.CalculationUseImportedUsage)
    ? 'IMPORTED'
    : getTenantSetting(TenantSettingKey.CalculationUsageQuantitySource);

  const tableProps = useCoreApiTableProps({
    schema: schemas.ml.getDemandUsageLines,
    queryParams: {
      filters: {
        itemIds: [itemUid],
        locationIds: [locationId],
        sourceItemCodes: filteredItemCodes,
        sourceLocationIds: filteredLocationIds,
        startDate: formatMomentDateToSqlDate(dateFilter.from),
        endDate: formatMomentDateToSqlDate(dateFilter.to),
      },
    },
    pageSize: 10, // Setting a smaller page size to make the bottom Save/Clear override buttons more visible
  });

  const { isLoading, setPage } = tableProps;
  useEffect(() => {
    setPage(1);
  }, [filteredItemCodes, filteredLocationIds, dateFilter, setPage]);

  // specific to how usage ids are formatted, as they are either usageId|linenumber or usageId
  const formatUsageId = (id: string) => (id.includes('|') ? id.slice(0, id.lastIndexOf('|')) : id);

  const tableColumns: (ColumnType<DemandUsageLineRecordDTO> | null)[] = [
    {
      title:
        {
          IMPORTED: <InfoTooltip title="Usage directly imported from ERP">Imported Usage ID</InfoTooltip>,
          [UsageQuantitySource.QuantityInvoiced]: 'Invoice No',
          [UsageQuantitySource.QuantityOrdered]: 'Order No',
        }[usageSourceSetting as UsageQuantitySource] || 'ID',
      dataIndex: 'id',
      render: (_, record: DemandUsageLineRecordDTO) =>
        usageSourceSetting === UsageQuantitySource.QuantityOrdered ? (
          <Link to={routes.orders.orderDetails(formatUsageId(record.id))}>{formatUsageId(record.id)}</Link>
        ) : (
          formatUsageId(record.id)
        ),
    },
    {
      title: 'Item',
      dataIndex: 'sourceItemName',
      render: (_, record: DemandUsageLineRecordDTO) => (
        <div>
          <Link to={routes.purchasing.itemDetails(record.sourceItemCode)}>{record.sourceItemCode}</Link>
          {`: ${record.sourceItemName}`}
        </div>
      ),
    },
    {
      title: 'Location',
      dataIndex: 'sourceLocationId',
      render: (_, record: DemandUsageLineRecordDTO) =>
        formatIdNameObj({ foreignId: record.sourceLocationId, name: record.sourceLocationName }),
    },
    sortableDateColumn({
      title: 'Usage Date',
      dataIndex: 'usageDate',
      sorter: true,
      defaultSortOrder: 'descend',
    }),
    sortableNumberColumn({
      title: 'Usage',
      dataIndex: 'usageQty',
      width: 150,
      sorter: true,
      render: (usageQty, record: DemandUsageLineRecordDTO) => {
        if (!shouldShowUsageOverride) return usageQty;
        const overrideInput = usageLineOverrideInputs.find((input) => input.usageId === record.id);

        return (
          <InputOverride
            override={{
              updatedBy: record.overrideUpdatedByUserName,
              updatedAt: record.overrideUpdatedAt,
              quantity: record.usageQty,
              baseQuantity: record.usageQtyBase,
              uom: record.unitOfMeasure,
              note: record.overrideNote,
            }}
            value={overrideInput?.usageQty ?? (record.overrideUpdatedByUserId ? record.usageQty : record.usageQtyBase)}
            onValueChange={(qty) =>
              onChangeUsageLine({
                usageId: record.id,
                itemId: record.itemId,
                locationId: record.sourceLocationId,
                usageDate: record.usageDate,
                usageQty: qty,
              })
            }
          />
        );
      },
    }),
    {
      title: 'UOM',
      dataIndex: 'unitOfMeasure',
    },
  ];

  const isOverridesChanged = usageLineOverrideInputs.some(
    (o) => o.usageQty !== tableProps.items.find((r) => r.id === o.usageId)?.usageQty,
  );

  async function handleUpdateUsageLineOverrides(overrides: UsageLineOverride[]) {
    const submitNotification = createSubmissionNotification({
      entityName: 'Usage Override',
      expectedWaitSeconds: 10,
    });

    const updates: DemandUsageLinesOverrideUpdate[] = overrides.map((o) => ({
      itemId: o.itemId,
      locationId: o.locationId,
      quantity: o.usageQty,
      usageId: o.usageId,
      note: overrideNote,
    }));

    try {
      await coreApiFetch(schemas.ml.updateDemandUsageLineOverrides, {
        bodyParams: {
          updates,
        },
      });

      submitNotification.success({ successMessage: 'Usage overrides applied', duration: 10 });
      tableProps.reload();
      onUsageOverridesUpdated();
    } catch (err) {
      submitNotification.error(err);
    }
  }

  async function handleClearUsageLineOverrides(itemId: string, locId: string) {
    const submitNotification = createSubmissionNotification({
      entityName: 'Usage Override',
      expectedWaitSeconds: 10,
    });

    try {
      await coreApiFetch(schemas.ml.updateDemandUsageLineOverrides, {
        bodyParams: {
          updates: [{ itemId, locationId: locId, quantity: null }],
        },
      });

      submitNotification.success({ successMessage: 'Usage overrides cleared', duration: 10 });
      tableProps.reload();
      onUsageOverridesUpdated();
    } catch (err) {
      submitNotification.error(err);
    }
  }

  return (
    <>
      <FilterBarBox>
        <AsyncMultiSelect
          selectProps={filterMultiSelectOptions(convertToMultiSelectProps(itemsSelectProps))}
          label="Item"
          queryPlaceholder="Search items"
          selectedValues={filteredItemCodes}
          onSelectedValuesChange={(values) => setFilteredItemCodes(values)}
          icon={<SkinOutlined />}
        />
        <AsyncMultiSelect
          selectProps={filterMultiSelectOptions(convertToMultiSelectProps(locationsSelectProps))}
          label="Location"
          queryPlaceholder="Search locations"
          selectedValues={filteredLocationIds}
          onSelectedValuesChange={(values) => setFilteredLocationIds(values)}
          icon={<EnvironmentOutlined />}
        />
        <FlexSpacer />
        <DateRangePicker
          value={[dateFilter.from, dateFilter.to]}
          onChange={(values) => setDateFilter(values ? { from: values[0], to: values[1] } : getDefaultDateFilter())}
          format="MM/DD/YYYY"
        />
        <ResultCount count={tableProps.totalCount} />
      </FilterBarBox>

      <AsyncTable tableProps={tableProps} columns={tableColumns.filter(filterCostAndGM)} loading={isLoading} />
      {shouldShowUsageOverride && (
        <>
          <DividerLine marginTop={5} marginBottom={15} color={colors.neutral[200]} />
          <FlexSpace justify="space-between">
            <Input
              value={overrideNote}
              disabled={!isOverridesChanged}
              onChange={(ev) => setOverrideNote(ev.target.value)}
              placeholder="Optional override note"
              size="small"
              className={css`
                max-width: 250px;
              `}
            />
            <FlexSpace justify="flex-end">
              <AsyncButton size="small" onClick={() => handleClearUsageLineOverrides(itemUid, locationId)}>
                Clear Overrides
              </AsyncButton>
              <AsyncButton
                disabled={!isOverridesChanged}
                type="primary"
                size="small"
                onClick={() => handleUpdateUsageLineOverrides(usageLineOverrideInputs)}
              >
                Override Usage Lines
              </AsyncButton>
            </FlexSpace>
          </FlexSpace>
        </>
      )}
    </>
  );
};
