import React from 'react';

import { css } from '@emotion/css';
import { TenantSettingsField } from '@recurrency/core-api-schema/dist/common/tenantSettingsTypes';
import { theme } from 'theme';

import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

export function HiddenTenantSettingFieldDescription({ settingField }: { settingField: TenantSettingsField }) {
  const title = settingField.displayConditionHelp ? (
    <span>{settingField.displayConditionHelp}</span>
  ) : (
    <span>
      There is a display condition for this setting that hides this from the tenant and marks it as read-only.
    </span>
  );
  return (
    <>
      <b>READ ONLY:</b>{' '}
      <span
        className={css`
          color: ${theme.colors.danger[500]};
        `}
      >
        <InfoTooltip title={title}>This setting not visible to the tenant.</InfoTooltip>
      </span>
      <br />
    </>
  );
}
