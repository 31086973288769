import React from 'react';

import { css, cx } from '@emotion/css';
import { theme } from 'theme';
import { colors } from 'theme/colors';

import { styled } from 'utils/styledComponent';

import { PRIMARY_NAVIGATION_HEIGHT, SECONDARY_NAVIGATION_WIDTH, TERTIARY_NAVIGATION_HEIGHT } from '../navConstants';

export const Container = ({ isDesktopView, children }: { isDesktopView: boolean; children: React.ReactNode }) => (
  <div
    className={css`
      width: ${isDesktopView ? `calc(100% - ${SECONDARY_NAVIGATION_WIDTH}px)` : '100%'};
      height: ${TERTIARY_NAVIGATION_HEIGHT}px;
      position: fixed;
      top: ${PRIMARY_NAVIGATION_HEIGHT}px;
      left: ${isDesktopView ? SECONDARY_NAVIGATION_WIDTH : 0}px;
      right: 0;
      z-index: ${theme.zIndices.tertiaryNav};
      transition: all ${theme.transitions.fast};
      background: ${theme.colors.neutral[0]};
    `}
  >
    {children}
  </div>
);

export const Content = styled('div')`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 0 16px;
  border-bottom: 1px solid ${colors.neutral[300]};
  @supports (overflow: overlay) {
    overflow-x: overlay;
  }
  @supports not (overflow: overlay) {
    overflow-x: scroll;
  }
`;

export const SubItem = ({ selected, className, ...otherProps }: { selected: boolean } & JSX.IntrinsicElements['a']) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a
    className={cx(
      css`
        -webkit-font-smoothing: antialiased;
        height: 100%;
        text-transform: uppercase;
        margin: 0 16px;
        display: flex;
        letter-spacing: 0.05em;
        font-size: 0.9em;
        align-items: center;
        white-space: nowrap;
        color: ${colors.neutral[900]};
        opacity: 1;
        border: 0;
        border-bottom: ${selected ? `2px solid ${colors.primary[600]}` : `2px solid transparent`};
        cursor: pointer;
        transition: all ${theme.transitions.fast};

        :hover {
          color: inherit;
          border-bottom-color: ${colors.primary[600]};
        }
      `,
      className,
    )}
    {...otherProps}
  />
);

export const HamburgerWrapper = styled('div')`
  margin-top: -4px;
  margin-left: -16px;

  > div {
    height: 42px !important;
  }
`;
