import React from 'react';

import { css } from '@emotion/css';
import moment, { Moment } from 'moment';

import { MultiSelectOption } from 'components/AsyncSelect/types';
import { IsoDatePicker } from 'components/DatePicker/IsoDatePicker';
import { FlexSpace } from 'components/FlexSpace';
import { Select } from 'components/Select';

import { formatDate } from 'utils/formatting';

import { ISODateStr } from 'types/hash-state';

import { ItemLocationSettingChangeModal } from './ItemLocationSettingChangeModal';
import { MultiModalSelection } from './utils';

export function IncludeExcludeModal({
  selection,
  exclude,
  onClose,
}: {
  selection: MultiModalSelection;
  exclude: boolean;
  onClose: (confirmData?: { excludedUntil?: ISODateStr }) => void;
}) {
  const [excludedUntil, setExcludedUntil] = React.useState<ISODateStr | undefined>(undefined);
  const [shouldShowCustomDatePicker, setShouldShowCustomDatePicker] = React.useState(false);

  const getIsoDateFromToday = (amount?: moment.DurationInputArg1, unit?: moment.DurationInputArg2) =>
    moment().startOf('day').add(amount, unit).toISOString();

  const FOREVER_VALUE = '';
  const CUSTOM_VALUE = 'custom';
  const excludedUntilOptions: MultiSelectOption[] = [
    { value: FOREVER_VALUE, label: 'Forever' },
    { value: getIsoDateFromToday(1, 'day'), label: '1 day' },
    { value: getIsoDateFromToday(1, 'week'), label: '1 week' },
    { value: getIsoDateFromToday(1, 'month'), label: '1 month' },
    { value: getIsoDateFromToday(6, 'months'), label: '6 months' },
    { value: CUSTOM_VALUE, label: 'Custom' },
  ];

  function onSelectExcludedUntilOption(value: string) {
    if (value === CUSTOM_VALUE) {
      setShouldShowCustomDatePicker(true);
      if (!excludedUntil) setExcludedUntil(moment().add(1, 'day').toISOString());
    } else {
      setShouldShowCustomDatePicker(false);
      setExcludedUntil(value || undefined);
    }
  }

  const isFutureDate = (date: Moment) =>
    // Can not select days before tomorrow
    date && date < moment().add(1, 'day').startOf('day');

  return (
    <ItemLocationSettingChangeModal
      selection={selection}
      set={exclude}
      bulkUpdateType={{ type: 'includeExclude', exclude }}
      description={`Status will be set as
        ${
          exclude
            ? `Excluded, and will not be set as Needs Review ${
                excludedUntil ? `until ${formatDate(excludedUntil)}` : `in the future`
              }.`
            : 'Needs Review or OK in the future.'
        }`}
      customization={
        exclude ? (
          <FlexSpace
            direction="row"
            gap={8}
            alignItems="center"
            className={css`
              margin-top: 10px;
            `}
          >
            <span>Exclude for </span>
            <Select
              className={css`
                width: 140px;
              `}
              size="small"
              value={shouldShowCustomDatePicker ? CUSTOM_VALUE : excludedUntil || FOREVER_VALUE}
              onSelect={onSelectExcludedUntilOption}
              options={excludedUntilOptions}
              placeholder="Select duration"
            />
            {shouldShowCustomDatePicker && (
              <IsoDatePicker
                className={css`
                  height: 32px;
                `}
                disabledDates={isFutureDate}
                onChange={setExcludedUntil}
                picker="date"
                value={excludedUntil}
                allowClear={false}
                showTime={false}
                size="small"
              />
            )}
          </FlexSpace>
        ) : (
          <></>
        )
      }
      onSubmit={() => onClose({ excludedUntil })}
      onClose={() => onClose()}
    />
  );
}
