import React from 'react';

import { DownloadOutlined, DownOutlined, FlagOutlined, LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { PlanningStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { UpdateItemLocationInfoType } from '@recurrency/core-api-schema/dist/purchasing/postUpdateItemLocationInfo';
import { ValidationRuleStatus } from '@recurrency/core-api-schema/dist/validationRules/common';
import { ResolutionMethod } from '@recurrency/core-api-schema/dist/validationRules/getRuleDetails';
import { ValidationRuleRunItemLocationDTO } from '@recurrency/core-api-schema/dist/validationRules/getValidationRuleRunsByRuleId';
import { Dropdown, Menu, notification } from 'antd';
import { ZipCelXSheet } from 'zipcelx';

import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { NavTabs } from 'components/NavTabs';
import { PageHeader } from 'components/PageHeader';
import { DetailPageSection, DetailPageSections } from 'components/recipes/detailPage/DetailPageSections';
import { DownloadXSheetColumn, notifyAndDownload, recordsToXSheet } from 'components/recipes/DownloadButton';
import { TextArea } from 'components/TextArea';

import { useCoreApi } from 'hooks/useApi';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { formatDate, splitIdNameStr } from 'utils/formatting';
import { IdPathParams, routes, usePathParams } from 'utils/routes';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { track, TrackEvent } from 'utils/track';

import { FlagRuleRunsModal } from './modals/FlagRuleRunsModal';
import { RuleDetailRunTable } from './RuleDetailRunTable';

async function getRuleRuns(ruleId: string): Promise<ValidationRuleRunItemLocationDTO[]> {
  const response = await coreApiFetch(schemas.validationRules.getValidationRuleRunsByRuleId, {
    pathParams: { ruleId },
    queryParams: {
      limit: 1_000_000,
    },
  });
  return response.data.items;
}

async function getDownloadData(ruleId: string): Promise<ZipCelXSheet> {
  const items = await getRuleRuns(ruleId);

  const exportColumns: Array<DownloadXSheetColumn<ValidationRuleRunItemLocationDTO>> = [
    {
      title: 'Item ID',
      type: 'string',
      value: (record) => `${record.itemId}: ${record.item_name}`,
    },
    {
      title: 'Location ID',
      type: 'string',
      value: (record) => record.location,
    },
    {
      title: 'Last Run Time',
      type: 'string',
      value: (record) => record.lastRunTime,
    },
  ];

  return recordsToXSheet(items, exportColumns);
}

export const RuleDetailPage = () => {
  const { id: ruleId } = usePathParams<IdPathParams>();
  const { data: rule, reload } = useCoreApi(schemas.validationRules.getRuleDetails, {
    pathParams: { ruleId },
  });

  const runTableProps = useCoreApiTableProps({
    schema: schemas.validationRules.getValidationRuleRunsByRuleId,
    pathParams: { ruleId },
  });

  function handleDownloadRuns() {
    notifyAndDownload(() => getDownloadData(ruleId));
  }

  const handleOnDeploy = async () => {
    const ruleName = rule!.name || '';
    const deploymentNotificaction = createSubmissionNotification({
      entityName: 'Rule',
      submittingMessage: `Deploying rule [${ruleName}]`,
      expectedWaitSeconds: 20,
    });

    try {
      await coreApiFetch(schemas.validationRules.postValidationRuleDeployment, {
        pathParams: {
          ruleId: rule!.ruleId,
        },
      });
      reload();
      // Refresh the run results
      runTableProps.reload();
    } catch (err) {
      deploymentNotificaction.error(err);
    } finally {
      deploymentNotificaction.success({
        successMessage: `Rule [${ruleName}] was deployed successfully.`,
      });
    }
  };

  const handleOnDisable = async () => {
    const ruleName = rule!.name || '';
    const deploymentNotificaction = createSubmissionNotification({
      entityName: 'Rule',
      submittingMessage: `Disabling rule [${ruleName}]`,
      expectedWaitSeconds: 10,
    });

    try {
      await coreApiFetch(schemas.validationRules.patchValidationRule, {
        pathParams: {
          ruleId: rule!.ruleId,
        },
        bodyParams: {
          status: ValidationRuleStatus.Draft,
        },
      });
      reload();
    } catch (err) {
      deploymentNotificaction.error(err);
    } finally {
      deploymentNotificaction.success({
        successMessage: `Rule [${ruleName}] was disabled successfully.`,
      });
    }
  };

  async function handleSubmitBulkFlagging() {
    if (!rule) return;

    let submitFlaggingNotificaction;
    const nonFlaggedItems = runTableProps.items.filter((run) => run.planning_status !== PlanningStatus.Flagged);

    try {
      if (nonFlaggedItems.length === 0) throw new Error('No items to flag.');

      submitFlaggingNotificaction = createSubmissionNotification({
        entityName: 'Items',
        submittingMessage: `Flagging ${nonFlaggedItems.length} items`,
        expectedWaitSeconds: 10,
      });

      const flagReason = `Flagged by validation rule [${rule.name}] at ${formatDate(rule.lastRunTime, true)}`;

      await coreApiFetch(schemas.purchasing.postUpdateItemLocationInfo, {
        pathParams: {
          updateType: UpdateItemLocationInfoType.Flag,
        },
        bodyParams: {
          updates: nonFlaggedItems.map((record) => ({
            itemUid: record.itemId,
            locationId: splitIdNameStr(record.location).foreignId,
            flagged: true,
            flagReason,
          })),
        },
      });

      submitFlaggingNotificaction.success({
        successMessage: `${nonFlaggedItems.length} Items were flagged successfully`,
      });

      track(TrackEvent.ValidationRules_Bulk_Flag, {
        ruleId: rule.ruleId,
        ruleName: rule.name,
        flagged: true,
        flagReason,
        count: nonFlaggedItems.length,
        recordUrl: window.location.origin + routes.validationRules.validationRuleDetails(rule.ruleId),
      });
    } catch (err) {
      if (err instanceof Error && err.message === 'No items to flag.') {
        notification.error({ message: 'No items to flag' });
        return;
      }
      submitFlaggingNotificaction?.error(err);
    }
  }

  async function handleOpenFlagModal() {
    await showAsyncModal(FlagRuleRunsModal, {
      rule,
      onSubmit: () => handleSubmitBulkFlagging(),
    });
  }

  const headerButtons = [
    rule?.status === ValidationRuleStatus.Draft ? (
      <Button key="deploy" onClick={handleOnDeploy} disabled={!rule}>
        Deploy
      </Button>
    ) : null,
    rule?.status === ValidationRuleStatus.Deployed ? (
      <Button key="disable" onClick={handleOnDisable} disabled={!rule}>
        Disable
      </Button>
    ) : null,
    <Dropdown
      key="bulk-actions"
      overlay={
        <Menu>
          <Menu.Item disabled={!rule} key="flag" icon={<FlagOutlined />} onClick={handleOpenFlagModal}>
            Flag all items
          </Menu.Item>
          {rule?.resolutionMethods?.includes(ResolutionMethod.Flag) ? (
            <Menu.Item key="download" icon={<DownloadOutlined />} onClick={handleDownloadRuns}>
              Download all runs
            </Menu.Item>
          ) : null}
        </Menu>
      }
    >
      <Button type="primary" title="Select table records to enable bulk actions">
        Bulk Actions <DownOutlined />
      </Button>
    </Dropdown>,
  ];

  const headerSections: DetailPageSection[] = [
    {
      title: 'Rule Configuration',
      rows: [
        [
          {
            label: 'Rule Name',
            value: rule?.name,
          },
          {
            label: 'Rule Description',
            value: rule?.description,
          },
        ],
        [
          {
            label: 'Rule Type',
            value: rule?.status,
          },
          {
            label: 'Last Run Time',
            value: formatDate(rule?.lastRunTime, true, true),
          },
        ],
      ],
    },
    {
      title: 'Rule SQL',
      isCollapsedByDefault: true,
      rows: [
        [
          {
            label: '',
            value: <TextArea disabled autoSize value={rule?.sql} />,
            colspan: 2,
          },
        ],
      ],
    },
  ];

  return (
    <Container>
      <PageHeader
        title={`Rule ${rule?.name}`}
        copyable
        entity={{
          kind: 'Validation Rule',
        }}
        headerActions={headerButtons}
      />
      <DetailPageSections sections={headerSections} />
      <div
        className={css`
          .ant-tabs {
            // Unsetting overflow so that sticky headers and side panel css will work
            overflow: unset;
          }
        `}
      >
        <NavTabs
          tabs={[
            {
              header: 'Rule Runs',
              content: rule ? <RuleDetailRunTable tableProps={runTableProps} /> : <LoadingOutlined />,
            },
          ]}
        />
      </div>
    </Container>
  );
};
