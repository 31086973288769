import React, { useState } from 'react';

import { css } from '@emotion/css';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';

import { ActionButton } from 'components/Button/ActionButton';
import { ExternalLink } from 'components/Links';
import { calcLine, calcOp, calcProp, Equation, noteLine } from 'components/recipes/equation/Equation';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { formatNumber } from 'utils/formatting';
import { isTenantErpTypeP21 } from 'utils/tenants';
import { getTenantSetting, showScheduledReleaseStockFeatures } from 'utils/tenantSettings';

import { EquationPopover } from './EquationPopover';

export interface NetStockRecord {
  netStock: number;
  qtyOnHand: number;
  qtyAllocated: number;
  qtyOnPurchaseOrder: number;
  qtyOnUnapprovedPurchaseOrder: number;
  qtyInTransit: number;
  qtyInProcess: number;
  qtyBackordered: number;
  qtyReservedDueIn: number;
  qtyInProduction: number;
  qtyForProduction: number;
  qtyQuarantined: number;
  qtyFrozen: number;
  qtyNonPickable: number;
  qtyOnReleaseSchedule: number;
  qtyOnPendingBackorder: number;
  qtyOnBlanketPurchaseOrder: number;
  unitOfMeasure: string;
}

export const NetStockPopover = ({ record }: { record: NetStockRecord }) => {
  const [showAllValues, setShowAllValues] = useState(false);
  const { activeTenant } = useGlobalApp();
  const isErpTypeP21 = isTenantErpTypeP21(activeTenant.erpType);
  const showQtyOnUnapprovedPurchaseOrder = getTenantSetting(
    TenantSettingKey.CalculationNetStockUseUnapprovedPurchaseOrders,
  );
  const showQtyInUnavailableBins = getTenantSetting(TenantSettingKey.CalculationNetStockRemoveQuantityQuarantined);

  const shouldShowScheduledReleaseStockFeatures = showScheduledReleaseStockFeatures(activeTenant);
  const currentLookaheadValue = getTenantSetting(TenantSettingKey.CalculationCustomLookaheadNetStock);

  const shouldShowLookaheadNotice = (record: NetStockRecord) => {
    // These values come from broker/transform/postgres/macros/p21/inputs_recurrency_inventory_stock.sql
    // Where {{ look_ahead_days}} is used.
    const valueKeys = ['qtyOnReleaseSchedule', 'qtyOnBlanketPurchaseOrder', 'qtyOnPendingBackorder'];
    const hasLookaheadValues = Object.keys(record).some(
      (key) => valueKeys.includes(key) && Number(record[key as keyof NetStockRecord]) !== 0,
    );
    return shouldShowScheduledReleaseStockFeatures && (hasLookaheadValues || showAllValues);
  };

  return (
    <EquationPopover title="Net Stock Calculation">
      <Equation
        lines={[
          calcLine(
            calcProp('Net Stock', `${formatNumber(record.netStock)} ${record.unitOfMeasure}`),
            calcOp('='),
            calcProp('On Hand', `${formatNumber(record.qtyOnHand)} ${record.unitOfMeasure}`),
            calcOp('-'),
            calcProp(
              isErpTypeP21 ? 'Allocated' : 'Committed',
              `${formatNumber(record.qtyAllocated)} ${record.unitOfMeasure}`,
            ),
            calcOp('+'),
            calcProp('On PO', `${formatNumber(record.qtyOnPurchaseOrder)} ${record.unitOfMeasure}`),
            showQtyOnUnapprovedPurchaseOrder && (record.qtyOnUnapprovedPurchaseOrder || showAllValues)
              ? [
                  calcOp('+'),
                  calcProp(
                    'On Unapproved PO',
                    `${formatNumber(record.qtyOnUnapprovedPurchaseOrder)} ${record.unitOfMeasure}`,
                  ),
                ]
              : null,
            isErpTypeP21 && (record.qtyInTransit || showAllValues)
              ? [calcOp('+'), calcProp('In Transit', `${formatNumber(record.qtyInTransit)} ${record.unitOfMeasure}`)]
              : null,
            isErpTypeP21 && (record.qtyInProcess || showAllValues)
              ? [calcOp('+'), calcProp('In Process', `${formatNumber(record.qtyInProcess)} ${record.unitOfMeasure}`)]
              : null,
            isErpTypeP21 && (record.qtyInProduction || showAllValues)
              ? [
                  calcOp('+'),
                  calcProp('In Production', `${formatNumber(record.qtyInProduction)} ${record.unitOfMeasure}`),
                ]
              : null,
            isErpTypeP21 && (record.qtyOnPendingBackorder || showAllValues)
              ? [
                  calcOp('+'),
                  calcProp(
                    `Pending Backorder${shouldShowScheduledReleaseStockFeatures ? ' *' : ''}`,
                    `${formatNumber(record.qtyOnPendingBackorder)} ${record.unitOfMeasure}`,
                  ),
                ]
              : null,
            isErpTypeP21 && (record.qtyBackordered || showAllValues)
              ? [calcOp('-'), calcProp('Backordered', `${formatNumber(record.qtyBackordered)} ${record.unitOfMeasure}`)]
              : null,
            isErpTypeP21 && (record.qtyReservedDueIn || showAllValues)
              ? [
                  calcOp('-'),
                  calcProp('Reserved Due In', `${formatNumber(record.qtyReservedDueIn)} ${record.unitOfMeasure}`),
                ]
              : null,

            isErpTypeP21 && (record.qtyForProduction || showAllValues)
              ? [
                  calcOp('-'),
                  calcProp('For Production', `${formatNumber(record.qtyForProduction)} ${record.unitOfMeasure}`),
                ]
              : null,

            showQtyInUnavailableBins && isErpTypeP21 && (record.qtyQuarantined || showAllValues)
              ? [calcOp('-'), calcProp('Quarantined', `${formatNumber(record.qtyQuarantined)} ${record.unitOfMeasure}`)]
              : null,

            showQtyInUnavailableBins && isErpTypeP21 && (record.qtyFrozen || showAllValues)
              ? [calcOp('-'), calcProp('Frozen', `${formatNumber(record.qtyFrozen)} ${record.unitOfMeasure}`)]
              : null,

            showQtyInUnavailableBins && isErpTypeP21 && (record.qtyNonPickable || showAllValues)
              ? [
                  calcOp('-'),
                  calcProp('Non-Pickable', `${formatNumber(record.qtyNonPickable)} ${record.unitOfMeasure}`),
                ]
              : null,

            isErpTypeP21 && (record.qtyOnReleaseSchedule || showAllValues)
              ? [
                  calcOp('-'),
                  calcProp(
                    `On Release Schedule${shouldShowScheduledReleaseStockFeatures ? ' *' : ''}`,
                    `${formatNumber(record.qtyOnReleaseSchedule)} ${record.unitOfMeasure}`,
                  ),
                ]
              : null,
            isErpTypeP21 && (record.qtyOnBlanketPurchaseOrder || showAllValues)
              ? [
                  calcOp('-'),
                  calcProp(
                    `Blanket PO${shouldShowScheduledReleaseStockFeatures ? ' *' : ''}`,
                    `${formatNumber(record.qtyOnBlanketPurchaseOrder)} ${record.unitOfMeasure}`,
                  ),
                ]
              : null,
          ),
          noteLine(
            <>
              {showAllValues ? (
                <ActionButton
                  className={css`
                    display: inline-block;
                  `}
                  label="Hide zero values"
                  onClick={() => setShowAllValues(false)}
                />
              ) : (
                <>
                  {shouldShowLookaheadNotice(record) ? (
                    <>
                      <br />
                      <span>
                        * Quantity is based on the lookahead window of {currentLookaheadValue} days defined in settings.
                      </span>
                      <br />
                    </>
                  ) : null}
                  <span>
                    Calculation does not show zero values that do not affect total.
                    <ActionButton
                      className={css`
                        display: inline-block;
                      `}
                      label="Show all values"
                      onClick={() => setShowAllValues(true)}
                    />
                  </span>
                </>
              )}
              {' | '}
              <ExternalLink to="https://help.recurrency.com/hc/en-us/articles/26913689024667-Recurrency-Net-Stock-Calculations">
                Learn more
              </ExternalLink>
            </>,
            '450px',
          ),
        ]}
      />
    </EquationPopover>
  );
};
