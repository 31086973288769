import React from 'react';

import { Link } from 'react-router-dom';

import { EditOutlined, WarningOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { GetItemUsageInheritanceDTO } from '@recurrency/core-api-schema/dist/items/getItemUsageInheritanceOverride';
import { Tooltip } from 'antd';
import { theme } from 'theme';
import { colors } from 'theme/colors';

import { ActionButton } from 'components/Button/ActionButton';

import { makePath, routePaths } from 'utils/routes';

import { ItemInheritanceData } from './sidePane.utils';
import { UsageInheritanceDescendantBanner } from './UsageInheritanceDescendantBanner';

interface UsageInheritanceInfoProps {
  itemInheritanceData: ItemInheritanceData;
  onEdit: () => void;
}

export const UsageInheritanceInfo: React.FC<UsageInheritanceInfoProps> = ({ itemInheritanceData, onEdit }) => {
  const {
    isLoading,
    appliedAncestorData,
    usingRecurrencyInheritance,
    pendingAncestorData,
    pendingRemovedAncestorData,
    invalidAncestors,
    validDescendants,
    nextUpdateDays,
  } = itemInheritanceData;

  const hasPendingAncestorData = pendingAncestorData && pendingAncestorData.length > 0;
  const hasPendingRemovedAncestorData = pendingRemovedAncestorData && pendingRemovedAncestorData.length > 0;
  const hasAppliedAncestorData = appliedAncestorData && appliedAncestorData.length > 0;

  const invalidAncestorItemCodes = invalidAncestors.map((ancestor) => ancestor.itemCode);
  const filteredAppliedAncestorData = appliedAncestorData
    ? appliedAncestorData.filter((item) => !invalidAncestorItemCodes.includes(item))
    : [];
  const hasFilteredAppliedAncestorData = filteredAppliedAncestorData && filteredAppliedAncestorData.length > 0;

  return isLoading ? null : (
    <div>
      <UsageInheritanceDescendantBanner validDescendants={validDescendants} />
      {hasFilteredAppliedAncestorData && (
        <>
          Inheriting usage from:{' '}
          {filteredAppliedAncestorData.map((item, index) => (
            <React.Fragment key={item}>
              <Link to={makePath(routePaths.purchasing.itemDetails, { id: item })}>{item}</Link>
              {index < filteredAppliedAncestorData.length - 1 ? ', ' : ''}
            </React.Fragment>
          ))}
        </>
      )}
      {invalidAncestors.length > 0 && (
        <div>
          Cannot inherit usage from:{' '}
          {invalidAncestors.map((item: GetItemUsageInheritanceDTO, index: number) => (
            <React.Fragment key={item.itemCode}>
              <Link to={makePath(routePaths.purchasing.itemDetails, { id: item.itemCode })}>{item.itemCode}</Link>
              {index < invalidAncestors.length - 1 ? ', ' : ''}
            </React.Fragment>
          ))}
          <Tooltip title="Cannot inherit usage from these items due to incompatible units of measure.">
            <WarningOutlined
              className={css`
                margin-left: 4px;
                color: ${colors.danger[500]};
              `}
            />
          </Tooltip>
        </div>
      )}
      {(hasPendingAncestorData || hasPendingRemovedAncestorData) && (
        <div>
          <div
            className={css`
              color: ${theme.colors.neutral[400]};
            `}
          >
            {nextUpdateDays !== null
              ? `On the next usage update in ${nextUpdateDays} day${nextUpdateDays !== 1 ? 's' : ''}:`
              : null}
          </div>
          {hasPendingAncestorData && (
            <div>
              Will inherit usage from:{' '}
              {pendingAncestorData.map((item, index) => (
                <React.Fragment key={item}>
                  <Link to={makePath(routePaths.purchasing.itemDetails, { id: item })}>{item}</Link>
                  {index < pendingAncestorData.length - 1 ? ', ' : ''}
                </React.Fragment>
              ))}
            </div>
          )}
          {hasPendingRemovedAncestorData && (
            <div>
              Will stop inheriting from:{' '}
              {pendingRemovedAncestorData.map((item, index) => (
                <React.Fragment key={item}>
                  <Link to={makePath(routePaths.purchasing.itemDetails, { id: item })}>{item}</Link>
                  {index < pendingRemovedAncestorData.length - 1 ? ', ' : ''}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      )}
      {(hasPendingAncestorData || hasAppliedAncestorData) && usingRecurrencyInheritance && (
        <ActionButton
          label={
            <>
              <EditOutlined /> Edit Inheritance
            </>
          }
          onClick={onEdit}
          className={css`
            margin-top: 8px;
          `}
        />
      )}
    </div>
  );
};
