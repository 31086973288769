import React from 'react';

import { css } from '@emotion/css';
import { theme } from 'theme';

import { Input } from 'components/Input';

export const PositiveNumberInput = ({
  value,
  disabled,
  onChange,
  requiresInteger = false,
  min = 0,
}: {
  value: number | undefined;
  onChange: (value?: number) => void;
  requiresInteger?: boolean;
  disabled?: boolean;
  min?: number;
}) => {
  let errorMessage = '';

  if (value !== undefined && requiresInteger && !Number.isInteger(value)) {
    errorMessage = `Value must be an integer`;
  }

  if (value !== undefined && value < min) {
    errorMessage = `Value must be greater than or equal to ${min}`;
  }

  return (
    <div>
      <Input
        type="number"
        disabled={disabled}
        onChange={(ev) => {
          const currentInputValue = ev.currentTarget.value;
          const numericInputValue = currentInputValue === '' ? undefined : Number(currentInputValue);
          onChange(numericInputValue);
        }}
        value={value}
        min={min}
        validate={() => !errorMessage}
      />
      {errorMessage && (
        <div
          className={css`
            color: ${theme.colors.danger[500]};
          `}
        >
          {errorMessage}
        </div>
      )}
    </div>
  );
};
