import React from 'react';

import { Link } from 'react-router-dom';

import { DashboardOutlined, PlusOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { ISODateStr } from '@recurrency/core-api-schema/dist/common/types';
import { ValidationRuleStatus } from '@recurrency/core-api-schema/dist/validationRules/common';
import { ValidationRuleDTO } from '@recurrency/core-api-schema/dist/validationRules/getValidationRules';
import moment from 'moment';

import { FORECAST_COLOR } from 'pages/purchasing/utils';

import { AsyncMultiSelect } from 'components/AsyncSelect/AsyncMultiSelect';
import { MultiSelectOption } from 'components/AsyncSelect/types';
import { AsyncTable } from 'components/AsyncTable';
import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpace } from 'components/FlexSpace';
import { FlexSpacer } from 'components/FlexSpacer';
import { PageHeader } from 'components/PageHeader';
import { NewValidationRuleFromTemplateModal } from 'components/recipes/NewValidationRuleFromTemplateModal';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { ResultCount } from 'components/ResultCount';
import { Sparkline } from 'components/Sparkline';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { showAsyncModal } from 'utils/asyncModal';
import { formatDate } from 'utils/formatting';
import { routes, useHashState } from 'utils/routes';
import { asKeyOf } from 'utils/tables';

import { ValidationRulesPageHashState } from 'types/hash-state';

export const ValidationRulesPage = () => {
  const [hashState, updateHashState] = useHashState<ValidationRulesPageHashState>();
  const tableProps = useCoreApiTableProps({
    schema: schemas.validationRules.getValidationRules,
    queryParams: {
      filter: {
        statuses: hashState.status ? [hashState.status] : undefined,
      },
    },
  });

  const { reload } = tableProps;

  const statusOptions: MultiSelectOption[] = [
    { label: 'All', value: '' },
    { label: 'Deployed', value: ValidationRuleStatus.Deployed },
    { label: 'Draft', value: ValidationRuleStatus.Draft },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: asKeyOf<ValidationRuleDTO>('name'),
      key: 'ruleName',
      render: (name: string, record: ValidationRuleDTO) => (
        <Link to={routes.validationRules.validationRuleDetails(record.ruleId)}>{name}</Link>
      ),
      sorter: true,
    },
    {
      title: 'Last Run Time',
      dataIndex: asKeyOf<ValidationRuleDTO>('lastRunTime'),
      key: 'lastRunTime',
      render: (lastRunTime: ISODateStr) => formatDate(lastRunTime, true, true),
      sorter: true,
    },
    {
      title: 'Items Failed Last Run',
      key: 'failedRules',
      render: (record: ValidationRuleDTO) => (record.runs[0] ? record.runs[0]?.failedItems : 0),
    },
    {
      title: 'Run History',
      key: 'runHistory',
      render: (record: ValidationRuleDTO) => {
        // If there is only 1 data point, duplicate it with 1 second in the future to show a line
        let { runs } = record;
        if (runs.length === 1) {
          const singleRun = runs[0];
          runs = [singleRun, { ...singleRun, runTime: moment(singleRun.runTime).add(1, 'second').toISOString() }];
        }
        return runs.length > 0 ? (
          <Sparkline
            height={50}
            series={[
              {
                data: runs.map((item) => ({
                  date: item.runTime,
                  value: item.failedItems,
                })),
                formatFn: (value: number) => `${value}`,
                title: 'Failed Items',
                color: FORECAST_COLOR,
                showGradient: true,
              },
            ]}
            useDateTime
          />
        ) : (
          <div
            className={css`
              height: 50px;
            `}
          >
            -
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: asKeyOf<ValidationRuleDTO>('status'),
      key: 'status',
      render: (status: BadgeStatus) => <StatusBadge status={status} />,
      sorter: true,
    },
  ];
  return (
    <Container>
      <PageHeader
        title={
          <InfoTooltip placement="bottom" title="Validation rules allow you to enforce business rules on your data.">
            Validation Rules
          </InfoTooltip>
        }
        headerActions={[
          <Button
            key="new-rule-from-template-button"
            type="primary"
            onClick={async () => {
              // open modal
              await showAsyncModal(NewValidationRuleFromTemplateModal, { onClose: () => reload() });
            }}
          >
            <PlusOutlined /> New Rule from Template
          </Button>,
        ]}
      />
      <FilterBarBox dividerLine>
        <FlexSpace direction="column" gap={16} fullWidth>
          <FlexSpace wrap fullWidth>
            <span>Filter</span>
            <AsyncMultiSelect
              label="Status"
              mode="single"
              icon={<DashboardOutlined />}
              selectProps={{ options: statusOptions }}
              selectedValues={[hashState.status || statusOptions[0].value]}
              onSelectedValuesChange={(values) =>
                updateHashState({ status: (values[0] || '') as ValidationRuleStatus })
              }
            />
            <FlexSpacer />
            <ResultCount count={tableProps.totalCount} />
          </FlexSpace>
        </FlexSpace>
      </FilterBarBox>

      <AsyncTable tableProps={tableProps} columns={columns} />
    </Container>
  );
};
