import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { CheckOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { SortDirection } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { GetInventoryDashboardStatusItemsQueryParams } from '@recurrency/core-api-schema/dist/inventory/getInventoryDashboard';
import {
  InventoryDashboardStatus,
  InventoryDashboardStatusRecordDTO,
} from '@recurrency/core-api-schema/dist/inventory/inventoryDashboardResultDTO';
import { Divider, notification } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { ZipCelXSheet } from 'zipcelx';

import { Container } from 'components/Container';
import { FlexSpace } from 'components/FlexSpace';
import { PageHeader } from 'components/PageHeader';
import { ColumnChooserSection } from 'components/recipes/ColumnChooserSection';
import { DownloadButton, DownloadXSheetColumn, recordsToXSheet } from 'components/recipes/DownloadButton';
import { flattenExportColumns } from 'components/recipes/SearchFrame/utils';
import { BadgeStatus, StatusBadge } from 'components/recipes/StatusBadge';
import { DEFAULT_PAGE_SIZE, Table } from 'components/Table';
import { Tooltip } from 'components/Tooltip';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useCoreApi } from 'hooks/useApi';
import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { arrUnique } from 'utils/array';
import { truthy } from 'utils/boolean';
import { formatDate, formatNumber, formatUSD } from 'utils/formatting';
import { LocalStorageKey, useLocalStorage } from 'utils/localStorage';
import { shouldShowFreeProduct } from 'utils/roleAndTenant';
import { routes, useHashState } from 'utils/routes';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { useUserViewSettingsState } from 'utils/tableAndSidePaneSettings/useUserViewSettingsState';
import { asKeyOf, ExportColumn } from 'utils/tables';
import { isTenantErpTypeP21, isTenantErpTypeNetSuite } from 'utils/tenants';
import { getTenantSetting } from 'utils/tenantSettings';

import { InventoryDashboardHashStateFilters, InventoryDashboardStatusHashState } from 'types/hash-state';

import { ReplenishmentMethod } from '../../utils';
import { DashboardUnitSelect } from '../shared/DashboardUnitSelect';
import { InventoryDashboardFilters } from '../shared/InventoryDashboardFilters';
import { RecommendedMaxToggle } from '../shared/RecommendedMaxToggle';
import { ResultsCounter } from '../shared/ResultsCounter';
import { KPIBar } from './KPIBar';
import { ZoomInBar } from './ZoomInBar';

const DOWNLOAD_LIMIT = 100_000;

export const CurrentInventoryExplorerPage = () => {
  const { activeTenant } = useGlobalApp();
  const history = useHistory();
  const [hashState, updateHashState] = useHashState<InventoryDashboardStatusHashState>();
  const [tableDataErrorState, setTableDataErrorState] = useState<string | undefined>(undefined);
  const shouldShowRecommendedToggle = !shouldShowFreeProduct(activeTenant);
  const useRecommendedMax = shouldShowRecommendedToggle && (hashState?.useRecommendedMax ?? false);
  const [localStorageRecommendedMaxEnabled, setLocalStorageRecommendedMaxEnabled] = useLocalStorage(
    LocalStorageKey.Demand_Planning_Recommended_Max_Toggle_Enabled,
    getTenantSetting(TenantSettingKey.FeaturePlanningMaxToggleDefault) ?? false,
  );

  const defaultHashState: InventoryDashboardStatusHashState = {
    page: 1,
    sortBy: [['onHandValue', SortDirection.Desc]],
    displayUnit: 'value',
  };

  if (!hashState || !hashState.displayUnit) {
    requestAnimationFrame(async () => {
      updateHashState(defaultHashState);
    });
  }
  // Set default for max toggle to the tenant setting
  if (!hashState || hashState.useRecommendedMax === undefined) {
    updateHashState({
      useRecommendedMax: localStorageRecommendedMaxEnabled,
    });
  }

  const viewValue = () => hashState.displayUnit === 'value';

  const recommendedVariants = {
    status: `recommendedStatus`,
    maxStock: `recommendedMaxStock`,
    normalQty: `recommendedNormalQty`,
    normalValue: `recommendedNormalValue`,
    overstockQty: `recommendedOverstockQty`,
    overstockValue: `recommendedOverstockValue`,
  };

  // Retrieve any recommended variants of the requested fields
  const appendRecommendedVariants = (requestedFields?: string[]) => {
    if (!requestedFields) {
      return requestedFields;
    }
    const newRecommendedFields = requestedFields
      .filter((field) => field in recommendedVariants)
      .map((field) => recommendedVariants[field as keyof typeof recommendedVariants]);
    return [...requestedFields, ...newRecommendedFields];
  };

  const requiredFields = {
    status: ['onHandQty', 'allocatedQty', 'minStock', 'replenishmentMethod', 'maxStock'],
    recommendedStatus: ['onHandQty', 'allocatedQty', 'minStock', 'replenishmentMethod', 'recommendedMaxStock'],
  };

  // Certain fields require others to be present, so this includes those
  const appendRequiredFields = (requestedFields?: string[]) => {
    if (!requestedFields) {
      return requestedFields;
    }
    requestedFields.forEach((field) => {
      if (field in requiredFields) {
        requiredFields[field as keyof typeof requiredFields].forEach((requiredField: string) => {
          if (!requestedFields.includes(requiredField)) {
            requestedFields.push(requiredField);
          }
        });
      }
    });
    return requestedFields;
  };

  const sorterDirection = {
    ascend: SortDirection.Asc,
    descend: SortDirection.Desc,
  };

  const onHashStateFilterChange = (filters: InventoryDashboardHashStateFilters) => {
    updateHashState({ filters });
  };

  const toggleMax = (value: boolean) => {
    updateHashState({ useRecommendedMax: value });
    setLocalStorageRecommendedMaxEnabled(value);
  };

  const orZero = (value?: number) => value ?? 0;

  // Adjust max to be OP + OQ for OP/OQ items
  const getMaxQuantity = (record: InventoryDashboardStatusRecordDTO) => {
    const baseMax = (useRecommendedMax ? record.recommendedMaxStock : record.maxStock) ?? 0;
    const maxAdjustment = (record.replenishmentMethod === ReplenishmentMethod.OPOQ ? record.minStock : 0) ?? 0;
    return formatNumber(baseMax + maxAdjustment);
  };

  const statusMessage = (record: InventoryDashboardStatusRecordDTO) =>
    (useRecommendedMax ? record.recommendedStatus : record.status) === 'Normal' ? (
      <div>
        <div>
          <strong>Normal</strong>
        </div>
        Inventory volume is normal
      </div>
    ) : (useRecommendedMax ? record.recommendedStatus : record.status) === 'Overstock' ? (
      <div>
        <div>
          <strong>Overstock</strong>
        </div>
        The available quantity of {formatNumber(orZero(record.onHandQty) - orZero(record.allocatedQty))}
        <br />
        exceeds the{' '}
        {record.replenishmentMethod === ReplenishmentMethod.OPOQ
          ? 'Order Point + Order Quantity'
          : 'current max'} of {getMaxQuantity(record)}
      </div>
    ) : (
      <div>
        <div>
          <strong>Dead Stock</strong>
        </div>
        Last Invoice: {formatDate(record.lastInvoiceDate)}
        <br />
        Last Purchased: {formatDate(record.lastPurchaseOrderDate)}
        <br />
        Last Sold: {formatDate(record.lastSalesOrderDate)}
      </div>
    );

  const formatValueOrVolume = (value?: number) =>
    hashState.displayUnit === 'value' ? formatUSD(value) : formatNumber(value);

  const dataIndex = (field: string) => (viewValue() ? `${field}Value` : `${field}Qty`);

  const columnSortDirection = (columnName: string, useDefault = false): 'ascend' | 'descend' | undefined => {
    if (useDefault && (!hashState.sortBy || hashState.sortBy.length < 1)) return 'ascend';

    const inSortBy = (hashState.sortBy ?? []).find(([sortField]) => sortField === columnName);
    return !inSortBy ? undefined : inSortBy[1] === SortDirection.Asc ? 'ascend' : 'descend';
  };

  function numericColumnSorter<RecordT>(field: string, a: RecordT, b: RecordT, sortDirection?: SortOrder) {
    if (!sortDirection) return 0;
    const key = dataIndex(field) as keyof RecordT;
    return sortDirection === 'ascend' ? Number(b[key]) - Number(a[key]) : Number(a[key]) - Number(b[key]);
  }

  const columns: (PersistedColumn<InventoryDashboardStatusRecordDTO> | null)[] = [
    {
      title: 'Item',
      settingKey: 'itemCode',
      dataIndex: asKeyOf<InventoryDashboardStatusRecordDTO>('itemCode'),
      fixed: 'left',
      width: `300px`,
      render: (_: string, record) => (
        <div>
          <Link to={routes.purchasing.itemDetails(record.itemCode)}>{record.itemCode}</Link>
          <div>{record.itemName}</div>
        </div>
      ),
      exportValue: {
        'Item ID': asKeyOf<InventoryDashboardStatusRecordDTO>('itemCode'),
        'Item Name': asKeyOf<InventoryDashboardStatusRecordDTO>('itemName'),
      },
      exportType: 'string',
      required: true,
    },
    {
      title: 'Location',
      settingKey: 'locationId',
      dataIndex: asKeyOf<InventoryDashboardStatusRecordDTO>('locationId'),
      width: `240px`,
      render: (_: string, record) => (
        <div>
          <div>{record.locationId}</div>
          <div>{record.locationName}</div>
        </div>
      ),
      exportValue: {
        'Location ID': asKeyOf<InventoryDashboardStatusRecordDTO>('locationId'),
        'Location Name': asKeyOf<InventoryDashboardStatusRecordDTO>('locationName'),
      },
      exportType: 'string',
      required: true,
    },
    {
      title: 'Primary Supplier',
      settingKey: 'supplierId',
      dataIndex: asKeyOf<InventoryDashboardStatusRecordDTO>('supplierId'),
      width: `300px`,
      render: (_: string, record) => (
        <div>
          <div>{record.supplierId}</div>
          <div>{record.supplierName}</div>
        </div>
      ),
      exportValue: {
        'Supplier ID': asKeyOf<InventoryDashboardStatusRecordDTO>('supplierId'),
        'Supplier Name': asKeyOf<InventoryDashboardStatusRecordDTO>('supplierName'),
      },
      exportType: 'string',
    },
    {
      title: 'Status',
      settingKey: 'status',
      dataIndex: asKeyOf<InventoryDashboardStatusRecordDTO>('status'),
      render: (_, record) => (
        <Tooltip title={<div>{statusMessage(record)}</div>}>
          <StatusBadge
            status={BadgeStatus[useRecommendedMax ? record.recommendedStatus : record.status]}
            className={css`
              cursor: help;
            `}
          />
        </Tooltip>
      ),
      exportType: 'string',
    },
    isTenantErpTypeP21(activeTenant.erpType)
      ? {
          title: 'ABC Class',
          settingKey: 'purchaseClass',
          dataIndex: asKeyOf<InventoryDashboardStatusRecordDTO>('purchaseClass'),
          exportType: 'string',
        }
      : null,
    {
      title: 'Stockable',
      settingKey: 'stockable',
      dataIndex: asKeyOf<InventoryDashboardStatusRecordDTO>('stockable'),
      align: 'center',
      render: (value) => (value === true ? <CheckOutlined /> : null),
      exportType: 'string',
    },
    {
      title: 'On Hand',
      settingKey: 'onHand',
      dataIndex: dataIndex('onHand'),
      sorter: (a, b, sortDirection) =>
        numericColumnSorter<InventoryDashboardStatusRecordDTO>('onHand', a, b, sortDirection),
      sortOrder: columnSortDirection(dataIndex('onHand')),
      align: 'right',
      render: (value) => formatValueOrVolume(value),
      exportType: 'number',
    },
    {
      title: 'Allocated',
      settingKey: 'allocated',
      dataIndex: dataIndex('allocated'),
      sorter: (a, b, sortDirection) =>
        numericColumnSorter<InventoryDashboardStatusRecordDTO>('allocated', a, b, sortDirection),
      sortOrder: columnSortDirection(dataIndex('allocated')),
      align: 'right',
      render: (value) => formatValueOrVolume(value),
      exportType: 'number',
    },
    {
      title: 'On Order',
      settingKey: 'onOrder',
      dataIndex: dataIndex('onPurchaseOrder'),
      sorter: (a, b, sortDirection) =>
        numericColumnSorter<InventoryDashboardStatusRecordDTO>('onPurchaseOrder', a, b, sortDirection),
      sortOrder: columnSortDirection(dataIndex('onPurchaseOrder')),
      align: 'right',
      render: (value) => formatValueOrVolume(value),
      exportType: 'number',
    },
    {
      title: 'Backordered',
      settingKey: 'backordered',
      dataIndex: dataIndex('backordered'),
      sorter: (a, b, sortDirection) =>
        numericColumnSorter<InventoryDashboardStatusRecordDTO>('backordered', a, b, sortDirection),
      sortOrder: columnSortDirection(dataIndex('backordered')),
      align: 'right',
      render: (value) => formatValueOrVolume(value),
      exportType: 'number',
    },
    {
      title: 'Last Purchase Order',
      settingKey: 'lastPurchaseOrderDate',
      dataIndex: 'lastPurchaseOrderDate',
      sorter: true,
      sortOrder: columnSortDirection('lastPurchaseOrderDate'),
      align: 'right',
      render: (value) => formatDate(value),
      exportValue: (record: InventoryDashboardStatusRecordDTO) => formatDate(record.lastPurchaseOrderDate),
      exportType: 'string',
    },
    {
      title: 'Last Sales Order',
      settingKey: 'lastSalesOrderDate',
      dataIndex: 'lastSalesOrderDate',
      sorter: true,
      sortOrder: columnSortDirection('lastSalesOrderDate'),
      align: 'right',
      render: (value) => formatDate(value),
      exportValue: (record: InventoryDashboardStatusRecordDTO) => formatDate(record.lastSalesOrderDate),
      exportType: 'string',
    },
    {
      title: 'Unique Customers (3M)',
      settingKey: 'uniqueCustomers3M',
      dataIndex: asKeyOf<InventoryDashboardStatusRecordDTO>('uniqueCustomers3M'),
      align: 'right',
      render: (value) => formatNumber(value || 0, 0),
      optional: true,
      exportType: 'number',
    },
    {
      title: 'Unique Customers (6M)',
      settingKey: 'uniqueCustomers6M',
      dataIndex: asKeyOf<InventoryDashboardStatusRecordDTO>('uniqueCustomers6M'),
      align: 'right',
      render: (value) => formatNumber(value || 0, 0),
      optional: true,
      exportType: 'number',
    },
    {
      title: 'Unique Customers (12M)',
      settingKey: 'uniqueCustomers12M',
      dataIndex: asKeyOf<InventoryDashboardStatusRecordDTO>('uniqueCustomers12M'),
      align: 'right',
      render: (value) => formatNumber(value || 0, 0),
      optional: true,
      exportType: 'number',
    },
    {
      title: 'Unique Hits (3M)',
      settingKey: 'uniqueOrders3M',
      dataIndex: asKeyOf<InventoryDashboardStatusRecordDTO>('uniqueOrders3M'),
      align: 'right',
      render: (value) => formatNumber(value || 0, 0),
      optional: true,
      exportType: 'number',
    },
    {
      title: 'Unique Hits (6M)',
      settingKey: 'uniqueOrders6M',
      dataIndex: asKeyOf<InventoryDashboardStatusRecordDTO>('uniqueOrders6M'),
      align: 'right',
      render: (value) => formatNumber(value || 0, 0),
      optional: true,
      exportType: 'number',
    },
    {
      title: 'Unique Hits (12M)',
      settingKey: 'uniqueOrders12M',
      dataIndex: asKeyOf<InventoryDashboardStatusRecordDTO>('uniqueOrders12M'),
      align: 'right',
      render: (value) => formatNumber(value || 0, 0),
      optional: true,
      exportType: 'number',
    },
  ];
  const tableColumns = columns.filter(truthy);

  const [visibleColumnKeys, setVisibleColumnKeys] = useUserViewSettingsState(
    ViewSettingKey.InventoryStatusExplorerTable,
    tableColumns.filter((column) => !column.optional).map((column) => column.settingKey),
  );

  // always query item, location, supplier fields as they are shown as id/name in same row
  const visibleDataIndexes = visibleColumnKeys
    .map((key) => columns.find((column) => column && column.settingKey === key)?.dataIndex as string)
    .filter(truthy);
  const apiFields =
    visibleColumnKeys && visibleColumnKeys.length > 0
      ? arrUnique([
          ...visibleDataIndexes,
          'itemCode',
          'itemName',
          'locationId',
          'locationName',
          'supplierId',
          'supplierName',
        ])
      : undefined;

  const pageSize = DEFAULT_PAGE_SIZE;
  const apiQueryParams: GetInventoryDashboardStatusItemsQueryParams = {
    filters: {
      status: useRecommendedMax ? undefined : (hashState.filters?.status as InventoryDashboardStatus[]),
      recommendedStatus: useRecommendedMax ? (hashState.filters?.status as InventoryDashboardStatus[]) : undefined,
      locationIds: hashState.filters?.locationIds,
      itemIds: hashState.filters?.itemIds,
      itemGroupIds: hashState.filters?.itemGroupIds,
      supplierIds: hashState.filters?.supplierIds,
      buyerIds: hashState.filters?.buyerIds,
      purchaseClasses: hashState.filters?.purchaseClasses,
      stockable: hashState.filters?.stockable?.length === 1 ? hashState.filters.stockable[0] === 'true' : undefined,
    },
    sortBy: hashState.sortBy ?? defaultHashState.sortBy,
    fields: appendRequiredFields(appendRecommendedVariants(apiFields)),
    limit: pageSize,
    offset: ((hashState.page ?? 1) - 1) * pageSize,
  };

  // TABLE DATA
  const {
    isLoading: tableDataIsLoading,
    data: tableData,
    error: tableDataError,
  } = useCoreApi(schemas.inventory.getInventoryDashboardStatusItems, {
    queryParams: apiQueryParams,
  });

  // RESULTS COUNT
  const { isLoading: totalCountIsLoading, data: totalCountData } = useCoreApi(
    schemas.inventory.getInventoryDashboardStatusTotalCount,
    {
      queryParams: {
        filters: apiQueryParams.filters,
      },
    },
  );

  if (tableDataError && !tableDataErrorState) {
    setTableDataErrorState(tableDataError.message);
  }

  useEffect(() => {
    if (tableDataErrorState && tableDataErrorState?.length > 0) notification.error({ message: tableDataErrorState });
  }, [tableDataErrorState]);

  const visibleColumns = visibleColumnKeys
    .map((key) => tableColumns.find((column) => column.settingKey === key))
    .filter(truthy);

  return (
    <Container>
      <PageHeader
        title={
          <InfoTooltip
            title="Explore your current inventory status, filtered to the specific items you want."
            placement="bottom"
          >
            Inventory Status Explorer
          </InfoTooltip>
        }
        entity={{ kind: 'Dashboard' }}
        onBack={() =>
          history.push(
            routes.demandPlanning.dashboard({
              filters: hashState.filters,
              displayUnit: hashState.displayUnit,
            }),
          )
        }
        headerActions={
          <DownloadButton
            recordType="Inventory Status"
            getDownloadData={() => getDownloadData(apiQueryParams, visibleColumns, DOWNLOAD_LIMIT)}
            downloadRecordLimit={DOWNLOAD_LIMIT}
          />
        }
      />
      <div>
        <div
          className={css`
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 16px;
            margin-bottom: 16px;
          `}
        >
          <FlexSpace gap={8}>
            <div>Measured in</div>
            <DashboardUnitSelect
              state={hashState.displayUnit}
              onChange={(value) => updateHashState({ displayUnit: value })}
            />
          </FlexSpace>
          <FlexSpace gap={8}>
            <RecommendedMaxToggle isChecked={useRecommendedMax} onToggle={toggleMax} />
          </FlexSpace>
        </div>
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
          `}
        >
          <InventoryDashboardFilters
            label="Filter"
            filterState={hashState.filters}
            onFiltersChange={onHashStateFilterChange}
            showStatusFilter
            showStockableFilter
            showBuyerFilter={getTenantSetting(TenantSettingKey.UiShowBuyerFilter)}
            showPurchaseClassFilter={!isTenantErpTypeNetSuite(activeTenant.erpType)}
            showProductGroupFilter={!isTenantErpTypeNetSuite(activeTenant.erpType)}
          />
          <ResultsCounter isLoading={totalCountIsLoading} count={totalCountData?.totalCount} />
        </div>
        <>
          {hashState.zoomEnabled ? (
            <ZoomInBar hashState={hashState} updateHashStateFn={updateHashState} apiQueryParams={apiQueryParams} />
          ) : (
            <>
              <Divider
                className={css`
                  margin: 0;
                  margin-bottom: 20px;
                `}
              />
              <KPIBar
                hashState={hashState}
                apiQueryParams={apiQueryParams}
                showRecommendedMaxVariant={useRecommendedMax}
              />
            </>
          )}
        </>
        <div>
          <ColumnChooserSection
            tableKey={ViewSettingKey.InventoryStatusExplorerTable}
            columns={tableColumns}
            visibleColumnKeys={visibleColumnKeys}
            setVisibleColumnKeys={setVisibleColumnKeys}
          />
          <Table
            columns={visibleColumns}
            data={tableDataError ? [] : tableData?.items || []}
            loading={tableDataIsLoading}
            size="small"
            verticalAlign="center"
            onChange={(_pagination, _filters, sorter, { action }) => {
              if (action === 'sort') {
                const sortArray = Array.isArray(sorter) ? sorter : [sorter];
                const sortBy = sortArray
                  .filter((sortOpt) => sortOpt.order !== undefined)
                  .map((sortOpt): [string, SortDirection] => [
                    sortOpt.field as string,
                    sorterDirection[sortOpt.order ?? 'ascend'],
                  ]);
                updateHashState({
                  sortBy,
                  page: 1,
                });
              }
            }}
            pagination={
              !tableDataIsLoading &&
              (totalCountData?.totalCount ?? 0) > pageSize && {
                onChange: (page) => updateHashState({ page }),
                pageSize,
                simple: true,
                current: hashState.page,
                total: totalCountData?.totalCount,
              }
            }
          />
        </div>
      </div>
    </Container>
  );
};

async function getDownloadData(
  apiQueryParams: GetInventoryDashboardStatusItemsQueryParams,
  columns: ExportColumn<InventoryDashboardStatusRecordDTO>[],
  limit: number,
): Promise<ZipCelXSheet> {
  const response = await coreApiFetch(schemas.inventory.getInventoryDashboardStatusItems, {
    queryParams: {
      ...apiQueryParams,
      // override pagination for download
      offset: 0,
      limit,
    },
  });
  const records = response.data.items || [];

  const exportColumns: Array<DownloadXSheetColumn<InventoryDashboardStatusRecordDTO>> = flattenExportColumns(
    columns,
  ).map((column) => ({
    ...column,
    // set the type and value function for each column
    type: column.exportType ? column.exportType : 'string',
    value: (record: InventoryDashboardStatusRecordDTO) => {
      const value =
        typeof column.exportValue === 'function'
          ? column.exportValue(record)
          : record[column.dataIndex as keyof InventoryDashboardStatusRecordDTO];
      return column.exportType === 'number' ? (value as number) : (value as string);
    },
  }));

  return recordsToXSheet(records, exportColumns);
}
