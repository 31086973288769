import React from 'react';

import { Link } from 'react-router-dom';

import { css } from '@emotion/css';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';
import { Button, Divider } from 'antd';
import { theme } from 'theme';

import { FlexSpace } from 'components/FlexSpace';
import { BorderedPropertyItem } from 'components/recipes/equation/BorderedPropertyItem';
import { Typography } from 'components/Typography';

import { formatNumber, pluralize } from 'utils/formatting';
import { routes } from 'utils/routes';

export const SparseReplenishmentEquation = ({
  record,
  showOpOqVariant = false,
  openSparseItemGrid,
}: {
  record: SearchPlanningDTO;
  showOpOqVariant?: boolean;
  openSparseItemGrid?: () => void;
}) => {
  const showMinStockFloorLogic = record.min_stock_floor && record.recommended_min_prefloor < record.min_stock_floor;

  return (
    <div>
      {Math.ceil((record.sparse_months_worth_of_stock ?? 0) * (record.estimated_month_with_demand_qty ?? 0)) >
        record.recommended_min && (
        <>
          <FlexSpace direction="row">
            <BorderedPropertyItem
              label="Total Annual Usage"
              value={`${formatNumber(record.recommended_min)} ${record.unit_of_measure}`}
            />
            <div
              className={css`
                align-self: stretch;
                padding-top: 16px;
              `}
            >
              <Typography type="large">=</Typography>
            </div>
            <BorderedPropertyItem
              label={`Recommended ${showOpOqVariant ? 'Order Point' : 'Min'}`}
              value={`${formatNumber(record.recommended_min)} ${record.unit_of_measure}`}
            />
          </FlexSpace>
          <div
            className={css`
              padding-top: 6px;
            `}
          >
            The sparse {showOpOqVariant ? 'order point' : 'min'} is greater than the past year's summed usage,
            <br />
            therefore the total annual usage is used as the {showOpOqVariant ? 'order point' : 'min'}
          </div>
          <Divider
            className={css`
              padding: 0px;
              margin: 6px;
            `}
          />
        </>
      )}
      <FlexSpace
        className={css`
          align-content: flex-start;
          padding-top: 6px;
        `}
      >
        <BorderedPropertyItem
          label="Months Worth of Stock"
          value={`${formatNumber(record.sparse_months_worth_of_stock ?? 0)} ${pluralize(
            record.sparse_months_worth_of_stock ?? 0,
            'month',
            'months',
          )}`}
          description={
            <Typography type="small" style={{ color: theme.colors.primary[500] }}>
              {`${formatNumber(record.m12_number_of_months_with_demand)} ${pluralize(
                record.m12_number_of_months_with_demand ?? 0,
                'month',
                'months',
              )} with demand`}
              <br />
              {`${formatNumber(record.lead_time)} day lead time`}
            </Typography>
          }
        />
        <div
          className={css`
            align-self: stretch;
            padding-top: 16px;
          `}
        >
          <Typography type="large">×</Typography>
        </div>
        <BorderedPropertyItem
          label="Typical Demand Quantity"
          value={`${formatNumber(record.estimated_month_with_demand_qty ?? 0)} ${record.unit_of_measure}`}
          description={
            <Typography type="small" style={{ color: theme.colors.primary[500] }}>
              The max between the median
              <br />
              and mode monthly usage
            </Typography>
          }
        />
        <div
          className={css`
            align-self: stretch;
            padding-top: 16px;
          `}
        >
          <Typography type="large">
            {Math.ceil((record.sparse_months_worth_of_stock ?? 0) * (record.estimated_month_with_demand_qty ?? 0)) >
            record.recommended_min
              ? '>'
              : '='}
          </Typography>
        </div>
        <BorderedPropertyItem
          label={`Recommended ${showOpOqVariant ? 'Order Point' : 'Min'}`}
          value={`${formatNumber(record.recommended_min_prefloor)} ${record.unit_of_measure}`}
        />
        {showMinStockFloorLogic && (
          <>
            <div
              className={css`
                align-self: stretch;
                padding-top: 16px;
              `}
            >
              <Typography type="large">{`<`}</Typography>
            </div>
            <BorderedPropertyItem
              label="Stock Floor"
              value={`${formatNumber(record.min_stock_floor)} ${record.unit_of_measure}`}
            />
          </>
        )}
      </FlexSpace>
      <div
        className={css`
          padding-top: 6px;
        `}
      >
        {showMinStockFloorLogic && (
          <>
            Because the recommendation is below the stock floor, the stock floor is used instead.
            <br />
          </>
        )}
        Sparse item replenishment looks at the last 12 months of usage
        <br />
        {openSparseItemGrid ? (
          <Button
            onClick={() => {
              openSparseItemGrid();
            }}
            type="link"
            style={{ paddingLeft: 0 }}
          >
            View current sparse item settings
          </Button>
        ) : (
          <>
            To change sparse item recommendations, see the{' '}
            <Link to={`${routes.settings.settings()}#setting=sparse-months-worth-of-stock`}>settings page</Link>.
          </>
        )}
      </div>
    </div>
  );
};
