import React from 'react';

import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';

import { LeadTimeHistoryChart } from 'components/Charts/LeadTimeHistoryChart';
import { ItemLocationSettingsAuditLog } from 'components/recipes/AuditLog/ItemLocationSettingsAuditLog';
import { LeadTimeHistoryTable } from 'components/recipes/LeadTimeHistoryTable';
import { Tabs } from 'components/Tabs';

import { splitIdNameStr } from 'utils/formatting';

import { PlanningBulkUpdateToFields } from './utils';

export function LeadTimeHistoryChartTable({ record }: { record: SearchPlanningDTO }) {
  // primary_supplier is sometimes null or empty in min_max index (e.g seafood-sales).
  // don't show history chart and table if that is the case, as the components require these fields.
  // records without a primary_supplier come as 'No Primary Supplier', so we need to guard against it.
  if (!record.primary_supplier || !record.primary_supplier.includes(':')) {
    return null;
  }

  return (
    <div>
      <Tabs
        tabs={[
          {
            header: `Historical Chart`,
            dataTestId: 'lead-time-history-chart-tab',
            content: (
              <LeadTimeHistoryChart
                currentLeadTime={record.lead_time}
                smartLeadTime={record.smart_lead_time}
                itemId={record.item_uid}
                itemCode={record.item_id}
                supplier={record.primary_supplier}
                location={record.location}
              />
            ),
          },
          {
            header: `Lead Times for Item #${record.item_id}`,
            content: (
              <LeadTimeHistoryTable
                itemId={record.item_uid}
                itemCode={record.item_id}
                supplierId={splitIdNameStr(record.primary_supplier).foreignId}
                locationId={splitIdNameStr(record.location).foreignId}
              />
            ),
          },
          {
            header: 'Lead Times for All Items',
            content: (
              <LeadTimeHistoryTable
                itemId={undefined}
                itemCode={undefined}
                supplierId={splitIdNameStr(record.primary_supplier).foreignId}
                locationId={splitIdNameStr(record.location).foreignId}
              />
            ),
          },
          {
            header: 'Audit Log',
            infoTooltip: `Lead time updates for Item #${record.item_id} at Location #${record.location}`,
            content: (
              <ItemLocationSettingsAuditLog
                itemUid={record.item_uid}
                locationId={splitIdNameStr(record.location).foreignId}
                fields={PlanningBulkUpdateToFields.leadTime}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
