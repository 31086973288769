import React from 'react';

import { css } from '@emotion/css';
import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { Tooltip } from 'antd';

import { TargetLineStatusBadge } from 'components/recipes/TargetLineStatusBadge';

import { captureError } from 'utils/error';
import { formatNumber } from 'utils/formatting';

export interface TooltipRecord {
  netStock: number;
  status: PurchaseTargetLineStatus;
  invMin: number;
  invMax: number;
}

export const TooltipStatusBadge: React.FC<{ record: TooltipRecord }> = ({ record }) => {
  // Returns the designated tooltip text for a status
  const purchaseItemStatusHoverText = () => {
    // Default secondary tooltip text
    let secondaryText = <>Status information is not available</>;

    try {
      const netStock = formatNumber(record.netStock);
      if (record.status === PurchaseTargetLineStatus.Normal) {
        secondaryText = (
          <>
            Net stock is in expected range between {record.invMin} and {record.invMax}
          </>
        );
      } else if (record.status === PurchaseTargetLineStatus.Overstock) {
        secondaryText = (
          <>
            The net stock of {netStock}
            <br />
            exceeds the current max of {formatNumber(record.invMax)}
          </>
        );
      } else if (record.status === PurchaseTargetLineStatus.Nonstock) {
        secondaryText = <>This item is not stockable, and will not be recommended to purchase if not backordered</>;
      } else if (record.status === PurchaseTargetLineStatus.Understock) {
        secondaryText = (
          <>
            The net stock of {netStock} is
            <br />
            below the current min of {formatNumber(record.invMin)}
          </>
        );
      } else if (record.status === PurchaseTargetLineStatus.Backordered) {
        secondaryText = <>This item has an associated backorder</>;
      }
    } catch (e) {
      captureError(new Error(`Attempted to read invalid PurchaseTargetLine: ${e}`));
    }
    return (
      <div>
        <div>
          <strong>{record.status}</strong>
        </div>
        {secondaryText}
      </div>
    );
  };
  return (
    <Tooltip title={purchaseItemStatusHoverText}>
      <TargetLineStatusBadge
        status={record.status}
        className={css`
          cursor: help;
        `}
      />
    </Tooltip>
  );
};
