import React from 'react';

import { EditOutlined, MinusCircleOutlined, PlusOutlined, SubnodeOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { IntegrationType, TenantIntegrationStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantIntegrationDTO } from '@recurrency/core-api-schema/dist/integrations/tenantIntegrationDTO';
import Popover from 'antd/lib/popover';
import { ColumnType } from 'antd/lib/table';

import { Button } from 'components/Button';
import { FilterBarBox } from 'components/FilterBarBox';
import { FlexSpacer } from 'components/FlexSpacer';
import { Select } from 'components/Select';
import { Table } from 'components/Table';

import { sortableStringColumn } from 'utils/tables';

export interface TenantIntegrationsTableProps {
  integrations?: TenantIntegrationDTO[];
  onIntegrationStatusChange: (tenantIntegration: TenantIntegrationDTO, status: TenantIntegrationStatus) => void;
  onEditButtonClick: (tenantIntegration: TenantIntegrationDTO) => void;
  onRemoveButtonClick: (tenantIntegrationId: string) => void;
  onAddIntegrationButtonClick: () => void;
  onConnectButtonClick: (type: IntegrationType) => void;
}

export function TenantIntegrationsTable({
  integrations,
  onIntegrationStatusChange,
  onEditButtonClick,
  onRemoveButtonClick,
  onAddIntegrationButtonClick,
  onConnectButtonClick,
}: TenantIntegrationsTableProps) {
  const columns: ColumnType<TenantIntegrationDTO>[] = [
    sortableStringColumn({
      title: 'Integration ID',
      dataIndex: 'id',
    }),
    sortableStringColumn({
      title: 'Name',
      dataIndex: 'name',
    }),
    sortableStringColumn({
      title: 'Type',
      dataIndex: 'type',
    }),
    sortableStringColumn({
      title: 'Environment',
      dataIndex: 'environment',
    }),
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status: TenantIntegrationStatus, record: TenantIntegrationDTO) => (
        <Select
          className={css`
            width: 100px;
          `}
          value={status}
          onChange={(newStatus) => onIntegrationStatusChange(record, newStatus)}
          options={Object.entries(TenantIntegrationStatus).map(([key, value]) => ({
            label: key,
            value,
          }))}
        />
      ),
    },
    {
      align: 'right',
      render: (record: TenantIntegrationDTO) => {
        const { credentials, name } = record;

        return (
          <div>
            <Popover content={credentials} title={`Credentials ARN: ${name}`} trigger="click">
              <Button>View Credentials ARN</Button>
            </Popover>
            <Button onClick={() => onConnectButtonClick(record.type)}>
              <SubnodeOutlined />
              Connect
            </Button>
            <Button onClick={() => onEditButtonClick(record)}>
              <EditOutlined /> Edit
            </Button>
            <Button danger onClick={() => onRemoveButtonClick(record.id)}>
              <MinusCircleOutlined /> Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <FilterBarBox>
        <FlexSpacer />
        <Button type="primary" onClick={onAddIntegrationButtonClick}>
          <PlusOutlined /> Add Integration
        </Button>
      </FilterBarBox>
      <Table columns={columns} data={integrations || []} rowKey="id" isLoading={!integrations} />
    </>
  );
}
