import React, { useMemo } from 'react';

import { schemas } from '@recurrency/core-api-schema';
import { ItemUnitOfMeasureDTO } from '@recurrency/core-api-schema/dist/v2/items/getItemUoms';

import { MiniTable } from 'components/MiniTable';

import { useCoreApi } from 'hooks/useApi';

import { truthy } from 'utils/boolean';
import { pluralize } from 'utils/formatting';

export function UnitOfMeasuresPanelContent({ itemId }: { itemId: string }) {
  const {
    data: uomsData,
    isLoading,
    error,
  } = useCoreApi(schemas.items.getItemUnitOfMeasuresV2, {
    pathParams: { itemId },
  });

  // sort default by unit size
  const uomList = useMemo(() => (uomsData?.items || []).sort((a, b) => a.unitSize - b.unitSize), [uomsData]);

  return (
    <MiniTable
      data={uomList}
      columns={[
        {
          render: (record) =>
            `${record.unitOfMeasure} = ${pluralize(record.unitSize, 'unit', 'units', true)}${getUOMLabel(record)}`,
        },
      ]}
      isLoading={isLoading}
      error={error}
    />
  );
}

const getUOMLabel = (record: ItemUnitOfMeasureDTO): string => {
  const label = [
    record.isBase ? 'base' : null,
    record.isPurchaseDefault ? 'purchase' : null,
    record.isSaleDefault ? 'sales' : null,
  ]
    .filter(truthy)
    .join(', ');
  return label ? ` (${label})` : '';
};
