import React from 'react';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Space } from 'antd';

import { Button } from 'components/Button/Button';
import { CenteredError } from 'components/Loaders';
import { Table, TableProps } from 'components/Table';

import { UseAsyncTableProps } from './useAsyncTableProps';

interface AsyncTableProps<ItemT = any> extends Omit<TableProps, 'data'> {
  /** hook passed from useTableProps util */
  tableProps: UseAsyncTableProps<ItemT>;
}

interface AsyncCursorPaginationTableProps<T> extends Omit<TableProps, 'data'> {
  tableProps: {
    isLoading: boolean;
    items: T[];
    hasNextPage: boolean;
    goNextPage: () => void;
    hasPrevPage: boolean;
    goPrevPage: () => void;
  };
}

export function AsyncTable<ItemT>({ tableProps, onChange, ...otherProps }: AsyncTableProps<ItemT>) {
  const { isLoading, items, totalCount, error, pageSize, page, setPage, setSortBy, setSortDir } = tableProps;
  if (error) {
    return <CenteredError error={error} />;
  }

  return (
    <Table
      data={items}
      isLoading={isLoading}
      pagination={
        totalCount > pageSize && {
          onChange: (oneIndexedPageNum: number) => setPage(oneIndexedPageNum),
          pageSize,
          simple: true,
          total: totalCount,
          current: page,
        }
      }
      onChange={(_pagination, _filters, sorter, ev) => {
        // !Array.isArray is there to typeguard into one sorter field
        // AsyncTable supports only one sorting field
        if (
          ev.action === 'sort' &&
          setSortBy &&
          setSortDir &&
          !Array.isArray(sorter) &&
          sorter.field &&
          sorter.column?.sorter === true
        ) {
          setPage(1);
          setSortBy(
            // For nested fields, sort by the key of the innermost object
            Array.isArray(sorter.field) ? sorter.field[sorter.field.length - 1]?.toString() : sorter.field?.toString(),
          );
          setSortDir(sorter.order === 'ascend' ? 'asc' : 'desc');
        }
        if (onChange) onChange(_pagination, _filters, sorter, ev);
      }}
      size="small"
      {...otherProps}
    />
  );
}

export function AsyncCursorPaginationTable<T>({ tableProps, ...otherProps }: AsyncCursorPaginationTableProps<T>) {
  const { items, isLoading, hasNextPage, hasPrevPage, goNextPage, goPrevPage } = tableProps;
  return (
    <div>
      <Table data={items} isLoading={isLoading} pagination={false} size="small" {...otherProps} />
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          marginTop: '10px',
          '& > *': {
            margin: '0 5px',
          },
        })}
      >
        <Space>
          <Button icon={<LeftOutlined />} disabled={!hasPrevPage} size="small" type="text" onClick={goPrevPage} />
          <Button icon={<RightOutlined />} disabled={!hasNextPage} size="small" type="text" onClick={goNextPage} />
        </Space>
      </div>
    </div>
  );
}
