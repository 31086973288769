import { schemas } from '@recurrency/core-api-schema';
import { PlanningUsageInheritanceType } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import {
  GetItemUsageInheritanceDTO,
  GetItemUsageInheritanceListDTO,
} from '@recurrency/core-api-schema/dist/items/getItemUsageInheritanceOverride';
import { SearchForecastDTO } from '@recurrency/core-api-schema/dist/search/getSearchForecasts';

import { useCoreApi } from 'hooks/useApi';

import { getTenantSetting } from 'utils/tenantSettings';
import { getNextUsageUpdateDate } from 'utils/usage';

// DemandDataType handles historical, inherited, and forecast types for calculation purposes.
// This is different from ForecastDataType which handles just usage and forecast, for displaying data in the chart.
enum DemandDataType {
  HistoricalInherited = 'historical_inherited',
  Historical = 'historical',
  Forecast = 'forecast',
}

function getSummedDemandData(data: { keys: string[]; vals: number[] }[], dataType: DemandDataType) {
  const obj: Record<string, number> = {};

  data.forEach((hit) => {
    const { keys, vals } = hit;
    keys.forEach((key, i) => {
      obj[key] = typeof obj[key] === 'undefined' ? vals[i] : obj[key] + vals[i];
    });
  });

  if (dataType === DemandDataType.Forecast) {
    return {
      forecast_dates: Object.keys(obj),
      forecast_demand: Object.values(obj),
    };
  }
  if (dataType === DemandDataType.Historical) {
    return {
      historical_dates: Object.keys(obj),
      historical_demand: Object.values(obj),
    };
  }
  return {
    historical_inherited_demand: Object.values(obj),
  };
}

export function getMergedForecastRecords(hits?: SearchForecastDTO[]): SearchForecastDTO | undefined {
  if (!hits || hits.length === 0) return undefined;

  const historicalData = hits.map((hit) => ({ keys: hit.historical_dates, vals: hit.historical_demand }));
  const historicalInheritedData = hits.map((hit) => ({
    keys: hit.historical_dates,
    vals: hit.historical_inherited_demand,
  }));
  const forecastData = hits.map((hit) => ({ keys: hit.forecast_dates, vals: hit.forecast_demand }));
  const summedHistoricalDemand = getSummedDemandData(historicalData, DemandDataType.Historical);
  const summedHistoricalInheritedDemand = getSummedDemandData(
    historicalInheritedData,
    DemandDataType.HistoricalInherited,
  );
  const summedForecastDemand = getSummedDemandData(forecastData, DemandDataType.Forecast);

  return {
    ...hits[0],
    ...summedForecastDemand,
    ...summedHistoricalDemand,
    ...summedHistoricalInheritedDemand,
  };
}

function getAncestorItemCodes(hits?: SearchForecastDTO[]): string[] | undefined {
  if (!hits || hits.length === 0) return undefined;
  const ancestorItemCodes = new Set<string>();

  hits.forEach((hit) => {
    if (hit.ancestor_item_codes) {
      // After chagning data type from string separated by comma to list, we can change this function
      hit.ancestor_item_codes.split(',').forEach((code: string) => ancestorItemCodes.add(code.trim()));
    }
  });

  return Array.from(ancestorItemCodes);
}

export interface ItemInheritanceData {
  isLoading: boolean;
  /** All the usage ancestors of this item that have already been processed, i.e. their usage is added to this item's usage during the last foreacst run, and the graph currently reflects it. */
  appliedAncestorData: string[] | undefined;
  usingRecurrencyInheritance: boolean;
  reloadInheritanceData: () => void;
  /** This is fetched directly from postgres, either from the "recurrency_item_usage_inheritance" table or the "recurrency_item_usage_inheritance_override" table, depending on the tenant's setting. This reflects all the real-time ancestors of this item, some of which may not be processed yet if they were added after the last forecast run. */
  allAncestorData: GetItemUsageInheritanceListDTO;
  /** The set difference allAncestorData \ ancestorData. This is all the ancestors of this item that have not been processed (added to usage/forecasts) yet. */
  pendingAncestorData: string[];
  /** The set difference ancestorData \ allAncestorData. This is all the ancestors of this item that have been removed but are not reflected in the usage yet. */
  pendingRemovedAncestorData: string[];
  /**
    *  Valid inheritance filtering documentation:
      
    *  An ancestor/descendant is valid if it shares the same UOM with its inheritance pair.
    *  We filter for invalid items on allAncestorData to show to the user all the items that will not be inherited. In practice, this should
    *  only be the items in pendingAncestorData, since the backend function for calculating inheritance blocks out items that do not share
    *  the same UOM, thus they will not proceed to ancestorData.
    *
    *  We don't display invalidDescendants because the purpose of displaying descendants is to warn the user to be mindful in purchasing
    *  more units of the ancestor item lest they double-purchase, so simply showing a warning for items with valid descendants is sufficient.
    * 
    *  We also filter out invalid pending ancestors, because they are set to be blocked and not inherited.
  */
  invalidAncestors: GetItemUsageInheritanceDTO[];
  validDescendants: GetItemUsageInheritanceDTO[];
  nextUpdateDays: number;
}

export function useItemInheritanceData(
  forecastRecords: SearchForecastDTO[] | undefined,
  itemUid: string,
): ItemInheritanceData {
  const appliedAncestorData = getAncestorItemCodes(forecastRecords) ?? [];

  const usingRecurrencyInheritance =
    getTenantSetting(TenantSettingKey.FeaturePlanningUsageInheritance) !== PlanningUsageInheritanceType.P21_Substitute;

  const {
    data: inheritanceData,
    isLoading,
    reload: reloadInheritanceData,
  } = useCoreApi(
    usingRecurrencyInheritance ? schemas.items.getItemUsageInheritanceOverrides : schemas.items.getItemUsageInheritance,
    {
      pathParams: {
        itemUid,
      },
    },
  );

  const allAncestorData = {
    totalCount: 0,
    ...inheritanceData,
    items: inheritanceData?.items.filter((item: GetItemUsageInheritanceDTO) => item.type === 'ancestor') || [],
  };

  const allDescendantData = {
    totalCount: 0,
    ...inheritanceData,
    items: inheritanceData?.items.filter((item: GetItemUsageInheritanceDTO) => item.type === 'descendant') || [],
  };

  const invalidAncestors =
    allAncestorData?.items.filter((item: GetItemUsageInheritanceDTO) => item.hasMismatchedBaseUoms) || [];

  const validDescendants =
    allDescendantData?.items.filter((item: GetItemUsageInheritanceDTO) => !item.hasMismatchedBaseUoms) || [];

  const pendingAncestorData =
    allAncestorData?.items
      .filter(
        (item: GetItemUsageInheritanceDTO) =>
          !appliedAncestorData.includes(item.itemCode) && !item.hasMismatchedBaseUoms,
      )
      .map((item: GetItemUsageInheritanceDTO) => item.itemCode) || [];

  const pendingRemovedAncestorData =
    appliedAncestorData.filter(
      (itemCode) => !allAncestorData?.items.some((item: GetItemUsageInheritanceDTO) => item.itemCode === itemCode),
    ) || [];

  const nextUpdateDays = getNextUsageUpdateDate();

  return {
    isLoading,
    usingRecurrencyInheritance,
    appliedAncestorData,
    reloadInheritanceData,
    allAncestorData,
    pendingAncestorData,
    pendingRemovedAncestorData,
    invalidAncestors,
    validDescendants,
    nextUpdateDays,
  };
}
