import React, { useMemo } from 'react';

import { useHistory } from 'react-router';

import { HourglassOutlined, LeftOutlined, LoadingOutlined, RightOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { schemas } from '@recurrency/core-api-schema';
import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { IdNameObj } from '@recurrency/core-api-schema/dist/purchaseOrders/getPurchaseOrderPdfDetails';
import { TransferOrderResponseDTO } from '@recurrency/core-api-schema/dist/transferOrders/createTransferOrder';
import { notification, Steps } from 'antd';
import { AxiosResponse } from 'axios';
import { colors } from 'theme/colors';

import { nonStockStatuses } from 'pages/purchasing/PurchaseTargetsPage/PurchaseTargetLinesPage/utils';
import { TransferSummary } from 'pages/purchasing/PurchaseTargetsPage/types';
import { createTransferOrderBodyFromTransferSummary } from 'pages/purchasing/PurchaseTargetsPage/utils';

import { useCoreApiTableProps } from 'components/AsyncTable/useAsyncTableProps';
import { Button } from 'components/Button';
import { ActionButton } from 'components/Button/ActionButton';
import { AsyncButton } from 'components/Button/AsyncButton';
import { ConditionalWrapper } from 'components/ConditionalWrapper';
import { Container } from 'components/Container';
import { FlexSpace } from 'components/FlexSpace';
import { CenteredLoader } from 'components/Loaders';
import { PageHeader } from 'components/PageHeader';
import { InfoTooltip } from 'components/Tooltip/InfoTooltip';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { coreApiFetch } from 'utils/api';
import { arrUnique } from 'utils/array';
import { ConcurrentPromiseQueue, PromiseQueueState } from 'utils/concurrentPromiseQueue';
import { getErpName } from 'utils/formatting';
import { routes, useHashState } from 'utils/routes';
import { createSubmissionNotification } from 'utils/submissionNotification';
import { track, TrackEvent } from 'utils/track';

import {
  TransferStep,
  TransferTargetLinesHashState,
  TransferTargetSummary,
  UpdatedTransferLine,
} from 'types/hash-state';

import { findMatchingLineFromUpdates, getTransferInputKey, getTransferTargetSummaries } from '../utils';
import { MarkSourceDestinationsAsReviewedButton } from './MarkSourceDestinationsAsReviewedButton';
import { TransferTargetLinesAllLines } from './TransferTargetLinesAllLines';
import { TransferTargetLinesFinalize } from './TransferTargetLinesFinalize';

const MAX_RECORDS = 10_000_000;

export function TransferReplenishmentLinesPage() {
  const { activeTenant } = useGlobalApp();
  const history = useHistory();
  const [hashState, updateHashState] = useHashState<TransferTargetLinesHashState>();
  const {
    step: currentStep = TransferStep.AllLines,
    statuses: currentStatuses = nonStockStatuses,
    transferInputByID = {},
  } = hashState;

  const apiTableProps = useCoreApiTableProps({
    schema: schemas.transferOrders.getTransferTargetLines,
    queryParams: {
      filter: {
        sourceDestinationIds: hashState.selectedTransferTargets?.map(
          (tt) => `${tt.sourceLocationId}|${tt.destinationLocationId}` ?? [],
        ),
        // Load all lines except nonstock, unless nonstock is selected
        statuses: currentStatuses.includes(PurchaseTargetLineStatus.Nonstock)
          ? [...nonStockStatuses, PurchaseTargetLineStatus.Nonstock]
          : nonStockStatuses,
      },
      limit: MAX_RECORDS,
    },
    initialState: {
      sortBy: 'qtyRequired',
      sortDir: 'desc',
    },
  });

  const tableProps = useMemo(() => {
    const newLines = apiTableProps.items?.filter((item) => currentStatuses.includes(item.status));
    return {
      ...apiTableProps,
      items: newLines,
      totalCount: newLines.length,
    };
  }, [apiTableProps, currentStatuses]);

  const goToStep = (step: TransferStep) => {
    updateHashState({ step });
  };

  const sourceLocations = hashState.selectedTransferTargets?.map((tt) => ({
    foreignId: tt.sourceLocationId,
    name: tt.sourceLocationName,
  }));
  const destinationLocations = hashState.selectedTransferTargets?.map((tt) => ({
    foreignId: tt.destinationLocationId,
    name: tt.destinationLocationName,
  }));

  /*
    Helper that renders the location name if there is only one location,
    otherwise it will render the number of locations with a tooltip.
  */
  const renderTitleLocation = (locations: IdNameObj[] | undefined, entityName: string) => {
    const uniqueLocations = arrUnique((locations || []).map((loc) => `${loc.foreignId}: ${loc.name}`));
    return (
      <ConditionalWrapper
        condition={(uniqueLocations?.length || 0) > 1}
        wrapper={(children) => (
          <InfoTooltip
            useInfoIcon
            title={uniqueLocations?.map((locationString, idx) => (
              <div key={idx}>{locationString}</div>
            ))}
          >
            {children}
          </InfoTooltip>
        )}
      >
        <>
          {uniqueLocations && uniqueLocations?.length > 1
            ? `${uniqueLocations.length} ${entityName}`
            : `${uniqueLocations?.[0]}` || ''}
        </>
      </ConditionalWrapper>
    );
  };

  const transferSummaries = getTransferTargetSummaries(
    hashState.transferLineUpdates,
    tableProps?.items,
    hashState.selectedTransferTargets,
  );

  const finalizedTransferSummaries: TransferTargetSummary[] = transferSummaries.map((summary) => ({
    ...summary,
    approved: transferInputByID[getTransferInputKey(summary)]?.approved,
    carrier: transferInputByID[getTransferInputKey(summary)]?.carrier,
  }));

  const updatedTransferLines: UpdatedTransferLine[] = (tableProps?.items || []).map((item) => ({
    ...item,
    originalTransferQty: item.defaultTransferQty,
    ...(findMatchingLineFromUpdates(item, hashState.transferLineUpdates) || []),
  }));

  const handleSubmitTransfers = async () => {
    const submitNotification = createSubmissionNotification({
      entityName: 'TOs',
      expectedWaitSeconds: 60,
      erpType: activeTenant.erpType,
    });

    const concurrentQueue = new ConcurrentPromiseQueue<TransferSummary, AxiosResponse<TransferOrderResponseDTO>>({
      taskIntervalMs: 2_000, // wait 2 seconds between each task so we don't deadlock pending_imports table
      maxConcurrency: 5, // submit 5 POs/TOs at a time
      requests: finalizedTransferSummaries,
      promiseFn: (summary) =>
        coreApiFetch(schemas.transferOrders.createTransferOrder, {
          bodyParams: { ...createTransferOrderBodyFromTransferSummary(summary), resetTransferReviewCycle: true },
        }),
      onStateChange: (queueState) => {
        notification.info({
          key: submitNotification.notificationKey,
          message: 'TOs status',
          description: (
            <FlexSpace
              direction="column"
              gap={8}
              className={css`
                max-height: 50vh;
                overflow-y: auto;
              `}
            >
              {queueState.map((task, idx) => (
                <div key={idx}>
                  <div>
                    TO from Location #{task.request.sourceLocation.foreignId} to Location #
                    {task.request.destinationLocation.foreignId}:
                  </div>

                  {task.state === PromiseQueueState.Queued ? (
                    <span>
                      Queued ... <HourglassOutlined />
                    </span>
                  ) : task.state === PromiseQueueState.Pending ? (
                    <span>
                      Submitting ... <LoadingOutlined />
                    </span>
                  ) : task.state === PromiseQueueState.Fulfilled ? (
                    (() => (
                      <div>
                        Transfer Order #
                        <ActionButton
                          label={`${task.response.data.transferOrderId} `}
                          className={css`
                            display: inline;
                          `}
                          onClick={() =>
                            history.push(
                              task.response.data.transferOrderId
                                ? routes.purchasing.transferOrderDetails(task.response.data.transferOrderId)
                                : routes.purchasing.transferOrderList(),
                            )
                          }
                        />
                        created
                      </div>
                    ))()
                  ) : task.state === PromiseQueueState.Rejected ? (
                    <div>
                      <span
                        className={css`
                          white-space: pre-wrap;
                          color: ${colors.danger[500]};
                        `}
                      >
                        Error: {task.error.message}
                      </span>
                      <div>{task.request.kind} failed submission, try resubmitting again.</div>
                    </div>
                  ) : null}
                </div>
              ))}
            </FlexSpace>
          ),
          duration: 0,
        });
      },
    });

    const results = await concurrentQueue.processQueue();
    const rejectedResults = results.filter((result) => result.state === PromiseQueueState.Rejected);
    const fulfilledResults = results.filter((result) => result.state === PromiseQueueState.Fulfilled);

    for (const result of fulfilledResults) {
      track(TrackEvent.Purchasing_EditTransferOrder_Submit, {
        numLineItems: result.request.totalLines,
        totalCost: result.request.totalCost,
      });
    }

    // only go to transfers page if all POs were successful and user hasn't navigated away
    if (rejectedResults.length === 0) {
      if (history.location.pathname === routes.purchasing.transferTargetLines()) {
        history.push(routes.purchasing.transferReplenishment());
      }
    } else {
      // TODO: keep failed transfers in hash state, so user can fix and retry
    }
  };

  // go back to transfer replenishment page, if required hash state is missing
  if (!sourceLocations || sourceLocations.length === 0) {
    history.push(routes.purchasing.transferReplenishment());
    return <></>;
  }

  if (tableProps.isLoading) {
    return <CenteredLoader />;
  }

  return (
    <Container>
      <PageHeader
        title={
          <>
            Transfers From {renderTitleLocation(sourceLocations, 'Sources')} To{' '}
            {renderTitleLocation(destinationLocations, 'Destinations')}
          </>
        }
        entity={{ kind: `Transfer Replenishment` }}
        headerActions={
          <>
            {hashState.selectedTransferTargets && hashState.selectedTransferTargets?.length > 0 ? (
              <MarkSourceDestinationsAsReviewedButton
                sourceDestinations={
                  hashState.selectedTransferTargets?.map((tt) => ({
                    sourceLocationId: tt.sourceLocationId,
                    destinationLocationId: tt.destinationLocationId,
                  })) || []
                }
              />
            ) : null}
            {currentStep > TransferStep.AllLines && (
              <Button onClick={() => goToStep(currentStep - 1)}>
                <LeftOutlined />
                Previous
              </Button>
            )}
            {currentStep < TransferStep.Finalize && (
              <Button type="primary" onClick={() => goToStep(currentStep + 1)}>
                Next
                <RightOutlined />
              </Button>
            )}
            {currentStep === TransferStep.Finalize && (
              <AsyncButton type="primary" disabled={transferSummaries.length === 0} onClick={handleSubmitTransfers}>
                Send to {getErpName(activeTenant.erpType)}
              </AsyncButton>
            )}
          </>
        }
      />

      <Steps
        className={css`
          margin-bottom: 16px;
        `}
        current={currentStep}
        onChange={(newStep) => updateHashState({ step: newStep })}
      >
        <Steps.Step key={TransferStep.AllLines} title="All Lines" />
        <Steps.Step key={TransferStep.Finalize} title="Finalize" />
      </Steps>

      {currentStep === TransferStep.AllLines && (
        <TransferTargetLinesAllLines
          transferSummaries={transferSummaries}
          isLoading={tableProps.isLoading}
          lines={updatedTransferLines}
        />
      )}
      {currentStep === TransferStep.Finalize && (
        <TransferTargetLinesFinalize
          transferSummaries={finalizedTransferSummaries}
          isLoading={tableProps.isLoading}
          lines={updatedTransferLines}
        />
      )}
    </Container>
  );
}
