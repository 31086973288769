import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import { schemas } from '@recurrency/core-api-schema';
import { ItemAccessoryDTO } from '@recurrency/core-api-schema/dist/items/getItemAccessories';
import { ColumnType } from 'antd/lib/table';
import { TableRowSelection } from 'antd/lib/table/interface';

import { Button } from 'components/Button';
import { CenteredError } from 'components/Loaders';
import { Modal } from 'components/Modal';
import { Table } from 'components/Table';

import { useCoreApi } from 'hooks/useApi';

import { routes } from 'utils/routes';
import { sortableNumberColumn, sortableStringColumn } from 'utils/tables';

import { QuoteLineItemP21 } from 'types/hash-state';
import { IdNameObj } from 'types/legacy-api';

export function AccessoryModal({
  lineItem,
  location,
  onClose,
}: {
  lineItem: QuoteLineItemP21;
  location: IdNameObj;
  onClose: (items?: QuoteLineItemP21[]) => void;
}) {
  const { data, error, isLoading } = useCoreApi(schemas.items.getItemAccessories, {
    pathParams: { itemId: lineItem.foreignId },
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<ItemAccessoryDTO[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: string[], selectedRows: ItemAccessoryDTO[]) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  } as TableRowSelection<ItemAccessoryDTO>;

  const calculateQuantityAvailable = (record: ItemAccessoryDTO) => {
    const availability = record.childItem.locationAvailabilities.filter(
      (locationAvailability) => locationAvailability.locationId === location.foreignId,
    )[0];
    return availability.qtyAvailable / record.childItem.unitOfMeasure.unitSize;
  };

  const columns: ColumnType<ItemAccessoryDTO>[] = [
    sortableStringColumn({
      title: 'ID',
      dataIndex: ['childItem', 'itemId'],
      render: (id: string) => <Link to={routes.sales.itemDetails(id)}>{id}</Link>,
    }),
    sortableStringColumn({
      title: 'Item',
      dataIndex: ['childItem', 'itemDesc'],
    }),
    sortableNumberColumn({
      title: 'Location Stock',
      render: calculateQuantityAvailable,
      defaultSortOrder: 'descend',
      sorter: (a, b): number => calculateQuantityAvailable(a) - calculateQuantityAvailable(b),
    }),
    sortableStringColumn({
      title: 'UOM',
      dataIndex: ['childItem', 'unitOfMeasure', 'name'],
    }),
  ];

  if (error) {
    return (
      <Modal onCancel={() => onClose()}>
        <CenteredError error={error} />
      </Modal>
    );
  }

  return (
    <Modal
      visible
      title={`Accessories for Item #${lineItem.foreignId}`}
      onCancel={() => onClose()}
      footer={
        <>
          <Button onClick={() => onClose()}>Cancel</Button>
          <Button
            disabled={!selectedRows.length}
            onClick={() =>
              onClose(
                selectedRows.map((row) => ({
                  foreignId: row.childItem.itemId,
                  name: row.childItem.itemDesc,
                  // TODO: This quantity should actually be: childQty / childUnitSize * (lineItemWithInfo.quantity* lineItemWithInfo.unitOfMeasure.unitSize / (parentQty * item.parent.unitSize))
                  // This would fail in the case that the user changes the uom and then adds accessories
                  // to fix it we need to get the unit sizes of the current line item uom and the default unit size
                  quantity:
                    (row.childUnitQuantity / row.childItem.unitOfMeasure.unitSize) *
                    ((lineItem.quantity ?? 0) / row.parentUnitQuantity),
                })),
              )
            }
            type="primary"
          >
            Add
          </Button>
        </>
      }
      width={900}
    >
      <Table
        columns={columns}
        data={data?.items ?? []}
        rowKey={(value) => value.childItem.itemId}
        isLoading={isLoading}
        rowSelection={rowSelection}
      />
    </Modal>
  );
}
