import React from 'react';

import { CheckOutlined, CloseOutlined, ReloadOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { PurchaseTargetLineStatus } from '@recurrency/core-api-schema/dist/common/enums';
import { TenantSettingKey } from '@recurrency/core-api-schema/dist/common/tenantSettings';
import { TransferTargetLineDTO } from '@recurrency/core-api-schema/dist/transferOrders/common';
import { Button } from 'antd';
import { SortOrder } from 'antd/lib/table/interface';
import { colors } from 'theme/colors';

import { FlexSpace } from 'components/FlexSpace';
import { ParsedInput } from 'components/Input/ParsedInput';
import { ColumnChooserSection } from 'components/recipes/ColumnChooserSection';
import { NetStockPopover, NetStockRecord } from 'components/recipes/equation/NetStockPopover';
import { RequiredQtyPopover } from 'components/recipes/equation/RequiredQtyPopover';
import { Select } from 'components/Select';
import { Table } from 'components/Table';
import { Tooltip } from 'components/Tooltip';

import { arrUnique } from 'utils/array';
import { truthy } from 'utils/boolean';
import { formatNumber, formatUSD } from 'utils/formatting';
import { routes, useHashState } from 'utils/routes';
import { PersistedColumn, ViewSettingKey } from 'utils/tableAndSidePaneSettings/types';
import { useUserViewSettingsState } from 'utils/tableAndSidePaneSettings/useUserViewSettingsState';
import { asKeyOf, sortDirections } from 'utils/tables';
import { getTenantSetting } from 'utils/tenantSettings';
import { floatOrDefault } from 'utils/units';

import { TransferTargetLinesHashState, UpdatedTransferLine } from 'types/hash-state';

import { TooltipStatusBadge } from '../../common/TooltipStatusBadge';
import {
  findMatchingLineFromUpdates,
  getSelectedUomAndUnitSize,
  getTransferQty,
  TransferTargetLineUom,
} from '../utils';

type TransferTargetLinesTableProps = {
  isLoading: boolean;
  isReadOnly?: boolean;
  focusedLine?: TransferTargetLineDTO;
  setFocusedLine: (line: TransferTargetLineDTO) => void;
  lines: UpdatedTransferLine[];
  setSortField: (field: keyof UpdatedTransferLine) => void;
  setSortDir: (dir: SortOrder) => void;
};

export function TransferTargetLinesTable({
  isLoading,
  isReadOnly = false,
  focusedLine,
  setFocusedLine,
  lines,
  setSortField,
  setSortDir,
}: TransferTargetLinesTableProps) {
  const tableColumns: PersistedColumn<TransferTargetLineDTO>[] = [
    {
      title: 'Source Location',
      dataIndex: asKeyOf<TransferTargetLineDTO>('sourceLocationId'),
      width: `200px`,
      fixed: true,
      sorter: true,
      sortDirections,
      settingKey: 'sourceLocationId',
      required: true,
      render: (_: string, record: TransferTargetLineDTO) => (
        <div>
          <div>{record.sourceLocationId}</div>
          <div>{record.sourceLocationName}</div>
        </div>
      ),
    },
    {
      title: 'Destination Location',
      dataIndex: asKeyOf<TransferTargetLineDTO>('destinationLocationId'),
      width: `200px`,
      fixed: true,
      sorter: true,
      sortDirections,
      settingKey: 'destinationLocationId',
      required: true,
      render: (_: string, record: TransferTargetLineDTO) => (
        <div>
          <div>{record.destinationLocationId}</div>
          <div>{record.destinationLocationName}</div>
        </div>
      ),
    },
    {
      title: 'Item',
      dataIndex: asKeyOf<TransferTargetLineDTO>('itemCode'),
      width: `300px`,
      fixed: true,
      sorter: true,
      sortDirections,
      settingKey: 'itemId',
      required: true,
      render: (_: string, record: TransferTargetLineDTO) => (
        <div>
          <span
            className={css`
              position: absolute;
              width: 3px;
              top: 0;
              left: 0;
              bottom: 0;
              background-color: transparent;
            `}
          />
          <a href={routes.purchasing.itemDetails(record.itemCode)} target="_blank" rel="noreferrer">
            {record.itemCode}
          </a>
          <div>{record.itemName}</div>
        </div>
      ),
    },
    {
      title: 'Destination Stockable',
      dataIndex: asKeyOf<TransferTargetLineDTO>('stockable'),
      align: 'center' as const,
      settingKey: 'stockable',
      optional: true,
      render: (value: boolean) => (value ? <CheckOutlined /> : null),
    },
    {
      title: 'Status',
      dataIndex: asKeyOf<TransferTargetLineDTO>('status'),
      settingKey: 'status',
      render: (_status: PurchaseTargetLineStatus, record: TransferTargetLineDTO) => (
        <TooltipStatusBadge
          record={{
            netStock: record.destinationNetStock,
            status: record.status,
            invMin: record.destinationMin,
            invMax: record.destinationMax,
          }}
        />
      ),
    },
    {
      title: 'Source',
      settingKey: 'source',
      children: [
        {
          title: 'Net Stock',
          dataIndex: asKeyOf<TransferTargetLineDTO>('sourceNetStock'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'sourceNetStock',
          render: (netStock: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record);
            const value = (netStock / selectedUnitSize).toFixed(2);
            return (
              <>
                {formatNumber(value)} {selectedUom}
                <NetStockPopover
                  record={
                    {
                      netStock: record.sourceNetStock / selectedUnitSize,
                      qtyOnHand: record.sourceQtyOnHand / selectedUnitSize,
                      qtyAllocated: record.sourceQtyAllocated / selectedUnitSize,
                      qtyOnPurchaseOrder: record.sourceQtyOnPO / selectedUnitSize,
                      qtyInTransit: record.sourceQtyInTransit / selectedUnitSize,
                      qtyInProcess: record.sourceQtyInProcess / selectedUnitSize,
                      qtyBackordered: record.sourceQtyBackordered / selectedUnitSize,
                      qtyReservedDueIn: record.sourceQtyReservedDueIn / selectedUnitSize,
                      qtyInProduction: record.sourceQtyInProduction / selectedUnitSize,
                      qtyForProduction: record.sourceQtyForProduction / selectedUnitSize,
                      qtyOnReleaseSchedule: record.sourceQtyOnReleaseSchedule / selectedUnitSize,
                      qtyOnPendingBackorder: record.sourceQtyOnPendingBackorder / selectedUnitSize,
                      qtyOnBlanketPurchaseOrder: record.sourceQtyOnBlanketPO / selectedUnitSize,
                      qtyOnUnapprovedPurchaseOrder: record.sourceUnapprovedQtyOnPO / selectedUnitSize,
                      unitOfMeasure: selectedUom,
                    } as NetStockRecord
                  }
                />
              </>
            );
          },
        },
        {
          title: 'Quantity Available',
          dataIndex: asKeyOf<TransferTargetLineDTO>('sourceQtyAvailable'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'sourceQtyAvailable',
          render: (qtyAvailable: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record);
            const value = Math.floor(qtyAvailable / selectedUnitSize);
            return `${formatNumber(value)} ${selectedUom}`;
          },
        },
        {
          title: 'Transfer Quantity Available',
          dataIndex: asKeyOf<TransferTargetLineDTO>('sourceTransferQtyAvailable'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'sourceTransferQtyAvailable',
          render: (sourceTransferQtyAvailable: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record);
            const value = Math.ceil(sourceTransferQtyAvailable / selectedUnitSize);
            return (
              <Tooltip title="The amount available to transfer from the source location using the transfer threshold defined in Settings">
                <div>
                  {formatNumber(value)} {selectedUom}
                </div>
              </Tooltip>
            );
          },
        },
      ],
    },
    {
      title: 'Destination',
      settingKey: 'destination',
      children: [
        {
          title: 'Net Stock',
          dataIndex: asKeyOf<TransferTargetLineDTO>('destinationNetStock'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'destinationNetStock',
          render: (netStock: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record);
            const value = (netStock / selectedUnitSize).toFixed(2);
            return (
              <>
                {formatNumber(value)} {selectedUom}
                <NetStockPopover
                  record={
                    {
                      netStock: record.destinationNetStock / selectedUnitSize,
                      qtyOnHand: record.destinationQtyOnHand / selectedUnitSize,
                      qtyAllocated: record.destinationQtyAllocated / selectedUnitSize,
                      qtyOnPurchaseOrder: record.destinationQtyOnPO / selectedUnitSize,
                      qtyInTransit: record.destinationQtyInTransit / selectedUnitSize,
                      qtyInProcess: record.destinationQtyInProcess / selectedUnitSize,
                      qtyBackordered: record.destinationQtyBackordered / selectedUnitSize,
                      qtyReservedDueIn: record.destinationQtyReservedDueIn / selectedUnitSize,
                      qtyInProduction: record.destinationQtyInProduction / selectedUnitSize,
                      qtyForProduction: record.destinationQtyForProduction / selectedUnitSize,
                      qtyOnReleaseSchedule: record.destinationQtyOnReleaseSchedule / selectedUnitSize,
                      qtyOnPendingBackorder: record.destinationQtyOnPendingBackorder / selectedUnitSize,
                      qtyOnBlanketPurchaseOrder: record.destinationQtyOnBlanketPO / selectedUnitSize,
                      qtyOnUnapprovedPurchaseOrder: record.destinationUnapprovedQtyOnPO / selectedUnitSize,
                      unitOfMeasure: selectedUom,
                    } as NetStockRecord
                  }
                />
              </>
            );
          },
        },
        {
          title: 'Quantity Available',
          dataIndex: asKeyOf<TransferTargetLineDTO>('destinationQtyAvailable'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'destinationQtyAvailable',
          render: (qtyAvailable: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record);
            const value = Math.floor(qtyAvailable / selectedUnitSize);
            return `${formatNumber(value)} ${selectedUom}`;
          },
        },
      ],
    },
    {
      title: 'Quantity',
      settingKey: 'quantity',
      children: [
        {
          title: 'Required',
          dataIndex: asKeyOf<TransferTargetLineDTO>('qtyRequired'),
          align: 'right' as const,
          sorter: true,
          sortDirections,
          settingKey: 'qtyRequired',
          render: (qtyRequired: number, record: TransferTargetLineDTO) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record);
            const value = Math.ceil(qtyRequired / selectedUnitSize);
            return (
              <>
                {formatNumber(value)} {selectedUom}
                <RequiredQtyPopover
                  record={{
                    requiredQty: value,
                    destinationMin: record.destinationMin / selectedUnitSize,
                    destinationMax: record.destinationMax / selectedUnitSize,
                    destinationNetStock: record.destinationNetStock / selectedUnitSize,
                    destinationBackOrderQty: record.destinationQtyBackordered / selectedUnitSize,
                    replenishmentMethod: record.destinationReplenishmentMethod,
                    stockable: record.stockable,
                    unitOfMeasure: selectedUom,
                  }}
                />
              </>
            );
          },
        },
        {
          title: 'To Transfer',
          settingKey: 'qtyToTransfer',
          render: (record: UpdatedTransferLine) => {
            const { selectedUnitSize, selectedUom } = getSelectedUomAndUnitSize(record);
            return isReadOnly ? (
              `${getTransferQty(record)} ${selectedUom}`
            ) : (
              <FlexSpace>
                <ParsedInput<number>
                  className={css`
                    min-width: 70px;
                    max-width: 100px;
                  `}
                  size="small"
                  valueParser={(value) => floatOrDefault(value, 0)}
                  value={getTransferQty(record)}
                  triggerChangeOnBlankValue
                  onValueChange={(qtyToTransfer: number) => {
                    const qtyOrMax = allowBackorders
                      ? qtyToTransfer * selectedUnitSize
                      : Math.min(qtyToTransfer * selectedUnitSize, Math.max(record.sourceQtyAvailable, 0));
                    if (qtyToTransfer !== getTransferQty(record)) {
                      updateTransferLine(record, { qtyToTransfer: qtyOrMax });
                    }
                  }}
                />
                <Button
                  size="small"
                  className={css`
                    border-radius: 8px;
                    height: 31px;
                  `}
                  disabled={record.qtyRequired === 0 && getTransferQty(record) === 0}
                  onClick={(e) => {
                    e.stopPropagation();
                    const currentVal = getTransferQty(record);
                    updateTransferLine(record, {
                      qtyToTransfer: currentVal > 0 ? 0 : record.defaultTransferQty,
                    });
                  }}
                >
                  {getTransferQty(record) > 0 ? <CloseOutlined /> : <ReloadOutlined />}
                </Button>
              </FlexSpace>
            );
          },
        },
      ],
    },
    {
      title: 'UOM',
      settingKey: 'uom',
      render: (_: unknown, record: TransferTargetLineDTO) => {
        const { selectedUom } = getSelectedUomAndUnitSize(record);
        return isReadOnly ? (
          selectedUom
        ) : (
          <Select
            className={css`
              width: 120px;
            `}
            onClick={(e) => e.stopPropagation()}
            value={selectedUom}
            onChange={(value) => updateTransferLine(record, { selectedUom: value })}
            options={arrUnique(
              [
                { value: TransferTargetLineUom.Purchasing, label: record.purchasingUom },
                { value: TransferTargetLineUom.Sales, label: record.salesUom },
              ],
              'label',
            )}
          />
        );
      },
    },

    // Optional columns
    {
      title: 'Last 3M Avg. Usage',
      dataIndex: asKeyOf<TransferTargetLineDTO>('destinationAverageUsage3m'),
      settingKey: 'averageUsage3m',
      align: 'right' as const,
      sorter: true,
      sortDirections,
      render: (destinationAverageUsage3m: number) => formatNumber(destinationAverageUsage3m ?? 0, 2),
    },
    {
      title: 'Unit Weight',
      dataIndex: asKeyOf<TransferTargetLineDTO>('unitWeight'),
      align: 'right' as const,
      sorter: true,
      sortDirections,
      settingKey: 'unitWeight',
      optional: true,
      render: (value: number, record: TransferTargetLineDTO) => {
        const { selectedUnitSize } = getSelectedUomAndUnitSize(record);
        return formatNumber(value * selectedUnitSize, 2);
      },
    },
    {
      title: `Extended Weight`,
      align: 'right' as const,
      settingKey: 'extendedWeight',
      optional: true,
      render: (_: unknown, record: TransferTargetLineDTO) => {
        const { selectedUnitSize } = getSelectedUomAndUnitSize(record);
        return formatNumber(record.unitWeight * getTransferQty(record) * selectedUnitSize, 2);
      },
    },
    {
      title: 'Unit Volume',
      dataIndex: asKeyOf<TransferTargetLineDTO>('unitVolume'),
      align: 'right' as const,
      sorter: true,
      sortDirections,
      settingKey: 'unitVolume',
      optional: true,
      render: (value: number, record: TransferTargetLineDTO) => {
        const { selectedUnitSize } = getSelectedUomAndUnitSize(record);
        return formatNumber(value * selectedUnitSize, 2);
      },
    },
    {
      title: `Extended Volume`,
      align: 'right' as const,
      settingKey: 'extendedVolume',
      optional: true,
      render: (_: unknown, record: TransferTargetLineDTO) => {
        const { selectedUnitSize } = getSelectedUomAndUnitSize(record);
        return formatNumber(record.unitVolume * (getTransferQty(record) || 0) * selectedUnitSize, 2);
      },
    },
    {
      title: `Unit Cost`,
      align: 'right' as const,
      dataIndex: 'unitCost',
      sorter: true,
      settingKey: 'unitCost',
      optional: true,
      render: (value: number, record: TransferTargetLineDTO) => {
        const { selectedUnitSize } = getSelectedUomAndUnitSize(record);
        return formatUSD(value * selectedUnitSize, true, 2);
      },
    },
    {
      title: `Extended Cost`,
      align: 'right' as const,
      dataIndex: 'extendedCost',
      sorter: true,
      settingKey: 'extCost',
      optional: true,
      render: (_: null, record: TransferTargetLineDTO) => formatUSD(getTransferQty(record) * record.unitCost, true, 2),
    },
  ].filter(truthy);

  const [visibleColumnKeys, setVisibleColumnKeys] = useUserViewSettingsState(
    ViewSettingKey.TransferTargetLinesTable,
    tableColumns.filter((column) => !column.optional).map((column) => column.settingKey),
  );

  const [hashState, updateHashState] = useHashState<TransferTargetLinesHashState>();
  const { transferLineUpdates } = hashState;

  const allowBackorders = getTenantSetting(TenantSettingKey.FeatureTransferReplenishmentCreateBackorders);

  const updateTransferLine = (record: TransferTargetLineDTO, updates: Partial<UpdatedTransferLine>) => {
    const matchingRecord = findMatchingLineFromUpdates(record, transferLineUpdates);
    updateHashState({
      transferLineUpdates: [
        ...(transferLineUpdates?.filter(
          (line) =>
            !(
              line.itemId === record.itemId &&
              line.sourceLocationId === record.sourceLocationId &&
              line.destinationLocationId === record.destinationLocationId
            ),
        ) || []),
        {
          itemId: record.itemId,
          sourceLocationId: record.sourceLocationId,
          destinationLocationId: record.destinationLocationId,
          qtyToTransfer: updates.qtyToTransfer ?? matchingRecord?.qtyToTransfer ?? undefined,
          selectedUom: updates.selectedUom ?? matchingRecord?.selectedUom ?? TransferTargetLineUom.Purchasing,
        },
      ],
    });
  };

  function focusedRowClassName(record: UpdatedTransferLine, focused: boolean) {
    return ['focusable-row', focused ? 'focused-row' : '', getTransferQty(record) > 0 ? 'purchasing-row' : '']
      .filter(truthy)
      .join(' ');
  }

  return (
    <>
      <ColumnChooserSection
        tableKey={ViewSettingKey.PurchaseTargetLinesTable}
        columns={tableColumns}
        visibleColumnKeys={visibleColumnKeys}
        setVisibleColumnKeys={setVisibleColumnKeys}
      />
      <Table
        data={lines}
        isLoading={isLoading}
        columns={visibleColumnKeys
          .map((key) => tableColumns.find((column) => column.settingKey === key))
          .filter(truthy)}
        className={css`
          tr.ant-table-row.purchasing-row > td {
            background-color: ${colors.primary[100]};
          }
        `}
        rowClassName={(record: UpdatedTransferLine) =>
          focusedRowClassName(
            record,
            focusedLine?.itemId === record.itemId &&
              focusedLine?.sourceLocationId === record.sourceLocationId &&
              focusedLine?.destinationLocationId === record.destinationLocationId,
          )
        }
        scroll={{ x: true }}
        size="small"
        onRow={(record: TransferTargetLineDTO) => ({
          onClick: () => {
            setFocusedLine(record);
          },
        })}
        onChange={(_pagination, _filters, sorter, ev) => {
          if (ev.action === 'sort' && !Array.isArray(sorter)) {
            setSortDir(sorter.order as SortOrder);
            setSortField(sorter.field as keyof UpdatedTransferLine);
          }
        }}
      />
    </>
  );
}
