import React, { useEffect, useState } from 'react';

import { css } from '@emotion/css';
import { TenantFeatureFlag } from '@recurrency/core-api-schema/dist/common/enums';
import { Form } from 'antd';

import { Disposition } from 'pages/orders/quotes/types';

import { AsyncSelect } from 'components/AsyncSelect';
import { useItemSuppliersSelectProps } from 'components/AsyncSelect/useAsyncSelectProps';
import { Button } from 'components/Button';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { Select } from 'components/Select';

import { useGlobalApp } from 'hooks/useGlobalApp';

import { splitIdNameStr, splitIfIdNameStr } from 'utils/formatting';
import { shouldShowFeatureFlag } from 'utils/roleAndTenant';
import { OrderType } from 'utils/track';

import { IdNameObj } from 'types/legacy-api';

import { getDuplicateLineItemsQty, QuoteLineItemP21WithInfo } from '../../quoteUtils';

export function DirectShipModal({
  companyId,
  item,
  items,
  location,
  orderDisposition,
  orderType,
  shipToLocation,
  onCancel,
  onOk,
}: {
  companyId: string;
  item: QuoteLineItemP21WithInfo;
  items?: QuoteLineItemP21WithInfo[];
  location: IdNameObj;
  orderDisposition: string;
  orderType?: OrderType;
  shipToLocation: IdNameObj;
  onCancel: () => void;
  /** undefined means source location is same as ship location */
  onOk: (supplier: IdNameObj | undefined, poCost: number | undefined) => void;
}) {
  const { activeTenant, activeUser } = useGlobalApp();

  const [supplier, setSupplier] = useState<IdNameObj | undefined>(item.supplier);
  const [poCost, setPOCast] = useState<number | undefined>(item.poCost);
  const availabilityByLocationId = item.availabilityInfo || {};
  const isQtyAllocated = shouldShowFeatureFlag(
    activeTenant,
    activeUser,
    TenantFeatureFlag.OrdersQtyAllocatedForDispositionDirectOrSpecial,
  );
  const duplicateLineItemQtyAllocated = getDuplicateLineItemsQty(items, item, location.foreignId, isQtyAllocated);
  const shipLocationAvailability = item.shipLocation?.foreignId
    ? availabilityByLocationId[item.shipLocation?.foreignId]
    : null;
  const shipLocation = shipLocationAvailability
    ? {
        foreignId: shipLocationAvailability.locationId,
        name: shipLocationAvailability.locationName,
      }
    : null;

  const availability = availabilityByLocationId[item.shipLocation?.foreignId || location.foreignId];

  if (
    isQtyAllocated &&
    availability &&
    availability.qtyOnHand &&
    duplicateLineItemQtyAllocated &&
    duplicateLineItemQtyAllocated <= availability.qtyOnHand - (availability.qtyAllocated || 0)
  ) {
    return (
      <Modal
        visible
        title={orderDisposition === Disposition.DirectShip ? `Direct Ship` : `Special Order`}
        onOk={onCancel}
        onCancel={onCancel}
        footer={
          <div>
            <Button onClick={onCancel}>Ok</Button>
          </div>
        }
        width={600}
      >
        <div
          className={css`
            margin-bottom: 20px;
          `}
        >
          {orderDisposition === Disposition.DirectShip
            ? `Sufficient quantity available at the location. Cannot select direct ship.`
            : `Sufficient quantity available at the location. Cannot select special order.`}
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      visible
      title={orderDisposition === Disposition.DirectShip ? `Direct Ship` : `Special Order`}
      onCancel={onCancel}
      onOk={() => onOk(supplier, poCost)}
      width={600}
    >
      <div
        className={css`
          margin-bottom: 20px;
        `}
      >
        {orderDisposition === Disposition.DirectShip
          ? `${
              orderType === OrderType.Quote ? 'Configure item to' : 'Item will'
            } be shipped directly from supplier to customer location. `
          : `${
              orderType === OrderType.Quote ? 'Configure item to' : 'Item will'
            } be shipped from supplier to location. `}
        {orderType === OrderType.Quote &&
          `Note: the line item's disposition will not be saved on the quote. To save the disposition, enter as an order.`}
      </div>
      <SupplierChooser
        companyId={companyId}
        itemInvMastUid={item.algoliaItem?.inv_mast_uid || ''}
        location={shipLocation || location}
        shipToLocation={shipToLocation}
        supplier={supplier}
        disposition={orderDisposition}
        poCost={poCost}
        onSupplierChange={(selectedSupplier: IdNameObj) => {
          if (!selectedSupplier) return;
          const supplierObj = selectedSupplier;
          setSupplier(supplierObj);
        }}
        onPOCostChange={(poCost: number) => {
          if (!poCost || poCost < 0) return;
          setPOCast(poCost);
        }}
      />
    </Modal>
  );
}

function SupplierChooser({
  companyId,
  itemInvMastUid,
  location,
  shipToLocation,
  supplier,
  disposition,
  poCost,
  onSupplierChange,
  onPOCostChange,
}: {
  companyId: string;
  itemInvMastUid: string;
  location: IdNameObj;
  shipToLocation: IdNameObj;
  supplier?: IdNameObj;
  disposition?: string;
  poCost?: number;
  onSupplierChange: (supplier: IdNameObj) => void;
  onPOCostChange: (poCost: number) => void;
}) {
  const suppliersSelectProps = useItemSuppliersSelectProps({
    companyId,
    invMastUid: itemInvMastUid,
    locationId: location.foreignId,
  });

  const placeholderDefault =
    suppliersSelectProps.options.find((s) => s.data?.supplierId && s.data.supplierId === supplier?.foreignId) ||
    (suppliersSelectProps.options.length === 1 && suppliersSelectProps.options[0]) ||
    suppliersSelectProps.options.find((s) => s.data?.isPrimarySupplier);

  useEffect(() => {
    if (!supplier) {
      const defaultSupplier = splitIfIdNameStr(placeholderDefault?.value);
      if (defaultSupplier) {
        onSupplierChange(defaultSupplier);
      }
    }
  });

  return (
    <div
      className={css`
        display: flex;
        flex-direction: column;
        gap: 14px;
      `}
    >
      <div>Supplier:</div>
      <Form.Item
        name="supplier"
        rules={[{ required: true, message: 'Please select a supplier' }]}
        className={css`
          margin: 0;
        `}
      >
        <AsyncSelect
          selectProps={suppliersSelectProps}
          entityPlural="suppliers"
          defaultValue={placeholderDefault?.value}
          onSelect={(_, option) => onSupplierChange(splitIdNameStr(option.value))}
        />
      </Form.Item>
      <div />
      <div>PO Cost:</div>
      <Form.Item name="poCost" rules={[{ required: false }]}>
        <Input
          type="number"
          min={0}
          defaultValue={poCost}
          onChange={(ev) => onPOCostChange(Number(ev.target.value))}
          prefix="$"
        />
      </Form.Item>
      <div>{disposition === 'S' ? `Ship Location:` : `Ship To:`}</div>
      <Form.Item name="shipTo">
        <Select disabled placeholder={`${shipToLocation.foreignId}: ${shipToLocation.name}`} />
      </Form.Item>
    </div>
  );
}
