import { schemas } from '@recurrency/core-api-schema';
import { UpdateItemLocationInfoType } from '@recurrency/core-api-schema/dist/purchasing/postUpdateItemLocationInfo';
import { notification } from 'antd';

import { coreApiFetch } from 'utils/api';
import { showAsyncModal } from 'utils/asyncModal';
import { captureAndShowError } from 'utils/error';
import { splitIdNameStr } from 'utils/formatting';
import { routes } from 'utils/routes';
import { track, TrackEvent } from 'utils/track';

import { FlagItemLocationModal } from './FlagItemLocationModal';
import { MultiModalSelection, getPlanningListSelectionFromFilterSelection } from './utils';

export async function changeRecordFlagStatus(selection: MultiModalSelection, flagged: boolean): Promise<boolean> {
  // confirm with user before applying action
  const confirmData = await showAsyncModal(FlagItemLocationModal, {
    selection,
    flagged,
  });
  if (!confirmData) {
    return false;
  }

  const flagReason = confirmData.comment;

  try {
    if (selection.type === 'filter') {
      selection = await getPlanningListSelectionFromFilterSelection(selection);
    }

    await coreApiFetch(schemas.purchasing.postUpdateItemLocationInfo, {
      pathParams: {
        updateType: UpdateItemLocationInfoType.Flag,
      },
      bodyParams: {
        updates: selection.items.map((record) => ({
          itemUid: record.item_uid,
          locationId: splitIdNameStr(record.location).foreignId,
          flagged,
          flagReason,
        })),
      },
    });

    notification.success({
      message: 'Successfully changed flag status',
      duration: 3,
    });

    // track event so we can pipe from Segment -> {MP, Slack} for notifications
    for (const record of selection.items) {
      const location = splitIdNameStr(record.location);

      track(TrackEvent.DemandPlanning_Record_Flag, {
        itemUid: record.item_uid,
        itemId: record.item_id,
        itemName: record.item_name || '',
        locationId: location.foreignId,
        locationName: location.name,
        demandPredictability: record.predictability_tag,
        demandPattern: record.demand_pattern,
        flagged,
        flagReason,
        recordUrl:
          window.location.origin +
          routes.demandPlanning.planning({
            where: {
              location: [record.location],
            },
            query: record.item_id,
          }),
      });
    }
  } catch (err) {
    captureAndShowError(err, `Unable to change flag status`);
  }

  return true;
}
