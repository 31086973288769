import React from 'react';

import { css } from '@emotion/css';

import { Counter } from 'components/Counter';

interface FailedValidationRulesPanelTitleProps {
  counter?: number;
}

export function FailedValidationRulesPanelTitle({ counter }: FailedValidationRulesPanelTitleProps) {
  if (typeof counter !== 'number') return <>Failed Validation Rules</>;

  return (
    <>
      Failed Validation Rules
      <Counter
        className={css`
          margin-left: 10px;
          font-weight: normal;
        `}
        value={counter}
        selected={counter > 0}
        variant="primary"
      />
    </>
  );
}
