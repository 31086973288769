import React from 'react';

import { css } from '@emotion/css';
import { P21LeadTimeSource } from '@recurrency/core-api-schema/dist/common/enums';
import { SearchPlanningDTO } from '@recurrency/core-api-schema/dist/search/getSearchPlanning';
import { theme } from 'theme';

import { FlexSpace } from 'components/FlexSpace';
import { BorderedPropertyItem } from 'components/recipes/equation/BorderedPropertyItem';
import { Typography } from 'components/Typography';

import { formatNumber } from 'utils/formatting';
import { showTransferBasedSpokeMinMaxes } from 'utils/tenantSettings';

import { formatSafetyStock, safetyStockSourceNames, transferTargetReviewMethodNames } from '../utils';

export const EOQFormulaMinEquation = ({
  record,
  showOpOqVariant = false,
}: {
  record: SearchPlanningDTO;
  showOpOqVariant?: boolean;
}) => {
  const showTransferBasedMinMaxes = showTransferBasedSpokeMinMaxes() && record.is_spoke;
  const showMinStockFloorLogic = record.min_stock_floor && record.recommended_min_prefloor < record.min_stock_floor;

  return (
    <FlexSpace
      direction="column"
      align-items="start"
      className={css`
        align-content: flex-start;
      `}
    >
      <FlexSpace>
        <BorderedPropertyItem
          label="Forecast Over Lead Time"
          value={`${formatNumber(record.lead_time_demand_qty ?? 0)} ${record.unit_of_measure}`}
          description={
            <Typography type="small" style={{ color: theme.colors.primary[500] }}>
              {`Lead Time Demand: ${formatNumber(record.avg_demand)} ${record.unit_of_measure} / day`}
              <br />
              {`Lead Time: ${formatNumber(record.lead_time)} days`}
              <br />
              {`Source: ${record.lead_time_source === P21LeadTimeSource.override ? 'Override' : 'Smart'}`}
            </Typography>
          }
        />
        <div
          className={css`
            align-self: stretch;
            padding-top: 16px;
          `}
        >
          <Typography type="large">+</Typography>
        </div>
        {showTransferBasedMinMaxes ? (
          <BorderedPropertyItem
            label="Transfer Cycle Qty"
            value={`${formatNumber(record.transfer_cycle_stock ?? 0)} ${record.unit_of_measure}`}
            description={
              <Typography type="small" style={{ color: theme.colors.primary[500] }}>
                {`Transfer Cycle Demand: ${formatNumber(record.transfer_cycle_demand ?? 0)} ${
                  record.unit_of_measure
                } / day`}
                <br />
                {`Transfer Cycle Days: ${record?.transfer_cycle_days} days`}
                {record.transfer_review_method && (
                  <>
                    <br />
                    Source: {transferTargetReviewMethodNames[record.transfer_review_method]}
                  </>
                )}
              </Typography>
            }
          />
        ) : (
          <BorderedPropertyItem
            label="Order Cycle Qty"
            value={`${formatNumber(record.order_cycle_demand_qty ?? 0)} ${record.unit_of_measure}`}
            description={
              <Typography type="small" style={{ color: theme.colors.primary[500] }}>
                {`Order Cycle Demand: ${formatNumber(record.daily_avg_forecast_demand_over_order_cycle ?? 0)} ${
                  record.unit_of_measure
                } / day`}
                <br />
                {`Order Cycle: ${formatNumber(record.order_cycle_days)} days`}
              </Typography>
            }
          />
        )}
        <div
          className={css`
            align-self: stretch;
            padding-top: 16px;
          `}
        >
          <Typography type="large">+</Typography>
        </div>
        <BorderedPropertyItem
          label="Safety Stock Qty"
          value={`${formatNumber(record.safety_stock_qty ?? 0)}  ${record.unit_of_measure}`}
          description={
            <Typography type="small" style={{ color: theme.colors.primary[500] }}>
              {`Safety Stock Demand: ${formatNumber(record.avg_demand)} ${record.unit_of_measure} / day`}
              <br />
              Safety Stock: {formatSafetyStock(record.safety_stock_value, record.safety_stock_type)}
              <br />
              Source: {safetyStockSourceNames[record.safety_stock_type]}
            </Typography>
          }
        />
        <div
          className={css`
            align-self: stretch;
            padding-top: 16px;
          `}
        >
          <Typography type="large">=</Typography>
        </div>
        <BorderedPropertyItem
          label={`Recommended ${showOpOqVariant ? 'Order Point' : 'Min'}`}
          value={`${formatNumber(record.recommended_min_prefloor)} ${record.unit_of_measure}`}
        />
        {showMinStockFloorLogic && (
          <>
            <div
              className={css`
                align-self: stretch;
                padding-top: 16px;
              `}
            >
              <Typography type="large">{`<`}</Typography>
            </div>
            <BorderedPropertyItem
              label="Stock Floor"
              value={`${formatNumber(record.min_stock_floor)} ${record.unit_of_measure}`}
            />
          </>
        )}
      </FlexSpace>
      {showMinStockFloorLogic && (
        <>
          Because the recommendation is below the stock floor, the stock floor is used instead.
          <br />
        </>
      )}
    </FlexSpace>
  );
};
